import logocorpoImage from '../Home/images/logo_corpo.png';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT} from '../../variaveis';
import Other from '../ChooseBank/images//bank.jfif'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function BankInformation () {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
 
  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });


    const location = useLocation();
  const selectedBank = location.state?.selectedBank;

  const onSubmit = async (data) => {
    try {
      setIsLoading(true); 
      const resHistory = await fetch(`${ENDPOINT}/accounts`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          accountNickname: data.nickname,
          pixKey: data.pixkey,
        }),
      });
      setIsLoading(false);
  
      if (resHistory.ok) {
        // Buscar novamente as informações da conta para ter certeza que estão atualizadas
        const resAccounts = await fetch(`${ENDPOINT}/accounts`, {
          method: 'GET',
          credentials: 'include',
        });
  
        const bodyAccounts = await resAccounts.json();
        localStorage.setItem('accounts', JSON.stringify(bodyAccounts.accounts || []));
  
        toast.success('Bank account successfully added.');
        setTimeout(() => {
          navigate('/banks');
        }, 3000); 
      } else {
        const errorData = await resHistory.json();
        toast.error(`Failed to add bank account: ${errorData.error || 'An unknown error occurred.'}`);
      }
    } catch (error) {
      toast.error(`Failed to add bank account: ${error.message || 'An unknown error occurred.'}`);
    }
  };
  
  

    const handleBackClick = () => {
      navigate('/banks')
    };
    
       


    return(<>
    
        <div id="headlessui-portal-root">
          <div data-headlessui-portal="">
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
            <div>
              <div
                className="cb-modal"
                data-segmentscreenname="link_bank_account"
                id="headlessui-dialog-:r2:"
                role="dialog"
                aria-modal="true"
                data-headlessui-state="open"
              >
                <div className="modal-container">
                  <div
                    className="overlay opacity-100"
                    data-testid="overlay"
                    id="headlessui-dialog-overlay-:r3:"
                    aria-hidden="true"
                    data-headlessui-state="open"
                  />
                  <div
                    className="modal-content screen opacity-100 scale-100"
                    data-testid="content"
                  >
                    <div className="cb-modal-coolwhip">
                     
                    </div>
                    <div className="h-screen overflow-x-auto">
                      <div className="grid grid-rows-auto-1">
                        <div className="sticky top-0 bg-white z-10">
                          <div className="grid grid-cols-3 py-5 px-9">
                            <div className="flex items-center justify-start">
                              <button data-testid="back-button">
                             
                              </button>
                            </div>
                            <div
                              className="flex items-center justify-center"
                              data-testid="circle-logo"
                            >
                             <img src={logocorpoImage} style={{width:'60px', height:'60px'}}></img>
                            </div>
                            <div className="flex items-center justify-end">
                              <button
                                className="cb-modal-basic-close top-right relative inset-0"
                                data-testid="close-button"
                                style={{color:'black'}}
                                onClick={handleBackClick}
                              >
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 14 14"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-cross pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-cross"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="w-full h-1 bg-black-75">
                            <div
                              aria-valuemax={100}
                              aria-valuemin={0}
                              aria-valuenow={40}
                              className="h-full bg-blue-300 transition-width duration-100"
                              role="progressbar"
                              style={{ width: "40%" }}
                            />
                          </div>
                        </div>
                        <div
                          className="cb-modal-body default relative pt-16 pb-20 font-circular"
                          data-testid="modal-body"
                        >
                          <div>
                            <div
                              className="grid h-full mb-8 text-center justify-center"
                              data-testid="center-horizontal"
                            >
                              <h2 className="mb-2 text-2xl text-black-600 font-circular-bold">
                                Bank account information
                              </h2>
                            </div>
                            <div
                              className="grid h-full justify-center"
                              data-testid="center-horizontal"
                            >
                              <div className="grid w-104 gap-6">
                                <div
                                  className="grid h-full content-center items-center justify-center"
                                  data-testid="center-both"
                                >
                        <img
        alt={selectedBank ? selectedBank.name : "Signature"}
        srcSet={
          selectedBank
            ? `${selectedBank.logo}?w=384&q=75 1x, ${selectedBank.logo}?w=750&q=75 2x`
            : `${Other}?w=384&q=75 1x, ${Other}?w=750&q=75 2x`
        }
        src={selectedBank ? selectedBank.logo : `${Other}?w=384&q=75 1x, ${Other}?w=750&q=75 2x`}
        width={372}
        height={142}
        decoding="async"
        data-nimg={1}
        className="w-32"
        loading="lazy"
        style={{ color: "transparent" }}
      />

                                </div>
                               
                                <form
                                  action=""
                                  className="grid w-104 gap-6"
                                  onSubmit={handleSubmit(onSubmit)}
                                >
                                  <div
                                    className="cb-input floating-label  w-full"
                                    data-testid="input-container"
                                  >
                                    <div className="input-container">
                                      <input
                                        aria-describedby=""
                                        className="input"
                                        placeholder="Nickname"
                                        defaultValue=""
                                        name="nickname"
                                        {...register('nickname', { required: true })}
                                      />
                                      <div className="label-container">
                                        <label
                                          htmlFor="cb-input-nickname"
                                          data-testid="label"
                                        >
                                          
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="cb-input floating-label w-full"
                                    data-testid="input-container"
                                  >
                                    <div className="input-container">
                                      <input
                                        id="cb-input-routingNumber"
                                        aria-labelledby="cb-input-PixKey"
                                        aria-describedby=""
                                        className="input"
                                        data-testid="input-PixKey"
                                        placeholder="Pix key"
                                        defaultValue=""
                                        name="pixkey"
                                        {...register('pixkey', { required: true })}
                                      />
                                      <div className="label-container">
                                        <label
                                          htmlFor="cb-input-PixKey"
                                          data-testid="label"
                                        >
                                          
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                               
                                  <div
                                    className="cb-modal-footer padded center"
                                    data-testid="modal-footer"
                                  >
                                    <button
                                      type="submit"
                                      className="cb-button base primary mt-6 w-64"
                                      disabled={!isValid || isLoading}
                                    >
                                      <span className="content">{isLoading ? "Loading..." : "ADD BANK"}</span>
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
           
            </div>
           
          </div>
         
        </div>
      
        <ToastContainer />
      </>
      )
};
export default BankInformation;