import { memo, SVGProps } from 'react';

const FrameIcon2 = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 22 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M20.3171 1.5625H1.55797C0.698402 1.5625 0 2.27051 0 3.13965V21.8604C0 22.7295 0.698402 23.4375 1.55797 23.4375H20.3171C21.1767 23.4375 21.88 22.7295 21.88 21.8604V3.13965C21.88 2.27051 21.1767 1.5625 20.3171 1.5625ZM6.61284 20.3125H3.36991V9.87305H6.61772V20.3125H6.61284ZM4.99137 8.44727C3.9511 8.44727 3.11106 7.60254 3.11106 6.56738C3.11106 5.53223 3.9511 4.6875 4.99137 4.6875C6.02677 4.6875 6.87169 5.53223 6.87169 6.56738C6.87169 7.60742 6.03165 8.44727 4.99137 8.44727ZM18.7689 20.3125H15.526V15.2344C15.526 14.0234 15.5016 12.4658 13.8411 12.4658C12.1512 12.4658 11.8924 13.7842 11.8924 15.1465V20.3125H8.64944V9.87305H11.7605V11.2988H11.8045C12.2391 10.4785 13.2989 9.61426 14.8764 9.61426C18.1584 9.61426 18.7689 11.7773 18.7689 14.5898V20.3125Z'
      fill='#C7C5D1'
    />
  </svg>
);
const Memo = memo(FrameIcon2);
export { Memo as FrameIcon2 };
