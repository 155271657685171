
import resets from '../Home/_resets.module.css';
import { CarbonMenuIcon } from '../Home/CarbonMenuIcon.js';
import { FrameIcon2 } from '../Home/FrameIcon2.js';
import { FrameIcon } from '../Home/FrameIcon.js';
import classes from './MobileBRLADigitalAbout.module.css';
import { memo, useState } from "react"
import Teresa from './images/luizSemFundo1.jpg'
import Mohammed from './images/matheusSemFundo1.jpg'
import Super from './images/leandroSemFundo1.jpg'
import Lucas from './images/lucasSemFundo1.jpg'
import Hector from './images/hectorRemovebgPreview11.jpg'
import Caio from './images/caioSemFundo1.jpg'
import Eric from './images/image3.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';
import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

declare var grecaptcha: any;

/* @figmaId 152:111 */
function AboutMobile() {
  const { t, i18n} = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };
 
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
      setMenuOpen(!menuOpen);
    };
    const [visibleMemberId, setVisibleMemberId] = useState(null);

    const toggleDescription = (id, e) => {
      e.preventDefault();
      if (visibleMemberId === id) {
        setVisibleMemberId(null);
      } else {
        setVisibleMemberId(id);
      }
    };
  
    const closeProfile = (e) => {
      e.preventDefault();
      setVisibleMemberId(null);
    };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');


    function onSubmit(e) {
      e.preventDefault();
    
      grecaptcha.ready(function() {
        grecaptcha.execute('6LdIKIQmAAAAABNZn-t5ilym4wSfgsAkLuzBtn00', {action: 'submit'}).then(function(token) {
          const data = {
            name: name,
            email: email,
            phone: phone,
            company: company,
            message: message,
            captcha: token
          };
    
          fetch('https://api.brla.digital:4567/v1/contact-us', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          })
          .then(response => {
            if (response.ok) {
              toast.success('Message sent successfully. We will respond soon.', {
                autoClose: 6000
              });
              setTimeout(() => window.location.reload(), 7000);
            } else {
              throw new Error('Failed to send message');
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error('Failed to send message. Please try again.', {
              autoClose: 6000
            });
            setTimeout(() => window.location.reload(), 7000);
          });
        });
      });
    }
  

    

  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
        <div className={classes.navigation}>
        <div className={classes.frame4}>
          <div className={classes.logo}></div>
        </div>
        <div onClick={toggleMenu} className={classes.carbonMenu}>
          <CarbonMenuIcon  style={{height:'50px', width:"50px"}} />
          <ul style={{marginTop:'40px', marginLeft:'0px', marginRight:'0px' , position:'absolute', background:'#008884'}}
          className={`main-navigation d-none d-lg-flex ${
            menuOpen ? 'd-flex' : 'd-none'
          }`}
        >
          {/* Adicione aqui os itens de menu */}
          <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/">
              HOME
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/about-brla-digital">
             {t('menu_about')} BRLA DIGITAL
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/about-brla-token">
             {t('menu_about')} BRLA TOKEN
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/login">
              LOGIN
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="/signup">
             {t('menu_sign')}
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="https://api-documentation.brla.digital" target="_blank">
             {t('menu_api')}
            </a>
            </li>
            <li className="menu-list">
            <a style={{fontWeight:'bolder'}} className="menu-link" href="#contact" target="_blank">
              {t('menu_contato')}
            </a>
            </li>
            
          

           
          {/* ... */}
        </ul>
        <div>
      <img 
        src={brazilFlag} 
        alt="Portuguese" 
        onClick={() => changeLanguage('ptbr')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'ptbr' ? 1 : 0.5, width:'30px', height:'30px', marginRight:'20px' }} 

      />
      <img 
        src={usaFlag} 
        alt="English" 
        onClick={() => changeLanguage('en')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'en' ? 1 : 0.5, width:'30px', height:'30px' }} 
      />
    </div>
        </div>
        
      </div>
      
      <div className={classes.section}>
        <div className={classes.ourVisionIsToBeTheLargestInfra}>
         {t('vision')}
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.frame35}>
          <div className={classes.ourMissionIsToBringProsperityT}>
           {t('mission')}
          </div>
          <div className={classes.bRLADigitalIsATechnologyCompan}>
            {t('mission_bt')}
          </div>
        </div>
      </div>
      <div className={classes.section4}>
        <div className={classes.heading2Team}>{t('team')}</div>

        <a onClick={(e) => toggleDescription(214, e)}
                            href="#team-member-214"
                            className="team-member-card post-214 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">
        <div className={classes.link}>
          <div className={classes.luizSemFundo1}></div>
          <div className={classes.heading3LuizCasteloBranco}>Luiz Castelo Branco</div>
          <div className={classes.heading5}>
            <div className={classes.cTO}>CTO</div>
          </div>
        </div>
        </a>

        <div
                            className="team-member-profile"
                            id="team-member-214"
                            style={{ display: visibleMemberId === 214 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Teresa}
                                         
                                        
                                      />
                                      
                                    <h3>Luiz Castelo Branco</h3>
                                    <h5 style={{marginBottom:'10px'}}>CTO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/luiz-castelo-branco-57369372/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('luizcastelo')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>


                          <a onClick={(e) => toggleDescription(300, e)}
                            href="#team-member-300"
                            className="team-member-card post-301 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">
        <div className={classes.link2}>
          <div className={classes.lucasSemFundo1}></div>
          <div className={classes.heading3LucasGiorgio}>Lucas Giorgio</div>
          <div className={classes.heading52}>
            <div className={classes.cBDO}>CBDO</div>
          </div>
        </div>
</a>
          
        <div
                            className="team-member-profile"
                            id="team-member-300"
                            style={{ display: visibleMemberId === 300 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Lucas}
                                         
                                        
                                      />
                                      
                                    <h3>Lucas Giorgio</h3>
                                    <h5 style={{marginBottom:'10px'}}>CBDO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/lucas-giorgio-rostworowski-70b96b22/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('lucasgiorgio')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>


        <a onClick={(e) => toggleDescription(218, e)}
                            href="#team-member-218"
                            className="team-member-card post-218 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">


        <div className={classes.link3}>
          <div className={classes.hectorRemovebgPreview11}></div>
          <div className={classes.heading3HectorFardin}>Hector Fardin</div>
          <div className={classes.heading53}>
            <div className={classes.cOO}>COO</div>
          </div>
        </div>
        </a>

        <div
                            className="team-member-profile"
                            id="team-member-218"
                            style={{ display: visibleMemberId === 218 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Hector}
                                         
                                        
                                      />
                                      
                                    <h3>Hector Fardin</h3>
                                    <h5 style={{marginBottom:'10px'}}>COO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/hector-selvatice-fardin-8419a357/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('hectorfardin')}.</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

                          <a onClick={(e) => toggleDescription(216, e)}
                            href="#team-member-216"
                            className="team-member-card post-216 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">
        <div className={classes.link4}>
          <div className={classes.matheusSemFundo1}></div>
          <div className={classes.heading3MatheusMoura}>Matheus Moura</div>
          <div className={classes.heading54}>
            <div className={classes.coCEO}>CEO</div>
          </div>
        </div>
        </a>

        <div
                            className="team-member-profile"
                            id="team-member-216"
                            style={{ display: visibleMemberId === 216 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Mohammed}
                                         
                                        
                                      />
                                      
                                    <h3>Matheus Moura</h3>
                                    <h5 style={{marginBottom:'10px'}}>CEO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/matheus-moura-56579769/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('matheusmoura')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>  

                          {/*<a onClick={(e) => toggleDescription(301, e)}
                            href="#team-member-301"
                            className="team-member-card post-301 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores">

        <div className={classes.link5}>
          <div className={classes.leandroSemFundo1}></div>
          <div className={classes.heading3LeandroNoel}>Leandro Noel</div>
          <div className={classes.heading55}>
            <div className={classes.coCEO2}>CSO</div>
          </div>
        </div>
        </a>

        <div
                            className="team-member-profile"
                            id="team-member-301"
                            style={{ display: visibleMemberId === 301 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Super}
                                         
                                        
                                      />
                                      
                                    <h3>Leandro Noel</h3>
                                    <h5 style={{marginBottom:'10px'}}>CSO</h5>
                                    <a
                                      href="https://www.linkedin.com/in/leandro-noel-542b1434/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('leandronoel')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
        </div>*/}

                          <a
                            href="#team-member-302"
                            className="team-member-card post-302 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores"
                            onClick={(e) => toggleDescription(302, e)}
                          >

        <div className={classes.link6}>
          <div className={classes.caioSemFundo1}></div>
          <div className={classes.heading3CaioElYanes}>Caio El Yanes</div>
          <div className={classes.heading6}>
            <div className={classes.productManager}>Product Manager</div>
          </div>
        </div>
</a>

        <div
                            className="team-member-profile"
                            id="team-member-302"
                            style={{ display: visibleMemberId === 302 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Caio}
                                         
                                        
                                      />
                                      
                                    <h3>Caio El Yanes</h3>
                                    <h5 style={{marginBottom:'10px'}}>Product Manager</h5>
                                    <a
                                      href="https://www.linkedin.com/in/caio-el-yanes/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('caioelyanes')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                          <a
                            href="#team-member-320"
                            className="team-member-card post-300 people type-people status-publish has-post-thumbnail hentry people_area-socios-diretores"
                            onClick={(e) => toggleDescription(320, e)}
                          >
        <div className={classes.link7}>
          <div className={classes.heading3EricBastos}>Eric Bastos</div>
          <div className={classes.heading56}>
            <div className={classes.techManager}>Tech Manager</div>
          </div>
          <div className={classes.image3}></div>
        </div>
        </a>

        <div
                            className="team-member-profile"
                            id="team-member-320"
                            style={{ display: visibleMemberId === 320 ? 'block' : 'none', overflowX: 'hidden' }}
                          >
                            <div style={{overflowX: 'hidden'}} className="team-member-profile-holder">
                            
                              <a onClick={(e) => closeProfile(e)} href="#" className="team-member-profile-close">
                                ╳
                              </a>
                              <div  >
                                
                              <div >
                                
                                  <div   >
                                  
      
                                    
                                   
                                    <div style={{display:'block', width:'300px', marginLeft:'20px'}} >
                                         
                                      <img
                                        width={300}
                                        height={300}
                                        src={Eric}
                                         
                                        
                                      />
                                      
                                    <h3>Eric Bastos</h3>
                                    <h5 style={{marginBottom:'10px'}}>Tech Manager</h5>
                                    <a
                                      href="https://www.linkedin.com/in/eric-bastos/"
                                      target="_blank"
                                      className="team-member-linkedin-link"
                                    >
                                      <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
                                      
                                    </a>
                                   <p>{t('ericbastos')}</p>
                                  </div>
                                  </div>
                                 
                                </div>
                              </div>
                            </div>
                          </div>

      </div>
      <div className={classes.section6}>
        <div className={classes.frame14}>
          <div className={classes.contact}>{t('call_contact')}</div>
          <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.inputMobileWidth}>
        <input
          className={classes.divPlaceholder}
          type="text"
          placeholder={t('name')}
          value={name}
          onChange={e => setName(e.target.value)}
        />
      </div>
      <div className={classes.inputMobileWidth2}>
        <input
          className={classes.divPlaceholder2}
          type="tel"
          placeholder={t('phone')}
          value={phone}
          onChange={e => setPhone(e.target.value)}
        />
      </div>
      <div className={classes.inputMobileWidth3}>
        <input
          className={classes.divPlaceholder3}
          type="text"
          placeholder={t('company')}
          value={company}
          onChange={e => setCompany(e.target.value)}
        />
      </div>
      <div className={classes.inputMobileWidth4}>
        <input
          className={classes.divPlaceholder4}
          type="email"
          placeholder={t('email')}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </div>
      <textarea
        className={classes.textarea}
        placeholder={t('message')}
        value={message}
        onChange={e => setMessage(e.target.value)}
      ></textarea>
      <button className={classes.button} type="submit">
        <div className={classes.submit}>{t('submit')}</div>
      </button>
    </form>
          <div className={classes.divHs_cos_wrapper_module_16619}>
            <div className={classes.followBRLAOnSocial}>{t('folow_social')}</div>
            <div className={classes.divSocialGrid}>
            <a href="https://twitter.com/BRLAdigital" target="_blank">
              <div className={classes.frame}>
            
                <FrameIcon className={classes.icon2} />
               
              </div>
              </a>
              <a href="https://www.linkedin.com/company/brla-digital/" target="_blank">
              <div className={classes.frame2}>
              
                <FrameIcon2 className={classes.icon3} />
               
              </div>
              </a>
            </div>
            <div className={classes.eMailInfoBrlaDigital}>E-mail: info@brla.digital</div>
            <div className={classes.phone55113777742}>{t('phone')}: +55 11 3777-0742</div>
            <div className={classes.addressRuaUrussui125Cj84SaoPau}>{t('address')}: Rua Urussui, 125 cj. 84 - São Paulo</div>
          </div>
          <div className={classes.digitalAssetsAreSubjectToANumb}>
           {t('disclaimer')}
          </div>
          <div className={classes._2023BRLADigital}>© 2023 BRLA Digital</div>
          <div className={classes.frame15}>
            <div className={classes.logocoin}></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default AboutMobile;
