import WalletHeader from "../../Components/WalletHeader";
import React, { useEffect, useState } from 'react';
import { ENDPOINT} from '../../variaveis';
import FooterLogged from "../../Components/FooterLogged";
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import HeaderWeb from "../../Components/HeaderWeb";
import FooterWeb from "../../Components/FooterWeb";
function Blog() {
    return (
    <>
    <HeaderWeb/>
    <div className="site" id="page">
            
      <div className="wrapper blue-gradient" id="index-wrapper">
        <div className="container" id="content" tabIndex={-1}>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <div className="row pb-3 pt-3">
            <div className="col-lg-6">
              <h1 style={{color:'white'}} className="h3 text-uppercase text-azul-5 l-spacing-3">News</h1>
            </div>
           
          </div>
          <hr className="border-azul-5 pb-3" />
          {/* Do the left sidebar check and opens the primary div */}
          <main className="site-main news" id="main">
            <div className="row row-cols-md-3">
              <div className="col-md-4 mb-4">
                <article
                  className="post-2094 post type-post status-publish format-standard has-post-thumbnail hentry category-cartas-de-gestao"
                  id="post-2094"
                >
                  <header className="entry-header">
                    <a
                      href="https://safaricapital.com.br/carta-macro-junho-2023/"
                      target=""
                    >
                      <picture
                        className="img-fluid mb-3 wp-post-image"
                        loading="lazy"
                      >
                        <source
                          type="image/webp"
                          srcSet="https://safaricapital.com.br/wp-content/uploads/cartamacro-05-2023.jpg.webp 1024w, https://safaricapital.com.br/wp-content/uploads/cartamacro-05-2023-300x168.jpg.webp 300w, https://safaricapital.com.br/wp-content/uploads/cartamacro-05-2023-768x431.jpg.webp 768w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                        <img
                          width={640}
                          height={359}
                          src="images/cartamacro-05-2023.jpg"
                          alt=""
                          loading="lazy"
                          srcSet="https://safaricapital.com.br/wp-content/uploads/cartamacro-05-2023.jpg 1024w, images/cartamacro-05-2023-300x168.jpg 300w, images/cartamacro-05-2023-768x431.jpg 768w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                      </picture>
                    </a>
                    <div className="content">
                      <p className="category">Crypto News</p>
                      <h2 className="entry-title h5">
                        <a
                          style={{ fontWeight: 600 }}
                          href="https://safaricapital.com.br/carta-macro-junho-2023/"
                          target=""
                          rel="bookmark"
                        >
                          Carta Macro – Junho 2023
                        </a>
                      </h2>
                      <div className="entry-meta small">24/05/2023, </div>
                      {/* .entry-meta */}
                      <a
                        href="https://safaricapital.com.br/carta-macro-junho-2023/"
                        target=""
                      >
                        <span>
                          Saiba mais <i className="fas fa-long-arrow-alt-right" />
                        </span>
                      </a>
                    </div>
                  </header>
                  {/* .entry-header */}
                  <div className="entry-content"></div>
                  {/* .entry-content */}
                  <footer className="entry-footer"></footer>
                  {/* .entry-footer */}
                </article>
                {/* #post-## */}
              </div>
              <div className="col-md-4 mb-4">
                <article
                  className="post-2064 post type-post status-publish format-standard has-post-thumbnail hentry category-cartas-de-gestao"
                  id="post-2064"
                >
                  <header className="entry-header">
                    <a
                      href="https://safaricapital.com.br/carta-trimestral-abril-2023/"
                      target=""
                    >
                      <picture
                        className="img-fluid mb-3 wp-post-image"
                        loading="lazy"
                      >
                        <source
                          type="image/webp"
                          srcSet="https://safaricapital.com.br/wp-content/uploads/carta-trimestral-04-2023.jpg.webp 1024w, https://safaricapital.com.br/wp-content/uploads/carta-trimestral-04-2023-300x168.jpg.webp 300w, https://safaricapital.com.br/wp-content/uploads/carta-trimestral-04-2023-768x431.jpg.webp 768w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                        <img
                          width={640}
                          height={359}
                          src="images/carta-trimestral-04-2023.jpg"
                          alt=""
                          loading="lazy"
                          srcSet="https://safaricapital.com.br/wp-content/uploads/carta-trimestral-04-2023.jpg 1024w, images/carta-trimestral-04-2023-300x168.jpg 300w, images/carta-trimestral-04-2023-768x431.jpg 768w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                      </picture>
                    </a>
                    <div className="content">
                      <p className="category">Crypto News</p>
                      <h2 className="entry-title h5">
                        <a
                          style={{ fontWeight: 600 }}
                          href="https://safaricapital.com.br/carta-trimestral-abril-2023/"
                          target=""
                          rel="bookmark"
                        >
                          Carta Trimestral – Abril 2023
                        </a>
                      </h2>
                      <div className="entry-meta small">10/04/2023, </div>
                      {/* .entry-meta */}
                      <a
                        href="https://safaricapital.com.br/carta-trimestral-abril-2023/"
                        target=""
                      >
                        <span>
                          Saiba mais <i className="fas fa-long-arrow-alt-right" />
                        </span>
                      </a>
                    </div>
                  </header>
                  {/* .entry-header */}
                  <div className="entry-content"></div>
                  {/* .entry-content */}
                  <footer className="entry-footer"></footer>
                  {/* .entry-footer */}
                </article>
                {/* #post-## */}
              </div>
              <div className="col-md-4 mb-4">
                <article
                  className="post-1992 post type-post status-publish format-standard has-post-thumbnail hentry category-cartas-de-gestao"
                  id="post-1992"
                >
                  <header className="entry-header">
                    <a
                      href="https://safaricapital.com.br/carta-macro-janeiro-2023/"
                      target=""
                    >
                      <picture
                        className="img-fluid mb-3 wp-post-image"
                        loading="lazy"
                      >
                        <source
                          type="image/webp"
                          srcSet="https://safaricapital.com.br/wp-content/uploads/cartamacro-01-2023.jpg.webp 1024w, https://safaricapital.com.br/wp-content/uploads/cartamacro-01-2023-300x168.jpg.webp 300w, https://safaricapital.com.br/wp-content/uploads/cartamacro-01-2023-768x431.jpg.webp 768w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                        <img
                          width={640}
                          height={359}
                          src="images/cartamacro-01-2023.jpg"
                          alt=""
                          loading="lazy"
                          srcSet="https://safaricapital.com.br/wp-content/uploads/cartamacro-01-2023.jpg 1024w, images/cartamacro-01-2023-300x168.jpg 300w, images/cartamacro-01-2023-768x431.jpg 768w"
                          sizes="(max-width: 640px) 100vw, 640px"
                        />
                      </picture>
                    </a>
                    <div className="content">
                      <p className="category">Crypto News</p>
                      <h2 className="entry-title h5">
                        <a
                          style={{ fontWeight: 600 }}
                          href="https://safaricapital.com.br/carta-macro-janeiro-2023/"
                          target=""
                          rel="bookmark"
                        >
                          Carta Macro – Janeiro 2023
                        </a>
                      </h2>
                      <div className="entry-meta small">16/01/2023, </div>
                      {/* .entry-meta */}
                      <a
                        href="https://safaricapital.com.br/carta-macro-janeiro-2023/"
                        target=""
                      >
                        <span>
                          Saiba mais <i className="fas fa-long-arrow-alt-right" />
                        </span>
                      </a>
                    </div>
                  </header>
                  {/* .entry-header */}
                  <div className="entry-content"></div>
                  {/* .entry-content */}
                  <footer className="entry-footer"></footer>
                  {/* .entry-footer */}
                </article>
                {/* #post-## */}
              </div>
           
           
       
  
         
            
            </div>
          </main>
    
      
     
        </div>
    
      </div>
     <FooterWeb/>
    </div>
   
   
  
  
    
  </>
  
      )
};
export default Blog;