import FooterLogged from "../../Components/FooterLogged"
import WalletHeader from "../../Components/WalletHeader"

function ApiKeys() {
    return (

<>
  <div id="__next">
    <div className="grid w-full min-h-screen font-circular">
      <WalletHeader/>
      <main className="flex flex-col bg-surface-background">
        <div className="grid flex-1 gap-y-12 pt-12 content-start main-container ">
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl  ">
              <header className="cb-header ">
                <div className="cb-header-head primary">
                  <h1 className="title">Developer</h1>
                  <div className="actions">
                    <a
                      className="cb-button base secondary"
                      href="https://api-documentation.brla.digital/"
                      role="link"
                      target="_blank"
                      type="button"
                    >
                      DEVELOPER DOCS
                      <svg
                        width={18}
                        height={18}
                        viewBox="0 0 18 18"
                        fill="none"
                        stroke="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        className="cb-icon cb-icon-externallink ml-2 pointer-events-none"
                        aria-hidden="true"
                        data-testid="icon-externallink"
                        focusable="false"
                        role="img"
                      >
                        <path
                          d="M7 3H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4M11 1h6m0 0v6m0-6L7 11"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </header>
              <nav className="cb-tabs-nav mb-5 mt-7" data-testid="tabs-nav">
                <a
                  className="cb-tabs-nav-item selected"
                  href="/api-keys"
                >
                  API keys
                </a>
               
                <a
                  className="cb-tabs-nav-item"
                  href="/subscriptions"
                >
                  Subscriptions
                </a>
              </nav>
            </div>
          </div>
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <h1 className="mb-4 flex-1">Sandbox API Key</h1>
              <p className="mb-10 text-sm text-black-400">
                To authenticate your API requests you need to use your API key.
                Below you can generate an API key for your account. Treat your
                API key as you would any other password. Make sure you write
                your API key down once it is generated. The API key is Base64
                encoded and can directly be used in the authentication header of
                the API request. For more information please visit our{" "}
                <a
                  className="whitespace-nowrap"
                  href="#"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  API documentation
                </a>
                .
              </p>
              <div className="mb-10 flex items-center">
                <button
                  className="cb-button base primary"
                  type="button"
                  data-testid="developer-mutation-button"
                >
                  <span>Get new API Key</span>
                </button>
              </div>
              <p className="text-sm text-black-400">
                If you lose your API key, it can't be recovered. You'll need to
                generate a new one.
              </p>
            </div>
          </div>
        </div>
        <FooterLogged/>
      </main>
    </div>
  </div>
  
</>

      )
};
export default ApiKeys