import FooterLogged from "../../Components/FooterLogged";
import SettingsHeader from "../../Components/SettingsHeader";
import React, { useState } from 'react';
import logoImage from '../Home/images/logo.png';
import { useForm } from 'react-hook-form';
import zxcvbn from 'zxcvbn';
import { useNavigate } from 'react-router-dom'
import logoImage2 from '../Home/images/logo3.png';
import { ENDPOINT} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useLocation } from 'react-router-dom';

function NewPassword() {

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prevShowNewPassword) => !prevShowNewPassword);
  };
  

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const validateToken = query.get('token');



  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

  const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
  const [password, setPassword] = useState('');
  const [Currentpassword, setCurrentPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  
const onPasswordConfirmationChange = (e) => {
setPasswordConfirmation(e.target.value);
};

const doPasswordsMatch = () => {
return password === passwordConfirmation;
};

  

  const onPasswordChange = (e) => {
    const newPassword = e.target.value;
    const result = zxcvbn(newPassword);
    setPasswordStrengthScore(result.score);
    setPassword(newPassword);
  };

  const onCurrentPasswordChange = (e) => {
    const currentPassword = e.target.value;
    const result = zxcvbn(currentPassword);
    setCurrentPassword(currentPassword);
  };



  const doesPasswordMeetCriteria = () => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[^a-zA-Z0-9]/.test(password);

    return (
      password.length >= 8 &&
      hasUppercase &&
      hasLowercase &&
      hasNumber &&
      hasSpecialCharacter
    );
  };

  const getPasswordCriteriaColor = () => {
    return doesPasswordMeetCriteria() ? 'black' : 'red';
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrengthScore) {
      case 0:
        return '#ddd';
      case 1:
        return 'red';
      case 2:
        return 'orange';
      case 3:
        return 'yellow';
      case 4:
        return 'green';
      default:
        return '#ddd';
    }
  };

  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();


 const onSubmit = async (data) => {
const response = await fetch(`${ENDPOINT}/reset-password/${validateToken}`, {
method: 'PATCH',
headers: {
  'Content-Type': 'application/json',
},
body: JSON.stringify({
   
  newPassword: data.password,
  newPasswordConfirm: data.confirm_password,
  
}),
});

if (response.status === 200 || response.status === 201) {
  toast.success('Senha alterada com sucesso.');
setShowMessage(true);
setTimeout(() => {
  navigate('/login');
}, 3000);
} else {
let responseData = {};
if (response.headers.get('content-type').includes('application/json')) {
  responseData = await response.json();
}
// Trate erros aqui, caso necessário
toast.error('Error:', responseData);
}
};


return (
<>
  <div id="__next">
    <div className="grid w-full min-h-screen font-circular grid min-h-screen w-full">
    
      <main className="flex flex-col bg-surface-background">
        <div className="grid flex-1 content-start gap-y-12 py-12 main-container">
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              
              <header className="cb-header">
                <div className="cb-header-head primary">
                  <h1 className="title">Alterar senha</h1>
                  <div className="actions" />
                </div>
                <p className="cb-header-desc primary">
                A senha deve ter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.


                </p>
              </header>
              <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div style={{maxWidth:"700px"}} className="cb-card surface_primary shadow_md">
                  <div  className="content grid grid-cols-1">
                   
                    <div className="grid gap-6 pb-6">
                      <div
                        className="cb-input floating-label  icon-right w-full"
                        data-testid="input-container"
                      >
                        <div className="input-container">
                          <input
                            id="new-password"
                            aria-labelledby="cb-input-newPassword"
                            aria-describedby=""
                            className="input"
                            data-testid="input"
                            placeholder="Nova senha"
                            type={showNewPassword ? "text" : "password"}
                            defaultValue=""
                            name="password"
                            {...register('password', { required: true })}
                        onChange={onPasswordChange}
                          />
                          
                          <button
                            type="button"
                            className="cb-icon w-5 h-4 limpar-botao"
                            aria-label="reveal password"
                            onClick={toggleNewPasswordVisibility}
                          />
                          <svg
                            width={16}
                            height={16}
                            viewBox="0 0 23 21"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cb-icon cb-icon-eyeoff pointer-events-none"
                            aria-hidden="true"
                            role="img"
                            focusable="false"
                            data-testid="icon-eyeoff"
                          >
                            <path d="M11.504 4.783a5.23 5.23 0 015.229 5.23c0 .679-.136 1.317-.377 1.913l3.054 3.054a12.358 12.358 0 003.587-4.968c-1.81-4.59-6.275-7.843-11.504-7.843-1.464 0-2.865.261-4.162.732L9.59 5.16a5.075 5.075 0 011.914-.377zM1.046 1.928L3.43 4.313l.481.48A12.345 12.345 0 000 10.014c1.81 4.59 6.275 7.842 11.504 7.842 1.62 0 3.168-.313 4.58-.878l.44.44 3.064 3.053 1.328-1.328L2.374.6 1.046 1.928zm5.783 5.783L8.45 9.332a2.95 2.95 0 00-.084.68 3.133 3.133 0 003.138 3.137c.23 0 .46-.03.68-.083l1.62 1.62c-.7.346-1.474.555-2.3.555a5.23 5.23 0 01-5.23-5.229c0-.826.21-1.6.555-2.3zm4.507-.815l3.295 3.294.02-.167a3.133 3.133 0 00-3.137-3.138l-.178.01z" />
                          </svg>
                        </div>
                      </div>
                      <div
                        className="cb-input floating-label  icon-right w-full"
                        data-testid="input-container"
                      >
                        <div className="input-container">
                          <input
                            id="re-typed-password"
                            aria-labelledby="cb-input-repeatNewPassword"
                            aria-describedby=""
                            className="input"
                            data-testid="input"
                            placeholder="Confirmar nova senha"
                            type={showConfirmPassword ? "text" : "password"} 
                            defaultValue=""
                            name="confirm_password"
                        {...register('confirm_password', { required: true })}
                        onChange={onPasswordConfirmationChange}
                          />
                    <div
                        id="password-strength-bar"
                        style={{
                          backgroundColor: getPasswordStrengthColor(),
                          width: "100%",
                          height: 5,
                          marginTop: 5
                        }}
                      />      
                          <button
                            type="button"
                            className="cb-icon w-5 h-4 limpar-botao"
                            aria-label="reveal password"
                            onClick={toggleConfirmPasswordVisibility}
                            style={{marginTop:'10px'}}
                          />
                          <svg
                            width={16}
                            height={16}
                            viewBox="0 0 23 21"
                            fill="currentColor"
                            xmlns="http://www.w3.org/2000/svg"
                            className="cb-icon cb-icon-eyeoff pointer-events-none"
                            aria-hidden="true"
                            role="img"
                            focusable="false"
                            data-testid="icon-eyeoff"
                            style={{marginTop:'10px'}}
                          >
                            <path d="M11.504 4.783a5.23 5.23 0 015.229 5.23c0 .679-.136 1.317-.377 1.913l3.054 3.054a12.358 12.358 0 003.587-4.968c-1.81-4.59-6.275-7.843-11.504-7.843-1.464 0-2.865.261-4.162.732L9.59 5.16a5.075 5.075 0 011.914-.377zM1.046 1.928L3.43 4.313l.481.48A12.345 12.345 0 000 10.014c1.81 4.59 6.275 7.842 11.504 7.842 1.62 0 3.168-.313 4.58-.878l.44.44 3.064 3.053 1.328-1.328L2.374.6 1.046 1.928zm5.783 5.783L8.45 9.332a2.95 2.95 0 00-.084.68 3.133 3.133 0 003.138 3.137c.23 0 .46-.03.68-.083l1.62 1.62c-.7.346-1.474.555-2.3.555a5.23 5.23 0 01-5.23-5.229c0-.826.21-1.6.555-2.3zm4.507-.815l3.295 3.294.02-.167a3.133 3.133 0 00-3.137-3.138l-.178.01z" />
                          </svg>
                        </div>
                      </div>
                      {!doPasswordsMatch() && (
          <p  className="text-red-500">
            As senhas não são iguais
          </p>
        )}

{!doesPasswordMeetCriteria() && (
          <p  className="text-red-500">
           A senha deve ter pelo menos 8 caracteres, incluindo pelo menos uma letra maiúscula, uma letra minúscula, um número e um caractere especial.
          </p>
        )}
                    </div>
                  </div>
                </div>
                <div className="mt-6 grid grid-cols-1">
                  <div>
                    <button type="button" className="cb-button base text">
                      <span></span>
                    </button>
                  </div>
                  <div className="grid grid-cols-2 justify-end gap-6">
                    
                    <button
                      type="submit"
                      className="cb-button base primary"
                      disabled={!isValid || !doesPasswordMeetCriteria() || !doPasswordsMatch()}
                      style={{maxWidth:'200px'}}
                    >
                      <span className="content">Alterar senha</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
       <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
</>)};
export default NewPassword;
