import { forwardRef, useState, useEffect } from 'react';
const ListaCoinsRecebe = forwardRef((props, ref) => {
    
  const { top, left,position, onTokenRecebeSelect } = props;
    
    
    return(
        <div
        ref={ref}
        data-popper-reference-hidden="false"
        data-popper-escaped="false"
        data-popper-placement="bottom"
        className="np-panel np-panel--open"
        style={{
         
          inset: "0px auto auto 0px",
          zIndex:300000,
          marginTop:top,
          marginLeft:left,
          position:position
         
        }}
      >
        <div className="np-panel__content">
          <ul
            id="tw-calculator-source-select-listbox"
            role="listbox"
            tabIndex={-1}
            className="np-dropdown-menu np-dropdown-menu-desktop np-dropdown-menu-lg p-a-1"
          >
            
            <li className="np-dropdown-header np-text-title-group">
              Todas as moedas
            </li>
            <li
       onClick={(e) => {
        e.stopPropagation();
        props.onTokenRecebeSelect({
          flag: 'currency-flag-brl',
          abbreviation: 'BRL'
        });
      }}
              id="option-tw-calculator-source-select-BRL"
              aria-selected="true"
              role="option"
              tabIndex={-1}
              className={`np-dropdown-item clickable ${
                props?.selectedTokenRecebe?.abbreviation === 'BRL' ? 'active' : ''
              }`}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                    <i className="currency-flag currency-flag-brl" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      BRL
                      <span className="np-text-body-default m-l-1">
                        Real brasileiro
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>
          
         
            <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onTokenRecebeSelect({
                  flag: 'currency-flag-usdt',
                  abbreviation: 'USDT'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                  <img style={{width:"25px", height:'25px', marginRight:'5px'}} src='https://brla.digital/static/media/usdt.png'/>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      USDT
                      <span className="np-text-body-default m-l-1">
                        Dólar Tether
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>


            <li
           
           id="option-tw-calculator-source-select-USD"
           aria-selected="false"
           role="option"
           tabIndex={-1}
           className="np-dropdown-item clickable"
           onClick={(e) => {
             e.stopPropagation();
             props.onTokenRecebeSelect({
               flag: 'currency-flag-usdc',
               abbreviation: 'USDC'
             });
           }}
         >
           <a>
             <div className="d-flex np-option-content">
               <div className="d-flex flex-column">
               <img style={{width:"25px", height:'25px', marginRight:'5px'}} src='https://brla.digital/static/media/usdc.png'/>
               </div>
               <div className="d-flex flex-column justify-content-center">
                 <span className="np-text-body-large">
                   USDC
                   <span className="np-text-body-default m-l-1">
                     USDC Bridged
                   </span>
                 </span>
               </div>
             </div>
           </a>
         </li>



            
            <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onTokenRecebeSelect({
                  flag: 'currency-flag-brla',
                  abbreviation: 'BRLA'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                    <img style={{width:"25px", height:'25px', marginRight:'5px'}} src='https://brla.digital/favicon.png'/>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      BRLA
                      <span className="np-text-body-default m-l-1">
                        Stablecoin do real
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>
         
        
          
          
          </ul>
        </div>
      </div>
    )
});
export default ListaCoinsRecebe;