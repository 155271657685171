/* global BigInt */
import React, { useState, useEffect , useRef} from 'react';
import './entry.5284199d.css';
import './index.9e37107d.css';
import SwapIcon from './turn.04d2bdb8.svg'
import TetherIcon from './0xdac17f958d2ee523a2206206994597c13d831ec7.png'
import UsdcIcon from './usdc_symbol.png'
import EthIcon from './eth-chain.png'
import BITIcon from './11221.png'
import PolygonIcon from './PolygonIcon.svg' // Add Polygon icon
import BRLAIcon from './logo_brla.png'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BRLAContractAbi, USDCContractAbi, USDTContractAbi } from '../../utils/abis';
import BigNumber from 'bignumber.js';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, SWAP_CONTRACT_ADDRESSES, SALTS, USDT_CONTRACT_ADDRESSES} from '../../variaveis';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import logoImage2 from '../Home/images/logo3.png';
import WalletHeader from '../../Components/WalletHeader';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import LinkImage from './link.png'
function Swap() {
  const eventSourceRef = useRef(null); // Cria uma referência para o EventSource
  const [brlaBalance, setBrlaBalance] = useState('');
  const [ApproveValue, setApproveValue] = useState('');
  const [usdcBalance, setUsdcBalance] = useState('');
  const [selectedNetwork, setSelectedNetwork] = useState('Polygon');
  const [networkIcon, setNetworkIcon] = useState(PolygonIcon);
  const [showDropdown, setShowDropdown] = useState(false);
  const [tokenIcon, setTokenIcon] = useState(BITIcon);
const [networkName, setNetworkName] = useState("Polygon");
const [amountUsd, setamountUsd] = useState(0);
const [basePrice, setbasePrice] = useState(0);
const [baseFees, setbaseFees] = useState(0);
const [gasFee, setgasFee] = useState(0);
const [amountBrla, setAmountBrla] = useState(0);
const [amountBrlainput, setAmountBrlainput] = useState('');
const [connectedWallet, setConnectedWallet] = useState(false);
const [walletAddress, setWalletAddress] = useState(null);
const [internalWalletId, setInternalWalletId] = useState('');
const [tokenSwap, settokenSwapBrla] = useState(0);  
const [eventSourceUrl, setEventSourceUrl] = useState(`${ENDPOINT}/swap/quote?amount=1000&chain=Polygon&usdToBrla=0&coin=USDC&walletAddress=${walletAddress}`);
const [sig, setsing] = useState(0);
const [signed, setSigned] = useState(false);
const [swapDirection, setSwapDirection] = useState('BRLA_TO_USDC');
const [tokenValue, setTokenValue] = useState('');
const navigate = useNavigate();
const [isApproved, setIsApproved] = useState(false);
const [quoteError, setQuoteError] = useState(null);

const handleAmountBrlaChange = (event) => {
  const value = event.target.value;
  console.log("valor:", value)

  // Verifica se o valor é um número inteiro ou decimal
  if (/^\d*\.?\d*$/.test(value)) {
    // Atualiza o estado se o valor for válido
    setAmountBrlainput(value);

    setTimeout(() => {

      const token = selectedToken
      const urlValue = value === '' ? 1000 : value;
      setEventSourceUrl(
        `${ENDPOINT}/swap/quote?amount=${Number(urlValue).toFixed(2)}&chain=${networkName}&usdToBrla=${
          swapDirection === "USDC_TO_BRLA" ? 1 : 0
        }&coin=${token.symbol}&walletAddress=${walletAddress}`
      );
    }, 1000);
    setSigned(false);
  }
};




const tokens = [
  {
    name: 'USD Coin',
    symbol: 'USDC',
    icon: UsdcIcon,
    address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174', // Replace with the actual address
  },
  {
    name: 'Tether USD',
    symbol: 'USDT',
    icon: TetherIcon,
    address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F', // Replace with the actual address
  },
];


const [selectedToken, setSelectedToken] = useState(tokens[0]); // Inicialmente, o token selecionado é o primeiro da lista




const [firstQuoteReceived, setFirstQuoteReceived] = useState(false);
const [disableSignButton, setDisableSignButton] = useState(true);
useEffect(() => {
  // Fecha o EventSource anterior se existir
  if (eventSourceRef.current) {
    console.log('Closing EventSource');
    eventSourceRef.current.close();
    eventSourceRef.current = null;
  }

  // Cria um novo EventSource
  const es = new EventSource(eventSourceUrl, {
    withCredentials: true
  });

  // Atualiza a referência para o novo EventSource
  eventSourceRef.current = es;

  const handleQuoteEvent = (e) => {
    const data = JSON.parse(e.data);
    if (data.error) {
      // Se houver um erro, atualize a mensagem de erro e retorne
      setQuoteError(data.error);
      return;
    }
    setQuoteError(null);
    
    setamountUsd(data.amountUsd);
    setbasePrice(data.basePrice);
    setbaseFees(data.baseFees);
    setgasFee(data.gasFee);
    setAmountBrla(data.amountBrla);
    settokenSwapBrla(data.token);
  
    // Adicione um delay de 0.6 segundos antes de habilitar o botão de assinatura
    setTimeout(() => {
      setDisableSignButton(false);
  
      // Desabilitar o botão de assinatura após 4 segundos
      setTimeout(() => {
        setDisableSignButton(true);
      }, 3400);
    }, 600);
  };
  
  es.addEventListener('quote', handleQuoteEvent);

  // Limpeza ao desmontar o componente
  return () => {
    // Fecha o EventSource ao desmontar o componente
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
      eventSourceRef.current = null;
    }
    es.removeEventListener('quote', handleQuoteEvent);
  };
}, [eventSourceUrl]); // Adicione eventSourceUrl como uma dependência




useEffect(() => {
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // O usuário mudou para uma nova aba. Feche a conexão EventSource.
      if (eventSourceRef.current) {
        console.log('Closing EventSource');
        eventSourceRef.current.close();
        eventSourceRef.current = null;
      }
    }
  };

  // Adicione o event listener quando o componente é montado
  document.addEventListener('visibilitychange', handleVisibilityChange);

  // Remova o event listener quando o componente é desmontado
  return () => {
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}, []);


const handleNetworkChange = (network) => {
  setSelectedNetwork(network);
  setNetworkIcon(network === 'Ethereum' ? EthIcon : PolygonIcon);
  setTokenIcon(network === 'Ethereum' ? BITIcon : UsdcIcon);
  setNetworkName(network);
  setShowDropdown(false);
  setSigned(false);
  const token = selectedToken
  // Update the eventSourceUrl based on the selected network
  setEventSourceUrl(
    `https://api.brla.digital:4567/v1/swap/quote?amount=${amountBrlainput}&chain=${network}&usdToBrla=${
      swapDirection === "USDC_TO_BRLA" ? 1 : 0
    }&coin=${token.symbol}&walletAddress=${walletAddress}`
  );
  
};

const toggleDropdown = () => {
  console.log("teste")
  setShowDropdown(!showDropdown);
};



const handleConnectClick = async () => {
  console.log("teste");
  if (window.ethereum) {
    try {
      const web3 = new Web3(window.ethereum);
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      

      const walletAddress = accounts[0];
      const chainId = await web3.eth.getChainId();

      
      const getChainName = (chainId) => {
          if ([1, 11155111].includes(chainId)) {
            return 'Ethereum';
          } else if ([137, 80001].includes(chainId)) {
            return 'Polygon';
          } else if ([66, 65].includes(chainId)) {
            return 'OKC';
          }
          return 'Unknown';
        };
  

      const chainName = getChainName(chainId);
      

      setWalletAddress(walletAddress);
      console.log("chain", chainId)
     

      const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[chainId];
      const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[chainId];
      const contractAddressUSDT = USDT_CONTRACT_ADDRESSES[chainId];
      console.log("contract", BRLA_CONTRACT_ADDRESS)
      console.log("contract", USDC_CONTRACT_ADDRESS)

      const BRLAContract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
      const USDCContract = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
      const BRLABalance = await BRLAContract.methods.balanceOf(walletAddress).call();
      const USDCBalance = await USDCContract.methods.balanceOf(walletAddress).call();
      
      const contractUSDT = new web3.eth.Contract(USDTContractAbi, contractAddressUSDT);
      const USDTBalance = await contractUSDT.methods.balanceOf(walletAddress).call();
      const approved_value = await contractUSDT.methods.allowance(accounts[0], SWAP_CONTRACT_ADDRESSES[chainId]).call();
      console.log("qual o approve: ", approved_value)
      console.log("qual o approve int: ", BigInt(approved_value))
      setApproveValue(approved_value);
      setBrlaBalance(BRLABalance);
      console.log("meu selected token é:", selectedToken)
      if (selectedToken.symbol === 'USDT') {
        setUsdcBalance(USDTBalance);
      } else {
        setUsdcBalance(USDCBalance);
      }
      console.log("contract 2", BRLAContract);
    } catch (error) {
      console.error("Error interacting with MetaMask: Try again and be sure you are connected at main polygon");
      toast.error(
        `Error interacting with MetaMask: Try again and be sure you are connected at main polygon`
      );
    }
  } else {
    toast.error("MetaMask is not installed. Please install MetaMask to continue.");
  }
};
      
      

      
      
    
const handleSignClick = async () => {  
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

  const web3 = new Web3(window.ethereum);

  const walletAddress = accounts[0];
  const chainId = await web3.eth.getChainId();
  const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[chainId];
  console.log("contract", BRLA_CONTRACT_ADDRESS)

  const BRLAContract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
  const SECOND = 1000;
  const fromAddress = accounts[0];

      const expiry = Math.trunc((Date.now() + 60 * 60 * SECOND) / SECOND);
      const nonce = await BRLAContract.methods.nonces(accounts[0]).call();
      const spender = SWAP_CONTRACT_ADDRESSES[chainId];
      //const spender = '0x061194fdcaE633Dd298f9CF4395E31F1d5C151b4'       
      const createPermitMessageData = function () {
        const message = {
          "owner": fromAddress,
          "spender": spender,
          "value": (BigInt(Math.round(Number(amountBrlainput) * 100)) * BigInt(10**16)).toString(),
          "nonce": nonce,
          "deadline": expiry
        };

      

        const msgParams = JSON.stringify({
          domain: {
            chainId: chainId,
            name: 'BRLA Token',
            verifyingContract: BRLA_CONTRACT_ADDRESS,
            version: '1',
          },

          message: message,
          primaryType: 'Permit',
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'chainId', type: 'uint256' },
              { name: 'verifyingContract', type: 'address' },
          ],
          Permit: [
            { "name": "owner", "type": "address" },
            { "name": "spender", "type": "address" },
            { "name": "value", "type": "uint256" },
            { "name": "nonce", "type": "uint256" },
            { "name": "deadline", "type": "uint256" }
          ],
        },
      });

      return {
        msgParams,
        message,
      };
    };

    const signData = async function (web3, fromAddress, data) {
        try {
          const result = await web3.request({
            id: 1,
            method: "eth_signTypedData_v4",
            params: [fromAddress, data.msgParams],
            from: fromAddress,
          })

          const r = result.slice(0, 66);
          const s = "0x" + result.slice(66, 130);
          const v = Number("0x" + result.slice(130, 132));
          const msg = data.message
          return { v, r, s, ...msg };

        } catch (error) {
          console.log(error);
          return;
        }
      };

      const messageData = createPermitMessageData();
      const sig = await signData(window.ethereum, fromAddress, messageData);

      console.log(sig);
      if (sig) {
        console.log(sig);
        setsing(sig)
      
        console.log("CHAIN ID", chainId);
        setSigned(true); // This will only be called if sig is not undefined
      }


  
}
 
const handleMaxClick = () => {
  let newAmount;
  if (swapDirection === 'BRLA_TO_USDC') {
    newAmount = Math.floor((brlaBalance / Math.pow(10, 18)) * 100) / 100;
  } else {
    newAmount = Math.floor((usdcBalance / Math.pow(10, 6)) * 100) / 100;
  }
  setAmountBrlainput(newAmount);
  const token = selectedToken

  // Atualiza a URL
  setEventSourceUrl(
    `${ENDPOINT}/swap/quote?amount=${newAmount}&chain=${networkName}&usdToBrla=${
      swapDirection === "USDC_TO_BRLA" ? 1 : 0
    }&coin=${token.symbol}&walletAddress=${walletAddress}`
  );
  setSigned(false);
};



const handleSwapClick = async () => {
  
  if (true) {
    try {
      const response = await fetch(`${ENDPOINT}/swap/place-order`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
         
          walletAddress: walletAddress,
          token: tokenSwap,
          permit: {
              deadline: sig.deadline,
              r: sig.r,
              s: sig.s,
              v: sig.v,
              nonce: parseInt(sig.nonce)
              },
              notifyEmail: true
        }),
      });

      if (response.status === 401) {
        // Redireciona para a página de login se o status for 401
        navigate('/login');
        return;
      }

      if (response.ok) {
        toast.success('Request successfully made. Please wait up to 1 minute for the Swap to be processed.');
        setTimeout(() => {
          navigate('/swap-history');
        }, 3000); 
      } else {
        console.error('Error in server response:', response.status, response.statusText);
        const errorData = await response.json();
        toast.error(`Error: ${errorData.error || 'An unknown error occurred.'}`);
      }
    } catch (error) {
      console.error('Error making request:', error);
      toast.error(`Error making request: ${error.error || 'An unknown error occurred.'}`);
    }
  } else {
    toast.error('MetaMask is not installed. Please install MetaMask to continue.');
  }

}



const handleSignClickInvertido = async () => {  
  const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

  const web3 = new Web3(window.ethereum);

  const walletAddress = accounts[0];
  const chainId = await web3.eth.getChainId();
  
  const SECOND = 1000;
  const fromAddress = accounts[0];

      const expiry = Math.trunc((Date.now() + 60 * 60 * SECOND) / SECOND);
       
      const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[chainId];
      const USDCContract = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
      const nonce = await USDCContract.methods.nonces(accounts[0]).call();;
      //const spender = await USDCContract.methods.operatorWallet().call();
      const spender = SWAP_CONTRACT_ADDRESSES[chainId];       
      const createPermitMessageData = function () {
        const message = {
          "owner": fromAddress,
          "spender": spender,
          "value": (BigInt(Math.round(Number(amountBrlainput) * 100)) * BigInt(10**4)).toString(),
          "nonce": nonce,
          "deadline": expiry
        };

      
        const SALT= SALTS[chainId]
        const msgParams = JSON.stringify({
          domain: {
            salt: SALT,
            name: 'USD Coin (PoS)',
            verifyingContract: USDC_CONTRACT_ADDRESS,
            version: '1',
          },

          message: message,
          primaryType: 'Permit',
          types: {
            EIP712Domain: [
              { name: 'name', type: 'string' },
              { name: 'version', type: 'string' },
              { name: 'verifyingContract', type: 'address' },
              { name: 'salt', type: 'bytes32' },
             
          ],
          Permit: [
            { "name": "owner", "type": "address" },
            { "name": "spender", "type": "address" },
            { "name": "value", "type": "uint256" },
            { "name": "nonce", "type": "uint256" },
            { "name": "deadline", "type": "uint256" }
          ],
        },
      });

      return {
        msgParams,
        message,
      };
    };

    const signData = async function (web3, fromAddress, data) {
        try {
          const result = await web3.request({
            id: 1,
            method: "eth_signTypedData_v4",
            params: [fromAddress, data.msgParams],
            from: fromAddress,
          })

          const r = result.slice(0, 66);
          const s = "0x" + result.slice(66, 130);
          const v = Number("0x" + result.slice(130, 132));
          const msg = data.message
          return { v, r, s, ...msg };

        } catch (error) {
          console.log(error);
          return;
        }
      };

      const messageData = createPermitMessageData();
      const sig = await signData(window.ethereum, fromAddress, messageData);

      if (sig) {
        console.log(sig);
        setsing(sig)
      
        console.log("CHAIN ID", chainId);
        setSigned(true); // This will only be called if sig is not undefined
      }



  
}

const handleSwapDirectionChange = () => {
  const token = selectedToken
  if (swapDirection === 'BRLA_TO_USDC') {
    setSwapDirection('USDC_TO_BRLA');
   
    // Atualize a URL do EventSource
    setEventSourceUrl(
      `${ENDPOINT}/swap/quote?amount=1000&chain=${networkName}&usdToBrla=1&coin=${token.symbol}&walletAddress=${walletAddress}`
    );
  } else {
    setSwapDirection('BRLA_TO_USDC');
    // Atualize a URL do EventSource
    setEventSourceUrl(
      `${ENDPOINT}/swap/quote?amount=1000&chain=${networkName}&usdToBrla=0&coin=${token.symbol}&walletAddress=${walletAddress}`
    );
  };
  setAmountBrlainput('');
};


const [duration, setDuration] = useState(5);
  const [key, setKey] = useState(0);

  useEffect(() => {
    const now = new Date();
    const seconds = now.getSeconds();
    const delay = 5 - (seconds % 5);
    setDuration(delay); // Atualiza a duração da animação

    const timeoutId = setTimeout(() => {
      setKey(prevKey => prevKey + 1); // Incrementa a chave para forçar a re-renderização do componente
    }, delay * 1000); // Converte o atraso para milissegundos

    return () => clearTimeout(timeoutId); // Limpa o timeout ao desmontar o componente
  }, [key]); // Adicione key como uma dependência


  const [isMultipleOfFive, setIsMultipleOfFive] = useState(false);

  useEffect(() => {
    // Inicializar o intervalo quando o componente é montado
    const intervalId = setInterval(() => {
      // A cada segundo, verifica se o segundo atual é múltiplo de 5
      const currentSecond = new Date().getSeconds();
      setIsMultipleOfFive(currentSecond % 5 === 0);
    }, 1000);

    // Limpar o intervalo quando o componente é desmontado
    return () => clearInterval(intervalId);
  }, []); // Dependências vazias significam que este efeito é executado apenas uma vez, na montagem






  const approve = async () => {
    try {
      // Verifique se o Metamask está instalado
      if (window.ethereum) {
        // Configurar o provedor para apontar para a rede Polygon
        
        const web3 = new Web3(window.ethereum);

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        // Endereço do contrato USDT na rede Polygon (substitua pelo endereço real)

        // ABI do contrato USDT (substitua pela ABI real)
        
        // Crie uma nova instância do contrato
        
        // Valor a ser aprovado (em wei)
        const value = "115792089237316195423570985008687907853269984665640564039457584007913109299935";
        const chainId = await web3.eth.getChainId();
        const contractAddress = USDT_CONTRACT_ADDRESSES[chainId];
        const contract = new web3.eth.Contract(USDTContractAbi, contractAddress);
        // Aprovar a transação
        await contract.methods.approve(SWAP_CONTRACT_ADDRESSES[chainId], value).send({ from: accounts[0], maxPriorityFeePerGas: null,
          maxFeePerGas: null }).on('receipt', async function(receipt) {
            await handleConnectClick();
            setIsApproved(true); // Atualiza o estado para true quando a transação de aprovação for bem-sucedida
        });
       
      } else {
        console.log('Metamask não está instalado');
      }
    } catch (error) {
      console.error('Ocorreu um erro ao aprovar a transação', error);
    }
  };


  const [modalIsOpen, setModalIsOpen] = useState(false);

  

  const handleTokenClick = async (token) => {
    setSelectedToken(token); // Atualiza o token selecionado
    console.log("MEU TOKEN é:", token);
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  
        
  
        const walletAddress = accounts[0];
        const chainId = await web3.eth.getChainId();
  
        
        const getChainName = (chainId) => {
            if ([1, 11155111].includes(chainId)) {
              return 'Ethereum';
            } else if ([137, 80001].includes(chainId)) {
              return 'Polygon';
            } else if ([66, 65].includes(chainId)) {
              return 'OKC';
            }
            return 'Unknown';
          };
    
  
        const chainName = getChainName(chainId);
        
  
        setWalletAddress(walletAddress);
        console.log("chain", chainId)
       
        const contractAddressUSDT = USDT_CONTRACT_ADDRESSES[chainId];
        const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[chainId];
        const USDC_CONTRACT_ADDRESS = USDC_CONTRACT_ADDRESSES[chainId];
        console.log("contract", BRLA_CONTRACT_ADDRESS)
        console.log("contract", USDC_CONTRACT_ADDRESS)
  
        const BRLAContract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
        const USDCContract = new web3.eth.Contract(USDCContractAbi, USDC_CONTRACT_ADDRESS);
        const BRLABalance = await BRLAContract.methods.balanceOf(walletAddress).call();
        const USDCBalance = await USDCContract.methods.balanceOf(walletAddress).call();
        
        const contractUSDT = new web3.eth.Contract(USDTContractAbi, contractAddressUSDT);
        const USDTBalance = await contractUSDT.methods.balanceOf(walletAddress).call();
        const approved_value = await contractUSDT.methods.allowance(accounts[0], SWAP_CONTRACT_ADDRESSES[chainId]).call();
        console.log("qual o approve: ", approved_value)
        console.log("qual o approve int: ", BigInt(approved_value))
      setApproveValue(approved_value);
        setBrlaBalance(BRLABalance);
        console.log("meu selected token é:", selectedToken)
        if (token.symbol === 'USDT') {
          setUsdcBalance(USDTBalance);
        } else {
          setUsdcBalance(USDCBalance);
        }
        console.log("contract 2", BRLAContract);
      } catch (error) {
        console.error("Error interacting with MetaMask: Try again and be sure you are connected at main polygon");
        toast.error(
          `Error interacting with MetaMask: Try again and be sure you are connected at main polygon`
        );
      }
    } else {
      toast.error("MetaMask is not installed. Please install MetaMask to continue.");
    }
    setModalIsOpen(false); // Fecha o modal
    // Atualiza a URL do EventSource
    setEventSourceUrl(
      `${ENDPOINT}/swap/quote?amount=1000&chain=${networkName}&usdToBrla=${swapDirection === 'BRLA_TO_USDC' ? 0 : 1}&coin=${token.symbol}&walletAddress=${walletAddress}`
    );
    
  };
  
  const handleClosePopup = () => {
    setModalIsOpen(false); // Fecha o modal
  }


  const handleOpenPopup = () => {
    setModalIsOpen(true); // Fecha o modal
  }


    return(
<>

{modalIsOpen && (
  <div className="wallet-popup-overlay">
    <div className="wallet-popup" style={{position: 'relative'}}>
    <button style={{position: 'absolute', fontSize: '2em'}} className="close-popup" onClick={handleClosePopup}>X</button>
    {tokens.map((token, index) => (
      <a>
       <div key={index} className="token" onClick={() => handleTokenClick(token)}>
          <div key={index} className="token">
            <img src={token.icon} alt={token.name} className="token-icon" />
            <div className="token-info">
              <div className="token-name">
                {token.name} 
                <span className="token-symbol">{token.symbol}</span>
              </div>
              <div className="token-address">
                {token.address.slice(0, 3)}...{token.address.slice(-3)}
                <a href={`https://polygonscan.com/address/${token.address}`} target="_blank" rel="noopener noreferrer">
                  <img src={LinkImage} alt="Link" className="link-icon" />
                </a>
              </div>
            </div>
          </div>
          </div>
          </a>
        ))}
    </div>
  </div>

)}



  <div id="__bybit_uniframe_reg_log_modal__" />
  <div id="__bybit_uniframe_reg_log_modal__" />
  <div id="__nuxt">
  <WalletHeader/>
  <div
              className="flex col-start-2 items-center justify-center"
              data-testid="logo-container"
              style={{ width:'600px', display:'flex', justifyContent:'center', margin:'0 auto'}}
            >
              
              <h1 className="text-base">
                <span className="sr-only">BRLA</span>
                <svg
                  className="sm:hidden"
                  data-testid="svg"
                  viewBox="102 50 910 236"
                  width="8em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      data-testid="linear-gradient"
                      gradientUnits="userSpaceOnUse"
                      id="linearGradient"
                      x1="177.86"
                      x2="341.06"
                      y1="291.18"
                      y2="127.98"
                    >
                      <stop offset={0} stopColor="#b090f5" />
                      <stop offset={1} stopColor="#5fbfff" />
                    </linearGradient>
                    <linearGradient
                      data-testid="linear-gradient-2"
                      gradientUnits="userSpaceOnUse"
                      id="linearGradient2"
                      x1="96.43"
                      x2="259.64"
                      y1="207.75"
                      y2="44.55"
                    >
                      <stop offset={0} stopColor="#68d7fa" />
                      <stop offset={1} stopColor="#4ee498" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M319.43,110.37l-5-8.78a5.14,5.14,0,0,0-8.11-1.08L294.82,112a5.17,5.17,0,0,0-.64,6.51,90.22,90.22,0,0,1,10,20.58l0,0a90.2,90.2,0,0,1-85.45,119,89.38,89.38,0,0,1-42.26-10.49l19.45-19.46a64.41,64.41,0,0,0,80.77-88.29,5.15,5.15,0,0,0-8.29-1.41L256.76,150a5.14,5.14,0,0,0-1.37,4.82l1,4.18a38.63,38.63,0,0,1-56.75,42.39l-5.13-2.94a5.13,5.13,0,0,0-6.2.83l-47.51,47.5a5.15,5.15,0,0,0,.51,7.73l7,5.37a114.86,114.86,0,0,0,70.46,23.88A116,116,0,0,0,319.43,110.37Z"
                    data-testid="icon-path"
                    fill="url(#linearGradient)"
                  />
                  <path
                    d="M289.21,75.82a114.83,114.83,0,0,0-70.46-23.89A116,116,0,0,0,118.06,225.37l5,8.77a5.16,5.16,0,0,0,8.12,1.09l11.48-11.48a5.19,5.19,0,0,0,.64-6.5,89.81,89.81,0,0,1-10-20.58l0,0a90.2,90.2,0,0,1,85.45-119A89.29,89.29,0,0,1,261,88.19l-19.46,19.45a64.39,64.39,0,0,0-87.21,60.23c0,1.07.29,5.95.38,6.79a64.76,64.76,0,0,0,6.07,21.27,5.16,5.16,0,0,0,8.3,1.41l11.64-11.65a5.15,5.15,0,0,0,1.38-4.81l-1-4.19a38.62,38.62,0,0,1,56.75-42.38l5.13,2.94a5.16,5.16,0,0,0,6.2-.83l47.5-47.5a5.16,5.16,0,0,0-.5-7.74Z"
                    data-testid="icon-path"
                    fill="url(#linearGradient2)"
                  />
                  <g
                    data-svg-origin="385.4800109863281 103.79999542236328"
                    data-testid="word-mark"
                    transform="matrix(1,0,0,1,0,0)"
                  >
                    <path
                      d="M484.48,199.84a4.7,4.7,0,0,0-6.09.14c-7.45,5.78-16.09,11.13-28.72,11.13-23.12,0-41.93-19.47-41.93-43.42s18.74-43.59,41.76-43.59c10,0,20.8,4.21,28.74,11.18a4.67,4.67,0,0,0,3.62,1.62,4.08,4.08,0,0,0,3-1.7l7.52-7.74a4.83,4.83,0,0,0,1.47-3.58,4.93,4.93,0,0,0-1.72-3.54C479,108.9,465.81,103.8,449.32,103.8c-35.2,0-63.84,28.82-63.84,64.24a63.94,63.94,0,0,0,63.84,63.89,60.32,60.32,0,0,0,43-17.3,5.32,5.32,0,0,0,1.52-3.85,4.21,4.21,0,0,0-1.36-3Z"
                      data-svg-origin="385.4800109863281 103.80000305175781"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M539.21,105.54H527.82a5,5,0,0,0-4.83,4.85v115a5,5,0,0,0,4.83,4.86h11.39a5,5,0,0,0,4.83-4.86V110.39A5,5,0,0,0,539.21,105.54Z"
                      data-svg-origin="522.989990234375 105.54000091552734"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M674,144.25c0-21.34-17.57-38.71-39.17-38.71H587.86a4.85,4.85,0,0,0-4.83,4.85v115a4.85,4.85,0,0,0,4.83,4.86h11.22a5,5,0,0,0,4.83-4.86V182.62h23.54L650,227.77a4.72,4.72,0,0,0,4.15,2.43h13.46a4.83,4.83,0,0,0,4.27-2.29,5.14,5.14,0,0,0,0-5L649,180.27C664.46,173.4,674,159.74,674,144.25Zm-21.05.35c0,10.83-8.93,20-19.5,20H604.26V125.49h29.15C644,125.49,652.91,134.24,652.91,144.6Z"
                      data-svg-origin="583.0299682617188 105.54000091552734"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M796.59,199.84a4.7,4.7,0,0,0-6.09.14c-7.45,5.78-16.09,11.13-28.72,11.13-23.12,0-41.93-19.47-41.93-43.42s18.73-43.59,41.75-43.59c10,0,20.8,4.21,28.75,11.18A4.65,4.65,0,0,0,794,136.9a4.08,4.08,0,0,0,3-1.7l7.52-7.74a4.82,4.82,0,0,0,1.46-3.58,4.88,4.88,0,0,0-1.71-3.54c-13.1-11.44-26.29-16.54-42.78-16.54-35.2,0-63.84,28.82-63.84,64.24a63.94,63.94,0,0,0,63.84,63.89,60.34,60.34,0,0,0,43-17.3,5.33,5.33,0,0,0,1.52-3.86,4.2,4.2,0,0,0-1.36-3Z"
                      data-svg-origin="697.6499633789062 103.79999542236328"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M901.35,210.94h-45.2V110.39a5,5,0,0,0-4.83-4.85H839.93a4.85,4.85,0,0,0-4.83,4.85v115a4.85,4.85,0,0,0,4.83,4.86h61.42a4.85,4.85,0,0,0,4.84-4.86v-9.55A4.85,4.85,0,0,0,901.35,210.94Z"
                      data-svg-origin="835.0999755859375 105.54000091552734"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M1003.67,125a4.85,4.85,0,0,0,4.83-4.85v-9.73a4.85,4.85,0,0,0-4.83-4.85h-69a4.85,4.85,0,0,0-4.83,4.85v115a4.85,4.85,0,0,0,4.83,4.86h69a4.85,4.85,0,0,0,4.83-4.86v-9.55a4.85,4.85,0,0,0-4.83-4.85h-53V176.71h44.51a4.85,4.85,0,0,0,4.84-4.85v-9.72a5,5,0,0,0-4.84-4.86H950.7V125Z"
                      data-svg-origin="929.8399658203125 105.56999969482422"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                  </g>
                </svg>
              </h1>
            </div>
    
    <div className="web3-body">
      {/*[*/}
      {/*[*/}
      <section
        className="swap flex-row-center flex-items-flex-start"
        data-v-4c83dc7d=""
        style={{marginTop:'-20px'}}
      >
        <section className="sp" data-v-4c83dc7d="" data-v-c9514df9="">
       
          <div
            className="sp-title font-600 flex-row-center flex-items-center"
            data-v-c9514df9=""
          >
            <div className="flex-1 fz20 font-bold" data-v-c9514df9="">
              Swap
            </div>
            {/**/}
            <div
        className="web3-select mr18 custom-web3-select"
        data-v-c9514df9=""
        data-v-8f71a0d3=""
        onClick={toggleDropdown}
        style={{ position: 'relative' }} // Add position: relative
      >
        <div
          className="web3-select-inner el-tooltip__trigger el-tooltip__trigger"
          data-v-3ceacf88=""
        >
          <div className="web3-select-label" data-v-3ceacf88="">
            <div
              className="img-wrap"
              data-v-37c8fb11=""
              data-v-47d017e7=""
              style={{ width: 24, height: 16, paddingBottom: 16 }}
            >
              <div className="el-image" data-v-37c8fb11="">
                <img
                  alt=""
                  src={networkIcon}
                  className="el-image__inner"
                  style={{ objectFit: "cover" }}
                  
                />
                {/*v-if*/}
                {/*v-if*/}
              </div>
            </div>
            <span className="ml4 flex-1 font-medium" data-v-47d017e7="">
              {selectedNetwork}
            </span>
                </div>
                <div className="web3-select-suffix" data-v-3ceacf88="">
                  <i  />
                </div>
              </div>
              {showDropdown && (
          <div
            className="dropdown-menu"
            style={{
              position: 'absolute',
              zIndex: 1000,
              background: '#fff',
              border: '1px solid #ccc',
              display: 'block', // Add display: block
              width: '100%', // Set the width to 100%
            }}
          >
           {/* <div className="dropdown-item" onClick={() => handleNetworkChange('Ethereum')}>
              <img src={EthIcon} alt="Ethereum" />
              Ethereum
          </div>*/}
            <div className="dropdown-item" onClick={() => handleNetworkChange('Polygon')}>
              <img src={PolygonIcon} alt="Polygon" />
              Polygon
            </div>
          </div>
        )}
            </div>
            <img
              src="./Bybit Web3 _ A Trusted Wallet Connecting You to Every Crypto_files/settings.b14ce622.svg"
              alt=""
              className="cursor-pointer"
              data-v-c9514df9=""
            />
          </div>
          <div className="sp-token sp-from mt24" data-v-c9514df9="">
          <div
  className="line-1 flex-item-center flex-row lh18 color-gray fz12"
  data-v-c9514df9=""
>
  <div className="label flex-1 font-400" data-v-c9514df9="">
    Pay
  </div>
  <div className="flex-row-end" data-v-c9514df9="">
    <img
      src="./Bybit Web3 _ A Trusted Wallet Connecting You to Every Crypto_files/balance.c85ed133.svg"
      className="mr4"
      style={{ width: 12 }}
      alt=""
      data-v-c9514df9=""
    />
    <span data-v-47d017e7="">
      {swapDirection === 'BRLA_TO_USDC'
        ? brlaBalance
          ? (brlaBalance / Math.pow(10, 18)).toFixed(2)
          : '--'
        : usdcBalance
        ? (usdcBalance / Math.pow(10, 6)).toFixed(2)
        : '--'}
    </span>
    <span className="swap-amount-token" data-v-47d017e7="">
      {swapDirection === 'BRLA_TO_USDC' ? 'BRLA' : selectedToken.symbol}
    </span>
  </div>
  <span
    style={{ color: "#00dc84" }}
    className="fz12 lh18 font-600 ml4 cursor-pointer"
    data-v-c9514df9=""
    onClick={handleMaxClick}
  >
    Max
  </span>
</div>

            <div className="line-2 flex-row mt8" data-v-c9514df9="">
              <div
                className="flex-row flex-1 flex-items-center cursor-pointer"
                data-v-c9514df9=""
                onClick={swapDirection === 'BRLA_TO_USDC' ? null : handleOpenPopup}
              >
                {/*[*/}
                <div style={{ position: "relative" }} data-v-c9514df9="">
                  <div
                    className="common-token-icon common-token-icon--default"
                    data-v-c9514df9=""
                    data-v-3187a4c3=""
                  >
                    <div
                      className="el-image common-token-icon-token"
                      style={{}}
                      data-v-3187a4c3=""
                    >
                    <img
  src={swapDirection === 'BRLA_TO_USDC' ? BRLAIcon : selectedToken.icon}
  className="el-image__inner"
/>

                      {/*v-if*/}
                      {/*v-if*/}
                    </div>
                  
                  </div>
                </div>
                <div className="ml10" data-v-c9514df9="">
                  <div className="swap-input-token" data-v-47d017e7="">
                  <span data-v-47d017e7="">{swapDirection === 'BRLA_TO_USDC' ? 'BRLA' : selectedToken.symbol}</span>
                    <div className="swap-input-icon" data-v-47d017e7="">
                      <i style={{background:'transparent', color:"transparent"}} className="icon-caret-bottom" data-v-47d017e7="" />
                    </div>
                  </div>
                
                </div>
                {/*]*/}
              </div>
              <div data-v-c9514df9="">
              <div style={{ position: 'relative', width: 'fit-content' }}>
              <input
  className={`sp-token-amountIn fz20 lh36 font-bold class-input ${amountBrlainput > (swapDirection === 'BRLA_TO_USDC' ? (brlaBalance / Math.pow(10, 18)) : (usdcBalance / Math.pow(10, 6))) ? "error-border" : ""}`}
  type="text"
  placeholder={1000.0}
  data-v-c9514df9=""
  onChange={handleAmountBrlaChange}
  value={amountBrlainput}
  disabled={brlaBalance === null || brlaBalance === ""}
/>


  {amountBrlainput > (swapDirection === 'BRLA_TO_USDC' ? (brlaBalance / Math.pow(10, 18)) : (usdcBalance / Math.pow(10, 6))) && (
    <span style={{
      color: "red",
      position: 'absolute',
      right: '-250px',
      top: '10px',
      fontSize: "0.8em"
    }}>
      You do not have enough balance.
    </span>
  )}
</div>


              </div>
            </div>
            <div
              className="line-3 mt8 sp-token-usd visibility"
              data-v-47d017e7=""
            >
              {" "}
             
            </div>
          </div>
          {/**/}
          <div
            className="sp-token-turn flex-row-center flex-items-center cursor-pointer"
            data-v-c9514df9=""
          >
           <img
  src={SwapIcon}
  alt=""
  data-v-c9514df9=""
  onClick={handleSwapDirectionChange}
/>
          </div>
          <div className="sp-token sp-to" data-v-c9514df9="">
          <div
  className="line-1 flex-item-center flex-row lh18 color-gray fz12"
  data-v-c9514df9=""
>
  <div className="label flex-1 font-400" data-v-c9514df9="">
    Receive net
  </div>
  <div className="flex-row-end" data-v-c9514df9="">
    <img
      src="./Bybit Web3 _ A Trusted Wallet Connecting You to Every Crypto_files/balance.c85ed133.svg"
      className="mr4"
      style={{ width: 12 }}
      alt=""
      data-v-c9514df9=""
    />
    <span data-v-47d017e7="">
      {swapDirection === 'BRLA_TO_USDC'
        ? usdcBalance
          ? (usdcBalance / Math.pow(10, 6)).toFixed(2)
          : '--'
        : brlaBalance
        ? (brlaBalance / Math.pow(10, 18)).toFixed(2)
        : '--'}
    </span>
    <span className="swap-amount-token" data-v-47d017e7="">
      {swapDirection === 'BRLA_TO_USDC' ? selectedToken.symbol : 'BRLA'}
    </span>
  </div>
</div>

            <div className="line-2 flex-row mt8" data-v-c9514df9="">
              <div
                className="flex-row flex-1 flex-items-center cursor-pointer"
                data-v-c9514df9=""
                onClick={swapDirection === 'BRLA_TO_USDC' ? handleOpenPopup : null}
              >
                {/*[*/}
                <div style={{ position: "relative" }} data-v-c9514df9="">
                  <div
                    className="common-token-icon common-token-icon--default"
                    data-v-c9514df9=""
                    data-v-3187a4c3=""
                  >
                    <div
                      className="el-image common-token-icon-token"
                      style={{}}
                      data-v-3187a4c3=""
                    >
                                  <img
  src={swapDirection === 'BRLA_TO_USDC' ? selectedToken.icon : BRLAIcon}
  className="el-image__inner"
/>
                      {/*v-if*/}
                      {/*v-if*/}
                    </div>
                  
                  </div>
                </div>
                <div className="ml10" data-v-c9514df9="">
                  <div className="swap-input-token" data-v-47d017e7="">
                  <span data-v-47d017e7="">{swapDirection === 'BRLA_TO_USDC' ? selectedToken.symbol : 'BRLA'}</span>
                    <div className="swap-input-icon" data-v-47d017e7="">
                      <i style={{background:'transparent', color:"transparent"}} className="icon-caret-bottom" data-v-47d017e7="" />
                    </div>
                  </div>
                 
                </div>
                {/*]*/}
              </div>
              <div className="flex-items-center flex-row" data-v-c9514df9="">
              <input
  className="sp-token-amountOut fz20 lh36 color-gray font-bold el-tooltip__trigger el-tooltip__trigger"
  readOnly=""
  data-v-47d017e7=""
  placeholder={
  quoteError
  ? "Loading..."
  : (
    !amountUsd || !amountBrla  || isNaN(Number(amountUsd)) || isNaN(Number(amountBrla)) 
    ? "Loading..."
    : (
      parseFloat(amountBrlainput) === 0
      ? 0
      : (
        swapDirection === 'BRLA_TO_USDC'
        ? (isNaN(parseFloat((amountUsd / amountBrla)) * (amountBrlainput || 1000)) ? "Loading..." : (parseFloat((amountUsd / amountBrla)) * (amountBrlainput || 1000)).toFixed(2))
        : (isNaN(parseFloat((amountBrla / amountUsd)) * (amountBrlainput || 1000)) ? "Loading..." : (parseFloat((amountBrla / amountUsd)) * (amountBrlainput || 1000)).toFixed(2))
      )
    )
  )
}

// Em algum lugar em seu componente de renderização, exiba a mensagem de erro se houver uma

  
  
  
  disabled
  style={{ background: "transparent" }}
/>




              </div>
              {quoteError && <p className="error-message">{quoteError}</p>}
            </div>
            <div className="line-3 mt8" data-v-47d017e7="">
              <span className="visibility sp-token-usd" data-v-47d017e7="">
                {" "}
               
              </span>
              <span
                className="text-red flex-row-center flex-items-center ml8"
                data-v-47d017e7=""
                style={{
                  borderLeft: "1px solid rgb(213, 218, 224)",
                  paddingLeft: 8,
                  color:"red"
                  
                }}
              >
                <img
                  src="./Bybit Web3 _ A Trusted Wallet Connecting You to Every Crypto_files/alert_triangle.603e8e09.svg"
                  alt=""
                  className="mr4"
                  data-v-47d017e7=""
                />{" "}
                
              </span>
            </div>
          </div>
          <div
            className="sp-rate mt24 flex-items-center flex-row-center"
            data-v-47d017e7=""
          >
            <div
              className="sp-rate-body flex-row font-500 flex-items-center"
              data-v-47d017e7=""
              style={{height:'50px'}}
            >
              <div
                className="el-progress el-progress--circle el-progress--without-text mr8"
                role="progressbar"
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
                data-v-47d017e7=""
              >
        <div
      className="el-progress-circle"
      style={{ height: 16, width: 16 }}
      key={key} // Força a re-renderização do componente sempre que a chave é alterada
    >
      <svg viewBox="0 0 100 100">
        {/* Seu código aqui... */}
        <path
          className={`el-progress-circle__path fill-circle-animation-${duration}`}
          d="
              M 50 50
              m 0 -43
              a 43 43 0 1 1 0 86
              a 43 43 0 1 1 0 -86
              "
          stroke="#0a1b23"
          fill="none"
          opacity={1}
          strokeLinecap="round"
          strokeWidth="12.5"
          style={{
            strokeDasharray: "270.177px, 270.177px",
            strokeDashoffset: 0,
          }}
        />
      </svg>
    </div>
                {/*v-if*/}
              </div>
              <div
  className="swap-rate-value el-tooltip__trigger el-tooltip__trigger"
  data-v-47d017e7=""
>
 

  <div>
    <span style={{fontSize:'14px'}} data-v-47d017e7="">{selectedToken.symbol} price ≈ </span>
    <span style={{fontSize:'14px'}} data-v-47d017e7="">
      {!amountUsd || !amountBrla || isNaN(Number(amountUsd)) || isNaN(Number(amountBrla)) 
      ? 'loading...'
      : (Number(basePrice)).toFixed(4)
      }
    </span>
    <span style={{fontSize:'14px'}} data-v-47d017e7=""> BRLA</span>
  </div>
  <div>
    <span  style={{fontSize:'14px'}} data-v-47d017e7="">Fees ≈ </span>
    <span style={{fontSize:'14px'}} data-v-47d017e7="">
      {!amountUsd || !amountBrla || isNaN(Number(amountUsd)) || isNaN(Number(amountBrla)) 
      ? 'loading...'
      : (Number(baseFees)).toFixed(4)
      }
    </span>
    <span style={{fontSize:'14px'}} data-v-47d017e7=""> {swapDirection === "USDC_TO_BRLA" ? 'BRLA' : selectedToken.symbol}</span>
  </div>
  <div>
    <span style={{fontSize:'14px'}} data-v-47d017e7="">Gas ≈ </span>
    <span style={{fontSize:'14px'}} data-v-47d017e7="">
      {!amountUsd || !amountBrla || isNaN(Number(amountUsd)) || isNaN(Number(amountBrla)) 
      ? 'loading...'
      : (Number(gasFee)).toFixed(4)
      }
    </span>
    <span style={{fontSize:'14px'}} data-v-47d017e7=""> {swapDirection === "USDC_TO_BRLA" ? 'BRLA' : selectedToken.symbol}</span>
  </div>
</div>




              <img
                src="./Bybit Web3 _ A Trusted Wallet Connecting You to Every Crypto_files/load.7b389ddd.svg"
                className="cursor-pointer ml8"
                alt=""
                data-v-47d017e7=""
              />
            </div>
            <div className="sp-rate-border" data-v-47d017e7="" />
          </div>
          <div className="sp-info" data-v-c9514df9="">
         
           
            
         
          </div>
        
          {brlaBalance ? (
  <div
    className={`sb flex-row-center state-5 mt32${amountBrlainput && !disableSignButton ? "" : " disabled"}`}
    data-v-c9514df9=""
    data-v-03e79a9a=""
    onClick={() => {
      if (amountBrlainput && !disableSignButton) {
        if (selectedToken.symbol === 'USDT' && BigInt(ApproveValue) >= parseFloat(amountBrlainput)    && amountBrlainput !== '' && amountBrlainput !== '0' && swapDirection === 'USDC_TO_BRLA') {
          handleSwapClick();
        } else {
          if (selectedToken.symbol === 'USDT' && BigInt(ApproveValue) < parseFloat(amountBrlainput) && amountBrlainput !== '' && amountBrlainput !== '0' && swapDirection === 'USDC_TO_BRLA') 
          approve()
          else {signed ? handleSwapClick() : (swapDirection === 'BRLA_TO_USDC' ? handleSignClick() : handleSignClickInvertido());}
          
        }
      }
    }}
  >
  {signed || (selectedToken.symbol === 'USDT' && BigInt(ApproveValue) >= parseFloat(amountBrlainput) && amountBrlainput !== '' && amountBrlainput !== '0' && swapDirection === 'USDC_TO_BRLA') ? (
    <button style={{background:'none'}}
      data-v-03e79a9a=""
      disabled={!amountBrlainput || disableSignButton } // Disables the button if amountBrlainput is false OR if the current second is a multiple of 5 OR if the current second is the fourth second
    >
      Swap
    </button>
  ) : (
    selectedToken.symbol === 'USDT' && BigInt(ApproveValue) < parseFloat(amountBrlainput) && swapDirection === 'USDC_TO_BRLA' ? (
      <button
        style={{background:'none'}}
        data-v-03e79a9a=""
        onClick={approve}
      >
        Approve
      </button>
    ) : (
      <button
        style={{background:'none'}}
        data-v-03e79a9a=""
        disabled={!amountBrlainput}
      >
        {amountBrlainput === '0' || amountBrlainput === '' ? 'Enter the amount' : 'Sign'}
      </button>
    )
  )}

  </div>
) : (
  <div
    className="sb flex-row-center state-5 mt32"
    data-v-c9514df9=""
    data-v-03e79a9a=""
    onClick={handleConnectClick}
  >
    <button style={{background:'none'}} className="btn-text" data-v-03e79a9a="">
      Connect Wallet
    </button>
  </div>
)}



    
        </section>
        
      </section>
      
      <div style={{ 
  margin:'0 auto', 
  textAlign:'center',
  backgroundColor: 'none',
  border: '0px solid black',
  borderRadius: '10px',
  padding: '0px',
  paddingLeft:'20px'
}} 

className='margin-nova'>
  <ol style={{ textAlign: 'justify' }}>
   <li>Most operations proceed in just a few minutes. There are rare occasions when more time is needed. The processing speed depends on how long it takes for a block to be included in the network.</li>
    
  </ol>
  
</div>




    </div>
  </div>
  
  <ToastContainer />
</>


)} ;

export default Swap