import React, { useState, useEffect } from 'react';
import logoImage from '../Home/images/logo.png';
import { useForm } from 'react-hook-form';
import logoImage2 from '../Home/images/BRLA_verde.svg';
import zxcvbn from 'zxcvbn';
import { useNavigate, useLocation } from 'react-router-dom'

import { ENDPOINT, MANTEINENCETOKEN} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input';
import InputMask from 'react-input-mask';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
function Signup() {
  const { t, i18n} = useTranslation();
  const [personType, setPersonType] = useState('physical');
  const [formattedDate, setFormattedDate] = useState('');
  const [formattedDatePJ, setFormattedDatePJ] = useState('');
  const [formattedStartDate, setFormattedStartDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const {
    register,
    handleSubmit,
    control,
    formState: { isValid },
    reset,
    setValue,
    setFocus,
    watch, // Adicione esta linha
  } = useForm({ mode: 'onChange' });
  
    
      const [passwordStrengthScore, setPasswordStrengthScore] = useState(0);
      const [password, setPassword] = useState('');
      const [passwordConfirmation, setPasswordConfirmation] = useState('');
      const phoneNumberWatch = watch('phone');


      
  const onPasswordConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
  };

  const doPasswordsMatch = () => {
    return password === passwordConfirmation;
  };

      
    
      const onPasswordChange = (e) => {
        const newPassword = e.target.value;
        const result = zxcvbn(newPassword);
        setPasswordStrengthScore(result.score);
        setPassword(newPassword);
      };
    
      const doesPasswordMeetCriteria = () => {
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasSpecialCharacter = /[^a-zA-Z0-9]/.test(password);
    
        return (
          password.length >= 8 &&
          hasUppercase &&
          hasLowercase &&
          hasNumber &&
          hasSpecialCharacter
        );
      };
    
      const getPasswordCriteriaColor = () => {
        return doesPasswordMeetCriteria() ? 'black' : 'red';
      };

      const getPasswordStrengthColor = () => {
        switch (passwordStrengthScore) {
          case 0:
            return '#ddd';
          case 1:
            return 'red';
          case 2:
            return 'orange';
          case 3:
            return 'yellow';
          case 4:
            return 'green';
          default:
            return '#ddd';
        }
      };
 

  const handleTabChange = () => {
    // Limpe os campos do formulário
    reset();
    setFormattedDate('');
    setFormattedDatePJ('');
    setFormattedStartDate('');
    setDate('');
    setDatePJ('');
    setStartDate('');
    setCep('');
    setNumber('');
    setPhoneNumber('');
  };

      const navigate = useNavigate();
      const location = useLocation();
      const query = new URLSearchParams(location.search);
      const validateToken = query.get('token');
    
      const onSubmit = async (data) => {
        setIsLoading(true); // Definir como loading quando a requisição começar
        const requestData = {
          phone: phoneNumber,
          fullName: data.full_name,
          taxIdType: 'CPF',
          cpf: data.cpf,
          birthDate: formattedDate,
          password: data.password,
          confirmPassword: data.confirm_password,
          email: data.email,
          address: {
            country: data.country.label,
            cep: cep,
            city: data.city,
            state: data.state,
            street: data.street,
            number: number,
            district: data.district,
            complement: data.complement,
            
          },
          // Outras propriedades do corpo da requisição, se houver
        };
      
        // Salvar o corpo da requisição no localStorage
        localStorage.setItem('user_data', JSON.stringify(requestData));
        const response = await fetch(`${ENDPOINT}/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "MAINTENANCE-TOKEN":MANTEINENCETOKEN
          },
          body: JSON.stringify(requestData),
          
        });
      
        setIsLoading(false); // Definir como não loading quando a requisição terminar
      
        if (response.status === 200 || response.status === 201) {
          toast.success('Verifique as instruções enviada por e-mail');
          setTimeout(() => {
            // Salva os dados no localStorage antes de redirecionar para a próxima página
            navigate('/email_verified');
          }, 3000);
        } else {
          
          const  responseData = await response.json();
          
          // Trate erros aqui, caso necessário
        
          toast.error('Error: ' + responseData.error); // Concatene a mensagem de erro
      
         
        }
      };



      const onSubmitPJ = async (data) => {
        setIsLoading(true); // Definir como loading quando a requisição começar
      
        const requestDataPJ = {
          phone: phoneNumber,
          fullName: data.full_name,
          cpf: data.cpf,
          birthDate: formattedDatePJ,
          password: data.password,
          confirmPassword: data.confirm_password,
          email: data.email,
          address: {
            country: data.country.label,
            cep: cep,
            city: data.city,
            state: data.state,
            street: data.street,
            number: number,
            district: data.district,
            complement: data.complement
          },
          cnpj: data.cnpj,
          companyName: data.company_name,
          startDate: formattedStartDate,
          fullName: data.full_name_pj,
          taxIdType: 'CNPJ'
        };
      
        // Salvar o corpo da requisição no localStorage
        localStorage.setItem('user_data', JSON.stringify(requestDataPJ));
      
        const response = await fetch(`${ENDPOINT}/create`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            "MAINTENANCE-TOKEN": MANTEINENCETOKEN
          },
          body: JSON.stringify(requestDataPJ),
        });
      
        setIsLoading(false); // Definir como não loading quando a requisição terminar
      
        if (response.status === 200 || response.status === 201) {
          toast.success('Verifique as instruções enviada por e-mail');
          setTimeout(() => {
            navigate('/email_verified');
          }, 3000);
        } else {
          const responseData = await response.json();
          // Trate erros aqui, caso necessário
          toast.error('Error: ' + responseData.error); // Concatene a mensagem de erro
        }
      };
      
const countries = [
  { value: 'af', label: 'افغانستان' }, // Afghanistan
  { value: 'ar', label: 'الجزائر' }, // Algeria
  { value: 'ar', label: 'Argentina' }, // Argentina
  { value: 'au', label: 'Australia' }, // Australia
  { value: 'at', label: 'Österreich' }, // Austria
  { value: 'bd', label: 'বাংলাদেশ' }, // Bangladesh
  { value: 'be', label: 'België' }, // Belgium
  { value: 'br', label: 'Brasil' }, // Brazil
  { value: 'bg', label: 'България' }, // Bulgaria
  { value: 'ca', label: 'Canada' }, // Canada
  { value: 'cl', label: 'Chile' }, // Chile
  { value: 'cn', label: '中国' }, // China
  { value: 'co', label: 'Colombia' }, // Colombia
  { value: 'hr', label: 'Hrvatska' }, // Croatia
  { value: 'cu', label: 'Cuba' }, // Cuba
  { value: 'cz', label: 'Česko' }, // Czech Republic
  { value: 'dk', label: 'Danmark' }, // Denmark
  { value: 'eg', label: 'مصر' }, // Egypt
  { value: 'fi', label: 'Suomi' }, // Finland
  { value: 'fr', label: 'France' }, // France
  { value: 'de', label: 'Deutschland' }, // Germany
  { value: 'gr', label: 'Ελλάδα' }, // Greece
  { value: 'hu', label: 'Magyarország' }, // Hungary
  { value: 'in', label: 'भारत' }, // India
  { value: 'id', label: 'Indonesia' }, // Indonesia
  { value: 'ir', label: 'ایران' }, // Iran
  { value: 'iq', label: 'العراق' }, // Iraq
  { value: 'ie', label: 'Éire' }, // Ireland
  { value: 'il', label: 'ישראל' }, // Israel
  { value: 'it', label: 'Italia' }, // Italy
  { value: 'jp', label: '日本' }, // Japan
  { value: 'mx', label: 'México' }, // Mexico
  { value: 'nl', label: 'Nederland' }, // Netherlands
  { value: 'nz', label: 'New Zealand' }, // New Zealand
  { value: 'ng', label: 'Nigeria' }, // Nigeria
  { value: 'kp', label: '조선' }, // North Korea
  { value: 'no', label: 'Norge' }, // Norway
  { value: 'pk', label: 'پاکستان' }, // Pakistan
  { value: 'pe', label: 'Perú' }, // Peru
  { value: 'ph', label: 'Pilipinas' }, // Philippines
  { value: 'pl', label: 'Polska' }, // Poland
  { value: 'pt', label: 'Portugal' }, // Portugal
  { value: 'ro', label: 'România' }, // Romania
  { value: 'ru', label: 'Россия' }, // Russia
  { value: 'sa', label: 'السعودية' }, // Saudi Arabia
  { value: 'rs', label: 'Србија' }, // Serbia
  { value: 'sg', label: 'Singapore' }, // Singapore
  { value: 'sk', label: 'Slovensko' }, // Slovakia
  { value: 'si', label: 'Slovenija' }, // Slovenia
  { value: 'za', label: 'South Africa' }, // South Africa
  { value: 'kr', label: '대한민국' }, // South Korea
  { value: 'es', label: 'España' }, // Spain
  { value: 'se', label: 'Sverige' }, // Sweden
  { value: 'ch', label: 'Schweiz' }, // Switzerland
  { value: 'tw', label: '台灣' }, // Taiwan
  { value: 'th', label: 'ไทย' }, // Thailand
  { value: 'tr', label: 'Türkiye' }, // Turkey
  { value: 'ua', label: 'Україна' }, // Ukraine
  { value: 'ae', label: 'الإمارات' }, // United Arab Emirates
  { value: 'gb', label: 'United Kingdom' }, // United Kingdom
  { value: 'us', label: 'United States' }, // United States
  { value: 'uy', label: 'Uruguay' }, // Uruguay
  { value: 've', label: 'Venezuela' }, // Venezuela
  { value: 'vn', label: 'Việt Nam' } // Vietnam
];




const [selectedCountry, setSelectedCountry] = useState(null);

const handleCountryChange = (selectedOption) => {
  setSelectedCountry(selectedOption);
};


function formatCPF(event) {
  const { value } = event.target;
  const formattedValue = value
    .replace(/\D/g, "") // Remove all non-digit characters
    .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after 3 digits
    .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after the next 3 digits
    .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Add a hyphen before the last 1 or 2 digits

  event.target.value = formattedValue;
}

const formatDate = (date) => {
  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  
  // Split the input date into day, month, and year
  const [day, month, year  ] = date.split("/");

  // Convert the month from a number to a name
  const monthName = monthNames[parseInt(month, 10) - 1];

  // Return the formatted date
  return `${year}-${monthName}-${day}`;
};



const [date, setDate] = useState('');
const [Startdate, setStartDate] = useState('');
const [datePJ, setDatePJ] = useState('');


const handleDateChange = (event) => {
  console.log(formatDate("01-07-1991"));  // Outputs: 1991-Jul-01

 let value = event.target.value.replace(/[^0-9]/g, '');

  // Adiciona barras
  if (value.length >= 2 && value.length < 4) {
    const day = parseInt(value.slice(0, 2));
    if (day > 31) {
      value = '31';
    }
    value = value.slice(0, 2) + '/' + value.slice(2);
  } else if (value.length >= 4) {
    const month = parseInt(value.slice(2, 4));
    if (month > 12) {
      value = value.slice(0, 2) + '12';
    }
    value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
  }

  // Limita o comprimento
  value = value.slice(0, 10);

  setDate(value);
  
  const formattedDate = formatDate(value);
  setFormattedDate(formattedDate);
  console.log(formattedDate)
};


const handleDatePJChange = (event) => {
  
 let value = event.target.value.replace(/[^0-9]/g, '');

  // Adiciona barras
  if (value.length >= 2 && value.length < 4) {
    const day = parseInt(value.slice(0, 2));
    if (day > 31) {
      value = '31';
    }
    value = value.slice(0, 2) + '/' + value.slice(2);
  } else if (value.length >= 4) {
    const month = parseInt(value.slice(2, 4));
    if (month > 12) {
      value = value.slice(0, 2) + '12';
    }
    value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
  }

  // Limita o comprimento
  value = value.slice(0, 10);

  setDatePJ(value);

  const formattedDatePJ = formatDate(value);
  setFormattedDatePJ(formattedDatePJ);
  console.log(formattedDatePJ)
  
  
};

const handleStartDateChange = (event) => {
  
  let value = event.target.value.replace(/[^0-9]/g, '');
 
   // Adiciona barras
   if (value.length >= 2 && value.length < 4) {
     const day = parseInt(value.slice(0, 2));
     if (day > 31) {
       value = '31';
     }
     value = value.slice(0, 2) + '/' + value.slice(2);
   } else if (value.length >= 4) {
     const month = parseInt(value.slice(2, 4));
     if (month > 12) {
       value = value.slice(0, 2) + '12';
     }
     value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4);
   }
 
   // Limita o comprimento
   value = value.slice(0, 10);
 
   setStartDate(value);
 
   const formattedStartDate = formatDate(value);
   setFormattedStartDate(formattedStartDate);
   console.log(formattedStartDate)
   
   
 };
 

const currentYear = new Date().getFullYear();
const minDate = `${currentYear - 120}-01-01`;
const maxDate = `${currentYear - 18}-12-31`;


function TestaCPF(strCPF) {
  var Soma;
  var Resto;
  Soma = 0;
  if (strCPF === "00000000000") return false;

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

  Soma = 0;
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if (Resto === 10 || Resto === 11) Resto = 0;
  if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
  return true;
}






function formatCNPJ(event) {
  const { value } = event.target;
  const formattedValue = value
    .replace(/\D/g, "") // Remove all non-digit characters
    .replace(/(\d{2})(\d)/, "$1.$2") // Add a dot after 2 digits
    .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after the next 3 digits
    .replace(/(\d{3})(\d)/, "$1/$2") // Add a slash after the next 3 digits
    .replace(/(\d{4})(\d)/, "$1-$2"); // Add a hyphen after the next 4 digits

  event.target.value = formattedValue;
}

const [passwordsMatch, setPasswordsMatch] = useState(true);

useEffect(() => {
  setPasswordsMatch(doPasswordsMatch());
}, [password, passwordConfirmation]);


const [invalidCEP, setinvalidCEP] = useState(false);

const [invalidCEPPJ, setinvalidCEPPJ] = useState(false);

const checkCEP = (cepValue) => {
  setIsLoading(true);
  const cep = cepValue.replace(/\D/g, '');
  fetch(`https://viacep.com.br/ws/${cep}/json/`)
    .then(res => res.json())
    .then(data => {
      setValue('street', data.logradouro);
      setValue('district', data.bairro);
      setValue('city', data.localidade);
      setValue('state', data.uf);
      setFocus('number');
      console.log(data);
      if (data.erro === true) {
        setinvalidCEP(true);
      } else {
        setinvalidCEP(false);
      }
      setIsLoading(false);
    });
};


const instantCheckCEP = debounce((cepValue) => checkCEP(cepValue), 0);

  const handleCepChange = (e) => {
    const cepValue = e.target.value.replace(/\D/g, '');
    setCep(cepValue);
    console.log("vamos la:", cepValue.length);
    console.log("cepValue", cepValue)
    if (cepValue.length === 8) {
      instantCheckCEP(cepValue); // Chamará a função checkCEP instantaneamente quando o CEP estiver completo
    }
  };












const [cep, setCep] = useState('');

const [number, setNumber] = useState('');



const isNumberEmpty = number.trim() === ''; // Verificar se o campo "number" está vazio


const handleKeyPress = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    e.preventDefault();
  }
};

const handleKeyPressPJ = (e) => {
  const charCode = e.which ? e.which : e.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    e.preventDefault();
  }
};
 
    return (
       
<>
  <div id="__next">
    <div className="w-full h-full pt-20 font-circular sm:pt-0">
      <div className="fixed top-0 z-30 w-full bg-white sm:relative">
        <div className="relative">
          <header className="relative grid grid-cols-3 px-6 w-full sm:py-7 sm:h-32 h-20">
            <div
              className="flex col-start-2 items-center justify-center"
              data-testid="logo-container"
            >
              <h1 className="text-base">
                <span className="sr-only">BRLA</span>
                <img src={logoImage2}></img>
               
              </h1>
            </div>
          </header>
        </div>
        <div  className="font-circular text-base tracking-tight text-black-400 absolute top-6 right-6 hidden justify-end lg:block">
          {t('ja_tem_uma_conta')}
          <a
            className="cb-button sm text ml-3.5 p-0"
            href="/login"
            role="link"
            
          >
            Sign In
          </a>
        </div>
      </div>
      <div className="relative px-4 pb-24 w-full">
        <div
          className="grid h-full content-center items-center justify-center"
          data-testid="center-both"
        >
          <div aria-atomic="true" aria-live="assertive" />
          <div className="max-w-160" data-testid="landing-form">
            <div className="grid items-center justify-center text-center">
              <h2 style={{marginTop:'60px'}} className="mb-5 text-3xl text-black-600 font-circular-bold">
                {t('create_account')}
              </h2>
             
            </div>
            <div style={{display:'flex', justifyContent:'center'}}>
            <label style={{marginRight:'20px'}}>
  <input
    type="radio"
    value="physical"
    checked={personType === 'physical'}
    onChange={(e) => {
      setPersonType(e.target.value);
      handleTabChange();
    }}
  />
  {t('pessoa_fisica')}
</label>
        
<label style={{marginBottom:'50px'}}>
  <input
    type="radio"
    value="legal"
    checked={personType === 'legal'}
    onChange={(e) => {
      setPersonType(e.target.value);
      handleTabChange();
    }}
  />
  {t('pessoa_juridica')}
</label>
</div>
{personType === 'physical' ? (
            <form
              action=""
              className="flex flex-col items-center justify-center gap-y-10"
              onSubmit={handleSubmit(onSubmit)}
            >

                
                
             

  
                
                

   


            
                  

              <div className="w-full max-w-116" data-testid="form">
                
                
                  
              <div>
    
      </div>
                <div className="grid">
                  
              <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  
                  >
                    <div className="input-container">
                      <input
                        id="cb-input-email"
                        aria-labelledby="cb-input-email"
                        aria-describedby=""
                        className="input"
                        data-testid="input-email"
                        placeholder="E-mail"
                        defaultValue=""
                        name="email"
                        {...register('email', { required: true })}
                      />
                    </div>

                    
                  </div>



  
               
            
                <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    
                    <div className="input-container">
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input"
  data-testid="input-entity-name"
  placeholder={t('cpf')}
  defaultValue=""
  name="cpf"
  pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
  maxLength="14"
  onInput={formatCPF}
  {...register("cpf", {
    required: "CPF is required",
    validate: (value) => TestaCPF(value.replace(/\D/g, "")) || "Invalid CPF",
  })}

/>
                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-birthdate"
      aria-labelledby="cb-input-birthdate"
      aria-describedby=""
      className="input"
      data-testid="input-birthdate"
      placeholder={t('date_of_birth')}
      value={date}
      name="birthdate"
      type="text"
      onChange={handleDateChange}
      
    />
                      </div>
                    </div>
                  </div>
                  <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                        <input
                          id="cb-input-fullName"
                          aria-labelledby="cb-input-fullName"
                          aria-describedby=""
                          className="input"
                          data-testid="input-full-name"
                          placeholder={t('full_name')}
                          defaultValue=""
                          name="full_name"
                          {...register('full_name', { required: true })}
                        />
                      </div>
                    </div>
            
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <Controller
  name="country"
  control={control}
  defaultValue=""
  rules={{ required: true }}
  render={({ field }) => (
    <Select
      {...field}
      options={countries}
      isSearchable
      placeholder={t('country')}
      onChange={value => field.onChange(value)} // Atualiza o valor do campo quando uma opção é selecionada
      onBlur={() => field.onBlur()} // Atualiza o estado de foco do campo
    />
  )}
/>

                    </div>
                  </div>

                  <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    
                    <div className="input-container">
                    <InputMask
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input"
  data-testid="input-entity-name"
  placeholder={t('zip_code')}
  defaultValue=""
  name="cep"
  mask="99999-999"
  value={cep}
  onChange={handleCepChange}
 
 

/>


                    </div>
                    {invalidCEP && (<p style={{color:'red', fontSize:'12px'}}>{t('cep_invalido')}</p>)}
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-city"
      aria-labelledby="cb-input-city"
      aria-describedby=""
      className="input  input-disabled"
      data-testid="input-city"
      placeholder={t('city')}
      name="city"
      type="text"
      {...register('city')}
      disabled
    />
                      </div>
                    </div>
                  </div>




                  <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    
                    <div className="input-container">
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input input-disabled"
  data-testid="input-entity-name"
  placeholder={t('state')}
  defaultValue=""
  name="state"
  disabled
  {...register('state')}

/>
                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-city"
      aria-labelledby="cb-input-city"
      aria-describedby=""
      className="input input-disabled"
      data-testid="input-city"
      placeholder={t('neighborhood')}
      name="district"
      type="text"
      disabled
      {...register('district')}
    />
                      </div>
                    </div>
                  </div>






                  <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                    
                  
                  >
                    
                    <div  className="input-container">
                      
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input input-disabled"
  data-testid="input-entity-name"
  placeholder={t('street')}
  defaultValue=""
  name="street"
  {...register('street')}
  disabled

/>
                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full "
                      data-testid="input-container"
                    >
                      <div  className="input-container">
                      <input
        id="cb-input-number"
        aria-labelledby="cb-input-number"
        aria-describedby=""
        className="input"
        data-testid="input-number"
        placeholder={t('number')}
        name="number"
        type="text"
        value={number}
        onKeyPress={handleKeyPress} // Impedir a inserção de caracteres não numéricos
        onChange={(e) => {
          const value = e.target.value;
          setNumber(value);
          setValue('number', value);
        }}
        required
      />
                      </div>
                    </div>
                  </div>


                  <div
                      className="cb-input floating-label no-label h-16 w-full sm:w-1/5"
                      data-testid="input-container"
                    >
                      <div  className="input-container">
                      <input
      id="cb-input-complement"
      aria-labelledby="cb-input-complement"
      aria-describedby=""
      className="input"
      data-testid="input-city"
      placeholder={t('complement')}
      name="complement"
      type="text"
      {...register('complement')}
    />
                      </div>
                    </div>


                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <Controller
  control={control}
  name="phone"
  rules={{ required: true }} // torna o campo obrigatório
  render={({ field }) => (
    <PhoneInput
      placeholder={t('phone')}
      value={phoneNumber}
      onChange={value => {
        setPhoneNumber(value);
        field.onChange(value); // atualiza o valor no react-hook-form
      }}
      className="input"
      style={{ backgroundColor: 'rgb(245 245 248/var(--tw-bg-opacity))' }}
    />
  )}
/>
                    </div>
                    
                  </div>



                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                    
                  >
                    <div className="input-container">
                    <input
                        id="cb-input-password"
                        aria-labelledby="cb-input-password"
                        aria-describedby="cb-input-password-message"
                        className="input"
                        data-testid="input-password"
                        placeholder={t('password')}
                        type="password"
                        defaultValue=""
                        name="password"
                        {...register('password', { required: true })}
                        onChange={onPasswordChange}

                      />
      
                    </div>

                    
                  </div>
                  <div
                    className="cb-input floating-label no-label icon-right box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                      <input
                        id="cb-input-password"
                        aria-labelledby="cb-input-password"
                        aria-describedby="cb-input-password-message"
                        className="input"
                        data-testid="input-password"
                        placeholder={t('confirm_password')}
                        type="password"
                        defaultValue=""
                        name="confirm_password"
                        {...register('confirm_password', { required: true })}
                        onChange={onPasswordConfirmationChange}
                        style={{marginBottom:'15px'}}
                      />
                      
                    </div>
                    
                    {!passwordsMatch && (
          <p  className="text-red-500">
            Passwords do not match
          </p>
        )}
            
                  
                    <div
                        id="password-strength-bar"
                        style={{
                          backgroundColor: getPasswordStrengthColor(),
                          width: "100%",
                          height: 5,
                          marginTop: 5,
                          marginBottom:'15px'
                        }}
                      />



                   
<div style={{ color: doesPasswordMeetCriteria() ? 'black' : 'red' }}>
{t('password_warning')}
</div>      
      

                  </div>

                  
                  
             


              
                  <button
className="cb-button base primary  h-10  py-2 px-4 disabled:bg-black-200 "
type="submit"
disabled={isLoading || !isValid || !phoneNumberWatch || isNumberEmpty || invalidCEP || cep?.match(/[a-zA-Z0-9]/g).length<8}
style={{marginTop:'120px'}}
>
<span>{isLoading ? "Loading..." : "Sign up"}</span>
</button>    

                  
                  
                </div>


               
            
                
                
             

  
                
                

               
   


              </div>
              

            </form>  ) : (         <form
              action=""
              className="flex flex-col items-center justify-center gap-y-10"
              onSubmit={handleSubmit(onSubmitPJ)}
            >
              <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                    style={{marginBottom:'-45px'}}
                  >
                    <div className="input-container">
                      <input
                        id="cb-input-email"
                        aria-labelledby="cb-input-email"
                        aria-describedby=""
                        className="input"
                        data-testid="input-email"
                        placeholder="E-mail"
                        defaultValue=""
                        name="email"
                        {...register('email', { required: true })}
                      />
                    </div>
                  </div>

                  

              <div className="w-full max-w-116" data-testid="form">
              <div>
    
      </div>

      
                <div className="grid">
                  
                <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    
                    <div className="input-container">
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input"
  data-testid="input-entity-name"
  placeholder={t('cpf_representante')}
  defaultValue=""
  name="cpf"
  pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
  maxLength="14"
  onInput={formatCPF}
  {...register("cpf", {
    required: "CPF is required",
    validate: (value) => TestaCPF(value.replace(/\D/g, "")) || "Invalid CPF",
  })}

/>
                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-birthdate"
      aria-labelledby="cb-input-birthdate"
      aria-describedby=""
      className="input"
      data-testid="input-birthdate"
      placeholder={t('date_of_birth')}
      value={datePJ}
      name="birthdate"
      type="text"
      onChange={handleDatePJChange}
    />
                      </div>
                    </div>
                  </div>

                  <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                        <input
                          id="cb-input-fullName-pj"
                          aria-labelledby="cb-input-fullName-pj"
                          aria-describedby=""
                          className="input"
                          data-testid="input-full-name-pj"
                          placeholder={t('nome_representante')}
                          defaultValue=""
                          name="full_name_pj"
                          {...register('full_name_pj', { required: true })}
                        />



                      </div>
                    </div>

                    <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input"
  data-testid="input-entity-name"
  placeholder={t('cnpj')}
  defaultValue=""
  name="cnpj"
  pattern="\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}"
  maxLength="18"
  onInput={formatCNPJ}
  {...register('cnpj', { required: true })}
/>

                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-startdate"
      aria-labelledby="cb-input-startdate"
      aria-describedby=""
      className="input"
      data-testid="input-startdate"
      placeholder={t("data_de_abertura")}
      value={Startdate}
      name="startdate"
      type="text"
      onChange={handleStartDateChange}
      
    
    />
                      </div>
                    </div>
                  </div>

                  <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                        <input
                          id="cb-input-firstName"
                          aria-labelledby="cb-input-firstName"
                          aria-describedby=""
                          className="input"
                          data-testid="input-first-name"
                          placeholder={t("razao_social")}
                          defaultValue=""
                          name="first_name"
                          {...register('company_name', { required: true })}
                        />
                      </div>
                    </div>
            
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <Controller
  name="country"
  control={control}
  defaultValue=""
  rules={{ required: true }}
  render={({ field }) => (
    <Select
      {...field}
      options={countries}
      isSearchable
      placeholder={t("country")}
      onChange={value => field.onChange(value)} // Atualiza o valor do campo quando uma opção é selecionada
      onBlur={() => field.onBlur()} // Atualiza o estado de foco do campo
    />
  )}
/>

                    </div>
                  </div>

                  <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    
                    <div className="input-container">
                    <InputMask
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input"
  data-testid="input-entity-name"
  placeholder={t("zip_code")}
  defaultValue=""
  name="cep"
  mask="99999-999"
  value={cep}
  onChange={handleCepChange}
 
 

/>


                    </div>
                    {invalidCEP && (<p style={{color:'red', fontSize:'12px'}}>CEP invalido</p>)}
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-city"
      aria-labelledby="cb-input-city"
      aria-describedby=""
      className="input  input-disabled"
      data-testid="input-city"
      placeholder={t("city")}
      name="city"
      type="text"
      {...register('city')}
      disabled
    />
                      </div>
                    </div>
                  </div>




                  <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    
                    <div className="input-container">
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input input-disabled"
  data-testid="input-entity-name"
  placeholder={t("state")}
  defaultValue=""
  name="state"
  disabled
  {...register('state')}

/>
                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full"
                      data-testid="input-container"
                    >
                      <div className="input-container">
                      <input
      id="cb-input-city"
      aria-labelledby="cb-input-city"
      aria-describedby=""
      className="input input-disabled"
      data-testid="input-city"
      placeholder={t('neighborhood')}
      name="district"
      type="text"
      disabled
      {...register('district')}
    />
                      </div>
                    </div>
                  </div>






                  <div className="box-content flex flex-col sm:flex-row sm:gap-4">
                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                    
                  
                  >
                    
                    <div  className="input-container">
                      
                    <input
  id="cb-input-entityName"
  aria-labelledby="cb-input-entityName"
  aria-describedby=""
  className="input input-disabled"
  data-testid="input-entity-name"
  placeholder={t('street')}
  defaultValue=""
  name="street"
  {...register('street')}
  disabled

/>
                    </div>
                  </div>
              
                    <div
                      className="cb-input floating-label no-label h-16 w-full "
                      data-testid="input-container"
                    >
                      <div  className="input-container">
                      <input
        id="cb-input-number"
        aria-labelledby="cb-input-number"
        aria-describedby=""
        className="input"
        data-testid="input-number"
        placeholder={t('number')}
        name="number"
        type="text"
        value={number}
        onKeyPress={handleKeyPress} // Impedir a inserção de caracteres não numéricos
        onChange={(e) => {
          const value = e.target.value;
          setNumber(value);
          setValue('number', value);
        }}
        required
      />
                      </div>
                    </div>
                  </div>


                  <div
                      className="cb-input floating-label no-label h-16 w-full sm:w-1/5"
                      data-testid="input-container"
                    >
                      <div  className="input-container">
                      <input
      id="cb-input-complement"
      aria-labelledby="cb-input-complement"
      aria-describedby=""
      className="input"
      data-testid="input-city"
      placeholder={t('complement')}
      name="complement"
      type="text"
      {...register('complement')}
    />
                      </div>
                    </div>


             



                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <Controller
  control={control}
  name="phone"
  rules={{ required: true }} // torna o campo obrigatório
  render={({ field }) => (
    <PhoneInput
      placeholder={t('phone')}
      value={phoneNumber}
      onChange={value => {
        setPhoneNumber(value);
        field.onChange(value); // atualiza o valor no react-hook-form
      }}
      className="input"
      style={{ backgroundColor: 'rgb(245 245 248/var(--tw-bg-opacity))' }}
    />
  )}
/>
                    </div>
                  </div>

                
            


                  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                    
                  >
                    <div className="input-container">
                    <input
                        id="cb-input-password"
                        aria-labelledby="cb-input-password"
                        aria-describedby="cb-input-password-message"
                        className="input"
                        data-testid="input-password"
                        placeholder={t('password')}
                        type="password"
                        defaultValue=""
                        name="password"
                        {...register('password', { required: true })}
                        onChange={onPasswordChange}

                      />
      
                    </div>

                    
                  </div>
                  <div
                    className="cb-input floating-label no-label icon-right box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                      <input
                        id="cb-input-password"
                        aria-labelledby="cb-input-password"
                        aria-describedby="cb-input-password-message"
                        className="input"
                        data-testid="input-password"
                        placeholder={t('confirm_password')}
                        type="password"
                        defaultValue=""
                        name="confirm_password"
                        {...register('confirm_password', { required: true })}
                        onChange={onPasswordConfirmationChange}
                        style={{marginBottom:'15px'}}
                      />
                      
                    </div>
                    
                    {!passwordsMatch && (
          <p  className="text-red-500">
            Passwords do not match
          </p>
        )}
            
                  
                    <div
                        id="password-strength-bar"
                        style={{
                          backgroundColor: getPasswordStrengthColor(),
                          width: "100%",
                          height: 5,
                          marginTop: 5,
                          marginBottom:'15px'
                        }}
                      />



                   
<div style={{ color: doesPasswordMeetCriteria() ? 'black' : 'red' }}>
{t('password_warning')}
</div>      
      

                  </div>
  
            
            <button
className="cb-button base primary  h-10  py-2 px-4 disabled:bg-black-200 "
type="submit"
disabled={isLoading || !isValid || !phoneNumberWatch || isNumberEmpty || invalidCEP || cep?.match(/[a-zA-Z0-9]/g).length<8 || !Startdate}
style={{marginTop:'120px'}}
>
<span>{isLoading ? t('carregando')+"..." : t('register')}</span>
</button>           
            
                

                   
                    
      

                 



           



                  
                </div>


               
            
                
                
             

  
                
                
    
   


              </div>
           

            </form> )}
            <div className="font-circular text-base tracking-tight text-black-400 mt-3 text-center lg:hidden">
            {t('ja_tem_uma_conta')}
                  <a
                    className="cb-button sm text ml-3.5 p-0"
                    href="/login"
                    role="link"
                  >
                    {t('login')}
                  </a>
                </div>
            <p
                className="max-w-116 text-xs font-circular text-black-400 font-circular-regular"
                data-testid="terms"
                style={{display:'flex',justifyContent:'center', marginTop:'20px'}}
              >
                {t('send_terms')} {" "}
                <a
                  className="whitespace-nowrap"
                  href="https://brla.digital/20231122_-_BRLA_Digital_-_Termos_de_Uso.pdf"
                  rel="noopener noreferrer"
                  target='_blank'
                  
                >
                  {t('terms_link')}
                </a>
            
              </p>
          </div>
        </div>
      </div>
    
    </div>
  </div>
  <ToastContainer />
</>
              
      
    );
}

export default Signup;
