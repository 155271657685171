/* global BigInt */
import  './css/styles.css'
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { ENDPOINT, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES, USDT_CONTRACT_ADDRESSES, BRLA_CONTRACT_ADDRESSES, USDC_NATIVE_CONTRACT_ADDRESSES} from '../../variaveis';
import logoImage2 from '../Home/images/BRLA_verde.svg';
import { parseJwt } from '../../utils/utils';
import jwt_decode from 'jwt-decode';
import WebSocketContext from '../../Components/Websocket/WebSocketContext';
import { useMediaQuery } from '@material-ui/core';
import classes from '../Wallet/Login.module.css';
import resets from '../Wallet/_resets.module.css';
import Logo_beta from '../Wallet/logo_beta.png';
import { useTranslation } from 'react-i18next';

import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';

function Login() {
  const { t, i18n} = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });
  const location = useLocation();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {socket, connectWebSocket} = useContext(WebSocketContext);
  const [limit, setLimit] = useState(0);
  const [usedLimit, setUsedLimit] = useState(0);
  const [users, setUsers] = useState([]); 
  const [wallets, setWallets] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [banks, setBanks] = useState([]);
  

  useEffect(() => {
    setIsLoading(false);
  }, [location]);

  useEffect(() => {
    localStorage.removeItem("userLoggedIn");;
  }, []);


  function numberWithThousandSeparator(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}


function mapStatus(item, type) {
  if (!item) return "unknown";

  if (type === 'swap') {
    if (!item.smartContractOps) return "Pendente";
    if (item.smartContractOps[0]?.feedback?.success === true) return "Completo";
    if (item.smartContractOps[0]?.feedback?.success === false) return "Falha";
    return "Pendente";
  }
  if (type === 'payment') {
    if (item?.paymentOps?.[0]?.transfers?.[0]?.feedbacks?.[0]?.transferStatus === "success") return "Completo";
    if (item?.paymentOps?.[0].smartContractOps?.[0]?.feedback?.success === false) return "Falha";
    if (item?.paymentOps?.[0]?.transfers?.[0]?.feedbacks?.[0]?.transferStatus === false) return "Falha";
    return "Pendente";
}


  if (type === 'buy') {
    if (item?.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.success) return "Completo";
    if (item?.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.success === false) return "Falha";
    if (item.status === "PAID") return "Pendente";
    if (item.status === "UNPAID") return "Pendente";
  } else if (type === 'sell') {
    if (item?.transfers?.[0]?.feedbacks?.[0]?.logType === "success") return "Completo";
    if (item?.smartContractOps?.[0]?.feedback?.success === false) return "Falha";
    return "Pendente"
  }
  if (type==='onchain-deposit') {
    return "Completo"
  }

  if (type==='onchain-out') {
    if (item?.smartContractOps?.[0]?.feedback?.success === true) return "Completo";
    if (item?.smartContractOps?.[0]?.feedback?.success === false) return "Falha";
    return "Pendente";
  }


  else return "Pendente"
  
}




const formatTaxId = (taxId) => {
  if (taxId && typeof taxId === 'string') {
    taxId = taxId.replace(/\D/g, ''); // Remover caracteres não numéricos, se houver

    if (taxId.length === 11) {
      return '***.' + taxId.slice(3, 6) + '.' + taxId.slice(6, 9)  + '-**'; // CPF
    } else if (taxId.length === 14) {
      return '**.' + taxId.slice(3, 6) + '.' + taxId.slice(6, 9) +   '/****-'  ; // CNPJ
    } else if (taxId.length === 6) {
      return '***.' + taxId.slice(0, 3) + '.' + taxId.slice(3, 6) + '-**'; // Seis números
    }
  }

  return '';
};


  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await fetch(`${ENDPOINT}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN":MANTEINENCETOKEN
      },
      credentials: 'include',
      body: JSON.stringify({
        email: data.username,
        password: data.password,
      }),
    });
  
    if (response.ok) {
      localStorage.setItem("userLoggedIn", "true");
      localStorage.removeItem('showPopup');
      const responseBody = await response.json();
      const token = responseBody.accessToken; // Aqui é onde você extrai o token do corpo da resposta
  
      // Agora você pode decodificar o token e extrair a data de expiração
      const { exp } = jwt_decode(token);
      localStorage.setItem('tokenExpiry', exp);
      const decodedToken = jwt_decode(token);
      localStorage.setItem('isPj', decodedToken.isPj);
      

  
      const resUser = await fetch(`${ENDPOINT}/info`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          "MAINTENANCE-TOKEN":MANTEINENCETOKEN
        },
      });
      
      const bodyUser = await resUser.json();
      localStorage.setItem('userData', JSON.stringify(bodyUser));
      

      
  
      const resFees = await fetch(`${ENDPOINT}/fees`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          "MAINTENANCE-TOKEN":MANTEINENCETOKEN
        },
      });
      
      const bodyFees = await resFees.json();
      localStorage.setItem('feesData', JSON.stringify(bodyFees));
      


      // Extrair user.kyc.level
      const userKycLevel = bodyUser.kyc.level;

      
      


      




  





  
     
      if (userKycLevel > 0) {
        const resUsedLimit = await fetch(`${ENDPOINT}/used-limit`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            "MAINTENANCE-TOKEN":MANTEINENCETOKEN
          },
        });
        const bodyUsedLimit = await resUsedLimit.json();
        localStorage.setItem('usedLimit', JSON.stringify(bodyUsedLimit));
      }
      
     {/* const reskychistory = await fetch(`${ENDPOINT}/kyc/history`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          "MAINTENANCE-TOKEN":MANTEINENCETOKEN
        },
      }); */}
      
      
     
      // const bodykychistory = await reskychistory.json();
      localStorage.setItem('historyData', JSON.stringify(historyData));
     // localStorage.setItem('kychistory', JSON.stringify(bodykychistory.history || []));

      // Dentro do bloco if (response.ok) { ... } no componente Login
// Dentro do bloco if (response.ok) { ... } no componente Login
// Dentro do bloco if (response.ok) { ... } no componente Login



  
 
        navigate('/wallet');
        
 
    } else {
      console.error('Erro na requisição:', response.status);
      setShowError(true);
      setIsLoading(false);
    }
  };
const matches = useMediaQuery('(min-width:1280px)');


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: '20px',
    width: '100%',
    margin: '0 auto',
  },
  label: {
    alignSelf: 'flex-start',
    margin: '10px 0 5px 20px',
    color: '#08383f',
  },
  input: {
    width: '90%',
    padding: '10px',
    borderRadius: '12px',
    border: '1px solid #ccc',
    marginBottom: '20px',
    backgroundColor: '#f5f5f5',
  },
  space: {
    height: '20px',
  },
  button: {
    width: '90%',
    padding: '10px',
    borderRadius: '12px',
    border: 'none',
    backgroundColor: '#008884',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  linkContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '90%',
    marginTop: '10px',
  },
  link: {
    color: '#00dc84',
    textDecoration: 'none',
    cursor: 'pointer',
  },
};  


 
    return (
         
<>
{matches ? (
<>
<div id="okta-login-container" style={{ display: "block" }}>
  


    <main
      data-se="auth-container"
      tabIndex={-1}
      id="okta-sign-in"
      className="auth-container main-container no-beacon"
      style={{ display: "block" }}
      
    >

      
<img src={logoImage2} style={{ display:'flex', justifyContent:'center', margin:'0 auto'}}></img>
      
      <div className="okta-sign-in-header auth-header">
        
        <h1>
          
        </h1>
        <div
          data-type="beacon-container"
          className="beacon-container"
          style={{ transform: "scale(0, 0)", textIndent: 0 }}
        />
          

      </div>
   
          
      <div className="auth-content" style={{display:'flex', justifyContent:'center'}}>
        
        <div className="auth-content-inner">
          
          <div className="primary-auth">
                      
            <form
              noValidate="novalidate"
              method="POST"
              action=""
              data-se="o-form"
              slot="content"
              id="form32"
              className="primary-auth-form o-form o-form-edit-mode"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                          className="flex items-center justify-center"
                          data-testid="circle-logo"
                        >
                        </div>


                        <div style={{display:'flex', justifyContent:'center', marginBottom:"10px"}}>
      <img 
        src={brazilFlag} 
        alt="Portuguese" 
        onClick={() => changeLanguage('ptbr')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'ptbr' ? 1 : 0.5, height:'30px', marginRight:'20px' }} 

      />
      <img 
        src={usaFlag} 
        alt="English" 
        onClick={() => changeLanguage('en')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'en' ? 1 : 0.5, width:'40px', height:'30px' }} 
      />
    </div>      
                       
              <div
                data-se="o-form-content"
                className="o-form-content o-form-theme clearfix"
              >
            
                <h2
                  data-se="o-form-head"
                  className="okta-form-title o-form-head"
                  style={{display:'flex', justifyContent:'center'}}
                >
            
                  {t('login')}
            
                </h2>
                <div
                  className="o-form-error-container"
                  data-se="o-form-error-container"
                />
                <div
                  className="o-form-fieldset-container"
                  data-se="o-form-fieldset-container"
                >
                  <div
                    data-se="o-form-fieldset"
                    className="o-form-fieldset o-form-label-top margin-btm-5"
                  >
                    <div
                      data-se="o-form-label"
                      className="okta-form-label o-form-label"
                    >
                      <label htmlFor="okta-signin-username">
                        E-mail
                      </label>
                    </div>
                    <div
                      data-se="o-form-input-container"
                      className="o-form-input"
                    >
                      <span
                        data-se="o-form-input-username"
                        className="o-form-input-name-username o-form-control okta-form-input-field input-fix"
                      >
                        <input
                          type="text"
                          placeholder=""
                          name="username"
                          id="okta-signin-username"
                          defaultValue=""
                          aria-label=""
                          autoComplete="username"
                          aria-required="true"
                          required=""
                          style={{width:'400px'}}
                          {...register('username', { required: true })}
                        />
                      </span>
                    </div>
                  </div>
                  <div
                    data-se="o-form-fieldset"
                    className="o-form-fieldset o-form-label-top margin-btm-30"
                  >
                    <div
                      data-se="o-form-label"
                      className="okta-form-label o-form-label"
                    >
                      <label htmlFor="okta-signin-password">{t('password')}</label>
                    </div>
                    <div
                      data-se="o-form-input-container"
                      className="o-form-input"
                    >
                      <span
                        data-se="o-form-input-password"
                        className="o-form-input-name-password o-form-control okta-form-input-field input-fix"
                      >
                        <input
                          type="password"
                          placeholder=""
                          name="password"
                          id="okta-signin-password"
                          defaultValue=""
                          aria-label=""
                          autoComplete="current-password"
                          aria-invalid="false"
                          aria-required="true"
                          required=""
                          style={{width:'400px'}}
                          {...register('password', { required: true })}
                        />
                      </span>
                    </div>
                  </div>
                  {showError && (
        <div style={{ color: 'red', marginTop: '10px' }}>{t('Wrong credentials')}</div>
      )}
                  <div
                    data-se="o-form-fieldset"
                    className="o-form-fieldset o-form-label-top margin-btm-0"
                  >
                    <div
                      data-se="o-form-input-container"
                      className="o-form-input"
                    >
                      <span
                        data-se="o-form-input-remember"
                        className="o-form-input-name-remember"
                      >
                      
                      </span>
                    </div>
                  </div>
                </div>
              </div>
          
<div class="">
  <input 
    style={{width:'400px', background:'#008884'}} 
    className="button button-primary" 
    type="submit" 
    value={isLoading ? t('carregando') + "..." : t('login')} 
    id="" 
    data-type="save"
    disabled={isLoading}
    onClick={() => {
      window.gtag('event', 'click', {
        event_category: 'button',
        event_label: 'Sign in button clicked',
      });
    }}
  />
</div>
            </form>
           
          </div>
        </div>
      </div>
    </main>
  </div>
  <div className="help-links-container" style={{ display: "block" }}>
    <ul style={{ display: "none" }}>
      <li>
      
        <a
          href="/signup"
          className="link"
          style={{color:'#00dc84'}}
        >
          {t('register')}
         
        </a>
        <a
          href="/forgot-password"
          className="link"
          style={{color:'#00dc84'}}
        >
          {t('forgot_password')}
         
        </a>
      </li>
      
    </ul>
  </div>
  </>
) : <>
<div className={classes.rectangle1}></div>
<div className={classes.rectangle2}></div>
<div className={classes.capturaDeTela2023829As22202}></div>
<div className={classes.linkHomeLogo2a621879dbc6828fb1}><img src={Logo_beta} /></div>
<form
onSubmit={handleSubmit(onSubmit)}
>
<div  style={styles.container}>

<label style={styles.label}>E-mail</label>
<input 
  type="email" 
  style={styles.input} 
  {...register('username', { required: true })}
  className='limpar-input'
/>

<label style={styles.label}>Senha</label>
<input 
  type="password" 
className='limpar-input'
  style={styles.input} 
  {...register('password', { required: true })}
/>

<div style={styles.space}></div>

<button
value={isLoading ? "Carregando..." : "Entrar"} 
disabled={isLoading}
type='submit' style={styles.button}>{isLoading ? "Carregando..." : "Entrar"} </button>

<div style={styles.linkContainer}>
  <a href="/signup" style={styles.link}>Registrar-se</a>
  <a href="/forgot-password" style={styles.link}>Esqueceu a senha?</a>
</div>
{showError && (
        <div style={{ color: 'red', marginTop: '10px' }}>Credenciais invalidas</div>
      )}

</div>
</form>

</>}
  



</>
      
    );
}

export default Login;
