import { memo, useState, useEffect, forwardRef } from 'react';

import { ENDPOINT, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES} from '../../variaveis';

import resets from './_resets.module.css';
import classes from './Section.module.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import QRCode from 'qrcode.react';
import LogoB from './logo_b.png';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useTranslation } from 'react-i18next';
const Deposit = forwardRef((props, ref) => {
  const [bgColor, setBgColor] = useState('#ffffff');
const [qrColor, setQrColor] = useState('none');
const [users, setUsers] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const [pendingRequests, setPendingRequests] = useState(1);
const [kychistoryData, setKycHistoryData] = useState([]);
const [currencyType, setcurrencyType] = useState('');
const { t, i18n} = useTranslation();
const [selectedNetwork, setSelectedNetwork] = useState('Polygon');
useEffect(() => {
  const loadData = async () => {
    const lastRequestDate = localStorage.getItem('lastRequestDate');
    const currentDate = new Date();

    if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
      // If less than a minute has passed since the last request, do not make a new request
      return;
    }

    const resUser = await fetch(`${ENDPOINT}/info`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        "MAINTENANCE-TOKEN":MANTEINENCETOKEN
      },
    });
    const bodyUser = await resUser.json();
    localStorage.setItem('userData', JSON.stringify(bodyUser));

   
    localStorage.setItem('nextRequestDate', currentDate.toISOString());

    // After making the requests, store the current date as the last request date
    localStorage.setItem('lastRequestDate', currentDate.toISOString());
  };

  loadData();
}, []);

useEffect(() => {
  // Obtem os usuários do localStorage
  const loadedUsers = localStorage.getItem('userData');
  if (loadedUsers) {
    setUsers(JSON.parse(loadedUsers));
  }

  const loadedKYCHistory = localStorage.getItem('kychistory');
  if (loadedKYCHistory) {
    const KycHistory = JSON.parse(loadedKYCHistory);
    // Criando um objeto onde a chave é o ID e o valor é o item do histórico
    setKycHistoryData(KycHistory);


  };



  setPendingRequests(0);
}, []);

const handleCopy = () => {
  toast.success('Chave PIX copiada com sucesso!');
};

const handleCopyWallet = () => {
  toast.success('Endereço da wallet copiado com sucesso!');
};

const ClickUsa = (e) => {
  e.stopPropagation(); // Impede a propagação do evento de clique
  setcurrencyType('USA');
  
}

const ClickBrazil = (e) => {
  e.stopPropagation(); // Impede a propagação do evento de clique
  setcurrencyType('Brazil');
  
}

const handleNetworkChange = (network) => {
  setSelectedNetwork(network);
};

  return (
    <div ref={ref} className={`${resets.storybrainResets} ${classes.root} main-container-11 `}>

      
      <div className={classes.frame15}>
        <div className={classes.frame6}>
          <div className={classes.frame2}>
            <div className={classes.pIXQRCode}>QR Code PIX</div>
            
  
       
  <QRCode
    value={users.brCode}
    size={200}
    bgColor="transparent"
    fgColor="#ffffff"
    renderAs="svg"
    imageSettings={{
      src: "https://brla.digital/static/media/logo_b.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    }}
  />

  <div style={{textAlign:'center'}} className={classes.frame16}>
    <div style={{textAlign:'center', }} className={classes.pIXCODE123456789}>{users?.pixKey}</div>
    <CopyToClipboard text={users?.pixKey} onCopy={handleCopy}>
      <button className='button-copy' ><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
  </div>
  <div className={classes.checkOurComplianceAMLAndCFTPol}>
    <p style={{marginLeft:'10px'}} className={classes.labelWrapper}>
      <span className={classes.label}>{t('check_policy')} <a target='_blank' href='https://brla.digital/20231122_-_BRLA_Digital_-_Termos_de_Uso.pdf'>{t('here')}</a></span>
     <a target='_blank' href='https://brla.digital/BRLA_Digital_LTDA_-_Politica_de_PLD_FT_e_KYC_20230825.pdf'> <span className={classes.label2}> {t('fique_atento')}</span> </a>
      
    </p>
  </div>


        
           
          </div>
          <div className={classes.frame4}>
            <div className={classes.onChainPayments}>{t('onchain_payments')}</div>
            <div style={{ color: 'white' }}>
  <button 
    style={{
      marginRight: '10px',
      background: selectedNetwork === 'Polygon' ? "#00dc84" : "transparent",
      padding: '5px',
     
    }}
    onClick={() => handleNetworkChange('Polygon')}>
    Polygon
  </button>
  <button 
    style={{
      background: selectedNetwork === 'Tron' ? "#00dc84" : "transparent",
      padding: '5px',
     
    }}
    onClick={() => handleNetworkChange('Tron')}>
    Tron
  </button>
</div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign:'center', justifyContent:"center"}}>
        <QRCode
        value={selectedNetwork === 'Polygon' ? users?.wallets?.evm : users?.wallets?.tron}
          size={200}
          bgColor="transparent"
          fgColor="#ffffff"
          renderAs="svg"
          imageSettings={{
            src: "https://brla.digital/static/media/logo_b.png",
            x: null,
            y: null,
            height: 24,
            width: 24,
            excavate: true,
          }}
        />
      </div>
            <div className={classes.frame19}>
              <div className={classes.walletAddressx123Wyz}>
              {t('wallet_address')}: {selectedNetwork === 'Polygon' ? `${users?.wallets?.evm?.slice(0, 4)}...${users?.wallets?.evm?.slice(-3)}` : `${users?.wallets?.tron?.slice(0, 4)}...${users?.wallets?.tron?.slice(-3)}`}
</div>
<CopyToClipboard text={selectedNetwork === 'Polygon' ? users?.wallets?.evm : users?.wallets?.tron} onCopy={handleCopyWallet}>
      <button className='button-copy' ><>
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="white"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
            </div>
            <div className={classes.remindersThisWalletIsOnPolygon}>
              <div className={classes.textBlock}>{t('remeinders')}:</div>
              <ol className={classes.list}>
                <li>
                  <div className={classes.textBlock2}>
                    <p className={classes.labelWrapper2}>
                      <span className={classes.label4}>{t('aviso_wallet')}</span>
                      
                    </p>
                  </div>
                </li>
                <li>
                  <div className={classes.textBlock3}>
                    <p className={classes.labelWrapper3}>
                      <span className={classes.label7}>{t('tokens_aceitos')} </span>
                      <span className={classes.label8}>{t('perda')}</span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className={classes.textBlock4}>
                   
                  </div>
                </li>
              </ol>
            </div>
          </div>




        </div>
      </div>
      <ToastContainer />
    </div>
  );
});
export default Deposit
