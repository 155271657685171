import FooterLogged from "../../Components/FooterLogged"
import WalletHeader from "../../Components/WalletHeader"

function Subscriptions() {
    return(<>
        <div id="__next">
          <div className="grid w-full min-h-screen font-circular">
            <WalletHeader/>
            <main className="flex flex-col bg-surface-background">
              <div className="grid flex-1 gap-y-12 pt-12 content-start main-container">
                <div className="px-16">
                  <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
                    <header className="cb-header">
                      <div className="cb-header-head primary">
                        <h1 className="title">Developer</h1>
                        <div className="actions">
                          <a
                            className="cb-button base secondary"
                            href="https://api-documentation.brla.digital/"
                            role="link"
                            target="_blank"
                            type="button"
                          >
                            DEVELOPER DOCS
                            <svg
                              width={18}
                              height={18}
                              viewBox="0 0 18 18"
                              fill="none"
                              stroke="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              className="cb-icon cb-icon-externallink ml-2 pointer-events-none"
                              aria-hidden="true"
                              data-testid="icon-externallink"
                              focusable="false"
                              role="img"
                            >
                              <path
                                d="M7 3H3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-4M11 1h6m0 0v6m0-6L7 11"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </header>
                    <nav className="cb-tabs-nav mb-5 mt-7" data-testid="tabs-nav">
                      <a
                        className="cb-tabs-nav-item"
                        href="/api-keys"
                      >
                        API keys
                      </a>
                    
                      <a
                        className="cb-tabs-nav-item selected"
                        href="/subscriptions"
                      >
                        Subscriptions
                      </a>
                    </nav>
                  </div>
                </div>
                <div className="px-16">
                  <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
                    <div className="flex justify-between">
                      <div>
                        <h1 className="mb-3 flex-1">Subscription Endpoints</h1>
                        <p className="flex-1 text-sm text-black-400">
                          Receive live notifications from Circle via subscription
                          endpoints.
                        </p>
                      </div>
                      <button
                        className="cb-button base primary h-10 rounded-sm"
                        type="button"
                        data-testid="developer-mutation-button"
                      >
                        <span>ADD SUBSCRIPTION</span>
                      </button>
                    </div>
                    <div className="flex-1">
                      <h2 className="mb-3 flex-1 text-lg font-circular-book">
                        Endpoint URLs
                      </h2>
                      <div>
                        <div className="cb-table overflow-visible">
                          <table>
                            <thead>
                              <tr>
                                <th>URL</th>
                                <th>Status</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody className="m-0" />
                          </table>
                        </div>
                        <div className="cb-card surface_primary shadow_sm">
                          <div className="content text-center text-sm leading-5 text-black-500 font-circular-regular">
                            No endpoints have been added.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             <FooterLogged/>            </main>
          </div>
        </div>
            </>
      )
};
export default Subscriptions