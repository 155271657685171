import resets from './_resets.module.css';
import classes from './deposit_mobile.module.css';
import QRCode from 'qrcode.react';
import LogoB from '../../Components/Deposit/logo_b.png';
import { memo, useState, useEffect, forwardRef, useRef } from 'react';
import { ENDPOINT, MANTEINENCETOKEN, USDC_CONTRACT_ADDRESSES} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';

function DepositMobile() {
    const [users, setUsers] = useState([]);
    const [metodo, setMetodo] = useState('PIX - Pagamento instantâneo');
    const [metodoUrl, setMetodoUrl] = useState('https://brla.digital/static/media/pix-icon.png');
    const [listmetodo, setListMetodo] = useState(false);
    const [pendingRequests, setPendingRequests] = useState(1);
const [kychistoryData, setKycHistoryData] = useState([]);
const DepositRef = useRef();
const buttonRefDeposit = useRef(null);
    useEffect(() => {
        const loadData = async () => {
          const lastRequestDate = localStorage.getItem('lastRequestDate');
          const currentDate = new Date();
      
          if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
            // If less than a minute has passed since the last request, do not make a new request
            return;
          }
      
          const resUser = await fetch(`${ENDPOINT}/info`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN":MANTEINENCETOKEN
            },
          });
          const bodyUser = await resUser.json();
          localStorage.setItem('userData', JSON.stringify(bodyUser));
      
         
          localStorage.setItem('nextRequestDate', currentDate.toISOString());
      
          // After making the requests, store the current date as the last request date
          localStorage.setItem('lastRequestDate', currentDate.toISOString());
        };
      
        loadData();
      }, []);
      
      useEffect(() => {
        // Obtem os usuários do localStorage
        const loadedUsers = localStorage.getItem('userData');
        if (loadedUsers) {
          setUsers(JSON.parse(loadedUsers));
        }
      
        const loadedKYCHistory = localStorage.getItem('kychistory');
        if (loadedKYCHistory) {
          const KycHistory = JSON.parse(loadedKYCHistory);
          // Criando um objeto onde a chave é o ID e o valor é o item do histórico
          setKycHistoryData(KycHistory);
      
      
        };
      
      
      
        setPendingRequests(0);
      }, []);

      useEffect(() => {
        const handleClickOutside = (event) => {
          if (listmetodo && DepositRef.current && !DepositRef.current.contains(event.target) && !buttonRefDeposit.current.contains(event.target)) {
            setListMetodo(false);
            
          }
        };
      
        document.addEventListener('click', handleClickOutside);
      
        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      }, [listmetodo]);

      const handleCopy = () => {
        toast.success('Chave PIX copiada com sucesso!');
      };

      const handleCopyWallet = () => {
        toast.success('Endereço da wallet copiado com sucesso!');
      };
      
    return (
      <div className={`${resets.storybrainResets} ${classes.root}`}>
        <div className={classes.section}>
          <div className={classes.divH2dC43aaa00}>
            <div className={classes.frame9}>
              <div className={classes.labelVoceEnviaExatamente}>Como deseja depositar?</div>
            </div>
            <div
            ref={buttonRefDeposit} 
            onClick={() => {
 setListMetodo(!listmetodo)

}}  className={classes.divTwMoneyInput}>
              <div className={classes.pseudo}>
              <img style={{marginRight:'5px', height:'20px', width:"20px"}} src={metodoUrl}/>
                <div style={{fontSize:'16px'}} className={classes._10000}>{metodo}</div>
              </div>
              <div className={classes.divDFlex}>
                <div className={classes.iCurrencyFlag}>
                  <svg className={classes.icon} preserveAspectRatio='none' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M5.75 8.875L12 15.125L18.25 8.875H5.75Z' fill='#00DC84' />
  </svg>
                </div>
              </div>
            </div>
          </div>
          <>
  { listmetodo ?  
   <>
   <div
      ref={DepositRef}
 data-popper-reference-hidden="false"
 data-popper-escaped="false"
 data-popper-placement="bottom"
 className="np-panel np-panel--open"
 style={{
   position: "absolute",
   inset: "0px auto auto 0px",
   zIndex:1000,
   top:'180px',
   left:'0px'
 }}
>
 <div className="np-panel__content">
   <ul
     id="tw-calculator-source-select-listbox"
     role="listbox"
     tabIndex={-1}
     className="np-dropdown-menu np-dropdown-menu-desktop np-dropdown-menu-lg p-a-1"
   >

     <li className="np-dropdown-header np-text-title-group">
     Todos os métodos
     </li>
     <li
onClick={(e) => {
 e.stopPropagation();
 setMetodo('PIX - Pagamento instantâneo');
 setListMetodo(false);
 setMetodoUrl('https://brla.digital/static/media/pix-icon.png');

}}
       id="option-tw-calculator-source-select-BRL"
       aria-selected="true"
       role="option"
       tabIndex={-1}
      
     >
       <a>
         <div className="d-flex np-option-content">
           <div className="d-flex flex-column">
           <img style={{marginRight:'5px', height:'30px', width:"30px"}} src='https://brla.digital/static/media/pix-icon.png'/>
           </div>
           <div className="d-flex flex-column justify-content-center">
             <span className="np-text-body-large">
               PIX
               <span className="np-text-body-default m-l-1">
                 Sistema de pagamento do Brasil
               </span>
             </span>
           </div>
         </div>
       </a>
     </li>
   
  
     <li
    
       id="option-tw-calculator-source-select-USD"
       aria-selected="false"
       role="option"
       tabIndex={-1}
       className="np-dropdown-item clickable"
       onClick={(e) => {
         e.stopPropagation();
         setMetodo('BRLA - Stablecoin do real');
         setListMetodo(false);
         setMetodoUrl('https://brla.digital/favicon.png');
        
       }}
     >
       <a>
         <div className="d-flex np-option-content">
           <div className="d-flex flex-column">
             <img style={{marginRight:'5px', height:'30px', width:"30px"}} src='https://brla.digital/favicon.png'/>
           </div>
           <div className="d-flex flex-column justify-content-center">
             <span className="np-text-body-large">
               BRLA
               <span className="np-text-body-default m-l-1">
                 Stablecoin do real
               </span>
             </span>
           </div>
         </div>
       </a>



     </li>

     <li
    
    id="option-tw-calculator-source-select-USD"
    aria-selected="false"
    role="option"
    tabIndex={-1}
    className="np-dropdown-item clickable"
    onClick={(e) => {
      e.stopPropagation();
      setMetodo('USDC - USD Coin');
      setListMetodo(false);
      setMetodoUrl('https://brla.digital/static/media/usdc.png');
     
    }}
  >
    <a>
      <div className="d-flex np-option-content">
        <div className="d-flex flex-column">
        <img style={{marginRight:'5px', height:'30px', width:"30px"}} src='https://brla.digital/static/media/usdc.png'/>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <span className="np-text-body-large">
            USDC
            <span className="np-text-body-default m-l-1">
              USD Coin
            </span>
          </span>
        </div>
      </div>
    </a>



  </li>


  { !users?.autoConvert &&
  <li
    
    id="option-tw-calculator-source-select-USD"
    aria-selected="false"
    role="option"
    tabIndex={-1}
    className="np-dropdown-item clickable"
    onClick={(e) => {
      e.stopPropagation();
      setMetodo('USDT - USD Tether');
      setListMetodo(false);
      setMetodoUrl('https://brla.digital/static/media/usdt.png');
     
    }}
  >
    <a>
      <div className="d-flex np-option-content">
        <div className="d-flex flex-column">
        <img style={{marginRight:'5px', height:'30px', width:"30px"}} src='https://brla.digital/static/media/usdt.png'/>
        </div>
        <div className="d-flex flex-column justify-content-center">
          <span className="np-text-body-large">
            USDT
            <span className="np-text-body-default m-l-1">
              USD Tether
            </span>
          </span>
        </div>
      </div>
    </a>



  </li>

  }
  

  


    
   
   </ul>
 </div>
</div></>
  : 
    <>
      <div></div>
    </>
  }
</>





          <div className={classes.frame2}>
          <>
            {metodo==='PIX - Pagamento instantâneo' ? (
               <>
            <div style={{width:"200px", textAlign:'center'}} className={classes.pIXQRCode}>PIX QR Code</div>
             
  <QRCode
    value={users.brCode}
    size={200}
    bgColor="transparent"
    fgColor="#000000"
    renderAs="svg"
    imageSettings={{
      src: "https://brla.digital/static/media/pix-icon.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    }}
  />
            <div style={{display:'flex',textAlign:'center', justifyContent:'center', marginLeft:'30px'}} className={classes.frame16}>
              <div className={classes.pIXCODE123456789}>{users?.pixKey}</div>
              <CopyToClipboard text={users?.pixKey} onCopy={handleCopy}>
      <button className='button-copy' ><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="black"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
            </div>
            </>) : metodo==='BRLA - Stablecoin do real' ? <>
            <div style={{width:'200px', textAlign:'center'}} className={classes.pIXQRCode}>Endereço da wallet</div>
             
  <QRCode
    value={users?.walletAddress}
    size={200}
    bgColor="transparent"
    fgColor="#000000"
    renderAs="svg"
    imageSettings={{
      src: "https://brla.digital/static/media/logo_b.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    }}
  />
            <div style={{display:'flex',textAlign:'center', justifyContent:'center', marginLeft:'30px'}} className={classes.frame16}>
              <div className={classes.pIXCODE123456789}>{users?.walletAddress}</div>
              <div className={classes.image5}><img style={{width:'20px', height:"20px"}} src='https://brla.digital/static/media/polygon-icon.png'/></div>
              <CopyToClipboard text={users?.walletAddress} onCopy={handleCopyWallet}>
      <button className='button-copy' ><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="black"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
            </div>
            </> : metodo==='USDC - USD Coin' ? <>
            <div style={{width:'200px', textAlign:'center'}} className={classes.pIXQRCode}>Endereço da wallet</div>
            
             
  <QRCode
    value={users?.walletAddress}
    size={200}
    bgColor="transparent"
    fgColor="#000000"
    renderAs="svg"
    imageSettings={{
      src: "https://brla.digital/static/media/usdc.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    }}
  />
            <div style={{display:'flex',textAlign:'center', justifyContent:'center', marginLeft:'30px'}} className={classes.frame16}>
              <div className={classes.pIXCODE123456789}>{users?.walletAddress}</div>
              <div className={classes.image5}><img style={{width:'20px', height:"20px"}} src='https://brla.digital/static/media/polygon-icon.png'/></div>
              <CopyToClipboard text={users?.walletAddress} onCopy={handleCopyWallet}>
      <button className='button-copy' ><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="black"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
            </div>
            </> : metodo==='USDT - USD Tether' ? <>
            <div style={{width:'200px', textAlign:'center'}} className={classes.pIXQRCode}>Endereço da wallet</div>
             
  <QRCode
    value={users?.walletAddress}
    size={200}
    bgColor="transparent"
    fgColor="#000000"
    renderAs="svg"
    imageSettings={{
      src: "https://brla.digital/static/media/usdt.png",
      x: null,
      y: null,
      height: 24,
      width: 24,
      excavate: true,
    }}
  />
            <div style={{display:'flex',textAlign:'center', justifyContent:'center', marginLeft:'30px'}} className={classes.frame16}>
              <div className={classes.pIXCODE123456789}>{users?.walletAddress}</div>
              <div className={classes.image5}><img style={{width:'20px', height:"20px"}} src='https://brla.digital/static/media/polygon-icon.png'/></div>
              <CopyToClipboard text={users?.walletAddress} onCopy={handleCopyWallet}>
      <button className='button-copy' ><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="black"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
            
            </div>
            </> : <div></div>}
            </>
          </div>
         
        </div>
        <ToastContainer />
      </div>
    );
  };
  export default DepositMobile;
  