import logoImage from '../Home/images/logo.png';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { ENDPOINT} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function ForgotPassword() {
    const {
        register,
        handleSubmit,
        formState: { isValid },
      } = useForm({ mode: 'onChange' });
    
      const navigate = useNavigate();
      const [successMessage, setSuccessMessage] = useState('');
    
      useEffect(() => {
        if (successMessage) {
          const timer = setTimeout(() => {
            navigate('/login', { replace: true });
          }, 3000);
          return () => clearTimeout(timer);
        }
      }, [successMessage, navigate]);
    
      const onSubmit = async (data) => {
        // Mantemos a chamada à API aqui
        await fetch(`${ENDPOINT}/forgot-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: data.username,
          }),
        });
    
        // Sempre mostramos a mensagem de sucesso e redirecionamos, independentemente da resposta da API
        toast.success('Se o e-mail existir, as instruções para redefinição de senha serão enviadas.');
      };


      const handleDoneClick = () => {
        navigate('/login'); 
      };


    return (
         
<>
 
  
  <div id="okta-login-container" style={{ display: "block" }}>
    <main
      data-se="auth-container"
      tabIndex={-1}
      id="okta-sign-in"
      className="auth-container main-container no-beacon"
      style={{ display: "block" }}
      
    >

      <div className="okta-sign-in-header auth-header">
        
        <h1>
          
        </h1>
        <div
          data-type="beacon-container"
          className="beacon-container"
          style={{ transform: "scale(0, 0)", textIndent: 0 }}
        />
      </div>
      

      <div className="auth-content" style={{display:'flex', justifyContent:'center'}}>
        <div className="auth-content-inner">
          <div className="primary-auth">
            <form
              noValidate="novalidate"
              method="POST"
              action=""
              data-se="o-form"
              slot="content"
              id="form32"
              className="primary-auth-form o-form o-form-edit-mode"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div
                data-se="o-form-content"
                className="o-form-content o-form-theme clearfix"
              >
            
                <h2
                  data-se="o-form-head"
                  className="okta-form-title o-form-head"
                  style={{display:'flex', justifyContent:'center'}}
                >
            
                  Redefinir senha
            
                </h2>
             
                <div
                  className="o-form-error-container"
                  data-se="o-form-error-container"
                />
                <div
                  className="o-form-fieldset-container"
                  data-se="o-form-fieldset-container"
                >
                  <div
                    data-se="o-form-fieldset"
                    className="o-form-fieldset o-form-label-top margin-btm-5"
                  >
                    <div
                      data-se="o-form-label"
                      className="okta-form-label o-form-label"
                    >
                      <label htmlFor="okta-signin-username">
                        E-mail
                      </label>
                    </div>
                    <div
                      data-se="o-form-input-container"
                      className="o-form-input"
                    >
                      <span
                        data-se="o-form-input-username"
                        className="o-form-input-name-username o-form-control okta-form-input-field input-fix"
                      >
                       <input
  type="text"
  placeholder=""
  name="username"
  id="okta-signin-username"
  defaultValue=""
  aria-label=""
  autoComplete="username"
  aria-required="true"
  required=""
  style={{ width: '400px' }}
  {...register('username', {
    required: true,
    
  })}
/>

                      </span>
                    </div>
                  </div>
               
                  <div
                    data-se="o-form-fieldset"
                    className="o-form-fieldset o-form-label-top margin-btm-0"
                  >
                    <div
                      data-se="o-form-input-container"
                      className="o-form-input"
                    >
                      <span
                        data-se="o-form-input-remember"
                        className="o-form-input-name-remember"
                      >
                      
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <button
                  className="cb-button base primary mt-12 h-10 w-full py-2 px-4 disabled:bg-black-200"
                  type="submit"
                  disabled={!isValid}
                >
                  <span>Redefinir</span>
                </button>
            </form>
           
         
          </div>
 
          <button
                                  className="cb-button base secondary w-60 center-div"
                                  type="button"
                              onClick={handleDoneClick}
                                  style={{display:'flex', justifyContent:'center', marginTop:'70px'}}
                                 

                               
                                >
                                  <span>Voltar</span>
                                </button>  
        </div>

      </div>
    </main>
  </div>

  {/*
  "OktaUtil" defines a global OktaUtil object
  that contains methods used to complete the Okta login flow.
     */}

<ToastContainer />
</>
      
    );
}

export default ForgotPassword;
