import { useState, useEffect, useRef } from "react";
import { BrowserRouter, useLocation, useNavigate } from "react-router-dom";
import RoutesApp from "./routes";
import { createContext } from "react";
import AppWrapper from './AppWrapper';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import WebSocketContext, { WebSocketProvider } from "./Components/Websocket/WebSocketContext";
export const PositionTabContext = createContext(null);

function MainApp() {

  const navigate = useNavigate();
  const { t, i18n} = useTranslation();
  const location = useLocation();

  useEffect(() => {
    window.gtag('config', 'G-FBR6XVYJML', {
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <WebSocketProvider>
    <AppWrapper navigate={navigate}>
      <RoutesApp />
    </AppWrapper>
    </WebSocketProvider>
  
  );
}

export default function App() {
  return (
 
    <BrowserRouter>
      <MainApp />
    </BrowserRouter>

  );
}