import { memo, useState } from 'react';

import resets from '../Home/_resets.module.css';
import { FrameIcon2 } from '../Home/FrameIcon2.js';
import { FrameIcon } from '../Home/FrameIcon.js';
import classes from './IpadBRLATokenAbout.module.css';
import { useTranslation } from 'react-i18next';
import brazilFlag from '../Home/images/brazil.png'; // import your flag images
import usaFlag from '../Home/images/usa.png';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

declare var grecaptcha: any;

function BRLATokenIpad() {
  const { t, i18n} = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };

  function onSubmit(e) {
    e.preventDefault();
  
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdIKIQmAAAAABNZn-t5ilym4wSfgsAkLuzBtn00', {action: 'submit'}).then(function(token) {
        const data = {
          name: name,
          email: email,
          phone: phone,
          company: company,
          message: message,
          captcha: token
        };
  
        fetch('https://api.brla.digital:4567/v1/contact-us', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(response => {
          if (response.ok) {
            toast.success('Message sent successfully. We will respond soon.', {
              autoClose: 6000
            });
            setTimeout(() => window.location.reload(), 7000);
          } else {
            throw new Error('Failed to send message');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to send message. Please try again.', {
            autoClose: 6000
          });
          setTimeout(() => window.location.reload(), 7000);
        });
      });
    });
  }



  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.header}>
        <div style={{marginLeft:'-15px'}} className={classes.logo}></div>
        <div className={classes.ulMainNavigation}>
  <a href='/'>
    <div className={classes.home}>Home</div>
  </a>
  <div className={classes.dropdown}>
    <div className={classes.about}>{t('menu_about')}</div>
    <div className={classes.dropdownContent}>
      <a href='/about-brla-token'>BRLA Token</a>
      <a href='/about-brla-digital'>BRLA Digital</a>
    </div>
  </div>
  <a href='/login'>
    <div className={classes.login}>Login</div>
  </a>
  <a href='signup'>
    <div className={classes.sIGNUP}>{t('menu_sign')}</div>
  </a>
  <a href='https://brla-superuser-api.readme.io/reference/welcome' target='_blank'>
    <div className={classes.aPIREFERENCE}>{t('menu_api')}</div>
  </a>
  <a href='#contact'>
    <div className={classes.cONTACT}>{t('menu_contato')}</div>
  </a>
  <div>
      <img 
        src={brazilFlag} 
        alt="Portuguese" 
        onClick={() => changeLanguage('ptbr')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'ptbr' ? 1 : 0.5, width:'30px', height:'30px', marginRight:'20px' }} 

      />
      <img 
        src={usaFlag} 
        alt="English" 
        onClick={() => changeLanguage('en')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'en' ? 1 : 0.5, width:'30px', height:'30px' }} 
      />
    </div>


</div>
      </div>
      <div className={classes.section1}>
        <div className={classes.frame39}>
          <div className={classes.bRLAToken}>BRLA Token</div>
          <div className={classes.bRLAIsABRLPeggedStablecoinCrea}>
            {t('token_bottom')}
          </div>
          <div className={classes.frame40}>
            <div className={classes.frame2}>
              <div className={classes.openYourAccount}>
                <a href='/signup' style={{color:'white'}}>
                  {t('call_brla')}
                </a>
                </div>
            </div>
            <a href='https://docsend.com/view/6ufui53iazaks38x' target='_blank'>
            <div className={classes.frame1}>
              <div className={classes.checkTheWhitepaper}>{t('call_whitepaper')}</div>
            </div>
            </a>
          </div>
        </div>
        <div className={classes.frame41}>
          <div className={classes.tela_home}></div>
          <div className={classes.tela_swap}></div>
          <div className={classes.logocoin}></div>
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.frame43}>
          <div className={classes.transparentAndSecure}>{t('transparent')}</div>
          <div className={classes.allTheBRLATokensAreStrictlyBac}>
            {t('transparent_bt')}
          </div>
          <a target='_blank' href='https://brladigital.notion.site/BRLA-Transparency-Page-238ba143aa2f4338902ee91ebe50298a?pvs=4'>
          <div className={classes.frame12}>
            <div className={classes.transparencyPage}>{t('transparent_page')}</div>
          </div>
          </a>
        </div>
        <div className={classes.frame42}>
          <div className={classes.image1}></div>
        </div>
      </div>
      <div className={classes.section3}>
        <div className={classes.frame45}>
          <div className={classes.image2}></div>
        </div>
        <div className={classes.frame44}>
          <div className={classes.accessTheBestOfCrypto}>{t('access_best')}</div>
          <div className={classes.withOver6MillionCryptoUsersInB}>
            {t('access_best_bt')}
          </div>
        </div>
      </div>
      <div className={classes.section4}>
        <div className={classes.frame47}>
          <div className={classes.bRLAAccount}>{t('brla_account')}</div>
          <div className={classes.createYourBRLAAccountAndStartU}>
            {t('create_brla')}
          </div>
          <div className={classes.frame13}>
            <div className={classes.openYourAccount2}>
                <a style={{color:'black'}} href='/signup'>
                  {t('call_brla')}
                </a>
                </div>
          </div>
        </div>
        <div className={classes.frame46}>
          <div className={classes.tela_home2}></div>
        </div>
      </div>
      <div id='contact' className={classes.footerFooter}>
        <div className={classes.contact}>{t('call_contact')}</div>
        <div className={classes.divRow}>
        <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.frame32}>
        <div className={classes.inputMobileWidth}>
          <input
            className={classes.divPlaceholder}
            type="text"
            placeholder={t('name')}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={classes.inputMobileWidth2}>
          <input
            className={classes.divPlaceholder2}
            type="email"
            placeholder={t('email')}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.frame33}>
        <div className={classes.inputMobileWidth3}>
          <input
            className={classes.divPlaceholder3}
            type="tel"
            placeholder={t('phone')}
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className={classes.inputMobileWidth4}>
          <input
            className={classes.divPlaceholder4}
            type="text"
            placeholder={t('company')}
            value={company}
            onChange={e => setCompany(e.target.value)}
          />
        </div>
      </div>
      <textarea
        className={classes.textarea}
        placeholder={t('message')}
        value={message}
        onChange={e => setMessage(e.target.value)}
      ></textarea>
      <div className={classes.frame34}>
        <button className={classes.button} type="submit">
          <div className={classes.submit}>{t('submit')}</div>
        </button>
      </div>
    </form>
          <div className={classes.divHs_cos_wrapper_module_16619}>
            <div className={classes.followBRLAOnSocial}>{t('follow_social')}</div>
            <div className={classes.divSocialGrid}>
            <a href="https://twitter.com/BRLAdigital" target="_blank">
              <div className={classes.frame}>
                <FrameIcon className={classes.icon} />
              </div>
              </a>
              <a href="https://www.linkedin.com/company/brla-digital/" target="_blank">
              <div className={classes.frame3}>
                <FrameIcon2 className={classes.icon2} />
              </div>
              </a>
            </div>
            <div className={classes.eMailInfoBrlaDigital}>E-mail: info@brla.digital</div>
            <div className={classes.phone55113777742}>{t('phone')}: +55 11 3777-0742</div>
            <div className={classes.addressRuaUrussui125Cj84SaoPau}>{t('address')}: Rua Urussui, 125 cj. 84 - São Paulo</div>
          </div>
        </div>
        <div className={classes.divRow2}>
          <div className={classes.frame31}>
            <div className={classes.logocoin2}></div>
          </div>
          <div className={classes.footer}>
            <div className={classes.digitalAssetsAreSubjectToANumb}>
              {t('disclaimer')}
            </div>
            <div className={classes._2023BRLADigital}>© 2023 BRLA Digital</div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default BRLATokenIpad;
