import WalletHeader from "../../Components/WalletHeader";
import React, { useEffect, useState } from 'react';
import { ENDPOINT} from '../../variaveis';
import FooterLogged from "../../Components/FooterLogged";
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function SwapHistory() {
  const [filteredData, setFilteredData] = useState([]);
  const [tooltip, setTooltip] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [startDate, setStartDate] = useState(null);
const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();
  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  const [SwapData, setSwapData] = useState([]);
 
  useEffect(() => {
    const loadData = async () => {
      const lastRequestDate = localStorage.getItem('lastRequestDate');
      const currentDate = new Date();
  
      if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
        // If less than a minute has passed since the last request, do not make a new request
        return;
      }
      const resSwap = await fetch(`${ENDPOINT}/swap/history?page=1&pageSize=100`, {
        method: 'GET',
        credentials: 'include',
      });
      const bodySwap = await resSwap.json();
      localStorage.setItem('swapHistory', JSON.stringify(bodySwap.swapLogs || []));
  
      localStorage.setItem('nextRequestDate', currentDate.toISOString());
      
      // After making the requests, store the current date as the last request date
      localStorage.setItem('lastRequestDate', currentDate.toISOString());
    };
  
    loadData();
  }, []);
  

  useEffect(() => {
    // Obtem os dados do histórico do localStorage
    const loadedSwapData = localStorage.getItem('swapHistory');
    if (loadedSwapData) {
        setSwapData(JSON.parse(loadedSwapData));
        console.log("SOSOSMSOS: ", JSON.parse(loadedSwapData))
    };
    setPendingRequests(0);
  }, []);

 

  useEffect(() => {
    // Se não há requisições pendentes, então as requisições acabaram
    if (pendingRequests === 0) {
      // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
      setIsLoading(false);
    } 
  }, [pendingRequests]);

  if (isLoading) {
    // Retorne um spinner de loading, um placeholder ou null
    return <div></div>;
  }

  function convertToCSV(data) {
    const headers = ["ID", "Created At", "Wallet Address", "Blockchain", "BRLA Amount", "USDC Amount"];
    
    const csvData = data.map(item => [
      item.blockchainTx,
      formatDate(item.swapCreatedAt),
      item.walletAddress,
      item.chain,
      (item.brlaAmount/100).toFixed(2),
      (item.usdcAmount/100).toFixed(2),
      
    ]);
    
    const csvContent = [headers, ...csvData]
      .map(e => e.join(","))
      .join("\n");
    
    return csvContent;
  }
  
  function handleExport() {
    const csvData = convertToCSV(SwapData);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "export.csv");
  }

 


  
    return (<>
        <div id="__next">
          <div className="grid w-full min-h-screen font-circular">
            <WalletHeader/>
            <main className="flex flex-col bg-surface-background" >
              <div className="grid flex-1 gap-y-12 pt-12 content-start">
                <div className="px-16">
                  <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
                    <a
                      className="cb-button base text gap-2.5 p-0 text-sm capitalize text-black-400 font-circular-regular hover:text-blue-400 main-container-6"
                      href="/wallet"
                      role="link"
                    >
                 <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        className="cb-icon cb-icon-chevronleftlight pointer-events-none"
                        aria-hidden="true"
                        data-testid="icon-chevronleftlight"
                        focusable="false"
                        role="img"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="m15 19-7-7 7-7"
                        />
                      </svg>
                      Home
                    </a>
                    <header className="cb-header main-container-6">
                      <div className="cb-header-head primary ">
                        <h1 className="title">Swap History</h1>
                        <div className="actions">
                          <button className="cb-button sm primary" type="button" onClick={handleExport}>
                            <span>
                              <span>Export All</span>
                              <span className="ml-1 normal-case">(.csv)</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={16}
                                height={16}
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                className="cb-icon cb-icon-download ml-2 pointer-events-none"
                                aria-hidden="true"
                                data-testid="icon-download"
                                focusable="false"
                                role="img"
                              >
                                <path
                                  fillRule="evenodd"
                                  d="M3 17a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm3.293-7.707a1 1 0 0 1 1.414 0L9 10.586V3a1 1 0 1 1 2 0v7.586l1.293-1.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </header>
                    <section data-testid="payments-activity ">
                      <div className="cb-card surface_primary shadow_md main-container-6">
                        <div className="content p-6">
                     {/*     <div
                            className="grid gap-6 mb-5 grid-cols-9"
                            data-testid="table-filters"
                          >
                            <span
                              className="w-full col-start-5 col-span-3"
                              data-testid="table-filter-date-range-createDate"
                            >
                              <span className="cb-date-picker">
                                <div className="react-datepicker-wrapper">
                                  <div className="react-datepicker__input-container">
                                    <div
                                      className="cb-input floating-label no-label icon-right w-full rounded-sm leading-none text-black-600 outline-none font-circular-regular focus:outline-none focus:ring"
                                      data-testid="input-container"
                                    >
                                      <div className="input-container">
                                      <DatePicker
  selected={startDate}
  onChange={date => setStartDate(date)}
  selectsStart
  startDate={startDate}
  endDate={endDate}
/>
<DatePicker
  selected={endDate}
  onChange={date => setEndDate(date)}
  selectsEnd
  startDate={startDate}
  endDate={endDate}
  minDate={startDate}
/>
                                        <svg
                                          width={18}
                                          height={18}
                                          viewBox="0 0 18 18"
                                          fill="none"
                                          stroke="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="cb-icon cb-icon-calendar text-black-200 pointer-events-none"
                                          aria-hidden="true"
                                          data-testid="icon-calendar"
                                          focusable="false"
                                          role="img"
                                        >
                                          <path
                                            d="M5.667 4.833V1.5m6.666 3.333V1.5m-7.5 6.667h8.334m-10 8.333h11.666c.92 0 1.667-.746 1.667-1.667v-10c0-.92-.746-1.666-1.667-1.666H3.167c-.92 0-1.667.746-1.667 1.666v10c0 .92.746 1.667 1.667 1.667Z"
                                            strokeWidth={2}
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </span>
                            </span>
                            <div
                              className="cb-dropdown drop-direction-down filled w-full col-start-auto col-span-2"
                              data-testid="table-filter-dropdown-type"
                            >
                              <button
                                className="toggle"
                                type="button"
                                id="downshift-0-toggle-button"
                                aria-haspopup="listbox"
                                aria-expanded="false"
                                aria-labelledby="downshift-0-label downshift-0-toggle-button"
                                aria-label="All types"
                              >
                                <span className="flex items-center">All types</span>
                                <svg
                                  width={6}
                                  height={6}
                                  viewBox="0 0 10 6"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-chevrondown caret pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-chevrondown"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414Z"
                                  />
                                </svg>
                              </button>
                              <div className="menu">
                                <ul
                                  className="dropdown-scroll-container max-menu-items-6 show-overflow-none"
                                  id="downshift-0-menu"
                                  role="listbox"
                                  aria-labelledby="downshift-0-label"
                                  tabIndex={-1}
                                />
                              </div>
                            </div>
                          </div> */}
                          <div
                            className="overflow-x-auto"
                            data-testid="table-card-body"
                          >
                                                  <nav className="cb-tabs-nav mt-7 mb-5" data-testid="tabs-nav">
  <a
    className="cb-tabs-nav-item"
    id="layout:page.platform.payments.title"
    href="/activity"
  >
    BRLA
  </a>
  <a
    className="cb-tabs-nav-item selected"
    id="layout:page.platform.payment-intents.title"
    href="/swap-history"
  >
    Swap
  </a>
  
</nav>
                            <div>
                              <table style={{border:'none'}} className="advanced-table border-collapse table-fixed w-full">
                                <thead data-testid="header">
                                  <tr>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '150px', background:'none', color:'grey', border:'none', textAlign: 'center' }} 
                                    >
                                      TXID
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
                                    >
                                      Created At
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '285px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
                                    >
                                      Wallet Address
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
                                    >
                                      Blockchain
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
                                    >
                                      BRLA Amount
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
                                    >
                                      USDC Amount
                                    </th>
                                    
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
                                    >
                                      Swap Direction
                                    </th>

                                    <th
        className=""
        colSpan={1}
        style={{ width: '110px', background:'none', color:'grey', border:'none', textAlign: 'center' }}
      >
        Status
      </th>


                                  </tr>
                                </thead>
                                <tbody>
    {SwapData.map((item, index) => (
    <tr key={index}>
    <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
      {item.smartContractOps[0]?.tx.substring(0, 3)}...{item?.smartContractOps[0]?.tx.substring(item?.smartContractOps[0]?.tx.length - 3)}
      <CopyToClipboard text={item?.smartContractOps[0]?.tx}>
        <a 
          href={`https://polygonscan.com/tx/${item?.smartContractOps[0]?.tx}`} 
          target="_blank"
          rel="noopener noreferrer"
          style={{color: '#00dc84', marginLeft: '5px'}} 
        >
          see tx
        </a>
      </CopyToClipboard>
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '110px', textAlign: 'center', border:'none' }}>
      {formatDate(item?.smartContractOps[0]?.createdAt)}
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '285px', textAlign: 'center', border:'none' }}>
      {item.walletAddress.substring(0, 3)}...{item.walletAddress.substring(item.walletAddress.length - 3)}
      <CopyToClipboard text={item.walletAddress}
        onCopy={() => toast.success("ID copied to clipboard!")}>
        <a 
          href="#" 
          style={{color: '#00dc84', marginLeft: '5px'}}
        >
          copy
        </a>
      </CopyToClipboard>
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '110px', textAlign: 'center', border:'none' }}>
      {item.chain}
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '110px', textAlign: 'center', border:'none' }}>
      {(item.brlaAmount/100).toFixed(2)}
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '110px', textAlign: 'center', border:'none' }}>
      {(item.usdAmount/100).toFixed(2)}
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '110px', textAlign: 'center', border:'none' }}>
    {item.usdToBrla ? `${item.coin} ➡️ BRLA` : `BRLA ➡️ ${item.coin}`}
    </td>
  
    <td className="text-sm leading-5 font-circular-book" style={{ width: '110px', textAlign: 'center', border:'none' }}>
  {item?.smartContractOps[0]?.posted ? (item?.smartContractOps[0]?.feedback ? (item?.smartContractOps[0]?.feedback.success ? 'Success' : item?.smartContractOps[0]?.feedback.errorMsg) : 'No feedback') : (item?.smartContractOps[0]?.notPostedReason ? `Failed - ${item?.smartContractOps[0]?.notPostedReason}` : 'Pending' )}
</td>

  </tr>
  
    ))}
  </tbody>
                              </table>
                              <div className="p-18">
                                <div
                                  className="grid h-full h-full w-full text-center font-circular content-center items-center justify-center"
                                  data-testid="lottie-notice-small"
                                >
                                  <div
                                    className="grid h-full mb-6 content-center items-center justify-center"
                                    data-testid="center-both"
                                  >
                                    <div
                                      className="cb-lottie-icon cb-lottie-icon-documents"
                                      data-testid="lottie-icon-documents"
                                      style={{ width: 64, height: 64 }}
                                    >
                                    {  SwapData.length == 0 && (     <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 468 468"
                                        width={468}
                                        height={468}
                                        preserveAspectRatio="xMidYMid meet"
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          transform: "translate3d(0px, 0px, 0px)",
                                          contentVisibility: "visible"
                                        }}
                                      >
                                        <defs>
                                          <clipPath id="__lottie_element_36">
                                            <rect
                                              width={468}
                                              height={468}
                                              x={0}
                                              y={0}
                                            />
                                          </clipPath>
                                          <clipPath id="__lottie_element_39">
                                            <path
                                              fill="#ffffff"
                                              clipRule="nonzero"
                                              d=" M17.5,215.5 C144.52499389648438,215.5 247.5,112.5260009765625 247.5,-14.5 C247.5,-141.52499389648438 144.52499389648438,-244.5 17.5,-244.5 C-109.5260009765625,-244.5 -212.5,-141.52499389648438 -212.5,-14.5 C-212.5,112.5260009765625 -109.5260009765625,215.5 17.5,215.5 C17.5,215.5 17.5,215.5 17.5,215.5"
                                              fillOpacity={1}
                                            />
                                          </clipPath>
                                          <clipPath id="__lottie_element_47">
                                            <path
                                              fill="#ffffff"
                                              clipRule="nonzero"
                                              d=" M-12.5,245.5 C114.5250015258789,245.5 217.5,142.5260009765625 217.5,15.5 C217.5,-111.5250015258789 114.5250015258789,-214.5 -12.5,-214.5 C-139.5260009765625,-214.5 -242.5,-111.5250015258789 -242.5,15.5 C-242.5,142.5260009765625 -139.5260009765625,245.5 -12.5,245.5 C-12.5,245.5 -12.5,245.5 -12.5,245.5"
                                              fillOpacity={1}
                                            />
                                          </clipPath>
                                        </defs>
                                        <g clipPath="url(#__lottie_element_36)">
                                          <g
                                            style={{ display: "block" }}
                                            transform="matrix(1,0,0,1,234,234)"
                                            opacity="0.9999993209596404"
                                          >
                                            <g
                                              opacity={1}
                                              transform="matrix(1,0,0,1,0,0)"
                                            >
                                              <path
                                                fill="rgb(239,247,255)"
                                                fillOpacity={1}
                                                d=" M0,230 C127.0250015258789,230 230,127.0260009765625 230,0 C230,-127.0250015258789 127.0250015258789,-230 0,-230 C-127.0260009765625,-230 -230,-127.0250015258789 -230,0 C-230,127.0260009765625 -127.0260009765625,230 0,230 C0,230 0,230 0,230z"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            clipPath="url(#__lottie_element_47)"
                                            style={{ display: "block" }}
                                            transform="matrix(1,0,0,1,247.2899932861328,218.48899841308594)"
                                            opacity={1}
                                          >
                                            <g
                                              opacity={1}
                                              transform="matrix(1,0,0,1,0,0.008626064285635948)"
                                            >
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fillOpacity={0}
                                                stroke="rgb(76,155,212)"
                                                strokeOpacity={1}
                                                strokeWidth="19.167"
                                                d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            clipPath="url(#__lottie_element_39)"
                                            style={{ display: "block" }}
                                            transform="matrix(1,0,0,1,217.2899932861328,248.48899841308594)"
                                            opacity={1}
                                          >
                                            <g
                                              opacity={1}
                                              transform="matrix(1,0,0,1,0,0)"
                                            >
                                              <path
                                                fill="rgb(239,247,255)"
                                                fillOpacity={1}
                                                d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                              />
                                              <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                fillOpacity={0}
                                                stroke="rgb(76,155,212)"
                                                strokeOpacity={1}
                                                strokeWidth="19.167"
                                                d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                              />
                                            </g>
                                          </g>
                                          <g
                                            style={{ display: "block" }}
                                            transform="matrix(1,0,0,1,203.48699951171875,241.51499938964844)"
                                            opacity={1}
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              fillOpacity={0}
                                              stroke="rgb(76,155,212)"
                                              strokeOpacity={1}
                                              strokeWidth="19.167"
                                              d=" M-25.996999740600586,0 C-25.996999740600586,0 25.996999740600586,0 25.996999740600586,0"
                                            />
                                          </g>
                                          <g
                                            style={{ display: "block" }}
                                            transform="matrix(1,0,0,1,217.2899932861328,207.58099365234375)"
                                            opacity={1}
                                          >
                                            <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              fillOpacity={0}
                                              stroke="rgb(76,155,212)"
                                              strokeOpacity={1}
                                              strokeWidth="19.167"
                                              d=" M-39.79999923706055,0 C-39.79999923706055,0 39.79999923706055,0 39.79999923706055,0"
                                            />
                                          </g>
                                        </g>
                                      </svg>)}
                                    </div>
                                  </div>
                                  {  SwapData.length == 0 && (   <p
                                    className="font-circular-regular leading-6 text-black-600 text-base"
                                    data-testid="subtitle"
                                  >
                                    No transactions
                                  </p>)}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
              <FooterLogged/>
            </main>
          </div>
        </div>
        <ToastContainer />
      </>
      )
};
export default SwapHistory;