import React, { useEffect, useState } from 'react';
import styles from './KYCLevel2.module.css'; // Importe o arquivo CSS
import { ENDPOINT} from '../../variaveis';
import idwSDKWeb from './idwSDKWeb'; // Adicione esta linha
import { useForm } from 'react-hook-form';
import Modal from './Modal'; // Adicione esta linha
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import logoImage2 from '../Home/images/logo3.png';
const FormsConfirm = () => {
    const navigate = useNavigate();
    const [cpf, setCpf] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [fullName, setFullName] = useState('');
    const [formattedDate, setFormattedDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { isValid },
      } = useForm({ mode: 'onChange' });
    

      const onSubmit = (data) => {
        setIsLoading(true);
        setCpf(data.cpf);
        setBirthDate(data.birthdate);
        setFullName(data.full_name);
        saveDataToSessionStorage(data.cpf, formattedDate, data.full_name);
        console.log(fullName);
        setIsLoading(false);
        setTimeout(() => {
            navigate('/kyc-pj-level-2');
          }, 3000);

      };
      

      
      
  
    
    function formatCPF(event) {
        const { value } = event.target;
        const formattedValue = value
          .replace(/\D/g, "") // Remove all non-digit characters
          .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after 3 digits
          .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after the next 3 digits
          .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Add a hyphen before the last 1 or 2 digits
      
        event.target.value = formattedValue;
      }
      
      const formatDate = (date) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date(date);
        return `${d.getFullYear()}-${monthNames[d.getMonth()]}-${String(d.getDate()+1).padStart(2, '0')}`;
      };
      
      const handleDateChange = (event) => {
        const formattedDate = formatDate(event.target.value);
        setFormattedDate(formattedDate);
      };
      
      const currentYear = new Date().getFullYear();
      const minDate = `${currentYear - 120}-01-01`;
      const maxDate = `${currentYear - 18}-12-31`;


      const saveDataToSessionStorage = (cpf, formattedDate, fullName) => {
        sessionStorage.setItem("cpf", cpf);
        sessionStorage.setItem("formattedDate", formattedDate);
        sessionStorage.setItem("fullName", fullName);
      };
      
  
    return (
      <div className={styles.container}>
        <main className={styles.main}>
        <div className="grid items-center justify-center text-center">
        <div className="fixed top-0 z-30 w-full bg-white sm:relative">
        <div className="relative">
          <header className="relative grid grid-cols-3 px-6 w-full sm:py-7 sm:h-32 h-20">
            <div
              className="flex col-start-2 items-center justify-center"
              data-testid="logo-container"
            >
              <h1 className="text-base">
                <span className="sr-only">BRLA</span>
                <img src={logoImage2}></img>
                <svg
                  className="sm:hidden"
                  data-testid="svg"
                  viewBox="102 50 910 236"
                  width="8em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <defs>
                    <linearGradient
                      data-testid="linear-gradient"
                      gradientUnits="userSpaceOnUse"
                      id="linearGradient"
                      x1="177.86"
                      x2="341.06"
                      y1="291.18"
                      y2="127.98"
                    >
                      <stop offset={0} stopColor="#b090f5" />
                      <stop offset={1} stopColor="#5fbfff" />
                    </linearGradient>
                    <linearGradient
                      data-testid="linear-gradient-2"
                      gradientUnits="userSpaceOnUse"
                      id="linearGradient2"
                      x1="96.43"
                      x2="259.64"
                      y1="207.75"
                      y2="44.55"
                    >
                      <stop offset={0} stopColor="#68d7fa" />
                      <stop offset={1} stopColor="#4ee498" />
                    </linearGradient>
                  </defs>
                  <path
                    d="M319.43,110.37l-5-8.78a5.14,5.14,0,0,0-8.11-1.08L294.82,112a5.17,5.17,0,0,0-.64,6.51,90.22,90.22,0,0,1,10,20.58l0,0a90.2,90.2,0,0,1-85.45,119,89.38,89.38,0,0,1-42.26-10.49l19.45-19.46a64.41,64.41,0,0,0,80.77-88.29,5.15,5.15,0,0,0-8.29-1.41L256.76,150a5.14,5.14,0,0,0-1.37,4.82l1,4.18a38.63,38.63,0,0,1-56.75,42.39l-5.13-2.94a5.13,5.13,0,0,0-6.2.83l-47.51,47.5a5.15,5.15,0,0,0,.51,7.73l7,5.37a114.86,114.86,0,0,0,70.46,23.88A116,116,0,0,0,319.43,110.37Z"
                    data-testid="icon-path"
                    fill="url(#linearGradient)"
                  />
                  <path
                    d="M289.21,75.82a114.83,114.83,0,0,0-70.46-23.89A116,116,0,0,0,118.06,225.37l5,8.77a5.16,5.16,0,0,0,8.12,1.09l11.48-11.48a5.19,5.19,0,0,0,.64-6.5,89.81,89.81,0,0,1-10-20.58l0,0a90.2,90.2,0,0,1,85.45-119A89.29,89.29,0,0,1,261,88.19l-19.46,19.45a64.39,64.39,0,0,0-87.21,60.23c0,1.07.29,5.95.38,6.79a64.76,64.76,0,0,0,6.07,21.27,5.16,5.16,0,0,0,8.3,1.41l11.64-11.65a5.15,5.15,0,0,0,1.38-4.81l-1-4.19a38.62,38.62,0,0,1,56.75-42.38l5.13,2.94a5.16,5.16,0,0,0,6.2-.83l47.5-47.5a5.16,5.16,0,0,0-.5-7.74Z"
                    data-testid="icon-path"
                    fill="url(#linearGradient2)"
                  />
                  <g
                    data-svg-origin="385.4800109863281 103.79999542236328"
                    data-testid="word-mark"
                    transform="matrix(1,0,0,1,0,0)"
                  >
                    <path
                      d="M484.48,199.84a4.7,4.7,0,0,0-6.09.14c-7.45,5.78-16.09,11.13-28.72,11.13-23.12,0-41.93-19.47-41.93-43.42s18.74-43.59,41.76-43.59c10,0,20.8,4.21,28.74,11.18a4.67,4.67,0,0,0,3.62,1.62,4.08,4.08,0,0,0,3-1.7l7.52-7.74a4.83,4.83,0,0,0,1.47-3.58,4.93,4.93,0,0,0-1.72-3.54C479,108.9,465.81,103.8,449.32,103.8c-35.2,0-63.84,28.82-63.84,64.24a63.94,63.94,0,0,0,63.84,63.89,60.32,60.32,0,0,0,43-17.3,5.32,5.32,0,0,0,1.52-3.85,4.21,4.21,0,0,0-1.36-3Z"
                      data-svg-origin="385.4800109863281 103.80000305175781"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M539.21,105.54H527.82a5,5,0,0,0-4.83,4.85v115a5,5,0,0,0,4.83,4.86h11.39a5,5,0,0,0,4.83-4.86V110.39A5,5,0,0,0,539.21,105.54Z"
                      data-svg-origin="522.989990234375 105.54000091552734"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M674,144.25c0-21.34-17.57-38.71-39.17-38.71H587.86a4.85,4.85,0,0,0-4.83,4.85v115a4.85,4.85,0,0,0,4.83,4.86h11.22a5,5,0,0,0,4.83-4.86V182.62h23.54L650,227.77a4.72,4.72,0,0,0,4.15,2.43h13.46a4.83,4.83,0,0,0,4.27-2.29,5.14,5.14,0,0,0,0-5L649,180.27C664.46,173.4,674,159.74,674,144.25Zm-21.05.35c0,10.83-8.93,20-19.5,20H604.26V125.49h29.15C644,125.49,652.91,134.24,652.91,144.6Z"
                      data-svg-origin="583.0299682617188 105.54000091552734"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M796.59,199.84a4.7,4.7,0,0,0-6.09.14c-7.45,5.78-16.09,11.13-28.72,11.13-23.12,0-41.93-19.47-41.93-43.42s18.73-43.59,41.75-43.59c10,0,20.8,4.21,28.75,11.18A4.65,4.65,0,0,0,794,136.9a4.08,4.08,0,0,0,3-1.7l7.52-7.74a4.82,4.82,0,0,0,1.46-3.58,4.88,4.88,0,0,0-1.71-3.54c-13.1-11.44-26.29-16.54-42.78-16.54-35.2,0-63.84,28.82-63.84,64.24a63.94,63.94,0,0,0,63.84,63.89,60.34,60.34,0,0,0,43-17.3,5.33,5.33,0,0,0,1.52-3.86,4.2,4.2,0,0,0-1.36-3Z"
                      data-svg-origin="697.6499633789062 103.79999542236328"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M901.35,210.94h-45.2V110.39a5,5,0,0,0-4.83-4.85H839.93a4.85,4.85,0,0,0-4.83,4.85v115a4.85,4.85,0,0,0,4.83,4.86h61.42a4.85,4.85,0,0,0,4.84-4.86v-9.55A4.85,4.85,0,0,0,901.35,210.94Z"
                      data-svg-origin="835.0999755859375 105.54000091552734"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                    <path
                      d="M1003.67,125a4.85,4.85,0,0,0,4.83-4.85v-9.73a4.85,4.85,0,0,0-4.83-4.85h-69a4.85,4.85,0,0,0-4.83,4.85v115a4.85,4.85,0,0,0,4.83,4.86h69a4.85,4.85,0,0,0,4.83-4.86v-9.55a4.85,4.85,0,0,0-4.83-4.85h-53V176.71h44.51a4.85,4.85,0,0,0,4.84-4.85v-9.72a5,5,0,0,0-4.84-4.86H950.7V125Z"
                      data-svg-origin="929.8399658203125 105.56999969482422"
                      data-testid="word-path"
                      fill="#3d3652"
                      transform="matrix(1,0,0,1,0,0)"
                    />
                  </g>
                </svg>
              </h1>
            </div>
          </header>
        </div>
     
      </div>
              <h2 style={{marginTop:'60px'}} className="mb-5 text-3xl text-black-600 font-circular-bold">
                KYC Level 2
              </h2>
              <h3 className="mb-12 text-lg text-black-300">
              Please complete the form below with your personal information to fulfill our KYC (Know Your Customer) requirements. 
              </h3>
            </div>
        </main>
          
       <form
       action=""
       className="flex flex-col items-center justify-center gap-y-10"
       onSubmit={handleSubmit(onSubmit)}
     >
       <div className="w-full max-w-116" data-testid="form">
         <div className="grid">
           <div className="box-content flex flex-col sm:flex-row sm:gap-4">
        
             <div
             className="cb-input floating-label no-label box-content h-16 w-full"
             data-testid="input-container"
           >
             <div className="input-container">
             <input
id="cb-input-entityName"
aria-labelledby="cb-input-entityName"
aria-describedby=""
className="input"
data-testid="input-entity-name"
placeholder="CPF"
defaultValue=""
name="cpf"
pattern="\d{3}\.\d{3}\.\d{3}-\d{2}"
maxLength="14"
onInput={formatCPF}
{...register('cpf', { required: true })}

/>

             </div>
           </div>
             <div
               className="cb-input floating-label no-label h-16 w-full"
               data-testid="input-container"
             >
               <div className="input-container">
               <input
id="cb-input-birthdate"
aria-labelledby="cb-input-birthdate"
aria-describedby=""
className="input"
data-testid="input-birthdate"
placeholder="Birth date"
defaultValue=""
name="birthdate"
{...register('birthdate', { required: true })}
type="date"
onChange={handleDateChange}
min={minDate} // Adicione esta linha para limitar a data mínima
max={maxDate} // Adicione esta linha para limitar a data máxima
/>
               </div>
             </div>
           </div>
        
         

           <div
               className="cb-input floating-label no-label h-16 w-full"
               data-testid="input-container"
             >
               <div className="input-container">
                 <input
                   id="cb-input-fullName"
                   aria-labelledby="cb-input-fullName"
                   aria-describedby=""
                   className="input"
                   data-testid="input-full-name"
                   placeholder="Legal full name"
                   defaultValue=""
                   name="full_name"
                   {...register('full_name', { required: true })}
                 />
               </div>
             </div>  


           
           
         </div>
         
         <button
           className="cb-button base primary mt-12 h-10 w-full py-2 px-4 disabled:bg-black-200"
           type="submit"
           disabled={!isValid }
          
         >
           <span className="content">{isLoading ? "Loading..." : "CONFIRM INFORMATION"}</span>
         </button>
         <div>


</div>
       </div>
    
     </form>
      <ToastContainer />
      </div>
    );
  };
  
  export default FormsConfirm;
  