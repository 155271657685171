import { Navigate, useLocation } from 'react-router-dom';

function PrivateRoute({ children }) {
  const tokenExpiry = localStorage.getItem('tokenExpiry');
  const currentTime = Math.floor(Date.now() / 1000);
  console.log("tempo atual: ", currentTime)
  console.log("token: ", tokenExpiry)
  const location = useLocation();

  return tokenExpiry && currentTime > tokenExpiry ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : (
    children
  );
}

export default PrivateRoute;
