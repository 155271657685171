
import resets from './_resets.module.css';
import classes from './Section.module.css';
import { forwardRef } from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDT_CONTRACT_ADDRESSES, USDC_NATIVE_CONTRACT_ADDRESSES} from '../../variaveis';
const TransactionDetailsBRL = forwardRef((props, ref) => {



  function formatDate(dateString) {
    // Cria um objeto Date a partir da string
    let date = new Date(dateString);
  
    // Ajusta para o fuso horário local
    date = new Date(date.getTime() );
  
    // Extrai os componentes da data
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
  
    // Mapeia os números dos meses para os nomes dos meses
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthName = monthNames[month];
  
    // Retorna a data formatada
    return `${monthName} ${day}, ${year}`;
  }



  function formatTime(dateString) {
    // Cria um objeto Date a partir da string
    let date = new Date(dateString);
  
    // Ajusta para o fuso horário local
    date = new Date(date.getTime() );
  
    // Extrai as componentes de hora e minuto
    let hour = date.getHours();
    const minute = date.getMinutes();
  
    // Converte a hora para o formato 12 horas
    let period = 'AM';
    if (hour >= 12) {
      period = 'PM';
      if (hour > 12) hour -= 12;
    } else if (hour === 0) {
      hour = 12;
    }
  
    // Retorna a hora formatada
    return `${hour}:${minute < 10 ? '0' : ''}${minute} ${period}`;
  }


  function transferReceipt(transferID, tx, chain) {
    if (props.transaction.type==='sell') {
      const url = `${ENDPOINT}/pay-out/receipt/${transferID}`;

      fetch(url, {
          method: 'GET', 
          headers: {
              'Content-Type': 'application/json'
          },
          credentials: 'include' // Inclui cookies para a primeira solicitação
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          const pdfUrl = data.url;
  
          // Cria um link para o PDF e aciona a abertura em uma nova aba
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.target = "_blank"; // Abre o PDF em uma nova aba
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
      });
    } else {
      let url;
  
      if (chain === "Polygon") {
        url = `https://polygonscan.com/tx/${tx}`;
      } else if (chain === "Tron") {
        url = `https://tronscan.org/#/transaction/${tx}`;
      } else {
        alert("Chain not supported.");
        return;
      }
    
      // Abre a URL no navegador
      window.open(url, '_blank');
    }
    
}





  return (
    <div ref={ref} style={{position:'absolute', zIndex:1000, marginLeft: 'auto', marginRight:'auto', left:0, right:0}} className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.frame16}>
        <div className={classes.frame2}>
          <div className={classes.transferReceipt}>Detalhes da transação</div>
          <div className={classes.frame20}>
            <div className={classes.conversionTime}>Data da transação:</div>
            <div className={classes.jul32023345PM}>{formatDate(props.transaction.updatedAt)} {formatTime(props.transaction.updatedAt)}</div>
          </div>
          <div className={classes.frame24}>
            <div className={classes.uSDTReceivedAmout}>Valor {props.transaction.amount.includes('(') ? 'pago' : 'recebido'} bruto de {props.transaction.coin}:</div>
            <div className={classes._736}>
  R$ {props.transaction
    ? props.transaction.amount.includes('(')
      ? props.transaction.amount.replace('(', '').replace(')', '')
      : (parseFloat(props.transaction.amount.replace(',','')) + parseFloat(props.transaction.fee)).toFixed(2)
    : 'Pendente'}
</div>

          </div>
          
          <div className={classes.frame21}>
            <div className={classes.totalFees}>Taxas:</div>
            <div className={classes._0736USDT}>R$ {parseFloat(props.transaction.fee).toFixed(2)}</div>
          </div>
          <div className={classes.frame22}>
            <div className={classes.bRLReceivedAmount}> Valor {props.transaction.amount.includes('(') ? 'pago' : 'recebido'} líquido de {props.transaction.coin}:</div>
            <div className={classes._3528BRL}>
  R$ {props.transaction.amount.includes('(')
    ? (parseFloat(props.transaction.amount.replace('(', '').replace(')', '').replace(',','')) - props.transaction.fee).toFixed(2)
    : parseFloat(props.transaction.amount.replace(',','')).toFixed(2)}
</div>

          </div>

        </div>

      </div>
      <div className={classes.frame21} style={{marginTop:'10px'}}>
            <div className={classes.totalFees}>Comprovante de transferência:</div>
            <div className={classes._0736USDT}> { ( props.transaction.status==='Completo' && (props.transaction.type==='sell' || props.transaction.type==='onchain-out' || props.transaction.type==='onchain-deposit')) ?
      <button  className='limpar-botao' onClick={(event) => 
        {event.stopPropagation();
          transferReceipt(props.transaction?.transferid, props.transaction?.tx , props.transaction?.chain);
        }
      } style={{marginLeft:"10px"}}> 
      <svg  class="w-6 h-6 text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="#ffffff" viewBox="0 0 20 20">
    <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z"/>
    <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z"/>
  </svg>
  </button> : <div></div>
}</div>
          </div>
    </div>
  );
});
export default TransactionDetailsBRL;
