import React, { createContext, useState, useEffect } from "react";
import { ENDPOINT } from '../../variaveis';

const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
  const [socket, setSocket] = useState(null);

  const connectWebSocket = () => {
    fetch(`${ENDPOINT}/websocket-token`, {
      method: 'POST',
      credentials: 'include',
      headers: { 
        "MAINTENANCE-TOKEN": "BRLAISTHEWAY"
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      const SOCKET_SERVER_URL = `wss://api.brla.digital:4567/v1/websocket/${data.token}`;
      const newSocket = new WebSocket(SOCKET_SERVER_URL);

      newSocket.onopen = event => {
        console.log('Conexão WebSocket estabelecida!', event);
        sessionStorage.setItem("wsConnected", "true");
      };

      newSocket.onmessage = event => {
        console.log('Mensagem recebida:', event.data);
      };

      newSocket.onclose = event => {
        console.log('Conexão WebSocket fechada:', event);
        sessionStorage.removeItem("wsConnected");
      };

      newSocket.onerror = error => {
        console.error('Erro na conexão WebSocket:', error);
      };

      setSocket(newSocket);
    })
    .catch(error => {
      console.error("Erro ao iniciar WebSocket:", error);
    });
  };

  useEffect(() => {
    // Verifique se o usuário está logado antes de tentar estabelecer a conexão WebSocket
    const userLoggedIn = localStorage.getItem("userLoggedIn");
    if (userLoggedIn === "true") {
      connectWebSocket();
    }

    return () => {
      if (socket) {
        socket.close();
        sessionStorage.removeItem("wsConnected");
      }
    };
  }, []);

  return (
    <WebSocketContext.Provider value={{ socket, connectWebSocket }}>
      {children}
    </WebSocketContext.Provider>
  );
}

export default WebSocketContext;
