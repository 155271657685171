
import WalletHeader from '../../Components/WalletHeader';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import '../Wallet/css/styles.css';
import ferramentaImage from '../Home/images/ferramenta2.png';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES} from '../../variaveis';
import FooterLogged from '../../Components/FooterLogged';
import { BRLAContractAbi } from '../../utils/abis';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AddBank from '../AddBank';
import BRLAIcon from '../Swap/logo_brla.png';
import UsdcIcon from '../Swap/usdc_symbol.png'
import QRCode from 'react-qr-code';
import Figma from '../../Components/FigmaTeste';
const responsiveStyle = {
  width: '100%',
  maxWidth: '100%',
  overflowX: 'hidden',
};
function Payments() {
  const [tooltip, setTooltip] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [users, setUsers] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [kychistoryData, setKycHistoryData] = useState([]);
  useEffect(() => {
    // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
    }

    // Obtem as carteiras do localStorage
    const loadedWallets = localStorage.getItem('wallets');
    if (loadedWallets) {
      setWallets(JSON.parse(loadedWallets));
    }

    // Obtem os dados do histórico do localStorage
    const loadedHistoryData = localStorage.getItem('historyData');
    if (loadedHistoryData) {
      let historyData = JSON.parse(loadedHistoryData);
    
      // Ordenando os dados do histórico pela data de atualização
      historyData = historyData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setHistoryData(historyData);
    };


    const loadedKYCHistory = localStorage.getItem('kychistory');
    if (loadedKYCHistory) {
      const KycHistory = JSON.parse(loadedKYCHistory);
      console.log("VAI: ", KycHistory)
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setKycHistoryData(KycHistory);


    };



    const fetchWallets = async () => {
    
      // Recuperando wallets do localStorage
      const storedWallets = localStorage.getItem('wallets');
      const WalletsData = storedWallets ? JSON.parse(storedWallets) : [];

      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider("https://polygon-rpc.com/"));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      const WalletsWithBalance = await Promise.all(WalletsData.map(async (wallet) => {
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[80002];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(wallet.walletAddress).call();
          return { ...wallet, BRLABalance: balance };
      }));

      setWallets(WalletsWithBalance);
      console.log("TESTE: ", WalletsWithBalance)
      

      
  };

  fetchWallets();
  setPendingRequests(0);

  }, []);

  function numberWithThousandSeparator(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function mapStatus(item, type) {
    if (!item) return "unknown";
  
    if (type === 'buy') {
      if (item?.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.success) return "minted";
      if (item?.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.success === false) return "failed";
      if (item.status === "PAID") return "paid";
      if (item.status === "UNPAID") return "unpaid";
    } else if (type === 'sell') {
      if (item?.transfers?.[0]?.feedbacks?.[0]?.logType === "success") return "transferred";
      if (item?.smartContractOps?.[0]?.feedback?.success) return "burned and pending transfer";
      if (item?.smartContractOps?.[0]?.feedback?.success === false) return "failed";
    }
    
  }

  useEffect(() => {
    const loadData = async () => {
      const lastRequestDate = localStorage.getItem('lastRequestDate');
      const currentDate = new Date();
  
      if (lastRequestDate && currentDate - new Date(lastRequestDate) < 10000) {
        // If less than a minute has passed since the last request, do not make a new request
        return;
      }
  
  
      const resPurchases = await fetch(`${ENDPOINT}/pay-in/history?page=1&pageSize=100`, {
        method: 'GET',
        credentials: 'include',
      });
      const resSales = await fetch(`${ENDPOINT}/pay-out/history?page=1&pageSize=100`, {
        method: 'GET',
        credentials: 'include',
      });
  
  
     
      const bodyPurchases = await resPurchases.json();
      const bodySales = await resSales.json();
      const purchases = bodyPurchases.depositsLogs || [];
      const sales = bodySales.transfersLogs || [];
  
      const depositsLogsWithType = bodyPurchases.depositsLogs.map(item => ({
        id: item.mintOps?.[0]?.smartContractOps?.[0].tx || 'pending payment',
        chain: item.chain,
        walletAddress: item.walletAddress,
        amount: numberWithThousandSeparator(item.amount/100),
        status: mapStatus(item, 'buy'),
        type: 'buy',
        updatedAt: item.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.[0]?.createdAt || item.mintOps?.[0]?.smartContractOps?.[0]?.createdAt || item.mintOps?.[0]?.createdAt || item.createdAt,
      }));
      
      const transfersLogsWithTypeAndRenamed = bodySales.transfersLogs.map(item => ({
        id: item.smartContractOps?.[0]?.tx || 'pending burn',
        chain: item.chain,
        walletAddress: item.walletAddress,
        amount: `(${numberWithThousandSeparator(item.amount/100)})`,
        type: 'sell',
        status: mapStatus(item, 'sell'),
        updatedAt: item.transfers?.[0]?.feedbacks?.[0]?.createdAt || item.transfers?.[0]?.createdAt || item.smartContractOps?.[0]?.feedback?.createdAt || item.smartContractOps?.[0]?.createdAt || item.createdAt, 
      }));
          
      const historyData = [...depositsLogsWithType, ...transfersLogsWithTypeAndRenamed];
  
      localStorage.setItem('nextRequestDate', currentDate.toISOString());
      localStorage.setItem('historyData', JSON.stringify(historyData));
  
      // After making the requests, store the current date as the last request date
      localStorage.setItem('lastRequestDate', currentDate.toISOString());
    };
  
    loadData();
  }, []);
  




  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  

  
  const navigate = useNavigate();
  const handleButtonWallet = () => {
    navigate("/addwallet");
  };
  const handleButtonTransfer = () => {
    navigate("/burn-fiat");
  };

  const handleButtonDeposit = () => {
    navigate("/mintfiat");
  };
  
  
  const [selectedWallet, setSelectedWallet] = useState('');
  const [selectedWalletBalance, setSelectedWalletBalance] = useState(0);

  

  const HandlePassKYC = async () => {
  
    
      try {
        const resHistory = await fetch(`${ENDPOINT}/pass-kyc`, {
          method: 'PATCH',
          credentials: 'include',
         
        });
  
        if (resHistory.ok) {
          toast.success('KYC successfully passed.');
          setTimeout(() => {
            navigate('/login');
          }, 3000); // Aguarda 5 segundos antes de executar o navigate
        } else {
          const errorData = await resHistory.json();
          toast.error(`Failed to pass KYC: ${errorData.message || 'An unknown error occurred.'}`);
        }
      } catch (error) {
       
        toast.error(`Failed to pass KYC: ${error.message || 'An unknown error occurred.'}`);
      }
    } 
  
  
  
    const handleConfirmClick = async () => {
      // Execute a confirmation action here (for example, call an API)
      setShowPopup(false); 
    
      // Get isPj from localStorage
      const isPj = localStorage.getItem('isPj');
    
      // Navigate based on the value of isPj
      if (isPj === 'true') {
        navigate('/kyc-pj-level-1');
      } else {
        navigate('/kyc-pf-level-1');
      }
    }
    




   
      const addTokenBRLA = async () => {
        try {
          // Check if MetaMask is installed
          if (!window.ethereum) {
            throw new Error('MetaMask is not installed');
          }
    
          // Get the user's current chainId
          const chainId = parseInt(window.ethereum.chainId);
    
          // Check if the current chainId has a corresponding BRLA contract
          if (!BRLA_CONTRACT_ADDRESSES[chainId]) {
            throw new Error('No BRLA contract for the current network');
          }
    
          // Add the token
          const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: BRLA_CONTRACT_ADDRESSES[chainId],
                symbol: 'BRLA',
                decimals: 18, // Assume que o token BRLA tem 18 casas decimais
                image: 'https://brla.digital/favicon.png', // Adicione a URL da imagem do token aqui
              },
            },
          });
    
          if (!wasAdded) {
            throw new Error('Something went wrong while trying to add the token');
          }
        } catch (error) {
          
        }
      };
      // No componente Wallet, dentro do useEffect que é chamado quando o componente é montado
// No componente Wallet, dentro do useEffect que é chamado quando o componente é montado000
// No componente Wallet, dentro do useEffect que é chamado quando o componente é montado
useEffect(() => {
  const kychistoryData = JSON.parse(localStorage.getItem('kychistory') || '[]');
  const shouldShowPopup = localStorage.getItem('showPopup');
  
  if (shouldShowPopup === null || shouldShowPopup === 'true') {
    
    setShowPopup(true);
    localStorage.setItem('showPopup', 'false');

  } 
}, []);

const colorPalette = ['#000000', '#FF0000', '#00FF00', '#0000FF', '#FFFF00', '#00FFFF', '#FF00FF', '#C0C0C0'];
const [bgColor, setBgColor] = useState('#ffffff');
const [qrColor, setQrColor] = useState('#000000');

const handleColorClick = (color) => {
  setQrColor(color);
};

const handleColorClickBg = (color) => {
    setBgColor(color);
  };

const handleCustomColorChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue[0] !== '#') {
      setQrColor('#' + inputValue);
    } else {
      setQrColor('#' + inputValue.slice(1));
    }
  };

  const handleCustomColorChangebg = (event) => {
    const inputValue = event.target.value;
    if (inputValue[0] !== '#') {
        setBgColor('#' + inputValue);
    } else {
        setBgColor('#' + inputValue.slice(1));
    }
  };


      const addTokenUSDC = async () => {
        try {
          // Check if MetaMask is installed
          if (!window.ethereum) {
           toast.error('MetaMask is not installed');
          }
    
          // Get the user's current chainId
          const chainId = parseInt(window.ethereum.chainId);
          console.log(chainId)
          console.log(USDC_CONTRACT_ADDRESSES[chainId])
          // Check if the current chainId has a corresponding USDC contract
          if (!USDC_CONTRACT_ADDRESSES[chainId]) {
            toast.error('No USDC contract for the current network');
          }
    
          // Add the token
          const wasAdded = await window.ethereum.request({
            method: 'wallet_watchAsset',
            params: {
              type: 'ERC20',
              options: {
                address: USDC_CONTRACT_ADDRESSES[chainId],
                symbol: 'USDC',
                decimals: 6, // USDC tem 6 casas decimais
                image: '', // Adicione a URL da imagem do token aqui
              },
            },
          });
    
          if (!wasAdded) {
            toast.error('Something went wrong while trying to add the token');
          }
        } catch (error) {
          
        }
      };

      useEffect(() => {
   
    }, []);
    const totalBalance = wallets.reduce((total, wallet) => total + Number(wallet.BRLABalance), 0);
         

    useEffect(() => {
      // Se não há requisições pendentes, então as requisições acabaram
      if (pendingRequests === 0) {
        // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
        setIsLoading(false);
      } 
    }, [pendingRequests]);

    if (isLoading) {
      // Retorne um spinner de loading, um placeholder ou null
      return <div></div>;
    }

    const handleClosePopup = () => {
      setShowPopup(false);
    }


    const statusColors = {
      'Pending': 'yellow',
      'Completed': 'green',
      'Canceled': 'red',
    };
    
    const StatusCell = ({status}) => (
      <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none', color: statusColors[status] }}>
        {status}
      </td>
    );  

    const statuses = ['Pending', 'Completed', 'Canceled'];

    const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];






    
return (
  
<div  id="__next">
{showPopup && users?.kyc?.level === 0 && (kychistoryData[0]?.status !== 'PENDING' || !kychistoryData[0]?.status) && (
  <div className="wallet-popup-overlay">
    <div className="wallet-popup" style={{position: 'relative'}}>
      <button style={{position: 'absolute', top: 0, right: 0, fontSize: '2em'}} className="close-popup" onClick={handleClosePopup}>X</button>
      <p className="warning" style={{display:"flex", justifyContent:'center', textAlign:'justify'}}>
      To access this page, you need to go through the verification process.
      <br/>
      <br/>
       Please follow the link to proceed.
      </p>
      <br/>
      <button className="cb-button base primary" onClick={handleConfirmClick}>
        Next verification Level
      </button>
    </div>
  </div>
)}
{showPopup && kychistoryData[0]?.status === "PENDING" && (
  <div className="wallet-popup-overlay">
    <div className="wallet-popup" style={{position: 'relative'}}>
      <button style={{position: 'absolute', top: 0, right: 0, fontSize: '2em'}} className="close-popup" onClick={handleClosePopup}>X</button>
      <p  style={{display:"flex", justifyContent:'center', textAlign:'justify', color:'black'}}>
        Your verification is being reviewed.
        <br/>
        <br/>
        You will receive an email once it has been processed.
      </p>
    </div>
  </div>
)}



  <div >
    <div
      style={{ backgroundColor: "#08383f !important" }}
      className="grid w-full min-h-screen font-circular"
    >
      <WalletHeader/>
      <main className="flex-grow flex flex-col bg-surface-background">
        <div className="grid flex-1 gap-y-12 content-start">
          <div data-testid="payments-page-header" className="px-16 mt-12">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header className="cb-header " >
                <div className="cb-header-head primary">
                  
                  <div className="actions main-container">
                    <div style={{display:'block'}}>
                  <h1 style={{ color: "#08383f" }} className="title  margin-titulo main-container-8">
                    Transactions
                  </h1>
                

                  </div>
                
              {/*    <button
                      style={{ backgroundColor: "#08383f" }}
                      type="button"
                      className="cb-button base primary"
                      data-testid="account-funding-button"
                      onClick={handleButtonWallet}
                    >
                      <span>Add wallet</span>
    </button> */}

<button
    style={{ color: "#008884 !important" }}
    type="button"
    className="cb-button base secondary"
    data-testid="account-funding-button"
    onClick={handleButtonTransfer}
    disabled={users?.kyc?.level === 0} // Desativa o botão se users.kyc.level é 0
>
    <span
        style={{
            color: "#008884 !important",
            borderColor: "#008884 !important"
        }}
    >
        Convert
    </span>
</button>
                    
                    <button
        style={{ backgroundColor: "#008884 !important" }}
        type="button"
        className="cb-button base primary"
        data-testid="account-funding-button"
        disabled={users?.kyc?.level === 0} // Desativa o botão se users.kyc.level é 0
        onClick={handleButtonDeposit}
    >
        <span>Transfer</span>
    </button>

               
                    
    <button
        style={{ backgroundColor: "#08383f" }}
        type="button"
        className="cb-button base primary"
        data-testid="account-funding-button"
        disabled={users?.kyc?.level === 0} // Desativa o botão se users.kyc.level é 0
        onClick={handleButtonDeposit}
    >
        <span>Deposit</span>
    </button>

                 


                  

                  </div>
                </div>
        
              </header>
            </div>
          </div>
          <div className="px-16 main-container-5">
            
            <div className="" data-testid="balance-breakdown">
            <div className="grid grid-cols-1 gap-10 cb-card surface_primary shadow_md" >
  <div className="content p-6">
    <div className="mt-2.5 mb-1.5 flex items-center justify-between">
      <h3 className="ml-5 text-2xl text-#08383f-700 font-circular-book">
        Collect Payments
      </h3>
    </div>
    <div className="overflow-x-auto" data-testid="table-card-body">
    <div className="grid grid-cols-3 gap-4">
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <span style={{display:'flex',fontSize:'18px', fontWeight:900, textAlign:'center', justifyContent:'center'}}>PIX QR Code</span>
    <QRCode style={{display:'flex', justifyContent:'center', textAlign:'center', width:'150px'}} value="b6d060f9-d052-4938-8014-87e1b0f111af" bgColor={bgColor} fgColor={qrColor} />
    <div style={{display: 'flex'}}>
    <span>PIXCODE123456789</span>
    <CopyToClipboard text="b6d060f9-d052-4938-8014-87e1b0f111af">
      <button style={{marginLeft:'10px'}}><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
    </div>
    <br/>
    <p style={{color:'#008884'}}>Check our <strong>Compliance</strong>, <strong>AML and CFT Policy</strong> to learn more about our fiat payments-in.</p>
  </div>
  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
    <span style={{display:'flex',fontSize:'18px', fontWeight:900, textAlign:'center', justifyContent:'center'}}>On-Chain Payments</span>
    <QRCode style={{display:'flex', justifyContent:'center', textAlign:'center', width:'150px'}} value="b6d060f9-d052-4938-8014-87e1b0f111af" bgColor={bgColor} fgColor={qrColor} />
    <div style={{display: 'flex'}}>
    <span>Wallet Address: 0x123...wyz</span>
    <CopyToClipboard text="b6d060f9-d052-4938-8014-87e1b0f111af">
      <button style={{marginLeft:'10px'}}><>
  {/*?xml version="1.0" standalone="no"?*/}
  <svg
    version={1.0}
    xmlns="http://www.w3.org/2000/svg"
    width="19.000000pt"
    height="16.000000pt"
    viewBox="0 0 64.000000 64.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M183 628 c-12 -6 -27 -23 -34 -39 -6 -16 -17 -29 -25 -29 -26 0 -62
-23 -73 -47 -15 -33 -15 -433 0 -466 18 -40 49 -47 219 -47 174 0 202 6 221
51 6 16 17 29 25 29 26 0 62 23 73 47 7 14 11 90 11 190 l0 165 -71 79 -71 79
-127 -1 c-69 0 -136 -5 -148 -11z m237 -73 c0 -80 32 -115 105 -115 l35 0 0
-148 c0 -102 -4 -152 -12 -160 -17 -17 -339 -17 -356 0 -17 17 -17 439 0 456
8 8 49 12 120 12 l108 0 0 -45z m107 -73 c-40 -5 -67 26 -67 77 l1 36 49 -55
48 -55 -31 -3z m-387 -147 c0 -113 4 -193 11 -208 18 -39 50 -47 184 -47 69 0
125 -4 125 -8 0 -4 -5 -13 -12 -20 -17 -17 -339 -17 -356 0 -9 9 -12 73 -12
228 0 224 3 240 42 240 17 0 18 -14 18 -185z"
      />
      <path
        d="M265 349 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
      <path
        d="M265 249 c-15 -23 14 -30 111 -27 80 2 99 6 99 18 0 12 -19 16 -102
18 -67 2 -104 -1 -108 -9z"
      />
    </g>
  </svg>
</>

</button>
    </CopyToClipboard>
    </div>
    <br/>
   
    <ul  style={{color:'#008884'}}>
        <li>Reminders:</li>
        <li>1. This wallet is on Polygon Network, <strong>we cannot receive money</strong> in another account</li>
        <li>2. We only accpet BRLA, USDC or USDT dont´t try to send others token</li>
        <li>3. All receivables will be automatically converted to BRL - <strong>you cannot</strong> hold USDC or USDT at the wallet</li>
    </ul>
    
  </div>

  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
    <span style={{display:'flex',fontSize:'18px', fontWeight:900, textAlign:'center', justifyContent:'center'}}>Customize Your QR Code</span>
    <br/>
    <div style={{border:'1px solid', padding:'15px'}}>
    <span style={{display:'flex',color:'#008884', textAlign:'center', justifyContent:'center', marginBottom:'10px'}}>Background Color</span>
   
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between'  }}>
        
      {colorPalette.map((color) => (
        <button
          key={color}
          style={{ backgroundColor: color, height: '30px', width: '30px' }}
          onClick={() => handleColorClick(color, setQrColor)}
        />
      ))}
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div style={{ backgroundColor: '#F0F0F0', height: '30px', width: '30px' }} />
      <input type="text" value={qrColor} onChange={(e) => handleCustomColorChange(e, setQrColor)} />
    </div>
    <br/>
    </div>
    <br/>
    <div style={{border:'1px solid', padding:'15px'}}>
    <span style={{display:'flex',color:'#008884', justifyContent:'center', marginBottom:'10px'}}>QR Code color</span>
    
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
    
      {colorPalette.map((color) => (
        <button
          key={color}
          style={{ backgroundColor: color, height: '30px', width: '30px' }}
          onClick={() => handleColorClickBg(color, setBgColor)}
        />
      ))}
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
      <div style={{ backgroundColor: '#F0F0F0', height: '30px', width: '30px' }} />
      <input type="text" value={bgColor} onChange={(e) => handleCustomColorChangebg(e, setBgColor)} />
    </div>
    </div>
  
    
    
    
    
  </div>

</div>


    </div>
  </div>
</div>




            </div>
          </div>
          <div className="px-16 pt-0 ">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl ">
           {/*   <div className="grid grid-cols-2 gap-10 main-container-2" >
                <div
                  className="cb-card surface_primary shadow_md flex flex-col bg-blue-75 bg-gradient-to-br from-blue-75 to-orange-50"
                  data-testid="get-api-card"
                >
                  <div className="content flex flex-col flex-1 gap-6">
                    <div className="flex flex-col gap-4">
                      <h2 className="text-2xl text-#08383f-700 font-circular-bold">
                        Get a API key
                      </h2>
                      <p>
                        Use the API key to test our functionalities or
                        explore our sample app.
                      </p>
                    </div>
                    <div className="flex flex-1 items-end">
                      <div className="flex flex-wrap gap-4">
                        <a
                          className="cb-button base secondary w-auto text-sm text-#08383f-600"
                          href="#"
                          role="link"
                          rel="noopener noreferrer"
                        >
                          get api key
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="cb-card surface_primary shadow_md flex flex-col"
                  data-testid="see-docs-card"
                >
                  <div className="content flex flex-col flex-1 pl-2">
                    <div className="flex">
                      <div className="my-auto pr-3">
                        <img
                          alt="building with a wrench pencil ruler"
                          srcSet={ferramentaImage}
                          src={ferramentaImage}
                          width={165}
                          height={165}
                          decoding="async"
                          data-nimg={1}
                          loading="lazy"
                          style={{ color: "transparent", objectFit: "contain" }}
                        />
                      </div>
                      <div className="flex flex-col justify-between gap-6">
                        <div className="flex flex-col gap-4">
                          <h2 className="text-2xl text-#08383f-700 font-circular-bold">
                            See documentations
                          </h2>
                          <p>
                            Find guides and references to help with integration.
                          </p>
                        </div>
                        <div className="flex flex-1 items-end">
                          <div className="flex flex-wrap gap-4">
                            <a
                              className="cb-button base secondary w-auto text-sm text-#08383f-600"
                              href="https://api-documentation.brla.digital/"
                              role="link"
                              rel="noopener noreferrer"
                              target="_blank"
                            >
                              see guides
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    </div> */}
             <Figma/>
            </div>
          </div>
        </div>
  <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
  <Tooltip id="my-tooltip" />
</div>)}
export default Payments;
