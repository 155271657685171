import { memo } from 'react';
import type { FC } from 'react';

import resets from './_resets.module.css';
import classes from './Main.module.css';

/* @figmaId 333:2095 */
function Figma() {
  return (
    <div  className={`${resets.storybrainResets} ${classes.root} main-container-9`}>
    
      
      <div  className={classes.frame26}>
        <div   className={`${classes.section2} main-container-10`}>
          <div  className={classes.frame152}>
            <div className={classes.divContent2}>
              <div className={classes.divFlex2}>
                <div className={classes.heading3RecentActivity2}>Make Payments</div>
              </div>
            </div>
            
            <div className={classes.frame62}>
            <form>
              <div className={classes.frame22}>
                <div className={classes.pIXPayments}>PIX Payments</div>
                <div className={classes.frame20}>
                  <div className={classes.pIXCode}>PIX Code: </div>
                  <div className={classes.frame21}>
                    <input style={{display:'flex', justifyContent:'center', textAlign:'center', fontSize:'15px', font:'#08383f' }} className={classes._1234567890123456} placeholder='1234567890123456'></input>
                  </div>
                </div>
                <div className={classes.frame212}>
                  <div className={classes.amount}>Amount:</div>
                  <div className={classes.frame213}>
                    <input style={{display:'flex', justifyContent:'center', textAlign:'center', fontSize:'15px', font:'#08383f' }} className={classes.r250032} placeholder='R$ 2500.32'></input>
                  </div>
                </div>
                <div className={classes.frame23}>
                  <div className={classes.frame214}></div>
                </div>
                <div className={classes.frame222}>
                  <button className={classes.sendPayment}>Send Payment</button>
                </div>
              </div>
              </form>
              
              <form style={{width:'33%'}}>
              <div  className={classes.frame32}>
                <div className={classes.onChainPayments2}>On-Chain Payments</div>
                
                <div className={classes.frame202}>
                  <div className={classes.wallet}>Wallet:</div>
                  <div className={classes.frame215}>
                    <input style={{display:'flex', justifyContent:'center', textAlign:'center', fontSize:'15px', font:'#08383f' }} className={classes._12345678901234562} placeholder='1234567890123456'></input>
                  </div>
                </div>
                <div className={classes.frame216}>
                  <div className={classes.amount2}>Amount:</div>
                  <div className={classes.frame217}>
                    <input style={{display:'flex', justifyContent:'center', textAlign:'center', fontSize:'15px', font:'#08383f' }} className={classes.r2500322} placeholder='R$ 2500.32'></input>
                  </div>
                </div>
                <div className={classes.frame232}>
                  <div className={classes.sendToken}>Send Token:</div>
                  <div className={classes.frame218}>
                    <div className={classes.uSDC}>USDC</div>
                  </div>
                  <div className={classes.frame223}>
                    <div className={classes._500USDCNet}>500 USDC Net</div>
                  </div>
                </div>
                <div className={classes.frame224}>
                  <button className={classes.sendPayment2}>Send Payment</button>
                </div>
              </div>
              </form>
              <div className={classes.frame42}>
                <div className={classes.bRLAAccountPayment}>BRLA Account Payment</div>
                <div className={classes.frame203}>
                  <div className={classes.accountID}>Account ID:</div>
                  <div className={classes.frame219}>
                    <input style={{display:'flex', justifyContent:'center', textAlign:'center', fontSize:'15px', font:'#08383f' }} className={classes._12345678901234563} placeholder='1234567890123456'></input>
                  </div>
                </div>
                <div className={classes.frame2110}>
                  <div className={classes.amount3}>Amount:</div>
                  <div className={classes.frame2111}>
                    <input style={{display:'flex', justifyContent:'center', textAlign:'center', fontSize:'15px', font:'#08383f' }} className={classes.r2500323} placeholder='R$ 2500.32'></input>
                  </div>
                </div>
                <div className={classes.frame233}>
                  <div className={classes.frame2112}></div>
                </div>
                <div className={classes.frame225}>
                  <button className={classes.sendPayment3}>Send Payment</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
  );
};
export default Figma;
