import React, { useEffect, useState } from 'react';
import styles from './KYCLevel2.module.css'; // Importe o arquivo CSS
import { ENDPOINT} from '../../variaveis';
import idwSDKWeb from './idwSDKWeb'; // Adicione esta linha
import { useForm } from 'react-hook-form';
import Modal from './Modal'; // Adicione esta linha
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const SdkIdwall = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
    const getDataFromSessionStorage = () => {
        const cpf = sessionStorage.getItem("cpf");
        const birthDate = sessionStorage.getItem("formattedDate");
        const fullName = sessionStorage.getItem("fullName");
      
        console.log("CPF:", cpf);
        console.log("Birth Date:", birthDate);
        console.log("Full Name:", fullName);
      
        return { cpf, birthDate, fullName };
      };
      
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [cpf, setCpf] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [fullName, setFullName] = useState('');
    const [isRG, setIsRG] = useState(false);
    const [formattedDate, setFormattedDate] = useState('');
    const [showModal, setShowModal] = useState(true);
    const {
        register,
        handleSubmit,
        formState: { isValid },
      } = useForm({ mode: 'onChange' });
    

    const onSubmit = (data) => {
      setIsLoading(true); 
        setCpf(data.cpf);
        setBirthDate(data.birthdate);
        setFullName(data.full_name);
        // Atualize o estado para indicar que o formulário foi enviado
        setFormSubmitted(true);
        setShowModal(false);
        console.log(fullName)
      };

      
      
  
      const handleComplete = async (token) => {
        const { cpf, birthDate, fullName } = getDataFromSessionStorage();
        try {
          const response = await fetch(`${ENDPOINT}/kyc/pf/level2`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
            },
            body: JSON.stringify({
              cpf: cpf,
              birthDate: birthDate,
              fullName: fullName,
              isRG:true,
              SDKToken: token,
            }),
            credentials: 'include',
          });
      
          // Verifique a resposta e lide com erros
          if (!response.ok) {
            let responseData = {};
    if (response.headers.get('content-type').includes('application/json')) {
      responseData = await response.json();
      toast.error(`Failed to send verification: ${responseData.error || 'An unknown error occurred.'}`);
          }}
      
          // Adicione um toast de sucesso se a resposta for OK
          toast.success('Verification successfully sent. An email will be sent when your verification has been reviewed.');
          localStorage.setItem('showPopup', 'true');
          const reskychistory = await fetch(`${ENDPOINT}/kyc/history`, {
            method: 'GET',
            credentials: 'include',
          });
          const bodykychistory = await reskychistory.json();
          localStorage.setItem('kychistory', JSON.stringify(bodykychistory.history || []));
      
          setTimeout(() => {
            navigate('/wallet');
          }, 3000);
      
         
          
      
        } catch (error) {
          console.error(error);
      
          // Adicione um toast de erro se houver algum problema
          toast.error(`Error: ${error.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        };
        setIsLoading(false); 

      };
      
  
    useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://sdkweb-lib.idwall.co/index.js';
      script.onload = () => {
        idwSDKWeb({
          token: 'U2FsdGVkX19X6iBBAV3cL7/rBAQfpaI0bXrjeJkw6N+mpI4j4Q==',
          onRender: () => {
            console.log('it renders!');
            
          },
          onComplete: ({ token }) => {
            console.log('SDK Token', token);
            handleComplete(token);
          },
          onError: (error) => {
            alert(error);
          },
        });
      };
      document.body.appendChild(script);
  
      return () => {
        document.body.removeChild(script);
      };
    }, []);

    function formatCPF(event) {
        const { value } = event.target;
        const formattedValue = value
          .replace(/\D/g, "") // Remove all non-digit characters
          .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after 3 digits
          .replace(/(\d{3})(\d)/, "$1.$2") // Add a dot after the next 3 digits
          .replace(/(\d{3})(\d{1,2})$/, "$1-$2"); // Add a hyphen before the last 1 or 2 digits
      
        event.target.value = formattedValue;
      }
      
      const formatDate = (date) => {
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const d = new Date(date);
        return `${d.getFullYear()}-${monthNames[d.getMonth()]}-${String(d.getDate()+1).padStart(2, '0')}`;
      };
      
      const handleDateChange = (event) => {
        const formattedDate = formatDate(event.target.value);
        setFormattedDate(formattedDate);
      };
      
      const currentYear = new Date().getFullYear();
      const minDate = `${currentYear - 120}-01-01`;
      const maxDate = `${currentYear - 18}-12-31`;


     
  
    return (
      <div className={styles.container}>
        <main className={styles.main}>
        <div className="grid items-center justify-center text-center">
              <h2 style={{marginTop:'60px'}} className="mb-5 text-3xl text-black-600 font-circular-bold">
              Intermediary Verification
              </h2>
              <h3 className="mb-12 text-lg text-black-300">
              Please complete the form below with your personal information to fulfill our verification requirements. 
              </h3>
            </div>
        </main>
     
      <div data-idw-sdk-web></div>
      <ToastContainer />
      </div>
    );
  };
  
  export default SdkIdwall;
  