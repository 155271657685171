import WalletHeader from "../../Components/WalletHeader";
import React, { useEffect, useState, useRef } from 'react';
import { ENDPOINT, MANTEINENCETOKEN} from '../../variaveis';
import FooterLogged from "../../Components/FooterLogged";
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Deposit from "../../Components/Deposit";
import Transfer from "../../Components/Transfer";
import Transactions from "../../Components/Transactions";
import { useTranslation } from 'react-i18next';
import SwapBa from "../../Components/Swap";

function Activity() {
  const { t, i18n} = useTranslation();
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const buttonRefDeposit = useRef(null);
  const [showSwap, setShowSwap] = useState(false);
  const buttonRefTransfer = useRef(null);
  const buttonRefSwap = useRef(null);
  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
 


  const depositRef = useRef();
  const transferRef = useRef();
  const swapRef = useRef();
  useEffect(() => {
      const handleClickOutside = (event) => {
        if (showDeposit && depositRef.current && !depositRef.current.contains(event.target) && !buttonRefDeposit.current.contains(event.target)) {
          setShowDeposit(false);
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showDeposit]);
    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransfer && transferRef.current && !transferRef.current.contains(event.target) && !buttonRefTransfer.current.contains(event.target)) {
          setShowTransfer(false);
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransfer]);
    


    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showSwap && swapRef.current && !swapRef.current.contains(event.target) && !buttonRefSwap.current.contains(event.target)) {
          setShowSwap(false);
         
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showSwap]);

  

  
  
    return (<>
    
        <div id="__next" style={{overflowX:'hidden'}}>
          <div className="grid w-full min-h-screen font-circular">
            <WalletHeader/>
            <main className="flex flex-col bg-surface-background" >
              <div className="grid flex-1 gap-y-12 pt-12 content-start">
                <div className="px-16">
                  <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
             
                    <header className="cb-header main-container-6">
                      <div className="cb-header-head primary ">
                        <h1 className="title">{t('activity_history')}</h1>
                        <div className="actions">
                        <button
  ref={buttonRefTransfer}
  style={{ backgroundColor: "#08383f" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  disabled={users?.kyc?.level === 0} // Desativa o botão se users.kyc.level é 0
  onClick={() => {
    setShowTransfer(!showTransfer);
  }}
>
  <span>{t('transfer')}</span>
</button>
    
    
               
                    
    <button
  ref={buttonRefDeposit}
  style={{ backgroundColor: "#08383f" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  disabled={users?.kyc?.level === 0} // Desativa o botão se users.kyc.level é 0
  onClick={() => {
    setShowDeposit(!showDeposit);
  }}
>
  <span>{t('deposit')}</span>
</button>

<button
  ref={buttonRefSwap}
  style={{ backgroundColor: "#08383f", marginLeft: "10px" }}
  type="button"
  className="cb-button base primary"
  data-testid="account-funding-button"
  onClick={() => {
    setShowSwap(!showSwap);
  }}
>
  <span>{t('convert')}</span>
</button>
                        </div>
                      
                      </div>

                      {showDeposit && <div ref={depositRef} className="overlay"></div>}
{showTransfer && <div ref={transferRef} className="overlay"></div>}
{showSwap && <div ref={swapRef} className="overlay"></div>}
<div style={{zIndex:10000, position:'absolute'}} className="deposit-activity">
{showDeposit && <Deposit ref={depositRef} />} 
</div>
<div style={{zIndex:10000}} className="transfer-activity">   
{showTransfer && <Transfer ref={transferRef} />}   
</div>

<div style={{zIndex:10000}} className="swap-activity">  
{showSwap && <SwapBa ref={swapRef} />}
</div>
                    </header>
              <Transactions showButton={true}/>
                  </div>
                </div>
              </div>
              <FooterLogged/>
            </main>
          </div>
        </div>
        <ToastContainer />
      </>
      )
};
export default Activity;