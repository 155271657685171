import logocorpoImage from '../Home/images/logo_corpo.png';
function MintCrypto () {
    return(<>
        <div id="__next">
          <div className="grid w-full min-h-screen font-circular">
          </div>
          <div
            style={{
              position: "fixed",
              top: 1,
              left: 1,
              width: 1,
              height: 0,
              padding: 0,
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0px, 0px, 0px, 0px)",
              whiteSpace: "nowrap",
              borderWidth: 0,
              display: "none"
            }}
          />
        </div>
        <next-route-announcer>
          <p
            aria-live="assertive"
            id="__next-route-announcer__"
            role="alert"
            style={{
              border: 0,
              clip: "rect(0px, 0px, 0px, 0px)",
              height: 1,
              margin: "-1px",
              overflow: "hidden",
              padding: 0,
              position: "absolute",
              width: 1,
              whiteSpace: "nowrap",
              overflowWrap: "normal"
            }}
          />
        </next-route-announcer>
        <div id="headlessui-portal-root">
          <div data-headlessui-portal="">
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
            <div>
              <div
                className="cb-modal"
                data-segmentscreenname="deposit_blockchain_step_2"
                id="headlessui-dialog-:r1:"
                role="dialog"
                aria-modal="true"
                data-headlessui-state="open"
              >
                <div className="modal-container">
                  <div
                    className="overlay opacity-100"
                    data-testid="overlay"
                    id="headlessui-dialog-overlay-:r2:"
                    aria-hidden="true"
                    data-headlessui-state="open"
                  />
                  <div
                    className="modal-content screen opacity-100 scale-100"
                    data-testid="content"
                  >
                    <div className="cb-modal-coolwhip">
                      
                    </div>
                    <div className="h-screen overflow-x-auto">
                      <div className="grid grid-rows-auto-1">
                        <div className="sticky top-0 bg-white z-10">
                          <div className="grid grid-cols-3 py-5 px-9">
                            <div className="flex items-center justify-start">
                              <button data-testid="back-button">
                                <svg
                                  width={26}
                                  height={26}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="cb-icon cb-icon-chevronleftlight pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-chevronleftlight"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m15 19-7-7 7-7"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div
                              className="flex items-center justify-center"
                              data-testid="circle-logo"


                            >


                                <img src={logocorpoImage} style={{width:'60px', height:'60px'}}></img>
                            </div>
                            <div className="flex items-center justify-end">
                              <button
                                className="cb-modal-basic-close top-right relative inset-0"
                                data-testid="close-button"
                              >
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 14 14"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-cross pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-cross"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="w-full h-1 bg-black-75">
                            <div
                              aria-valuemax={100}
                              aria-valuemin={0}
                              aria-valuenow={-200}
                              className="h-full bg-blue-300 transition-width duration-100"
                              role="progressbar"
                            />
                          </div>
                        </div>
                        <div
                          className="cb-modal-body default relative pt-16 pb-20 font-circular"
                          data-testid="modal-body"
                        >
                          <div>
                            <div
                              className="grid h-full content-center items-center justify-center"
                              data-testid="center-both"
                            >
                              <div className="flex h-full w-184 flex-col items-center justify-between">
                                <div
                                  className="mb-8"
                                  data-testid="blockchain-deposit-instruction"
                                >
                                  <div className="mb-5">
                                    <div className="text-center col-span-4">
                                      <h2 className="text-lg leading-7 text-black-600 font-circular-bold">
                                        Ethereum
                                      </h2>
                                      <p className="mt-4 text-sm leading-5 text-black-600 font-circular-regular">
                                        To transfer BRLA on Ethereum Goerli Testnet
                                        from an external blockchain wallet into your
                                        BRLA Account, use your public BRLA on
                                        Ethereum Goerli Testnet Address. To avoid
                                        typing addresses, copy and paste.
                                      </p>
                                      <p className="mt-6 text-sm leading-5 text-black-600 font-circular-regular">
                                        <svg
                                          width={15}
                                          height={15}
                                          viewBox="0 0 16 14"
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="cb-icon cb-icon-alertfilled mr-1 mb-1 inline-block text-orange-400 pointer-events-none"
                                          aria-hidden="true"
                                          data-testid="icon-alertfilled"
                                          focusable="false"
                                          role="img"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.257 1.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H2.42C.89 14-.073 12.354.677 11.02l5.58-9.92ZM9 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8 3a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1Z"
                                          />
                                        </svg>
                                        <b>Important:</b> This is a sandbox wallet
                                        address. Any real BRLA sent to this address is
                                        unrecoverable. Additionally, funds transferred
                                        to the wrong address might be lost
                                        permanently. Sending any token other than BRLA
                                        to this address will result in funds being
                                        lost forever. Only send amounts using two
                                        decimal places, because after that, you won’t
                                        be credited (e.g. sending 1.009999 results in
                                        you getting 1.00).
                                        <br />
                                        <br />
                                        You may be subject to and responsible for any
                                        and all blockchain network fees for on-chain
                                        transfers.
                                      </p>
                                    </div>
                                  
                                    <div className="cb-copy-to-clipboard base-variant mt-4">
  <div className="label">BRLA on Ethereum Goerli Testnet Wallet Address</div>
  <button type="button">
    <div className="truncated-text" style={{ height: 24 }}>
      <div
        aria-hidden="true"
        className="absolute w-0 overflow-hidden"
        data-testid="font-reference"
      >
        .
      </div>
      <div
        className="untruncated visible"
        data-testid="untruncated-text"
        style={{ height: 24, marginTop: 0 }}
      >
        0x412e9fddef6303f0ae32928e6869e8cba1754307
      </div>
      <div
        aria-hidden="true"
        className="truncated invisible pointer-events-none"
        data-testid="truncated-text"
      >
        <div
          aria-hidden="true"
          className="start"
          data-testid="start"
          style={{ height: 24 }}
        >
          0x412e9fddef6303f0ae3
        </div>
        <div>...</div>
        <div className="end" data-testid="end" dir="rtl" style={{ height: 24 }}>
          7034571abc8e9686e8292
        </div>
      </div>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 20 20"
      fill="currentColor"
      className="cb-icon cb-icon-duplicate pointer-events-none"
      aria-hidden="true"
      data-testid="icon-duplicate"
      focusable="false"
      role="img"
    >
      <path d="M7 9a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V9z" />
      <path d="M5 3a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2V5h8a2 2 0 0 0-2-2H5z" />
    </svg>
  </button>
</div>

                                  </div>
                                  
                                  <p className="text-sm leading-5 text-black-600 font-circular-regular">
                                    <b>Disclaimer</b>: BRLA is not a bank; your
                                    BRLA Account is not a bank account, and any
                                    funds are not insured by the Federal Deposit
                                    Insurance Corporation, the Securities Investor
                                    Protection Corporation or by any US or foreign
                                    government agency, insurance fund, person or
                                    entity.
                                  </p>
                                </div>
                                <button
                                  className="cb-button base secondary w-60"
                                  type="button"
                                >
                                  <span>Done</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
          </div>
        </div>
      </>
      )
};
export default MintCrypto;