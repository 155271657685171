import SettingsHeader from "../../Components/SettingsHeader";
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT} from '../../variaveis';
import FooterLogged from "../../Components/FooterLogged";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {CopyToClipboard} from 'react-copy-to-clipboard';
function BlockchainAddress()  {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/addwallet");
  };
  const [wallets, setWallets] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  
  useEffect(() => {
    // Obtem os usuários do localStorage

    // Obtem as carteiras do localStorage
    const loadedWallets = localStorage.getItem('wallets');
    if (loadedWallets) {
      setWallets(JSON.parse(loadedWallets));
    }

    // Obtem os dados do histórico do localStorage
    setPendingRequests(0);
  }, []);


  const deleteWallet = async (walletId) => {
    console.log("Deleting wallet: ", walletId);
  
    const response = await fetch(`${ENDPOINT}/wallets/${walletId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
  
    if(response.ok) {
      toast.success("Wallet deleted successfully");
      setWallets(wallets.filter(wallet => wallet.id !== walletId));
  
      // Fetch the updated wallet information after successful deletion
      const resWallets = await fetch(`${ENDPOINT}/wallets`, {
        method: 'GET',
        credentials: 'include',
      });
  
      // If the response is ok, update the localStorage
      if (resWallets.ok) {
        const bodyWallets = await resWallets.json();
        localStorage.setItem('wallets', JSON.stringify(bodyWallets.wallets || []));
      } else {
        console.error("Failed to update wallets: ", await resWallets.text());
      }
    } else {
      toast.error("Failed to delete wallet");
    }
  };
  


  useEffect(() => {
    // Se não há requisições pendentes, então as requisições acabaram
    if (pendingRequests === 0) {
      // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
      setIsLoading(false);
    } 
  }, [pendingRequests]);

  if (isLoading) {
    // Retorne um spinner de loading, um placeholder ou null
    return <div></div>;
  }



    return(
<>
  <div id="__next">
    <div className="grid w-full min-h-screen font-circular grid min-h-screen w-full">
      <SettingsHeader/>
      <main className="flex flex-col bg-surface-background">
        <div className="grid flex-1 content-start gap-y-12 py-12 main-container">
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header className="cb-header">
                <div className="cb-header-head primary">
                  <h1 className="title">Wallets</h1>
                  <div className="actions">
                    <button
                      type="button"
                      className="cb-button base primary"
                      data-testid="blockchain-button"
                      onClick={handleButtonClick}
                    >
                      <span>Add wallet</span>
                    </button>
                  </div>
                </div>
                
              </header>
              <section>
                <div className="cb-card surface_primary shadow_md">
                  <div className="content p-6">
                    <div
                      className="grid gap-6 mb-5 gap-x-2 grid-cols-9"
                      data-testid="table-filters"
                    >
                     
                    </div>
                    <div
                      className="overflow-x-auto"
                      data-testid="table-card-body"
                    >
                      <div>
                      <table className="advanced-table border-collapse table-fixed w-full" style={{ border: 'none' }}>
  <thead data-testid="header">
    <tr>
      <th
        className=""
        colSpan={1}
        style={{ width: '300px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        ID
      </th>
      <th
        className=""
        colSpan={1}
        style={{ width: '300px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Wallet address
      </th>
      <th
        className="w-20"
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Blockchain
      </th>
      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Nickname
      </th>
      

      <th
          className=""
          colSpan={1}
          style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
        >
          Delete
        </th>
    </tr>
  </thead>
  <tbody>
    {wallets.map((item, index) => (
      <tr key={index}>
      <td className="text-sm leading-5 font-circular-book" style={{ width: '250px', textAlign: 'center', border:'none' }}>
  {item.id.substring(0, 3)}...{item.id.substring(item.id.length - 3)}
  <CopyToClipboard text={item.id}
    onCopy={() => toast.success('ID copied to clipboard!')}>
    <a 
      href="#" 
      style={{color: '#00dc84', marginLeft: '5px'}} 
    >
      copy
    </a>
  </CopyToClipboard>
</td>
<td className="text-sm leading-5 font-circular-book" style={{ width: '285px', textAlign: 'center', border:'none' }}>
  {item.walletAddress.substring(0, 3)}...{item.walletAddress.substring(item.walletAddress.length - 3)}
  <CopyToClipboard text={item.walletAddress}
    onCopy={() => toast.success("ID copied to clipboard!")}>
    <a 
      href="#" 
      style={{color: '#00dc84', marginLeft: '5px'}}
    >
      copy
    </a>
  </CopyToClipboard>
</td>
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          {item.chain}
        </td>
        
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          {item.name}
        </td>
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none', color:'#00dc84' }}>
              <a href="#" onClick={() => deleteWallet(item.id)}>X</a>
            </td>
      </tr>
    ))}
  </tbody>
</table>


                        <div className="p-18">
                          <div
                            className="grid h-full h-full w-full text-center font-circular content-center items-center justify-center"
                            data-testid="lottie-notice-small"
                          >
                            <div
                              className="grid h-full mb-6 content-center items-center justify-center"
                              data-testid="center-both"
                            >
                              
                              <div
                                className="cb-lottie-icon cb-lottie-icon-documents"
                                data-testid="lottie-icon-documents"
                                style={{ width: 64, height: 64 }}
                              >
                                {
  wallets.length == 0 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 468 468"
                                  width={468}
                                  height={468}
                                  preserveAspectRatio="xMidYMid meet"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    transform: "translate3d(0px, 0px, 0px)",
                                    contentVisibility: "visible"
                                  }}
                                >
                                  <defs>
                                    <clipPath id="__lottie_element_138">
                                      <rect
                                        width={468}
                                        height={468}
                                        x={0}
                                        y={0}
                                      />
                                    </clipPath>
                                    <clipPath id="__lottie_element_141">
                                      <path
                                        fill="#ffffff"
                                        clipRule="nonzero"
                                        d=" M17.5,215.5 C144.52499389648438,215.5 247.5,112.5260009765625 247.5,-14.5 C247.5,-141.52499389648438 144.52499389648438,-244.5 17.5,-244.5 C-109.5260009765625,-244.5 -212.5,-141.52499389648438 -212.5,-14.5 C-212.5,112.5260009765625 -109.5260009765625,215.5 17.5,215.5 C17.5,215.5 17.5,215.5 17.5,215.5"
                                        fillOpacity={1}
                                      />
                                    </clipPath>
                                    <clipPath id="__lottie_element_149">
                                      <path
                                        fill="#ffffff"
                                        clipRule="nonzero"
                                        d=" M-12.5,245.5 C114.5250015258789,245.5 217.5,142.5260009765625 217.5,15.5 C217.5,-111.5250015258789 114.5250015258789,-214.5 -12.5,-214.5 C-139.5260009765625,-214.5 -242.5,-111.5250015258789 -242.5,15.5 C-242.5,142.5260009765625 -139.5260009765625,245.5 -12.5,245.5 C-12.5,245.5 -12.5,245.5 -12.5,245.5"
                                        fillOpacity={1}
                                      />
                                    </clipPath>
                                  </defs>
                                  <g clipPath="url(#__lottie_element_138)">
                                    <g
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,234,234)"
                                      opacity={1}
                                    >
                                      <g
                                        opacity={1}
                                        transform="matrix(1,0,0,1,0,0)"
                                      >
                                        <path
                                          fill="rgb(239,247,255)"
                                          fillOpacity={1}
                                          d=" M0,230 C127.0250015258789,230 230,127.0260009765625 230,0 C230,-127.0250015258789 127.0250015258789,-230 0,-230 C-127.0260009765625,-230 -230,-127.0250015258789 -230,0 C-230,127.0260009765625 -127.0260009765625,230 0,230 C0,230 0,230 0,230z"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      clipPath="url(#__lottie_element_149)"
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,247.2899932861328,218.48899841308594)"
                                      opacity={1}
                                    >
                                      <g
                                        opacity={1}
                                        transform="matrix(1,0,0,1,0,0.009621759876608849)"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          fillOpacity={0}
                                          stroke="rgb(76,155,212)"
                                          strokeOpacity={1}
                                          strokeWidth="19.167"
                                          d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      clipPath="url(#__lottie_element_141)"
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,217.2899932861328,248.48899841308594)"
                                      opacity={1}
                                    >
                                      <g
                                        opacity={1}
                                        transform="matrix(1,0,0,1,0,0)"
                                      >
                                        <path
                                          fill="rgb(239,247,255)"
                                          fillOpacity={1}
                                          d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          fillOpacity={0}
                                          stroke="rgb(76,155,212)"
                                          strokeOpacity={1}
                                          strokeWidth="19.167"
                                          d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,203.48699951171875,241.51499938964844)"
                                      opacity={1}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fillOpacity={0}
                                        stroke="rgb(76,155,212)"
                                        strokeOpacity={1}
                                        strokeWidth="19.167"
                                        d=" M-25.996999740600586,0 C-25.996999740600586,0 25.996999740600586,0 25.996999740600586,0"
                                      />
                                    </g>
                                    <g
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,217.2899932861328,207.58099365234375)"
                                      opacity={1}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fillOpacity={0}
                                        stroke="rgb(76,155,212)"
                                        strokeOpacity={1}
                                        strokeWidth="19.167"
                                        d=" M-39.79999923706055,0 C-39.79999923706055,0 39.79999923706055,0 39.79999923706055,0"
                                      />
                                    </g>
                                  </g>
                                </svg>
                               )
                              }
                              </div>
                              
                            </div>
                            {
  wallets.length == 0 && (
                            <p
                              className="font-circular-regular leading-6 text-black-600 text-base"
                              data-testid="subtitle"
                            >
                              No data available
                            </p> )}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
       <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
</>
)};
export default BlockchainAddress;