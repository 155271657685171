import FooterLogged from "../../Components/FooterLogged";
import SettingsHeader from "../../Components/SettingsHeader";

function WithdrawDelay() {
    return(
<>
  <div id="__next">
    <div className="grid w-full min-h-screen font-circular grid min-h-screen w-full">
      <SettingsHeader/>
      <main className="flex flex-col bg-surface-background">
        <div className="grid flex-1 content-start gap-y-12 py-12 main-container">
          <div className="px-16">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <header className="cb-header">
                <div className="cb-header-head primary">
                  <h1 className="title">Delayed Withdrawals</h1>
                  <div className="actions" />
                </div>
                <p className="cb-header-desc primary">
                  When enabled, newly added Blockchain addresses in your Address
                  book will undergo a 24-hour hold period before it is available
                  for withdrawals to guard against unauthorized activity.
                </p>
              </header>
              <div
                className="cb-card surface_primary shadow_md font-circular"
                data-testid="toggle-card"
              >
                <div className="content p-10">
                  <div className="flex flex-col gap-y-3">
                    <div className="flex items-center justify-between">
                      <div className="flex gap-x-2">
                        <div
                          className="grid h-full self-center content-center items-center"
                          data-testid="center-vertical"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={24}
                            height={24}
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                            className="cb-icon cb-icon-clock pointer-events-none"
                            aria-hidden="true"
                            role="img"
                            focusable="false"
                            data-testid="icon-clock"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 8v4l3 3m6-3a9 9 0 1 1-18 0 9 9 0 0 1 18 0z"
                            />
                          </svg>
                        </div>
                        <h3
                          className="justify-self-start pr-4 text-2xl leading-8 text-black-700 font-circular-book"
                          data-testid="header"
                        >
                          Delayed withdrawals are disabled
                        </h3>
                      </div>
                      <div className="justify-self-end">
                        <div className="cb-toggle">
                          <button
                            data-testid="toggle"
                            className="toggle-switch"
                            id="headlessui-switch-:r3:"
                            role="switch"
                            type="button"
                            tabIndex={0}
                            aria-checked="false"
                            data-headlessui-state=""
                          >
                            <span
                              aria-hidden="true"
                              className="toggle-switch-circle"
                            />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterLogged/>
      </main>
    </div>
  </div>
  <next-route-announcer>
    <p
      aria-live="assertive"
      id="__next-route-announcer__"
      role="alert"
      style={{
        border: 0,
        clip: "rect(0px, 0px, 0px, 0px)",
        height: 1,
        margin: "-1px",
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: 1,
        whiteSpace: "nowrap",
        overflowWrap: "normal"
      }}
    >
      Delayed withdrawals - Circle
    </p>
  </next-route-announcer>
</>)};
export default WithdrawDelay;
