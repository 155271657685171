import { memo, SVGProps } from 'react';

const FrameIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg preserveAspectRatio='none' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M22.4302 7.40801C22.446 7.63008 22.446 7.8522 22.446 8.07427C22.446 14.8477 17.2906 22.6523 7.86802 22.6523C4.96509 22.6523 2.26841 21.8115 0 20.3522C0.412451 20.3998 0.808984 20.4156 1.2373 20.4156C3.63257 20.4156 5.83755 19.6066 7.59834 18.2266C5.3458 18.179 3.45811 16.7037 2.80771 14.6732C3.125 14.7208 3.44224 14.7525 3.77539 14.7525C4.2354 14.7525 4.69546 14.6891 5.12373 14.5781C2.77603 14.1021 1.01519 12.04 1.01519 9.54951V9.48608C1.69727 9.8668 2.49048 10.1047 3.33115 10.1364C1.95107 9.21636 1.04692 7.64595 1.04692 5.86929C1.04692 4.91753 1.30068 4.04507 1.74487 3.28364C4.26709 6.39277 8.05835 8.42319 12.3096 8.64531C12.2303 8.2646 12.1827 7.86807 12.1827 7.47148C12.1827 4.64785 14.4669 2.34775 17.3064 2.34775C18.7816 2.34775 20.1141 2.96641 21.05 3.96577C22.208 3.7437 23.3184 3.31538 24.302 2.72847C23.9212 3.91821 23.1122 4.91758 22.0494 5.55205C23.0805 5.44106 24.0799 5.15547 24.9999 4.75894C24.3021 5.77412 23.4295 6.67827 22.4302 7.40801Z'
      fill='#C7C5D1'
    />
  </svg>
);
const Memo = memo(FrameIcon);
export { Memo as FrameIcon };
