import ListaCoins from '../../Components/ListaCoins';
import ListaCoinsRecebe from '../../Components/ListaCoinsRecebe';
import './4fcf14209b98191d.css';
import './e09eadbd6818c4e7.css';
import React, { useEffect, useState, useRef } from 'react';


function Wise () {
    const [showListaCoins, setShowListaCoins] = useState(false);
    const [showListaCoinsRecebe, setShowListaCoinsRecebe] = useState(false);
    const ListaCoinsRef = useRef();
    const ListaCoinsRecebeRef = useRef();

    const buttonRefListaCoinst = useRef(null);
    const buttonRefListaCoinsRecebet = useRef(null);
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showListaCoins && ListaCoinsRef.current && !ListaCoinsRef.current.contains(event.target) && !buttonRefListaCoinst.current.contains(event.target)) {
        setShowListaCoins(false);
        console.log("VAI")
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showListaCoins]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showListaCoinsRecebe && ListaCoinsRecebeRef.current && !ListaCoinsRecebeRef.current.contains(event.target) && !buttonRefListaCoinsRecebet.current.contains(event.target)) {
        setShowListaCoinsRecebe(false);
        console.log("VAI")
      }
    };
  
    document.addEventListener('click', handleClickOutside);
  
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showListaCoinsRecebe]);


  const [selectedToken, setSelectedToken] = useState({
    flag: 'currency-flag-brl',
    abbreviation: 'BRL'
  });

  
  const [selectedTokenRecebe, setSelectedTokenRecebe] = useState({
    flag: 'currency-flag-brl',
    abbreviation: 'BRL'
  });

  const handleTokenSelect = (token) => {
    setSelectedToken(token);
    setShowListaCoins(false); // Close the dropdown after selection
  };

  const handleTokenRecebeSelect = (token) => {
    setSelectedTokenRecebe(token);
    setShowListaCoinsRecebe(false); // Close the dropdown after selection
  };

  const currencyFlagClass = `currency-flag ${selectedToken.flag}`;
  const currencyFlagRecebeClass = `currency-flag ${selectedTokenRecebe.flag}`;
  const currencyFlagPIXClass = `currency-flag currency-flag-pix`;
  const currencyFlagPolygonClass = `currency-flag currency-flag-polygon`;

    return(
    <div  className="np-theme-personal">
    <div  className="send-flow send-flow--wide-navigation">
      <div className="tw-flow">

        <div>
          <section className="tw-flow__main" style={{ position: "relative" }}>
            <div className="tw-flow__steps" style={{}}>
              <div className="tw-flow__step container" style={{}}>
                <div
                  className="row tw-enter-transfer"
                  data-testid="enter-transfer"
                  style={{display:"flex", justifyContent:'center', alignItems:'center', justifyContent:'center'}}
                
                >
              <div className="col-lg-6 mx-auto" style={{ display: 'grid', placeItems: 'center' }}>
                  <div className="col-lg-6 mx-auto"/>
                    <div  className="row mx-auto">
                      <div  className="">
                        <div className="tabs">
                  
                          <div
                            className="tabs__panel-container"
                            style={{ overflow: "visible" }}
                          >
                            <div
                              className="tabs__slider"
                              style={{
                                width: "100%",
                                transform: "translateX(0px)"
                              }}
                            >
                              <div style={{ width: 32, display: "none" }} />
                              <div
                                className="tabs__panel"
                                role="tabpanel"
                                id="calculator-tabs-panel-0"
                                aria-labelledby="calculator-tabs-tab-0"
                                style={{ width: "100%", display: "block" }}
                              >
                                <div
                                  data-tracking-id="calculator"
                                  data-testid="calculator"
                                  className="tw-claculator"
                                >
                                  <div className="">
                                    <label htmlFor="tw-calculator-source">
                                      Você envia exatamente
                                    </label>
                                    <div className="tw-money-input input-group input-group-lg" >
                                      <input
                                        className="form-control np-form-control np-form-control--size-auto np-text-body-large np-form-control--shape-rectangle"
                                        id="tw-calculator-source"
                                        inputMode="decimal"
                                        placeholder="0.00"
                                        autoComplete="off"
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                        <div className="np-select btn-block btn-group">
                                         <button
  ref={buttonRefListaCoinst}
  className={`btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy`}
  id="tw-calculator-source-select"
  role="combobox"
  aria-controls="tw-calculator-source-select-listbox"
  aria-expanded="false"
  aria-autocomplete="none"
  type="button"
  onClick={() => {
    setShowListaCoins(!showListaCoins);
  }}
>
                                            <div className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i className={currencyFlagClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span className="np-text-title-subsection tw-money-input__text">
                                                  {selectedToken.abbreviation}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                                className={`${showListaCoins ? 'rotate-icon' : ''}`}
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                            
                                          </button>
                                          
                                        </div>
                                        
                                      </div>
                                      {showListaCoins && <ListaCoins ref={ListaCoinsRef} onTokenSelect={handleTokenSelect}  />}

                                      <div
  style={{
    position: 'absolute',
    top: selectedTokenRecebe.abbreviation !== selectedToken.abbreviation &&
      !(selectedTokenRecebe.abbreviation === 'USDT' && selectedToken.abbreviation === 'USD')
      ? '320px'
      : '180px',
    left: '200px',
    zIndex:2000
  }}
>
  {showListaCoinsRecebe && <ListaCoinsRecebe ref={ListaCoinsRecebeRef} onTokenRecebeSelect={handleTokenRecebeSelect} />}
</div>


                                      
                                    </div>
                                    
                                  </div>
                                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' )  ? (
                                  <ul className="tw-calculator-breakdown tw-calculator-breakdown--detailed">
                                    <li>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>–</span>
                                        </span>
                                      </span>
                                      <div className="tw-calculator-breakdown__content">
                                        <span className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          21,71 BRL
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span data-tracking-id="calculator-payment-select">
                                            <div className="np-select btn-group">
                                              <button
                                                className="btn btn-sm np-btn np-btn-sm np-dropdown-toggle np-text-body-large"
                                                id="np-select-443252"
                                                role="combobox"
                                                aria-controls="np-select-443252-listbox"
                                                aria-expanded="false"
                                                aria-autocomplete="none"
                                                type="button"
                                              >
                                                <span className="np-text-body-large text-ellipsis">
                                                  Tarifa total
                                                  custo
                                                </span>
                                                <span
                                                  className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                                  aria-hidden="true"
                                                  role="presentation"
                                                  data-testid="chevron-up-icon"
                                                >
                                                  <svg
                                                    width={16}
                                                    height={16}
                                                    fill="currentColor"
                                                    focusable="false"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                                  </svg>
                                                </span>
                                              </button>
                                            </div>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                
                                    <li>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>=</span>
                                        </span>
                                      </span>
                                      <div className="tw-calculator-breakdown__content">
                                        <span className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          978,29 BRL
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          Valor que converteremos
                                        </span>
                                      </div>
                                    </li>
                                    <li>
                                      <span className="tw-calculator-breakdown__icon">
                                        <span>
                                          <span>÷</span>
                                        </span>
                                      </span>
                                      <div className="tw-calculator-breakdown__content">
                                        <span className="tw-calculator-breakdown-item__left tw-calculator-breakdown-item__value">
                                          <a
                                            role="button"
                                            className="btn-unstyled"
                                            tabIndex={0}
                                          >
                                            <span className="tw-calculator-breakdown-rate__value">
                                              5,39313
                                            </span>
                                          </a>
                                        </span>
                                        <span className="tw-calculator-breakdown-item__right tw-calculator-breakdown-item__label">
                                          <span className="np-popover">
                                            <span className="d-inline-block">
                                              <button
                                                className="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 btn-unstyled p-a-0 tw-calculator-rate-text"
                                                type="button"
                                                style={{
                                                  height: "auto",
                                                  verticalAlign: "bottom"
                                                }}
                                              >
                                                <span data-tracking-id="calculator-fixed-rate-tooltip">
                                                  Taxa de câmbio comercial
                                                </span>{" "}
                                                <span
                                                  style={{ whiteSpace: "nowrap" }}
                                                >
                                                 
                                                </span>
                                              </button>
                                            </span>
                                          </span>
                                        </span>
                                      </div>
                                    </li>
                                  </ul> ) :(<div></div>)}
                                  <div className="">
                                    <label htmlFor="tw-calculator-target">
                                      Beneficiário recebe{" "}
                                    </label>
                                    <div className="tw-money-input input-group input-group-lg">
                                      <input
                                        className="form-control np-form-control np-form-control--size-auto np-text-body-large np-form-control--shape-rectangle"
                                        id="tw-calculator-target"
                                        inputMode="decimal"
                                        placeholder="0.00"
                                        autoComplete="off"
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                        <div className="np-select btn-block btn-group">
                                          <button
                                           ref={buttonRefListaCoinsRecebet}
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            onClick={() => {
                                                setShowListaCoinsRecebe(!showListaCoinsRecebe);
                                                console.log(showListaCoinsRecebe)
                                              }}
                                          >
                                            <div className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i className={currencyFlagRecebeClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span className="np-text-title-subsection tw-money-input__text">
                                                  {selectedTokenRecebe.abbreviation}
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {selectedTokenRecebe.abbreviation === 'BRL' ? (

                                  <div className="">
                                    <label htmlFor="tw-calculator-target">
                                      Chave PIX{" "}
                                    </label>
                                    <div  className="tw-money-input input-group input-group-lg input-transfer">
                                      <input
                                        className="form-control np-form-control np-form-control--size-auto np-text-body-large np-form-control--shape-rectangle"
                                        id="tw-calculator-target"
                                        inputMode="decimal"
                                        placeholder="Digite sua chave PIX"
                                        autoComplete="off"
                                        
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                        <div className="np-select btn-block btn-group">
                                          <button
                                           
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            
                                            
                                          >
                                            <div className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i className={currencyFlagPIXClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span className="np-text-title-subsection tw-money-input__text">
                                                  PIX
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>) : (


                                  
                                  <div className="">
                                    <label htmlFor="tw-calculator-target">
                                      Endereço da Wallet{" "}
                                    </label>
                                    <div className="tw-money-input input-group input-group-lg">
                                      <input
                                        className="form-control np-form-control np-form-control--size-auto np-text-body-large np-form-control--shape-rectangle"
                                        id="tw-calculator-target"
                                        inputMode="decimal"
                                        placeholder="0xc37...3887"
                                        autoComplete="off"
                                       
                                      />
                                      <div className="input-group-btn amount-currency-select-btn">
                                        <div className="np-select btn-block btn-group">
                                          <button
                                         
                                            className="btn btn-lg np-btn np-btn-lg btn-block np-btn-block np-dropdown-toggle np-text-body-large np-dropdown-toggle-navy"
                                            id="tw-calculator-target-select"
                                            role="combobox"
                                            aria-controls="tw-calculator-target-select-listbox"
                                            aria-expanded="false"
                                            aria-autocomplete="none"
                                            type="button"
                                            
                                            
                                          >
                                            <div className="d-flex np-option-content">
                                              <div className="d-flex flex-column justify-content-center">
                                              <i className={currencyFlagPolygonClass} />
                                              </div>
                                              <div className="d-flex flex-column justify-content-center">
                                                <span className="np-text-body-large text-ellipsis">
                                                  <span className="np-text-title-subsection tw-money-input__text">
                                                  Polygon
                                                  </span>
                                                </span>
                                         
                                              </div>
                                              
                                            </div>
                                         
                                            <span
                                              className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom tw-icon tw-chevron-up-icon tw-chevron bottom np-select-chevron"
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="chevron-up-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                                              </svg>
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>) }


                                  {(selectedTokenRecebe.abbreviation !== selectedToken.abbreviation ) && !(selectedTokenRecebe.abbreviation==='USDT' && selectedToken.abbreviation==='USD' )  ? (


                                  <div className="tw-calculator-effective-rate m-t-2">
                                    <strong>1 USDT = 5,00000 BRL</strong>{" "}
                                    <span className="np-popover">
                                      <span className="d-inline-block">
                                        <button
                                          className="btn btn-md np-btn np-btn-md btn-accent btn-priority-3 btn-unstyled p-a-0"
                                          type="button"
                                          style={{
                                            height: "auto",
                                            verticalAlign: "bottom"
                                          }}
                                        >
                                          Câmbio efetivo (VET)
                                        </button>
                                      </span>
                                    </span>
                                  </div> ) :(<div></div>)}

                                  <hr className="m-y-2" />

                                  <div className="m-b-2">
                                  <strong className="tw-review-details-card__title">
                                    <small className="ng-binding">
                                      {" "}
                                      Dados do beneficiário{" "}
                                    </small>
                                  </strong>
                               
                                </div>
                                {/* ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element m-b-1 ng-scope"
                                  style={{}}
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Nome do titular da conta
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    Caio Correa Lima El Yanes
                                  </span>
                                </p>
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element m-b-1 ng-scope"
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Agência bancária
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    2977
                                  </span>
                                </p>
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element m-b-1 ng-scope"
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Número da conta
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    2079-7
                                  </span>
                                </p>
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  className="m-b-0 tw-review-element ng-hide"
                                  ng-show="$ctrl.deliveryMethod === 'LINK'"
                                  style={{}}
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Banco
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    Itaú
                                  </span>
                                </p>
                               
                                
                                  <div className="m-t-3 tw-calculator-notices">
                                    <div
                                      role="alert"
                                      className="alert d-flex alert-neutral"
                                    >
                                      <div
                                        className="alert__content d-flex flex-grow-1 desktop"
                                        data-testid="desktop"
                                      >
                                        <span
                                          data-testid="status-icon"
                                          className="status-circle status-circle-lg neutral"
                                        >
                                          <span
                                            className="tw-icon tw-icon-info status-icon light"
                                            aria-hidden="true"
                                            role="presentation"
                                            data-testid="info-icon"
                                          >
                                            <svg
                                              width={16}
                                              height={16}
                                              fill="currentColor"
                                              focusable="false"
                                              viewBox="0 0 24 24"
                                            >
                                              <path d="M11.143 9.857h1.714v9.857h-1.714z" />
                                              <circle cx={12} cy={6} r="1.714" />
                                            </svg>
                                          </span>
                                        </span>


                                        <div className="alert__message">
                                          <span className="np-text-body-large d-block">
                                            <span>
                                              Ao continuar, você aceita os Termos
                                              de Uso da nossa empresa.
                                            </span>
                                          </span>
                                          <a
                                            href="https://wise.com/terms-and-conditions"
                                            target="_blank"
                                            className="np-link np-text-link-large d-inline-flex m-t-1"
                                            rel="noreferrer"
                                          >
                                            Saiba mais
                                            <span
                                              className="tw-icon tw-icon-navigate-away "
                                              aria-hidden="true"
                                              role="presentation"
                                              data-testid="navigate-away-icon"
                                            >
                                              <svg
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                focusable="false"
                                                viewBox="0 0 24 24"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M12.857 1.287h8.571a.86.86 0 0 1 .857.857v8.572h-1.714V4.2l-9.428 9.429-1.2-1.2 9.428-9.429h-6.514V1.287ZM3.429 20.998H18v-6h1.714v6.857a.86.86 0 0 1-.857.858H2.572a.86.86 0 0 1-.858-.858V4.713a.86.86 0 0 1 .857-.858h6V5.57H3.43v15.428Z"
                                                />
                                              </svg>
                                            </span>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="d-flex m-t-2">
                                    <button
                                      className="btn btn-md np-btn np-btn-md btn-accent btn-priority-2 m-r-1 schedule-cta d-flex justify-content-center align-items-center"
                                      aria-label="Agendamento"
                                      type="button"
                                    >
                                      <span
                                        className="tw-icon tw-icon-calendar d-inline-block"
                                        aria-hidden="true"
                                        role="presentation"
                                        data-testid="calendar-icon"
                                      >
                                        <svg
                                          width={24}
                                          height={24}
                                          fill="currentColor"
                                          focusable="false"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M16.286 6.856V8.57H18V6.856h2.571v12H3.43v-12H6V8.57h1.714V6.856h8.572ZM2.576 5.142H6V2.57h1.714v2.572h8.572V2.57H18v2.572h3.424c.476 0 .862.386.862.861V19.71a.861.861 0 0 1-.862.861H2.576a.861.861 0 0 1-.862-.861V6.003c0-.476.386-.861.862-.861Zm14.567 11.571a1.286 1.286 0 1 0 0-2.571 1.286 1.286 0 0 0 0 2.571Z"
                                          />
                                        </svg>
                                      </span>
                                    </button>
                                    <button
                                      className="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-positive btn-priority-1 m-t-0"
                                      data-testid="continue-button"
                                      type="button"
                                    >
                                      Confirmar transferência
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div style={{ width: 32, display: "none" }} />
                              
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
     
    </div>
  </div>
  )
};
export default Wise;