import logocorpoImage from '../Home/images/logo_corpo.png';
import Qrcode from '../MintFiat/static_qrcode.png'
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ENDPOINT} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import '../MintFiat/main.css';

function PixtoUsd () {
    const [lastTrade, setLastTrade] = useState(null);
    const [miniTicker, setMiniTicker] = useState(null);
    const [depthData, setDepthData] = useState(null);
    const [klineData, setKlineData] = useState(null);
    const [Token, setToken] = useState(null);
 


    
    useEffect(() => {

      fetch(`${ENDPOINT}/pay-in/pix-to-usd`, {
        method: 'POST',
        credentials: 'include',
        headers:{
          "MAINTENANCE-TOKEN":"BRLAISTHEWAY"
        }
    }).then(response => {
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        return response.json();
    }).then(data => {
        // Aqui, 'data' é o corpo da resposta parseado como JSON
        const token = data.token;
        // Agora você pode fazer algo com o token...
        console.log(token);
        const SOCKET_SERVER_URL = `wss://api.brla.digital:4567/v1/websocket/${data.token}`;

        const socket = new WebSocket(SOCKET_SERVER_URL);
  
        socket.onopen = () => {
            // Enviar o token de manutenção quando a conexão for aberta
            console.log("qualquer coisa")
            socket.send(JSON.stringify({
              messageId:"qualquer",
              operation:"Quote",
              data:{
                  amountBrl:90000,
                  chain:"Polygon",
                  coin:"USDC"
              }
          }));
        };
    
        socket.onmessage = (event) => {
            try {
                // Tente parsear a mensagem como JSON
                const data = JSON.parse(event.data);
         
                // Se o parsing foi bem sucedido, 'data' agora contém os dados JSON
                console.log(data);
            } catch (error) {
                // Se um erro foi lançado, isso significa que a mensagem não era um JSON válido
                console.log("A mensagem recebida não é um JSON válido:", event.data);
            }
        };

        socket.onerror = (event) => {
          // Este evento é disparado quando ocorre um erro na conexão WebSocket
          console.error("WebSocket error observed:", event);
      };

      socket.onclose = (event) => {
          // Este evento é disparado quando a conexão WebSocket é fechada
          console.log("WebSocket connection closed:", event);
      };

        

        // Definir o estado do token
        setToken(token);
    }).catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
    });
       



  
     
  }, []);

  const [wallets, setWallets] = useState([]);
  const [showQRCode, setShowQRCode] = useState(false);
  const [mintValue, setMintValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(2);
  const handleMintClick = () => {
    setShowPopup(true);
  };
  const [isInvalid, setIsInvalid] = useState(false);
  const handleMintInputChange = (e) => {
    let value = e.target.value;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setMintValue(value);
      setIsInvalid(false);
      return;
    }
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setMintValue(value);
      setIsInvalid(value < 50);
    }
  };
  
  
  
    const navigate = useNavigate();
  
    
    useEffect(() => {
      // Obtem as carteiras do localStorage
      const loadedWallets = localStorage.getItem('wallets');
      if (loadedWallets) {
        setWallets(JSON.parse(loadedWallets));
      }
  
    
      setPendingRequests(0);
    }, []);

    

    const [selectedWallet, setSelectedWallet] = useState('');

    

    const handleAddWalletClick = () => {
      navigate('/addwallet'); 
    };
    const handleDoneClick = () => {
      navigate('/wallet'); 
    };
    const [showPopup, setShowPopup] = useState(false);
    const [isSelectDisabled, setIsSelectDisabled] = useState(false);

    const handleConfirmClick = async () => {
      // Execute a ação de confirmação aqui (por exemplo, chamar uma API)
      setShowPopup(false);
    
      try {
        const resHistory = await fetch(`${ENDPOINT}/pay-in/pix`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            walletId: selectedWallet.value,
            amount: (mintValue*100),
            notifyEmail: true

          }),
        });
    
        if (resHistory.ok) {
          toast.success('Request successful. Make sure to pay the Pix with the same amount you entered');
          setIsSelectDisabled(true); // Desabilita o Select
          setShowQRCode(true);
        } else {
          const errorData = await resHistory.json();
          toast.error(`DONT PAY THE PIX Request failed: ${errorData.error || 'Log in again.'}`);
        }
      } catch (error) {
        console.error("Erro ao processar a transação Pix:", error);
        toast.error(`DONT PAY THE PIX Request failed: ${error.error || 'Log in again.'}`);
      }
    };
       

    const [selectedWalletData, setSelectedWalletData] = useState(null);

    const handleWalletChange = (selectedOption) => {
      setSelectedWallet(selectedOption);
      const walletData = wallets.find((wallet) => wallet.id === selectedOption.value);
      setSelectedWalletData(walletData);
    };
    


const handleBackClick = () => {
  navigate('/wallet')
};

const options = wallets.map(wallet => {
  const address = wallet.walletAddress;
  const shortAddress = address.slice(0, 3) + '...' + address.slice(-3);
  return { value: wallet.id, label: `${wallet.name} (${shortAddress})` };
});


const handleChange = selectedOption => {
  console.log(`Option selected:`, selectedOption);
  setSelectedWallet(selectedOption); // Aqui estamos atualizando o estado com a opção selecionada
};



  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#00dc84',
      borderColor: 'white',
      color: 'white',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: 'white',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: 'white',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'white',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'white',
    }),
    option: (provided) => ({
      ...provided,
      color: 'white',
      backgroundColor: '#00dc84',
      '&:hover': {
        color: '#00dc84',
        backgroundColor: 'white',
      },
    }),
  };  

 

useEffect(() => {
  // Se não há requisições pendentes, então as requisições acabaram
  if (pendingRequests === 0) {
    // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
    setIsLoading(false);
  } 
}, [pendingRequests]);

if (isLoading) {
  // Retorne um spinner de loading, um placeholder ou null
  return <div></div>;
}




    
    return(<>
   {showPopup && selectedWalletData && (
  <div className="wallet-popup-overlay">
    <div className="wallet-popup">
      <p>Transaction ID: {selectedWalletData.id}</p>
      <br/>
      <p>Wallet Address: {selectedWalletData.walletAddress}</p>
      <br/>
      <p>Blockchain: {selectedWalletData.chain}</p>
      <br/>
      <p>You have to pay via pix exactly the same amount you entered here: {mintValue} BRL. If you pay a different amount your transaction will not be recognized and will be returned after transfers fee deduction</p>
      <br/>
      <p className="warning">
        By confirming, you acknowledge that you have read all the information
        above.
      </p>
      <br/>
      <button className="cb-button base primary" onClick={handleConfirmClick}>
        Confirm
      </button>
    </div>
  </div>
)}


      
        <div id="headlessui-portal-root">
          <div data-headlessui-portal="">
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
             
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
            <div>
              <div
                className="cb-modal"
                data-segmentscreenname="deposit_blockchain_step_2"
                id="headlessui-dialog-:r1:"
                role="dialog"
                aria-modal="true"
                data-headlessui-state="open"
              >
                <div className="modal-container">
                  <div
                    className="overlay opacity-100"
                    data-testid="overlay"
                    id="headlessui-dialog-overlay-:r2:"
                    aria-hidden="true"
                    data-headlessui-state="open"
                  />
                  <div
                    className="modal-content screen opacity-100 scale-100"
                    data-testid="content"
                  >
                    <div className="cb-modal-coolwhip">
                      
                    </div>
                    <div className="h-screen overflow-x-auto">
                      <div className="grid grid-rows-auto-1">
                        <div className="sticky top-0 bg-white z-10">
                          <div className="grid grid-cols-3 py-5 px-9">
                            <div className="flex items-center justify-start">
                              <button onClick={handleBackClick} style={{color:'black'}} data-testid="back-button">
                                <svg
                                  width={26}
                                  height={26}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="cb-icon cb-icon-chevronleftlight pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-chevronleftlight"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m15 19-7-7 7-7"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="flex items-center justify-center">
      
          <img src={logocorpoImage} style={{ width: '60px', height: '60px' }} />
        
    </div>
                            <div className="flex items-center justify-end">
                              <button
                                className="cb-modal-basic-close top-right relative inset-0"
                                data-testid="close-button"
                                style={{color:'black'}}
                                onClick={handleBackClick}
                              >
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 14 14"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-cross pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-cross"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="w-full h-1 bg-black-75">
                            <div
                              aria-valuemax={100}
                              aria-valuemin={0}
                              aria-valuenow={-200}
                              className="h-full bg-blue-300 transition-width duration-100"
                              role="progressbar"
                            />
                          </div>
                        </div>
                        <div
                          className="cb-modal-body default relative pt-16 pb-20 font-circular"
                          data-testid="modal-body"
                        >
                          <div>
                            <div
                              className="grid h-full content-center items-center justify-center"
                              data-testid="center-both"
                            >
                              <div className="flex h-full w-184 flex-col items-center justify-between">
                                <div
                                  className="mb-8"
                                  data-testid="blockchain-deposit-instruction"
                                >
                                  <div className="mb-5">
                                    <div className="text-center col-span-4">
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {wallets.length > 0 ? (
    <>
      <button
        className="cb-button base primary w-64"
        style={{ marginBottom: '10px' }} // Adiciona uma margem inferior de 10px
        disabled=""
        type="submit"
        data-testid="button-submit"
        onClick={handleAddWalletClick}
      >
        <span>Add wallet</span>
      </button>

      <Select
  value={selectedWallet}
  options={options}
  className="basic-multi-select"
  classNamePrefix="select"
  isSearchable
  placeholder="Select a wallet"
  onChange={handleWalletChange}
  isDisabled={isSelectDisabled} // Desabilita o Select baseado no estado
  styles={{
    control: (base) => ({
      ...base,
      width: 290, // Set the width here
    }),
  }}
/>

    </>
  ) : (
    <button
      className="cb-button base primary w-64"
      disabled=""
      type="submit"
      data-testid="button-submit"
      onClick={handleAddWalletClick}
    >
      <span>Add wallet</span>
    </button>
  )}
</div>



      {/*   <p className="mt-4 text-sm leading-5 text-black-600 font-circular-regular">
                              To transfer BRLA to your BRLA Account, ensure that the CPF registered with your bank account matches the CPF registered with your BRLA Account. This is crucial for a successful transaction.
                                      </p>
                                      
                                      <p className="mt-4 text-sm leading-5 text-black-600 font-circular-regular">
                                      Please note that PIX transactions are typically instant, allowing for prompt transfers. However, outside of regular banking hours, the transactions may experience delays and be subject to limits on the amount transferred. Keep in mind that we rely on the speed of the network to process the transactions. Depending on network traffic, it may take up to 30 minutes for your transfer to be completed.
</p>*/}
                                    {/*  <p className="mt-6 text-sm leading-5 text-black-600 font-circular-regular">
                                        <svg
                                          width={15}
                                          height={15}
                                          viewBox="0 0 16 14"
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="cb-icon cb-icon-alertfilled mr-1 mb-1 inline-block text-orange-400 pointer-events-none"
                                          aria-hidden="true"
                                          data-testid="icon-alertfilled"
                                          focusable="false"
                                          role="img"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.257 1.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H2.42C.89 14-.073 12.354.677 11.02l5.58-9.92ZM9 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8 3a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1Z"
                                          />
                                        </svg>
                                        <b>Important:</b> Always double-check that the CPF registered with your bank account matches the one registered with your BRLA Account to avoid issues with your transaction. Remember that the processing time may vary depending on factors such as network traffic and banking hours.
                                        <br />
                                        <br />
                                        You may be subject to and responsible for any
                                        and all blockchain network fees for on-chain
                                        transfers.
</p> */}


                                    </div>
                                    {!showQRCode && (
        <div className='center-div' style={{width:'50%', marginTop:'50px'}}>
        
          <div className="cb-copy-to-clipboard base-variant" >
  <div className="label">Amount</div>
  <div
                    className="cb-input floating-label no-label box-content h-16 w-full"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <div style={{ position: 'relative', width: 'fit-content' }}> {/* Contêiner com posição relativa */}
                    <input
    id="cb-input-email"
    aria-labelledby="cb-input-email"
    aria-describedby=""
    className={`input w-64 ${mintValue < 50 && mintValue !== "" ? "error-border" : ""}`} 
    data-testid="input-email"
    defaultValue=""
    name="email"
    onChange={handleMintInputChange}
    value={mintValue}
    style={{justifyContent:"center"}}
    type="text" // Altere o tipo para "text"
/>


  {mintValue < 50 && mintValue !== "" && (  // Se mintValue for menor que 50 e não for vazio, renderiza a mensagem de erro
    <span style={{ 
        color: "red", 
        position: 'absolute', // Posiciona absolutamente em relação ao contêiner
        right: '0px', // Ajuste este valor conforme necessário para mover a mensagem de erro para a esquerda ou direita
        top: '45px', // Ajuste este valor conforme necessário para mover a mensagem de erro para cima ou para baixo
        fontSize: "0.8em"
    }}>
      The minimum mint value is 50 BRLA
    </span>
  )}
</div>



                    </div>
                  </div>
</div>
<button
  className="cb-button base primary w-64"
  disabled={!selectedWallet || mintValue < 50} // o botão será desabilitado se não houver carteira selecionada ou se mintValue for menor que 50
  type="submit"
  data-testid="button-submit"
  onClick={handleMintClick}
>
  <span>Buy</span>
</button>
</div>
       
      )}
      {showQRCode && (
        <div >
          <h2 style={{marginTop:'20px', display:'flex', justifyContent:'center'}}>PIX QR Code</h2>
          <img src={Qrcode} style={{ display: 'flex', justifyContent: 'center', margin: '0 auto' }} />
          <div style={{display:'flex', justifyContent:'center'}} className="label-class">Amount: {mintValue} BRL</div>
        </div>
      )}

<p style={{display:'flex', textAlign:'center'}}  className="text-sm leading-5 text-black-600 font-circular-regular">
 BRLA is not a bank; your Account is not a bank account.
  <br/>
  After paying this pix go to home and look for recent BRLA activities to confirm the purchase.
</p>

                                  


                                    <div>

                                  </div>
               
                                  
                                  
                                  <button
                                  className="cb-button base secondary w-60 center-div"
                                  type="button"
                                  onClick={handleDoneClick}
                                  style={{display:'flex', justifyContent:'center'}}
                                 

                               
                                >
                                  <span>Back</span>
                                </button>
                                </div>
                                </div>
                           
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
          </div>
        </div>
        <ToastContainer />
      </>
      )
};
export default PixtoUsd;