
/* global BigInt */
import 'react-tooltip/dist/react-tooltip.css';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, MANTEINENCETOKEN, USDT_CONTRACT_ADDRESSES, USDC_NATIVE_CONTRACT_ADDRESSES} from '../../variaveis';
import { BRLAContractAbi } from '../../utils/abis';
import Web3 from 'web3';
import "react-toastify/dist/ReactToastify.css";
import classes from './Main.module.css';
import TransactionDetails from '../TransactionDetails';
import TransactionDetailsBRL from '../TransactionDetailsBRL';
import TransactionDetailsBRLUSD from '../TransactionDetailsBRLUSD';
import TransactionDetailsUSDBRL from '../TransactionDetailsUSDBRL';
import TransactionDetailsDepositOnchain from '../TransactionDetailsDepositOnchain';
import TransactionDetailsOnchainOut from '../TransactionDetailsOnchainOut';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import * as XLSX from "sheetjs-style";
import * as FileSaver from 'file-saver';
const responsiveStyle = {
  width: '100%',
  maxWidth: '100%',
  overflowX: 'hidden',
};
function Transactions({ limit, showLink, showButton  }) {
  const { t, i18n} = useTranslation();
  const [tooltip, setTooltip] = React.useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingExtrato, setIsLoadingExtrato] = useState(false);
  const [pendingRequests, setPendingRequests] = useState(1);
  const [users, setUsers] = useState([]);
  const [balance, setBalance] = useState([]);
  const [wallets, setWallets] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [kychistoryData, setKycHistoryData] = useState([]);
  const [showDeposit, setShowDeposit] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const buttonRefDeposit = useRef(null);
  const buttonRefTransfer = useRef(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  useEffect(() => {
   


    const fetchBalance = async () => {
    
   
      // Cria uma nova instância do web3 com o Infura
      const web3 = new Web3(new Web3.providers.HttpProvider("https://polygon-rpc.com/"));

      // Mapeia cada carteira para um objeto que inclui o saldo BRLA
      
          const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[80002];
          const contract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
          const balance = await contract.methods.balanceOf(users.walletAddress).call();
          console.log("balance novo:", balance)
          setBalance(balance)

      
      

      
  };

  if (users.walletAddress) {
    fetchBalance();
  }
  setPendingRequests(0);

  }, [users.walletAddress]);

  function numberWithThousandSeparator(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function mapStatus(item, type) {
    if (!item) return "unknown";
  
    if (type === 'swap') {
      if (!item.smartContractOps) return "Pendente";
      if (item.smartContractOps[0]?.feedback?.success === true) return "Completo";
      if (item.smartContractOps[0]?.feedback?.success === false) return "Falha";
      return "Pendente";
    }
    if (type === 'payment') {
      if (item?.paymentOps?.[0]?.transfers?.[0]?.feedbacks?.[0]?.transferStatus === "success") return "Completo";
     else if (item?.paymentOps?.[0].smartContractOps?.[0]?.feedback?.success === true) return "Completo";
     else if (item?.paymentOps?.[0].smartContractOps?.[1]?.feedback?.success === true) return "Completo";
     else if (item?.paymentOps?.[0].smartContractOps?.[0]?.feedback?.success === false) return "Falha";
    else  if (item?.paymentOps?.[0]?.transfers?.[0]?.feedbacks?.[0]?.transferStatus === false) return "Falha";
    else  return "Pendente";
  }
  
  
    if (type === 'buy') {
      if (item?.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.success) return "Completo";
      if (item?.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.success === false) return "Falha";
      if (item.status === "PAID") return "Pendente";
      if (item.status === "UNPAID") return "Pendente";

    } else if (type === 'sell') {
      if (item?.transfers?.[0]?.feedbacks?.[0]?.logType === "success") return "Completo";
      if (item?.smartContractOps?.[0]?.feedback?.success === false ) return "Falha";
      if (item?.transfers?.[0]?.feedbacks?.[0]?.logType === "failed") return "Falha";
      if (item?.transfers?.[0]?.feedbacks?.[0]?.logType === "refunded") return "Falha";
      return "Pendente"
    }
    if (type==='onchain-deposit') {
      return "Completo"
    }
  
    if (type==='onchain-out') {
      if (item?.smartContractOps?.[0]?.feedback?.success === true) return "Completo";
      if (item?.smartContractOps?.[0]?.feedback?.success === false) return "Falha";
      return "Pendente";
    }
  
  
    else return "Pendente"
    
  }
  
  useEffect(() => {
    const loadData = async () => {
      const lastRequestDate = localStorage.getItem('lastRequestDate');
      const currentDate = new Date();


  
      if (lastRequestDate && currentDate - new Date(lastRequestDate) < 3000) {
        // If less than a minute has passed since the last request, do not make a new request
         // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
    }


    // Obtem os dados do histórico do localStorage
    const loadedHistoryData = localStorage.getItem('historyData');
    if (loadedHistoryData) {
      let historyData = JSON.parse(loadedHistoryData);
    
      // Ordenando os dados do histórico pela data de atualização
      historyData = historyData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setHistoryData(historyData);
    
    };


    const loadedKYCHistory = localStorage.getItem('kychistory');
    if (loadedKYCHistory) {
      const KycHistory = JSON.parse(loadedKYCHistory);
      console.log("VAI: ", KycHistory)
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setKycHistoryData(KycHistory);


    };

      }

      else {
        async function fetchAllPagesForPurchases() {
          let page = 1;
          let allResults = [];
          let hasMore = true;
        
      
            const resPurchases = await fetch(`${ENDPOINT}/pay-in/pix/history?pageSize=100&page=${page}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                "MAINTENANCE-TOKEN": MANTEINENCETOKEN
              },
            });

            if (resPurchases.status === 401) {
              navigate('/login'); // Substitua 'navigate' pela função de navegação do seu framework
              return;
            }
        
            const bodyPurchases = await resPurchases.json();
            const purchases = bodyPurchases.depositsLogs || [];
        
       
          return purchases;
        }
        const allPurchases = await fetchAllPagesForPurchases();
        console.log("allPurchases: ", allPurchases)
     
        async function fetchAllPagesForSales() {
          let page = 1;
          let allResults = [];
          let hasMore = true;
        
        
            const resSales = await fetch(`${ENDPOINT}/pay-out/history?pageSize=100&page=${page}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                "MAINTENANCE-TOKEN": MANTEINENCETOKEN
              },
            });
        
            const bodySales = await resSales.json();
            const sales = bodySales.transfersLogs || [];
        
        
        
          return sales;
        }
        
        // E então, ao invés de chamar diretamente a requisição, você usa:
        const allSales = await fetchAllPagesForSales();
        
  
        async function fetchAllPagesForOnchainDeposit() {
          let page = 1;
          let allResults = [];
          let hasMore = true;
        
          
            const resOnchainDeposit = await fetch(`${ENDPOINT}/on-chain/history/in?pageSize=100&page=${page}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                "MAINTENANCE-TOKEN": MANTEINENCETOKEN
              },
            });
        
            const bodyOnchainDeposit = await resOnchainDeposit.json();
            const onchainDeposits = bodyOnchainDeposit.onchainLogs || [];
        
          
        
          return onchainDeposits;
        }
        
        // E então, ao invés de chamar diretamente a requisição, você usa:
        const allOnchainDeposits = await fetchAllPagesForOnchainDeposit();
  
        async function fetchAllPagesForOnchainOut() {
          let page = 1;
          let allResults = [];
          let hasMore = true;
        
         
            const resOnchainOut = await fetch(`${ENDPOINT}/on-chain/history/out?pageSize=100&page=${page}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                "MAINTENANCE-TOKEN": MANTEINENCETOKEN
              },
            });
        
            const bodyOnchainOut = await resOnchainOut.json();
            const onchainOuts = bodyOnchainOut.onchainLogs || [];
        
         
          return onchainOuts;
        }
        
        // E então, ao invés de chamar diretamente a requisição, você usa:
        const allOnchainOuts = await fetchAllPagesForOnchainOut();
        
  
  
  
        async function fetchAllPagesForSwaps() {
          let page = 1;
          let allResults = [];
          let hasMore = true;
        
         
            const resSwaps = await fetch(`${ENDPOINT}/swap/history?pageSize=100&page=${page}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                "MAINTENANCE-TOKEN": MANTEINENCETOKEN
              },
            });
        
            const bodySwaps = await resSwaps.json();
            const swaps = bodySwaps.swapLogs || [];
        
         
        
          return swaps;
        }
        
        // E então, ao invés de chamar diretamente a requisição, você usa:
        const allSwaps = await fetchAllPagesForSwaps();
  
        async function fetchAllPagesForPayments() {
          let page = 1;
          let allResults = [];
          let hasMore = true;
        
         
            const resPayments = await fetch(`${ENDPOINT}/payment/history?pageSize=100&page=${page}`, {
              method: 'GET',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                "MAINTENANCE-TOKEN": MANTEINENCETOKEN
              },
            });
        
            const bodyPayments = await resPayments.json();
            const payments = bodyPayments.paymentLogs || [];
        
         
        
          return payments;
        }
        
        // E então, ao invés de chamar diretamente a requisição, você usa:
        const allPayments = await fetchAllPagesForPayments();
  
    
     
      const formatTaxId = (taxId) => {
        if (taxId && typeof taxId === 'string') {
          taxId = taxId.replace(/\D/g, ''); // Remover caracteres não numéricos, se houver
      
          if (taxId.length === 11) {
            return '***.' + taxId.slice(3, 6) + '.' + taxId.slice(6, 9)  + '-**'; // CPF
          } else if (taxId.length === 14) {
            return '**.' + taxId.slice(3, 6) + '.' + taxId.slice(6, 9) +   '/****-'  ; // CNPJ
          } else if (taxId.length === 6) {
            return '***.' + taxId.slice(0, 3) + '.' + taxId.slice(3, 6) + '-**'; // Seis números
          }
        }
      
        return '';
      };
      
      
      
      const depositsLogsWithType = allPurchases.map(item => ({
        id: item.mintOps?.[0]?.smartContractOps?.[0].tx || 'pending payment',
        chain: item.chain,
        walletAddress: item.walletAddress,
        amount: numberWithThousandSeparator((item.amount-item.mintOps?.[0]?.fee)/100),
        status: mapStatus(item, 'buy'),
        type: 'buy',
        coin:'BRL',
        updatedAt: item.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.[0]?.createdAt || item.mintOps?.[0]?.smartContractOps?.[0]?.createdAt || item.mintOps?.[0]?.createdAt || item.createdAt,
        taxId: formatTaxId(item.taxId),
        fullTaxId:item.taxId,
        failedReason: item.mintOps?.[0]?.smartContractOps?.[0]?.notPostedReason?.[0] || item.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.[0]?.errorMsg?.[0],
        name: item.payerName,
        fee: item.mintOps?.[0]?.fee/100,
        referenceLabel: item.referenceLabel
        
      }));
     
      
      const transfersLogsWithTypeAndRenamed = allSales.map(item => ({
        id: item.smartContractOps?.[0]?.tx || 'pending burn',
        transferid: item.transfers?.[0]?.id,
        chain: item.chain,
        walletAddress: item.walletAddress,
        amount: `(${numberWithThousandSeparator(item.amount/100)})`,
        type: 'sell',
        coin: 'BRL',
        status: mapStatus(item, 'sell'),
        updatedAt: item.transfers?.[0]?.feedbacks?.[0]?.createdAt || item.transfers?.[0]?.createdAt || item.smartContractOps?.[0]?.feedback?.createdAt || item.smartContractOps?.[0]?.createdAt || item.createdAt,
        taxId:  formatTaxId(item.transfers?.[0]?.taxId),
        fullTaxId: item.transfers?.[0]?.taxId,
        failedReason: item.smartContractOps?.[0]?.notPostedReason || item.smartContractOps?.[0]?.feedback?.errorMsg || 'A transação falhou',
        name: item.transfers?.[0]?.name,
        fee:item.fee/100,
        referenceLabel: item.referenceLabel
      }));

      const PaymentsLogsWithTypeAndRenamed = allPayments.map(item => ({
        id: item.smartContractOps?.[0]?.tx || 'pending payment',
        chain: item.chain,
        walletAddress: item.address,
        amount: `(${numberWithThousandSeparator(item.usdAmount/100)})`,
        type: 'payment',
        status: mapStatus(item, 'payment'),
        updatedAt: item.paymentOps?.[0]?.smartContractOps?.[0]?.feedback?.createdAt || item.paymentOps?.[0]?.smartContractOps?.[0]?.createdAt || item.paymentOps?.[0].createdAt || item.createdAt, 
        coin: item.coin,
        brlaAmount: item.brlaAmount,
        usdAmount: item.usdAmount,
        basePrice: item.basePrice,
        baseFee: item.baseFee,
        gassFee: 0,
        failedReason: item.paymentOps?.[0]?.smartContractOps?.[0]?.notPostedReason?.[0] || item.paymentOps?.[0]?.smartContractOps?.[0]?.feedback?.errorMsg,
        name: item.paymentOps?.[0].transfers?.[0].name || 'Pendente',
        taxId:  formatTaxId(item.paymentOps?.[0].transfers?.[0].taxId),
        fullTaxId: item.paymentOps?.[0].transfers?.[0].taxId,
        fee: item.baseFee
      
      }));

      const OnchainDepositLogsWithTypeAndRenamed = allOnchainDeposits.map(item => ({
        id: item.id,
        chain: item.chain,
        tokenAddress: item.tokenAddress,
        fromAddress: item.fromAddress,
        toAddress: item.toAddress,
        tx: item.tx,
        amount: `${numberWithThousandSeparator(item.amount)}`,
        amountclean: parseFloat(item.amount).toFixed(2),
        type: 'onchain-deposit',
        status: mapStatus(item, 'onchain-deposit'),
        updatedAt: item.createdAt, 
        coin:  item.tokenAddress.toLowerCase().trim() === USDT_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'USDT'  : item.tokenAddress.toLowerCase().trim() ===  BRLA_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'BRLA' :  item.tokenAddress.toLowerCase().trim() ===  USDC_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'USDC' : item.tokenAddress.toLowerCase().trim() ===  USDC_NATIVE_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'USDC' : 'TOKEN',
        fee:0 
      
      })); 

      const calculateValue = (coin, value) => {
        if (coin === 'USDT' || coin === 'USDC') {
          return value / 1000000;
        } else if (coin === 'BRLA') {
          return parseFloat(value)/10**18 ;
        } else {
          return value;
        }
      };
      
      
      
      const OnchainOutLogsWithTypeAndRenamed = allOnchainOuts.map(item => {
        const calculatedValue = calculateValue(item.outputCoin, item.value);
      
        return {
            id: item.id,
            userId: item.userId,
            chain: item.chain,
            fromAddress: item.from,
            toAddress: item.to,
            tx: item.smartContractOps?.[0]?.Tx,
            amount: `(${numberWithThousandSeparator(calculatedValue)})`,
            amountclean: parseFloat(calculatedValue).toFixed(2),
            type: 'onchain-out',
            status: mapStatus(item, 'onchain-out'),
            updatedAt: item.createdAt,
            coin: item.outputCoin,
            fee:0  
        };
      });

    
          // Mapear os dados
    const swapsLogsWithTypeAndRenamed = allSwaps.map(item => ({
      id: item.smartContractOps?.[0]?.tx || 'pending swap',
      chain: item.chain,
      walletAddress: item.walletAddress,
      amount: item.usdToBrla ? numberWithThousandSeparator(item.brlaAmount/100) : `(${numberWithThousandSeparator(item.usdAmount/100)})`,
      type: 'swap',
      status: mapStatus(item, 'swap'),
      updatedAt: item.smartContractOps?.[0]?.feedback?.createdAt || item.smartContractOps?.[0]?.createdAt || item.createdAt, 
      coin: item.coin,
      brlaAmount: item.brlaAmount,
      usdAmount: item.usdAmount,
      usdToBrla: item.usdToBrla,
      basePrice: item.basePrice,
      baseFee: item.baseFee,
      gassFee: 0,
      failedReason: item.smartContractOps?.[0]?.notPostedReason?.[0] || item.smartContractOps?.[0]?.feedback?.errorMsg,
      name: `${item.walletAddress.slice(0, 3)}....${item.walletAddress.slice(-3)}`,
      walletAddress: item.walletAddress,
      receiverAddress: item.receiverAddress,
      usdToBrla: item.usdToBrla,
      tx: item.smartContractOps?.[0]?.tx,
      referenceLabel: item.referenceLabel

    }));

    const swapLogTxValues = allSwaps.map(log => 
      log.smartContractOps && log.smartContractOps.length > 0 ? log.smartContractOps[0].tx : null
    ).filter(tx => tx !== null);
    
    const paymentsLogTxValues = allPayments.map(log => 
      log.paymentOps?.[0]?.smartContractOps?.[0]?.tx 
    ).filter(tx => tx !== null);

    const depositLogTxValues = allPurchases.map(log => 
      log.mintOps?.[0]?.smartContractOps?.[0]?.tx 
    ).filter(tx => tx !== null);
    
    
    const filteredOnchainDepositLogs = OnchainDepositLogsWithTypeAndRenamed.filter(item => 
      !swapLogTxValues.includes(item.tx) && !paymentsLogTxValues.includes(item.tx) && !depositLogTxValues.includes(item.tx) 
    );
    
    
    
    
   
    
    const filteredOnchainOut = OnchainOutLogsWithTypeAndRenamed.filter(item => 
      !swapLogTxValues.includes(item.tx) && !paymentsLogTxValues.includes(item.tx)
    );
    
    const filteredDepositsLogs = depositsLogsWithType.filter(item => item.chain !== 'XRP');
    console.log("filteredDepositsLogs: ", filteredDepositsLogs)
          
    const historyData = [...filteredDepositsLogs, ...transfersLogsWithTypeAndRenamed, ...swapsLogsWithTypeAndRenamed, ...PaymentsLogsWithTypeAndRenamed, ...filteredOnchainDepositLogs, ...filteredOnchainOut];
    console.log("historyData novo: ", historyData)
      localStorage.setItem('nextRequestDate', currentDate.toISOString());
      localStorage.setItem('historyData', JSON.stringify(historyData));
  
      // After making the requests, store the current date as the last request date
      localStorage.setItem('lastRequestDate', currentDate.toISOString());
      setKycHistoryData(historyData)

          // Obtem os usuários do localStorage
    const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
    }


    // Obtem os dados do histórico do localStorage
    const loadedHistoryData = localStorage.getItem('historyData');
    if (loadedHistoryData) {
      let historyData = JSON.parse(loadedHistoryData);
    
      // Ordenando os dados do histórico pela data de atualização
      historyData = historyData.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setHistoryData(historyData);
      console.log("vamos ver: ", historyData)
    };


    const loadedKYCHistory = localStorage.getItem('kychistory');
    if (loadedKYCHistory) {
      const KycHistory = JSON.parse(loadedKYCHistory);
      console.log("VAI: ", KycHistory)
      // Criando um objeto onde a chave é o ID e o valor é o item do histórico
      setKycHistoryData(KycHistory);


    };

  }};
  
    loadData();
  }, []);
  


 

  function formatDate(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }
  
  function formatDateDDMMYYYY(dateString) {
    const date = new Date(dateString);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
  
    return `${day}/${month}/${year}`;
}

function formatTimeHHMM(dateString) {
  const date = new Date(dateString);

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${hours}:${minutes}`;
}


  
  const navigate = useNavigate();
  
   


    const [showTransactionDetails, setShowTransactionDetails] = useState(false);
    const [showTransactionDetailsBRL, setShowTransactionDetailsBRL] = useState(false);
    const [showTransactionDetailsBRLUSD, setShowTransactionDetailsBRLUSD] = useState(false);
    const [showTransactionDetailsUSDBRL, setShowTransactionDetailsUSDBRL] = useState(false);
    const [showTransactionDetailsDepositOnchain, setShowTransactionDetailsDepositOnchain] = useState(false);
    const [showTransactionDetailsOnchainOut, setShowTransactionDetailsOnchainOut] = useState(false);
    const buttonRefTransactionDetails = useRef(null);
    const buttonRefTransactionDetailsBRL = useRef(null);
    const buttonRefTransactionDetailsBRLUSD = useRef(null);
    const buttonRefTransactionDetailsUSDBRL = useRef(null);
    const buttonRefTransactionDetailsDepositOnchain = useRef(null);
    const buttonRefTransactionDetailsOnchainOut = useRef(null);
    const TransactionDetailsRef = useRef();
    const TransactionDetailsRefBRL = useRef();
    const TransactionDetailsRefBRLUSD = useRef();
    const TransactionDetailsRefUSDBRL = useRef();
    const TransactionDetailsRefDepositOnchain = useRef();
    const TransactionDetailsRefOnchainOut = useRef();

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransactionDetails && TransactionDetailsRef.current && !TransactionDetailsRef.current.contains(event.target) && !buttonRefTransactionDetails.current.contains(event.target)) {
          // Adicione um atraso antes de fechar o pop-up
          setTimeout(() => {
            setShowTransactionDetails(false);
          }, 100); // Ajuste o valor do atraso conforme necessário
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransactionDetails]);
    

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransactionDetailsBRL && TransactionDetailsRefBRL.current && !TransactionDetailsRefBRL.current.contains(event.target) && !buttonRefTransactionDetailsBRL.current.contains(event.target)) {
          // Adicione um atraso antes de fechar o pop-up
          setTimeout(() => {
            setShowTransactionDetailsBRL(false);
          }, 100); // Ajuste o valor do atraso conforme necessário
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransactionDetailsBRL]);
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransactionDetailsBRLUSD && TransactionDetailsRefBRLUSD.current && !TransactionDetailsRefBRLUSD.current.contains(event.target) && !buttonRefTransactionDetailsBRLUSD.current.contains(event.target)) {
          // Adicione um atraso antes de fechar o pop-up
          setTimeout(() => {
            setShowTransactionDetailsBRLUSD(false);
          }, 100); // Ajuste o valor do atraso conforme necessário
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransactionDetailsBRLUSD]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransactionDetailsUSDBRL && TransactionDetailsRefUSDBRL.current && !TransactionDetailsRefUSDBRL.current.contains(event.target) && !buttonRefTransactionDetailsUSDBRL.current.contains(event.target)) {
          // Adicione um atraso antes de fechar o pop-up
          setTimeout(() => {
            setShowTransactionDetailsUSDBRL(false);
          }, 100); // Ajuste o valor do atraso conforme necessário
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransactionDetailsUSDBRL]);


    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransactionDetailsDepositOnchain && TransactionDetailsRefDepositOnchain.current && !TransactionDetailsRefDepositOnchain.current.contains(event.target) && !buttonRefTransactionDetailsDepositOnchain.current.contains(event.target)) {
          // Adicione um atraso antes de fechar o pop-up
          setTimeout(() => {
            setShowTransactionDetailsDepositOnchain(false);
          }, 100); // Ajuste o valor do atraso conforme necessário
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransactionDetailsDepositOnchain]);

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (showTransactionDetailsOnchainOut && TransactionDetailsRefOnchainOut.current && !TransactionDetailsRefOnchainOut.current.contains(event.target) && !buttonRefTransactionDetailsOnchainOut.current.contains(event.target)) {
          // Adicione um atraso antes de fechar o pop-up
          setTimeout(() => {
            setShowTransactionDetailsOnchainOut(false);
          }, 100); // Ajuste o valor do atraso conforme necessário
        }
      };
    
      document.addEventListener('click', handleClickOutside);
    
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }, [showTransactionDetailsOnchainOut]);
    
    const [selectedTransaction, setSelectedTransaction] = useState(null);
    const [selectedTransactionBRL, setSelectedTransactionBRL] = useState(null);
    const [selectedTransactionBRLUSD, setSelectedTransactionBRLUSD] = useState(null);
    const [selectedTransactionUSDBRL, setSelectedTransactionUSDBRL] = useState(null);
    const [selectedTransactionDepositOnchain, setSelectedTransactionDepositOnchain] = useState(null);
    const [selectedTransactionOnchainOut, setSelectedTransactionOnchainOut] = useState(null);

    useEffect(() => {
      // Se não há requisições pendentes, então as requisições maamacabaram
      if (pendingRequests === 0) {
        // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
        setIsLoading(false);
      } 
    }, [pendingRequests]);

    if (isLoading) {
      // Retorne um spinner de loading, um placeholder ou null
      return <div></div>;
    }

    const handleClosePopup = () => {
      setShowPopup(false);
    }


    const statusColors = {
      'Pendente': 'yellow',
      'Completo': 'green',
      'Cancelada': 'red',
    };


    const statuses = ['Pendente', 'Completo', 'Cancelada'];

    const randomStatus = statuses[Math.floor(Math.random() * statuses.length)];


    function formatDocument(document, type) {
      if (type === 'CPF') {
        return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      } else if (type === 'CNPJ') {
        return document.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
      } else {
        return document;
      }
    }


    function formatDate(dateString) {
      // Extrai os componentes da data da string
      const [year, month, day] = dateString.slice(0, 10).split('-');
    
      // Mapeia os números dos meses para os nomes dos meses
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthName = monthNames[parseInt(month, 10) - 1];
    
      // Retorna a data formatada
      return `${monthName} ${day}, ${year}`;
    }



    function formatDate(dateString) {
      // Cria um objeto Date a partir da string
      let date = new Date(dateString);
    
      // Ajusta para o fuso horário local
      date = new Date(date.getTime() );
    
      // Extrai os componentes da data
      const year = date.getFullYear();
      const month = date.getMonth();
      const day = date.getDate();
    
      // Mapeia os números dos meses para os nomes dos meses
      const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const monthName = monthNames[month];
    
      // Retorna a data formatada
      return `${monthName} ${day}, ${year}`;
    }



    function formatTime(dateString) {
      // Cria um objeto Date a partir da string
      let date = new Date(dateString);
    
      // Ajusta para o fuso horário local
      date = new Date(date.getTime() );
    
      // Extrai as componentes de hora e minuto
      let hour = date.getHours();
      const minute = date.getMinutes();
    
      // Converte a hora para o formato 12 horas
      let period = 'AM';
      if (hour >= 12) {
        period = 'PM';
        if (hour > 12) hour -= 12;
      } else if (hour === 0) {
        hour = 12;
      }
    
      // Retorna a hora formatada
      return `${hour}:${minute < 10 ? '0' : ''}${minute} ${period}`;
    }

    function getStatusColor(status) {
      switch (status) {
        case 'Pendente':
          return '#ecc434';
        case 'Completo':
          return '#00dc84';
        case 'Falha':
          return '#ff0000'; // substitua por sua cor vermelha preferida
        default:
          return '#000000'; // cor padrão para status desconhecidos
      }
    }
    
    function formatAmount(amount) {
      const isPositive = !amount.includes("(");
      const color = isPositive ? '#00dc84' : '#ff0000'; // substitua por suas cores preferidas
      const sign = isPositive ? '+' : '-';
      const absoluteAmount = amount.replace(/[()]/g, ''); // remove os parênteses
      return (
        <span style={{marginLeft:"22px", color: color}}> {sign}{absoluteAmount} BRL  </span>
      );
    }


    function capitalizeName(name) {
      if (!name) return ""; // Retorna uma string vazia se o nome não estiver definido
    
      const namesArray = name.split(' '); // Divide o nome em um array de palavras
      const capitalizedNames = namesArray.map((word) => {
        // Transforma a primeira letra de cada palavra em maiúscula e o restante em minúscula
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      });
    
      return capitalizedNames.join(' '); // Junta as palavras do array de volta em uma string
    }
    





    function formatAmountNew(amount) {
      if (amount.startsWith('(') && amount.endsWith(')')) {
        return '-' + amount.replace(/[(),]/g, ''); // Remove parênteses e vírgulas
      }
      return amount.replace(/[(),]/g, '');
    }
    


    function processDataForExcel(data, startDate, endDate) {
      const filteredData = data.filter(item => item?.status === 'Completo');
    console.log("filteredData: ", filteredData)
      // Ordena os dados pela data
      const sortedData = filteredData.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    
      // Inicializa um objeto para manter os totais acumulados
      const runningTotals = {};
    
      // Processa cada item
      let result = [];
      sortedData.forEach(item => {
        const valorLiquido = parseFloat(formatAmountNew(item?.amount)) || 0;
        const coin = item?.coin === 'BRLA' ? 'BRL' : item?.coin;
    
        // Atualiza o total acumulado para a moeda
        if (!runningTotals[coin]) {
          runningTotals[coin] = 0;
        }
    
        if (item.type === 'swap') {
          if (!runningTotals['BRL']) {
            runningTotals['BRL'] = 0;
          }          
          const brlLine = {
            ID: item?.id,
            Data: formatDateDDMMYYYY(item?.updatedAt),
            Hora: formatTimeHHMM(item?.updatedAt),
            Transacao: 'Swap',
            Moeda: 'BRL',
            Valor: (item.usdToBrla ? parseFloat(item?.brlaAmount || 0)/100 : parseFloat(item?.brlaAmount || 0)/100 * -1) - (item.usdToBrla ? ((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)) * -1 : 0),
            Taxa: item.usdToBrla ? ((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)) * -1 : 0,
            'Valor Final': item.usdToBrla ? parseFloat(item?.brlaAmount || 0)/100 : parseFloat(item?.brlaAmount || 0)/100 * -1,
            'Saldo': (runningTotals['BRL'] += item.usdToBrla ? parseFloat(item?.brlaAmount || 0)/100 : parseFloat(item?.brlaAmount || 0)/100 * -1),
            'Preco Base': parseFloat(item?.basePrice),
            'Taxa BRL': item.usdToBrla ? ((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)) * -1 : 0,
            Rede: item?.chain,
            'Hash da Transacao':item?.tx,
            updatedAt: item.updatedAt, 
            ReferenceLabel: item.referenceLabel,
          };
    
          const foreignLine = {
            ID: item?.id,
            Data: formatDateDDMMYYYY(item?.updatedAt),
            Hora: formatTimeHHMM(item?.updatedAt),
            Transacao: 'Swap',
            Moeda: coin,
            Valor: (item.usdToBrla ? parseFloat(item?.usdAmount || 0)/100 * -1 : parseFloat(item?.usdAmount || 0)/100) - (item.usdToBrla ? 0 : ((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)) * -1),
            Taxa: item.usdToBrla ? 0 : ((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)) * -1,
            'Valor Final': item.usdToBrla ? parseFloat(item?.usdAmount || 0)/100 * -1 : parseFloat(item?.usdAmount || 0)/100,
            'Saldo': (runningTotals[coin] += item.usdToBrla ? parseFloat(item?.usdAmount || 0)/100 * -1 : parseFloat(item?.usdAmount || 0)/100),
            'Preco Base': parseFloat(item?.basePrice),
            'Taxa BRL': item.usdToBrla ? 0 : (((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)) * -1)*parseFloat(item?.basePrice),
            Rede: item?.chain,
            'Hash da Transacao':item?.tx,
            updatedAt: item.updatedAt,
            ReferenceLabel: item.referenceLabel,
          };
    
          result.push(brlLine, foreignLine);

          if (item.receiverAddress && item?.walletAddress!==item?.receiverAddress) {
            const transferLine = {
              ID: item?.id,
              Data: formatDateDDMMYYYY(item?.updatedAt),
              Hora: formatTimeHHMM(item?.updatedAt),
              Transacao: '*Transfer on chain',
              Moeda: item.usdToBrla ? 'BRL' : coin,
              Valor: item.usdToBrla ? -parseFloat(item?.brlaAmount || 0)/100 : -parseFloat(item?.usdAmount || 0)/100,
              Taxa: 0,
              'Valor Final': item.usdToBrla ? -parseFloat(item?.brlaAmount || 0)/100 : -parseFloat(item?.usdAmount || 0)/100,
              'Saldo': runningTotals[item.usdToBrla ? 'BRL' : coin] += item.usdToBrla ? -parseFloat(item?.brlaAmount || 0)/100 : -parseFloat(item?.usdAmount || 0)/100,
              'Preco Base': parseFloat(item?.basePrice),
              'Taxa BRL': 0,
              'Endereco da Wallet': item.receiverAddress,
              Rede: item?.chain,
              'Hash da Transacao':item?.tx,
              updatedAt: item.updatedAt,
              ReferenceLabel: item.referenceLabel,
            };
            result.push(transferLine);
          }

        }
        else if  (item.type==='payment' && !item?.transfer_status && item?.smartcontract_status) {
         
          const paymentLine = {
              ID: item?.id,
              Data: formatDateDDMMYYYY(item?.updatedAt),
              Hora: formatTimeHHMM(item?.updatedAt),
              Transacao: 'Payment',
              Moeda:  'BRL',
              Valor: parseFloat(item?.brlaAmount || 0)/100,
              Taxa: 0,
              'Valor Final': parseFloat(item?.brlaAmount || 0)/100,
              'Saldo':  runningTotals['BRL'] += parseFloat(item?.brlaAmount || 0)/100,
              'Taxa BRL': 0,
              Rede: item?.chain,
              'Hash da Transacao':item?.tx,
              updatedAt: item.updatedAt
            };

            const paymentLineCoin = {
              ID: item?.id,
              Data: formatDateDDMMYYYY(item?.updatedAt),
              Hora: formatTimeHHMM(item?.updatedAt),
              Transacao: 'Payment',
              Moeda:  coin,
              Valor: parseFloat(item?.usdAmount || 0)/100*-1,
              Taxa: 0,
              'Valor Final': parseFloat(item?.usdAmount || 0)/100*-1,
              'Saldo': runningTotals[coin] += parseFloat(item?.usdAmount || 0)/100*-1,
              'Taxa BRL': 0,
              Rede: item?.chain,
              'Hash da Transacao':item?.tx,
              updatedAt: item.updatedAt
            };


            result.push(paymentLine, paymentLineCoin);
          }
        else {
          runningTotals[coin] += valorLiquido;
          result.push({
            ID: item?.id,
            Data: formatDateDDMMYYYY(item?.updatedAt),
            Hora: formatTimeHHMM(item?.updatedAt),
            Transacao: item?.type === 'buy' ? 'PIX in' : item?.type === 'sell' ? 'PIX out' : item?.type === 'payment' ? 'Payment' : item?.type === 'onchain-deposit' ? 'Deposit On chain' : item?.type === 'onchain-out' ? 'Transfer on chain' : 'NA',
            Moeda: coin,
            Valor: parseFloat(formatAmountNew(item?.amount)) + ((parseFloat(item?.fee) || 0) + (parseFloat(item?.baseFee) || 0)),
            Taxa: (item?.fee !== null && item?.fee !== undefined) ? item?.fee * -1 : (item?.baseFee || 0) * -1,
            'Valor Final': valorLiquido,
            'Saldo': runningTotals[coin],
            Contraparte: item?.type === 'buy' || item?.type === 'sell' ? capitalizeName(item?.name) : '',
            'CPF/CNPJ da Contraparte': item?.fullTaxId,
            'Preco Base': item?.basePrice,
            'Taxa BRL': (item?.fee !== null && item?.fee !== undefined) ? item?.fee * -1 : !item?.usdToBrla ? (item?.baseFee * item?.basePrice || 0) * -1 : (item?.baseFee || 0) * -1,
            'Endereco da Wallet': item?.type === 'onchain-deposit' ? item?.fromAddress : item?.type === 'onchain-out' ? item?.toAddress : '',
            Rede: item?.chain,
            'Hash da Transacao':item?.tx,
            ReferenceLabel: item.referenceLabel,
            updatedAt: item.updatedAt
          });
        }
      });

      const startDateParts = startDate.split('-'); // Divide a data de início em componentes
      const endDateParts = endDate.split('-'); // Divide a data de fim em componentes
      
      // Cria a data de início em UTC
      const start = new Date(Date.UTC(startDateParts[0], startDateParts[1] - 1, startDateParts[2]));
      
      // Cria a data de fim em UTC e ajusta para o final do dia
      const end = new Date(Date.UTC(endDateParts[0], endDateParts[1] - 1, endDateParts[2], 23, 59, 59));
     if (startDate && endDate) {
      result = result.filter(item => {
        const itemDate = new Date(item.updatedAt);
        return itemDate >= start && itemDate <= end;
      }).map(item => {
  const { updatedAt, ...rest } = item; // Desestruturação para remover `updatedAt`
  return rest; // Retorna o restante do objeto sem `updatedAt`
});
     }
      
    console.log("result: ", result)
      return result;
    }
    
        
    


    const handleExportXLSX = async () => {
setIsLoadingExtrato(true)
      async function fetchAllPagesForPurchases() {
        let page = 1;
        let allResults = [];
        let hasMore = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
        while (hasMore) {
          const resPurchases = await fetch(`${ENDPOINT}/pay-in/pix/history?pageSize=100&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN": MANTEINENCETOKEN
            },
          });
      
          const bodyPurchases = await resPurchases.json();
          const purchases = bodyPurchases.depositsLogs || [];
      
          if (purchases.length > 0) {
            allResults = allResults.concat(purchases);
            page++;

      // Aguarda 5 segundos antes de iniciar o próximo loop
      await delay(5000);


          } else {
            hasMore = false;
          }
        }
      
        return allResults;
      }
      const allPurchases = await fetchAllPagesForPurchases();
      console.log("allPurchases 2: ",allPurchases)
   
      async function fetchAllPagesForSales() {
        let page = 1;
        let allResults = [];
        let hasMore = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
 
        while (hasMore) {
          const resSales = await fetch(`${ENDPOINT}/pay-out/history?pageSize=100&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN": MANTEINENCETOKEN
            },
          });
      
          const bodySales = await resSales.json();
          const sales = bodySales.transfersLogs || [];
      
          if (sales.length > 0) {
            allResults = allResults.concat(sales);
            page++;
            await delay(5000);
          } else {
            hasMore = false;
          }
        }
      
        return allResults;
      }
      
      // E então, ao invés de chamar diretamente a requisição, você usa:
      const allSales = await fetchAllPagesForSales();
      

      async function fetchAllPagesForOnchainDeposit() {
        let page = 1;
        let allResults = [];
        let hasMore = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
        while (hasMore) {
          const resOnchainDeposit = await fetch(`${ENDPOINT}/on-chain/history/in?pageSize=100&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN": MANTEINENCETOKEN
            },
          });
      
          const bodyOnchainDeposit = await resOnchainDeposit.json();
          const onchainDeposits = bodyOnchainDeposit.onchainLogs || [];
      
          if (onchainDeposits.length > 0) {
            allResults = allResults.concat(onchainDeposits);
            page++;
            await delay(5000);
          } else {
            hasMore = false;
          }
        }
      
        return allResults;
      }
      
      // E então, ao invés de chamar diretamente a requisição, você usa:
      const allOnchainDeposits = await fetchAllPagesForOnchainDeposit();

      async function fetchAllPagesForOnchainOut() {
        let page = 1;
        let allResults = [];
        let hasMore = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
 
        while (hasMore) {
          const resOnchainOut = await fetch(`${ENDPOINT}/on-chain/history/out?pageSize=100&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN": MANTEINENCETOKEN
            },
          });
      
          const bodyOnchainOut = await resOnchainOut.json();
          const onchainOuts = bodyOnchainOut.onchainLogs || [];
      
          if (onchainOuts.length > 0) {
            allResults = allResults.concat(onchainOuts);
            page++;
            await delay(5000);
          } else {
            hasMore = false;
          }
        }
      
        return allResults;
      }
      
      // E então, ao invés de chamar diretamente a requisição, você usa:
      const allOnchainOuts = await fetchAllPagesForOnchainOut();
 
      



      async function fetchAllPagesForSwaps() {
        let page = 1;
        let allResults = [];
        let hasMore = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
        while (hasMore) {
          const resSwaps = await fetch(`${ENDPOINT}/swap/history?pageSize=100&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN": MANTEINENCETOKEN
            },
          });
      
          const bodySwaps = await resSwaps.json();
          const swaps = bodySwaps.swapLogs || [];
      
          if (swaps.length > 0) {
            allResults = allResults.concat(swaps);
            page++;
            await delay(5000);
          } else {
            hasMore = false;
          }
        }
      
        return allResults;
      }
      
      // E então, ao invés de chamar diretamente a requisição, você usa:
      const allSwaps = await fetchAllPagesForSwaps();

      async function fetchAllPagesForPayments() {
        let page = 1;
        let allResults = [];
        let hasMore = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
         while (hasMore) {
          const resPayments = await fetch(`${ENDPOINT}/payment/history?pageSize=100&page=${page}`, {
            method: 'GET',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              "MAINTENANCE-TOKEN": MANTEINENCETOKEN
            },
          });
      
          const bodyPayments = await resPayments.json();
          const payments = bodyPayments.paymentLogs || [];
      
          if (payments.length > 0) {
            allResults = allResults.concat(payments);
            page++;
            await delay(5000);
          } else {
            hasMore = false;
          }
        }
      
        return allResults;
      }
      
      // E então, ao invés de chamar diretamente a requisição, você usa:
      const allPayments = await fetchAllPagesForPayments();

  
   
    const formatTaxId = (taxId) => {
      if (taxId && typeof taxId === 'string') {
        taxId = taxId.replace(/\D/g, ''); // Remover caracteres não numéricos, se houver
    
        if (taxId.length === 11) {
          return '***.' + taxId.slice(3, 6) + '.' + taxId.slice(6, 9)  + '-**'; // CPF
        } else if (taxId.length === 14) {
          return '**.' + taxId.slice(3, 6) + '.' + taxId.slice(6, 9) +   '/****-'  ; // CNPJ
        } else if (taxId.length === 6) {
          return '***.' + taxId.slice(0, 3) + '.' + taxId.slice(3, 6) + '-**'; // Seis números
        }
      }
    
      return '';
    };
    
    
    
    const depositsLogsWithType = allPurchases.map(item => ({
      id: item.mintOps?.[0]?.smartContractOps?.[0].tx || 'pending payment',
      chain: item.chain,
      walletAddress: item.walletAddress,
      amount: numberWithThousandSeparator((item.amount-item.mintOps?.[0]?.fee)/100),
      status: mapStatus(item, 'buy'),
      type: 'buy',
      coin:'BRL',
      updatedAt: item.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.[0]?.createdAt || item.mintOps?.[0]?.smartContractOps?.[0]?.createdAt || item.mintOps?.[0]?.createdAt || item.createdAt,
      taxId: formatTaxId(item.taxId),
      fullTaxId:item.taxId,
      failedReason: item.mintOps?.[0]?.smartContractOps?.[0]?.notPostedReason?.[0] || item.mintOps?.[0]?.smartContractOps?.[0]?.feedback?.[0]?.errorMsg?.[0],
      name: item.payerName,
      fee: item.mintOps?.[0]?.fee/100,
      referenceLabel: item.referenceLabel

      
    }));

    console.log("depositsLogsWithType 10:", depositsLogsWithType)
   
    
    const transfersLogsWithTypeAndRenamed = allSales.map(item => ({
      id: item.smartContractOps?.[0]?.tx || 'pending burn',
      chain: item.chain,
      walletAddress: item.walletAddress,
      amount: `(${numberWithThousandSeparator(item.amount/100)})`,
      type: 'sell',
      coin: 'BRL',
      status: mapStatus(item, 'sell'),
      updatedAt: item.transfers?.[0]?.feedbacks?.[0]?.createdAt || item.transfers?.[0]?.createdAt || item.smartContractOps?.[0]?.feedback?.createdAt || item.smartContractOps?.[0]?.createdAt || item.createdAt,
      taxId:  formatTaxId(item.transfers?.[0]?.taxId),
      fullTaxId: item.transfers?.[0]?.taxId,
      failedReason: item.smartContractOps?.[0]?.notPostedReason || item.smartContractOps?.[0]?.feedback?.errorMsg || 'A transação falhou',
      name: item.transfers?.[0]?.name,
      fee:item.fee/100,
      referenceLabel: item.referenceLabel,
    }));

    

    const PaymentsLogsWithTypeAndRenamed = allPayments.map(item => ({
      id: item.smartContractOps?.[0]?.tx || 'pending payment',
      chain: item.chain,
      walletAddress: item.address,
      amount: `(${numberWithThousandSeparator(item.usdAmount/100)})`,
      type: 'payment',
      status: mapStatus(item, 'payment'),
      updatedAt: item.paymentOps?.[0]?.smartContractOps?.[0]?.feedback?.createdAt || item.paymentOps?.[0]?.smartContractOps?.[0]?.createdAt || item.paymentOps?.[0].createdAt || item.createdAt, 
      coin: item.coin,
      brlaAmount: item.brlaAmount,
      usdAmount: item.usdAmount,
      basePrice: item.basePrice,
      baseFee: item.baseFee,
      gassFee: 0,
      failedReason: item.paymentOps?.[0]?.smartContractOps?.[0]?.notPostedReason?.[0] || item.paymentOps?.[0]?.smartContractOps?.[0]?.feedback?.errorMsg,
      name: item.paymentOps?.[0].transfers?.[0].name || 'Pendente',
      taxId:  formatTaxId(item.paymentOps?.[0].transfers?.[0].taxId),
      fullTaxId: item.paymentOps?.[0].transfers?.[0].taxId,
      fee: item.baseFee,
      transfer_status:item?.paymentOps?.[0]?.transfers?.[0]?.feedbacks?.[0]?.transferStatus,
      smartcontract_status: item?.paymentOps?.[0].smartContractOps?.[0]?.feedback?.success || item?.paymentOps?.[0].smartContractOps?.[1]?.feedback?.success,
      tx: item?.paymentOps?.[0]?.smartContractOps?.[0]?.tx
    
    }));


    const OnchainDepositLogsWithTypeAndRenamed = allOnchainDeposits.map(item => ({
      id: item.id,
      chain: item.chain,
      tokenAddress: item.tokenAddress,
      fromAddress: item.fromAddress,
      toAddress: item.toAddress,
      tx: item.tx,
      amount: `${numberWithThousandSeparator(item.amount)}`,
      amountclean: parseFloat(item.amount).toFixed(2),
      type: 'onchain-deposit',
      status: mapStatus(item, 'onchain-deposit'),
      updatedAt: item.createdAt, 
      coin:  item.tokenAddress.toLowerCase().trim() === USDT_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'USDT'  : item.tokenAddress.toLowerCase().trim() ===  BRLA_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'BRLA' :  item.tokenAddress.toLowerCase().trim() ===  USDC_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'USDC' : item.tokenAddress.toLowerCase().trim() ===  USDC_NATIVE_CONTRACT_ADDRESSES[80002].toLowerCase().trim() ? 'USDC' : 'TOKEN',
      fee:0 
    
    }
    )); 
    console.log("OnchainDepositLogsWithTypeAndRenamed 2:", OnchainDepositLogsWithTypeAndRenamed)

    const calculateValue = (coin, value) => {
      if (coin === 'USDT' || coin === 'USDC') {
        return value / 1000000;
      } else if (coin === 'BRLA') {
        return parseFloat(value)/10**18 ;
      } else {
        return value;
      }
    };
    
    
    
    const OnchainOutLogsWithTypeAndRenamed = allOnchainOuts.map(item => {
      const calculatedValue = calculateValue(item.outputCoin, item.value);
    
      return {
          id: item.id,
          userId: item.userId,
          chain: item.chain,
          fromAddress: item.from,
          toAddress: item.to,
          tx: item.smartContractOps?.[0]?.Tx,
          amount: `(${numberWithThousandSeparator(calculatedValue)})`,
          amountclean: parseFloat(calculatedValue).toFixed(2),
          type: 'onchain-out',
          status: mapStatus(item, 'onchain-out'),
          updatedAt: item.createdAt,
          coin: item.inputCoin,
          fee:0  
      };
    });

  
        // Mapear os dados
  const swapsLogsWithTypeAndRenamed = allSwaps.map(item => ({
    id: item.smartContractOps?.[0]?.tx || 'pending swap',
    chain: item.chain,
    walletAddress: item.walletAddress,
    amount: item.usdToBrla ? numberWithThousandSeparator(item.brlaAmount/100) : `(${numberWithThousandSeparator(item.usdAmount/100)})`,
    type: 'swap',
    status: mapStatus(item, 'swap'),
    updatedAt: item.smartContractOps?.[0]?.feedback?.createdAt || item.smartContractOps?.[0]?.createdAt || item.createdAt, 
    coin: item.coin,
    brlaAmount: item.brlaAmount,
    usdAmount: item.usdAmount,
    usdToBrla: item.usdToBrla,
    basePrice: item.basePrice,
    baseFee: item.baseFee,
    gassFee: 0,
    failedReason: item.smartContractOps?.[0]?.notPostedReason?.[0] || item.smartContractOps?.[0]?.feedback?.errorMsg,
    name: `${item.walletAddress.slice(0, 3)}....${item.walletAddress.slice(-3)}`,
    walletAddress: item.walletAddress,
    receiverAddress: item.receiverAddress,
    usdToBrla: item.usdToBrla,
    tx: item.smartContractOps?.[0]?.tx,
    referenceLabel: item.referenceLabel,

  }));

  const swapLogTxValues = allSwaps.map(log => 
    log.smartContractOps && log.smartContractOps.length > 0 ? log.smartContractOps[0].tx : null
  ).filter(tx => tx !== null);

  console.log("swapLogTxValues: ", swapLogTxValues)
  
  const paymentsLogTxValues = allPayments.map(log => 
    log.paymentOps?.[0]?.smartContractOps?.[0]?.tx 
  ).filter(tx => tx !== null);

  const depositLogTxValues = allPurchases.map(log => 
    log.mintOps?.[0]?.smartContractOps?.[0]?.tx 
  ).filter(tx => tx !== null);
  
  
  const filteredOnchainDepositLogs = OnchainDepositLogsWithTypeAndRenamed.filter(item => 
    !swapLogTxValues.includes(item.tx) && !paymentsLogTxValues.includes(item.tx) && !depositLogTxValues.includes(item.tx) 
  );

   console.log("filteredOnchainDepositLogs: 11", filteredOnchainDepositLogs)
  
  
  
 
  
  const filteredOnchainOut = OnchainOutLogsWithTypeAndRenamed.filter(item => 
    !swapLogTxValues.includes(item.tx) && !paymentsLogTxValues.includes(item.tx)
  );
  
  const filteredDepositsLogs = depositsLogsWithType.filter(item => item.chain !== 'XRP');
  console.log("filteredDepositsLogs 12: ", filteredDepositsLogs)
  
        
  const historyDataExtrato = [...filteredDepositsLogs, ...transfersLogsWithTypeAndRenamed, ...swapsLogsWithTypeAndRenamed, ...PaymentsLogsWithTypeAndRenamed, ...filteredOnchainDepositLogs, ...filteredOnchainOut];
    console.log("historyDataExtrato: ", historyDataExtrato)
    
    // After making the requests, store the current date as the last request date
    

      // Define the file type and extension for the Excel file
      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      const fileExtension = '.xlsx';
    
      const processedData = processDataForExcel(historyDataExtrato, startDate, endDate);
      const ws = XLSX.utils.json_to_sheet(processedData);
      const wb = XLSX.utils.book_new(); 
      XLSX.utils.book_append_sheet(wb, ws, 'data'); // Add the worksheet to the Workbook
    
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      let now = new Date();
let timestamp = now.getFullYear().toString() + 
                (now.getMonth() + 1).toString().padStart(2, '0') + 
                now.getDate().toString().padStart(2, '0') + '-' + 
                now.getHours().toString().padStart(2, '0') + 
                now.getMinutes().toString().padStart(2, '0') + 
                now.getSeconds().toString().padStart(2, '0');
           
// Concatenando para formar o nome do arquivo
let fileName = `BRLA-${timestamp}-${users?.fullName}`;
      FileSaver.saveAs(data, fileName + fileExtension);
      setIsLoadingExtrato(false)
     
    }

    function transferReceipt(transferID) {
      const url = `${ENDPOINT}/pay-out/receipt/${transferID}`;
  
      fetch(url, {
          method: 'GET', 
          headers: {
              'Content-Type': 'application/json'
          },
          credentials: 'include' // Inclui cookies para a primeira solicitação
      })
      .then(response => {
          if (!response.ok) {
              throw new Error('Network response was not ok');
          }
          return response.json();
      })
      .then(data => {
          const pdfUrl = data.url;
  
          // Cria um link para o PDF e aciona o download
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = "downloaded_receipt.pdf"; // Nome que você deseja dar ao arquivo baixado
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      })
      .catch(error => {
          console.error('There has been a problem with your fetch operation:', error);
      });
  }
  
  
  

    

return (

 
  
<div  id="__next">

{showTransactionDetails && <div ref={TransactionDetailsRef} className="overlay"></div>}
{showTransactionDetailsBRL && <div ref={TransactionDetailsRefBRL} className="overlay"></div>}
{showTransactionDetailsBRLUSD && <div ref={TransactionDetailsRefBRLUSD} className="overlay"></div>}
{showTransactionDetailsUSDBRL && <div ref={TransactionDetailsRefUSDBRL} className="overlay"></div>}
{showTransactionDetailsDepositOnchain && <div ref={TransactionDetailsRefDepositOnchain} className="overlay"></div>}
{showTransactionDetailsOnchainOut && <div ref={TransactionDetailsRefOnchainOut} className="overlay"></div>}
              <section data-testid="payments-activity ">
                <div className="cb-card surface_primary shadow_md main-container-6" >
                  <div className="content p-6">
                    <div className="mt-2.5 mb-3.5 flex items-center justify-between">
                      <h3 className="ml-4 text-2xl text-#08383f-700 font-circular-book">
                      {t('recent_activity')}
                      </h3>
                      {showLink && (
  <a href="/activity">
    {t('view_all')}
    <svg width="16" height="16" viewBox="0 0 25 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg" class="cb-icon cb-icon-arrownarrowright ml-2 inline pointer-events-none" aria-hidden="true" role="img" focusable="false" data-testid="icon-arrownarrowright">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M16.054 1.204a1.55 1.55 0 0 1 2.192 0l6.2 6.2a1.55 1.55 0 0 1 0 2.192l-6.2 6.2a1.55 1.55 0 0 1-2.192-2.192l3.554-3.554H1.65a1.55 1.55 0 0 1 0-3.1h17.958l-3.554-3.554a1.55 1.55 0 0 1 0-2.192Z"></path>
    </svg>
  </a>
)}
<>
  {showButton && (
    <button className="cb-button sm primary" type="button" onClick={handleExportXLSX} disabled={isLoadingExtrato}>
      <span>
        <span>{isLoadingExtrato ? 'Carregando...' : t('export_all')}</span>
        <span className="ml-1 normal-case">{isLoadingExtrato ? "" : "(.xlsx)"}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={16}
          height={16}
          viewBox="0 0 20 20"
          fill="currentColor"
          className="cb-icon cb-icon-download ml-2 pointer-events-none"
          aria-hidden="true"
          data-testid="icon-download"
          focusable="false"
          role="img"
        >
          <path
            fillRule="evenodd"
            d="M3 17a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zm3.293-7.707a1 1 0 0 1 1.414 0L9 10.586V3a1 1 0 1 1 2 0v7.586l1.293-1.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 0-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    </button>
  )}

  
</>



                    </div>
{showButton && (
    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop:"20px" }}>
    <div style={{display:"flex", flexDirection:"column"}}>
    <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
      <label style={{marginRight:"10px"}} htmlFor="startDate">Data Inicial:</label>
      <input
        type="date"
        id="startDate"
        name="startDate"
        value={startDate}
        onChange={handleStartDateChange}
      />
</div>

 <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}>
      <label style={{marginRight:"10px"}} htmlFor="endDate">Data Final:</label>
      <input
      style={{marginLeft:"10px"}}
        type="date"
        id="endDate"
        name="endDate"
        value={endDate}
        onChange={handleEndDateChange}
      />
      </div>
      </div>
    </div>
  )}                    
                    <div
                      className="overflow-x-auto"
                      data-testid="table-card-body"
                      style={{height:'100%'}}
                    >
                      <div>
                      {showTransactionDetails && <TransactionDetails ref={TransactionDetailsRef} transaction={selectedTransaction} />}
                      {showTransactionDetailsBRL && <TransactionDetailsBRL ref={TransactionDetailsRefBRL} transaction={selectedTransactionBRL} />}
                      {showTransactionDetailsBRLUSD && <TransactionDetailsBRLUSD ref={TransactionDetailsRefBRLUSD} transaction={selectedTransactionBRLUSD} />}
                      {showTransactionDetailsUSDBRL && <TransactionDetailsUSDBRL ref={TransactionDetailsRefUSDBRL} transaction={selectedTransactionUSDBRL} />}
                      {showTransactionDetailsDepositOnchain && <TransactionDetailsDepositOnchain ref={TransactionDetailsRefDepositOnchain} transaction={selectedTransactionDepositOnchain} />}
                      {showTransactionDetailsOnchainOut && <TransactionDetailsOnchainOut ref={TransactionDetailsRefOnchainOut} transaction={selectedTransactionOnchainOut} />}    
                      <table style={{border:'none'}} className="advanced-table border-collapse table-fixed w-full">
                      

                                <thead data-testid="header">
                                  <tr>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '150px', background:'white', border:'none', textAlign: 'left', color:'#08383f', fontWeight:900 }} 
                                    >
                                      {t('identification')}
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'white', border:'none', textAlign: 'left', color:'#08383f', fontWeight:900 }}
                                    >
                                      {t('date')}
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '110px', background:'white', border:'none', textAlign: 'left', color:'#08383f', fontWeight:900 }}
                                    >
                                      Status
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '180px', background:'white', border:'none', textAlign: 'left', color:'#08383f', fontWeight:900 }}
                                    >
                                      {t('value')}
                                    </th>
                                    <th
                                      className=""
                                      colSpan={1}
                                      style={{ width: '210px', background:'white', border:'none', textAlign: 'left', color:'#08383f', fontWeight:900 }}
                                    >
                                      {t('description')}
                                    </th>
                              
                                 
                                  </tr>
                                </thead>
                                <tbody>
                                {historyData.slice(0, limit || historyData.length).map((item, index) => (
      
      <tr key={index}>
<td className="text-sm leading-5 font-circular-book" style={{  border:'none', textAlign: 'left' }}>
  <div style={{marginLeft:'22px'}}>
  <div style={{ color: '#08383f', fontWeight: 900 }}>
  {item?.type==='onchain-deposit' ? item.fromAddress.slice(0, 5) + '...' + item.fromAddress.slice(-4)  :
  item?.type==='onchain-out' ? item.toAddress.slice(0, 5) + '...' + item.toAddress.slice(-4):
  item?.receiverAddress===item?.walletAddress ? "Troca de moeda" :
  item?.type === 'swap' ? (
    item?.name
  ) : (
    <>
      {capitalizeName(item?.name?.split(' ')[0])} {capitalizeName(item?.name?.split(' ').pop())}
    </>
  )}
</div>

    
  </div>
</td>




<td className="text-sm leading-5 font-circular-book" style={{  textAlign: 'left', border:'none' }}>
  <div style={{color: '#08383f', fontWeight:900, marginLeft:'22px'}}>{formatDate(item?.updatedAt)}</div>
  
</td>

<td className={` ${classes.textBlock21}`} style={{textAlign: 'left', border:'none', color: getStatusColor(item?.status) }}>
  <span style={{marginLeft:"22px"}}> {item?.status} </span>
</td>
<td className="text-sm leading-5 font-circular-book"  style={{   border:'none' }}>
  {item?.type === 'swap' || item?.type==='payment' ? (
    <div style={{display:"flex", marginLeft:"25px"}}>
      <div className={classes._3528BRL475USDTBRL}>
        { item?.receiverAddress===item?.walletAddress && !item?.usdToBrla ?  (
          <>
        <div className={classes.textBlock21}>+{(item?.usdAmount/100).toFixed(2)} USD</div>
        <div style={{color:"red"}} className={classes.textBlock21}>-{(item?.brlaAmount/100).toFixed(2)} BRL</div>
        </>
        ) : item?.receiverAddress===item?.walletAddress && item?.usdToBrla ? (
        <>
        <div  className={classes.textBlock21}>+{(item?.brlaAmount/100).toFixed(2)} BRL</div>
        <div style={{color:"red"}} className={classes.textBlock21}>-{(item?.usdAmount/100).toFixed(2)} USD</div>
        
        </>
        )

        : item?.receiverAddress!==item?.walletAddress && !item?.usdToBrla ? (
          <>
          <div style={{color:'red'}}  className={classes.textBlock21}>-{(item?.brlaAmount/100).toFixed(2)} BRL</div>
          
          
          </>)
        
      : item?.type=='payment' ? (<div style={{color:"red"}} className={classes.textBlock21}>-{(item?.usdAmount/100).toFixed(2)} USD</div>)
        : (<div className={classes.textBlock21}>+{(item?.amount)} BRL</div>)
      }
        <div className={classes.textBlock22}>
          <p className={classes.labelWrapper11}>
            <span className={classes.label11}>{(parseFloat(item?.brlaAmount)/parseFloat(item?.usdAmount)).toFixed(2)} USD/BRL</span>
          </p>
        </div>
      </div>
      <div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
      { item?.receiverAddress===item?.walletAddress && !item?.usdToBrla ?  (
        <button
          ref={buttonRefTransactionDetailsBRLUSD}
          onClick={(event) => {
            event.stopPropagation();
            setShowTransactionDetailsBRLUSD(!showTransactionDetailsBRLUSD);
            setSelectedTransactionBRLUSD(item);
          }}
          className='button-table'
        >
          <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
            <path
              d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
              fill='#008884'
            />
          </svg>
        </button>) :  item?.receiverAddress===item?.walletAddress && item?.usdToBrla  ? ( <button
          ref={buttonRefTransactionDetailsUSDBRL}
          onClick={(event) => {
            event.stopPropagation();
            setShowTransactionDetailsUSDBRL(!showTransactionDetailsUSDBRL);
            setSelectedTransactionUSDBRL(item); 
          }}
          className='button-table'
        >
          <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
            <path
              d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
              fill='#008884'
            />
          </svg>
        </button>)
        :  item?.receiverAddress!==item?.walletAddress && !item?.usdToBrla  ? ( <button
          ref={buttonRefTransactionDetailsBRLUSD}
          onClick={(event) => {
            event.stopPropagation();
            setShowTransactionDetailsBRLUSD(!showTransactionDetailsBRLUSD);
            setSelectedTransactionBRLUSD(item); 
          }}
          className='button-table'
        >
          <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
            <path
              d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
              fill='#008884'
            />
          </svg>
        </button>)
        :  item?.type==='swap' ?  ( <button
          ref={buttonRefTransactionDetails}
          onClick={(event) => {
            event.stopPropagation();
            setShowTransactionDetails(!showTransactionDetails);
            setSelectedTransaction(item); 
          }}
          className='button-table'
        >
          <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
            <path
              d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
              fill='#008884'
            />
          </svg>
        </button>) :
      ( <button
        ref={buttonRefTransactionDetailsUSDBRL}
        onClick={(event) => {
          event.stopPropagation();
          setShowTransactionDetailsUSDBRL(!showTransactionDetailsUSDBRL);
          setSelectedTransactionUSDBRL(item); 
        }}
        className='button-table'
      >
        <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
          <path
            d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
            fill='#008884'
          />
        </svg>
      </button>)  
      }
      </div>
    </div>
  ) : item?.type==='payment' ?  (
    <div className={` ${classes.textBlock21}`} style={{display:'flex', textAlign: 'left', border:'none', marginLeft:'3px'}}>
      {formatAmount(item.amount)}
      <div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
        <button
          ref={buttonRefTransactionDetailsUSDBRL}
          onClick={(event) => {
            event.stopPropagation();
            setShowTransactionDetailsUSDBRL(!showTransactionDetailsUSDBRL);
            setSelectedTransactionUSDBRL(item);  // Atualize o estado aqui
            console.log(showTransactionDetailsUSDBRL);
          }}
          className='button-table'
        >
          <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
            <path
              d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
              fill='#008884'
            />
          </svg>
        </button>
      </div>
    </div>

    
  ) : item?.type==='onchain-deposit' && item?.coin!=='BRLA' ? (<div className={` ${classes.textBlock21}`} style={{display:'flex', textAlign: 'left', border:'none', marginLeft:'3px'}}>
  <div style={{marginLeft:'20px'}} className={classes.textBlock21}>+{(item?.amountclean)} USD</div> 
  <div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
    <button
      ref={buttonRefTransactionDetailsDepositOnchain}
      onClick={(event) => {
        event.stopPropagation();
        setShowTransactionDetailsDepositOnchain(!showTransactionDetailsDepositOnchain);
        setSelectedTransactionDepositOnchain(item);  // Atualize o estado aqui
        console.log(showTransactionDetailsDepositOnchain);
      }}
      className='button-table'
    >
      <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
        <path
          d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
          fill='#008884'
        />
      </svg>
    </button>
  </div>
</div>)
: item?.type==='onchain-deposit' && item?.coin==='BRLA' ? (<div className={` ${classes.textBlock21}`} style={{display:'flex', textAlign: 'left', border:'none', marginLeft:'3px'}}>
<div style={{marginLeft:'20px'}} className={classes.textBlock21}>+{(item?.amountclean)} BRL</div> 
<div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
  <button
    ref={buttonRefTransactionDetailsBRL}
    onClick={(event) => {
      event.stopPropagation();
      setShowTransactionDetailsBRL(!showTransactionDetailsBRL);
      setSelectedTransactionBRL(item);  // Atualize o estado aqui
      console.log(showTransactionDetailsBRL);
    }}
    className='button-table'
  >
    <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
      <path
        d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
        fill='#008884'
      />
    </svg>
  </button>
</div>
</div>)
: item?.type==='onchain-out' && item?.coin!=='BRLA' ? (
  <div className={` ${classes.textBlock21}`} style={{display:'flex', textAlign: 'left', border:'none', marginLeft:'3px'}}>
  <div style={{marginLeft:'23px', color:'red'}} className={classes.textBlock21}>-{(item?.amountclean)} USD</div> 
  <div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
    <button
      ref={buttonRefTransactionDetailsOnchainOut}
      onClick={(event) => {
        event.stopPropagation();
        setShowTransactionDetailsOnchainOut(!showTransactionDetailsOnchainOut);
        setSelectedTransactionOnchainOut(item);  // Atualize o estado aqui
        console.log(showTransactionDetailsOnchainOut);
      }}
      className='button-table'
    >
      <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
        <path
          d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
          fill='#008884'
        />
      </svg>
    </button>
  </div>
</div>
)
: item?.type==='onchain-out' && item?.coin==='BRLA' ? (
  <div className={` ${classes.textBlock21}`} style={{display:'flex', textAlign: 'left', border:'none', marginLeft:'3px'}}>
  <div style={{marginLeft:'23px', color:'red'}} className={classes.textBlock21}>-{(item?.amountclean)} BRL</div> 
  <div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
    <button
      ref={buttonRefTransactionDetailsBRL}
      onClick={(event) => {
        event.stopPropagation();
        setShowTransactionDetailsBRL(!showTransactionDetailsBRL);
        setSelectedTransactionBRL(item);  // Atualize o estado aqui
        console.log(showTransactionDetailsBRL);
      }}
      className='button-table'
    >
      <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
        <path
          d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
          fill='#008884'
        />
      </svg>
    </button>
  </div>
</div>
)
: (
    <div className={` ${classes.textBlock21}`} style={{display:'flex', textAlign: 'left', border:'none', marginLeft:'3px'}}>
      {formatAmount(item.amount)}
      <div style={{marginTop:'15px', marginLeft:'5px'}} className={classes.mdiRecurringPayment}>
        <button
          ref={buttonRefTransactionDetailsBRL}
          onClick={(event) => {
            event.stopPropagation();
            setShowTransactionDetailsBRL(!showTransactionDetailsBRL);
            setSelectedTransactionBRL(item);  // Atualize o estado aqui
            console.log(showTransactionDetailsBRL);
          }}
          className='button-table'
        >
          <svg className={classes.icon2} preserveAspectRatio='none' viewBox='0 0 30 31' fill='none' xmlns='http://www.w3.org/2000/svg' >
            <path
              d='M3.75 8V23H16.65C16.3844 22.1933 16.2494 21.3493 16.25 20.5H8.75C8.75 19.837 8.48661 19.2011 8.01777 18.7322C7.54893 18.2634 6.91304 18 6.25 18V13C7.6375 13 8.75 11.8875 8.75 10.5H21.25C21.25 11.163 21.5134 11.7989 21.9822 12.2678C22.4511 12.7366 23.087 13 23.75 13V13.075C24.5875 13.075 25.425 13.225 26.25 13.5V8H3.75ZM15 11.75C12.875 11.7875 11.25 13.375 11.25 15.5C11.25 17.625 12.875 19.175 15 19.25C15.475 19.25 15.9625 19.15 16.425 18.9625C16.7625 17.5875 17.325 16.2875 18.7125 15.0125C18.5625 13.35 16.9875 11.7125 15 11.75ZM23.75 14.25L26.5625 17.0625L23.75 19.875V18C21.4375 18 19.925 20.45 20.95 22.525L19.5875 23.8875C17.3875 20.5625 19.7625 16.125 23.75 16.125V14.25ZM23.75 28L20.9375 25.1875L23.75 22.375V24.25C26.0625 24.25 27.575 21.8 26.55 19.725L27.9125 18.3625C30.1125 21.6875 27.7375 26.125 23.75 26.125V28Z'
              fill='#008884'
            />
          </svg>

          
   
     </button>

      </div>
     
    </div>

    
  )}
</td>


        <td className="text-sm leading-5 font-circular-book"  style={{ width: '200px', textAlign: 'left', border:'none' }}>
        <span style={{color:'#08383f', fontWeight:900, marginLeft:"25px"}}>
        {
  item?.status === 'Falha'
    ? t('failed_transaction')
    : item?.type==='onchain-deposit' ? item?.coin + t('received_from_wallet') + item?.fromAddress?.slice(0, 5) + '...' + item?.fromAddress?.slice(-4)
    : item?.type==='onchain-out' ? item?.coin + t('transferred_to_wallet') + item?.toAddress?.slice(0, 5) + '...' + item?.toAddress?.slice(-4)
    : item?.receiverAddress===item?.walletAddress && !item?.usdToBrla ? t('convert_brl_to_usd')
    : item?.receiverAddress===item?.walletAddress && item?.usdToBrla ? t('convert_usd_to_brl')
    : item?.receiverAddress!==item?.walletAddress && !item?.usdToBrla && item?.type==='swap' ? (item?.coin + t('transferred_to_wallet') + item?.receiverAddress?.slice(0, 5)+ '...' + item?.receiverAddress?.slice(-4))
    : item.amount.includes('(')
    ? (item?.taxId?.length === 14
        ? t('value_paid_to_cpf') + item?.taxId
        : item?.taxId?.length === 17 ? t('value_paid_to_cnpj') + item?.taxId
        : t('carregando')
      )
    : item.type === 'swap'
    ? item.coin + t('received_from_wallet') + item?.name
    : (item?.taxId?.length === 14 || item?.taxId?.length === 11
        ? t('pix_received_from_cpf')  + item?.taxId
        : item?.taxId?.length === 17 || item?.taxId?.length === 16 ? t('pix_received_from_cnpj')  + item?.taxId
        : t('carregando')
      )
}


</span>
        </td>


  
      </tr>
    ))}
  </tbody>
                              </table>
                  
       <div className="p-18">
                          <div
                            className="grid h-full h-full w-full text-center font-circular content-center items-center justify-center"
                            data-testid="lottie-notice-small"
                          >
                            <div
                              className="grid h-full mb-6 content-center items-center justify-center"
                              data-testid="center-both"
                            >
                              <div
                                className="cb-lottie-icon cb-lottie-icon-documents"
                                data-testid="lottie-icon-documents"
                                style={{ width: 64, height: 64 }}
                              >
                            {  historyData.length == 10 && (     <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 468 468"
                                  width={468}
                                  height={468}
                                  preserveAspectRatio="xMidYMid meet"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    transform: "translate3d(0px, 0px, 0px)",
                                    contentVisibility: "visible"
                                  }}
                                >
                                  <defs>
                                    <clipPath id="__lottie_element_2">
                                      <rect
                                        width={468}
                                        height={468}
                                        x={0}
                                        y={0}
                                      />
                                    </clipPath>
                                    <clipPath id="__lottie_element_5">
                                      <path
                                        fill="#ffffff"
                                        clipRule="nonzero"
                                        d=" M17.5,215.5 C144.52499389648438,215.5 247.5,112.5260009765625 247.5,-14.5 C247.5,-141.52499389648438 144.52499389648438,-244.5 17.5,-244.5 C-109.5260009765625,-244.5 -212.5,-141.52499389648438 -212.5,-14.5 C-212.5,112.5260009765625 -109.5260009765625,215.5 17.5,215.5 C17.5,215.5 17.5,215.5 17.5,215.5"
                                        fillOpacity={1}
                                      />
                                    </clipPath>
                                    <clipPath id="__lottie_element_13">
                                      <path
                                        fill="#ffffff"
                                        clipRule="nonzero"
                                        d=" M-12.5,245.5 C114.5250015258789,245.5 217.5,142.5260009765625 217.5,15.5 C217.5,-111.5250015258789 114.5250015258789,-214.5 -12.5,-214.5 C-139.5260009765625,-214.5 -242.5,-111.5250015258789 -242.5,15.5 C-242.5,142.5260009765625 -139.5260009765625,245.5 -12.5,245.5 C-12.5,245.5 -12.5,245.5 -12.5,245.5"
                                        fillOpacity={1}
                                      />
                                    </clipPath>
                                  </defs>
                                  <g clipPath="url(#__lottie_element_2)">
                                    <g
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,234,234)"
                                      opacity="0.9999995405502644"
                                    >
                                      <g
                                        opacity={1}
                                        transform="matrix(1,0,0,1,0,0)"
                                      >
                                        <path
                                          fill="#08383F"
                                          fillOpacity={1}
                                          d=" M0,230 C127.0250015258789,230 230,127.0260009765625 230,0 C230,-127.0250015258789 127.0250015258789,-230 0,-230 C-127.0260009765625,-230 -230,-127.0250015258789 -230,0 C-230,127.0260009765625 -127.0260009765625,230 0,230 C0,230 0,230 0,230z"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      clipPath="url(#__lottie_element_13)"
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,247.2899932861328,218.48899841308594)"
                                      opacity={1}
                                    >
                                      <g
                                        opacity={1}
                                        transform="matrix(1,0,0,1,0,0.008486258797347546)"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          fillOpacity={0}
                                          stroke="#00dc84"
                                          strokeOpacity={1}
                                          strokeWidth="19.167"
                                          d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      clipPath="url(#__lottie_element_5)"
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,217.2899932861328,248.48899841308594)"
                                      opacity={1}
                                    >
                                      <g
                                        opacity={1}
                                        transform="matrix(1,0,0,1,0,0)"
                                      >
                                        <path
                                          fill="#08383F"
                                          fillOpacity={1}
                                          d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                        />
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          fillOpacity={0}
                                          stroke="#00dc84"
                                          strokeOpacity={1}
                                          strokeWidth="19.167"
                                          d=" M-55.641998291015625,89.61399841308594 C-55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 55.641998291015625,89.61399841308594 C63.26100158691406,89.61399841308594 69.44499969482422,83.43800354003906 69.44499969482422,75.81199645996094 C69.44499969482422,75.81199645996094 69.44499969482422,-75.81199645996094 69.44499969482422,-75.81199645996094 C69.44499969482422,-83.43800354003906 63.26100158691406,-89.61399841308594 55.641998291015625,-89.61399841308594 C55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 -55.641998291015625,-89.61399841308594 C-63.26100158691406,-89.61399841308594 -69.44499969482422,-83.43800354003906 -69.44499969482422,-75.81199645996094 C-69.44499969482422,-75.81199645996094 -69.44499969482422,75.81199645996094 -69.44499969482422,75.81199645996094 C-69.44499969482422,83.43800354003906 -63.26100158691406,89.61399841308594 -55.641998291015625,89.61399841308594z"
                                        />
                                      </g>
                                    </g>
                                    <g
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,203.48699951171875,241.51499938964844)"
                                      opacity={1}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fillOpacity={0}
                                        stroke="#00dc84"
                                        strokeOpacity={1}
                                        strokeWidth="19.167"
                                        d=" M-25.996999740600586,0 C-25.996999740600586,0 25.996999740600586,0 25.996999740600586,0"
                                      />
                                    </g>
                                    <g
                                      style={{ display: "block" }}
                                      transform="matrix(1,0,0,1,217.2899932861328,207.58099365234375)"
                                      opacity={1}
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        fillOpacity={0}
                                        stroke="#00dc84"
                                        strokeOpacity={1}
                                        strokeWidth="19.167"
                                        d=" M-39.79999923706055,0 C-39.79999923706055,0 39.79999923706055,0 39.79999923706055,0"
                                      />
                                    </g>
                                  </g>
                                </svg>)}
                              </div>
                            </div>
                            {  historyData.length == 0 && (  <p
                              className="font-circular-regular leading-6 text-#08383f-600 text-base"
                              data-testid="subtitle"
                            >
                              {t('no_transactions')}
                            </p> )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
         
)}
export default Transactions;
