import React, { useEffect } from 'react';
import { ENDPOINT } from './variaveis';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AppWrapper({ children, navigate }) {
  const { t, i18n} = useTranslation();
  function numberWithThousandSeparator(number) {
    return number.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  function mapStatus(status) {
    if (status === "PAID") return "paid";
    if (status === "UNPAID") return "unpaid";
    return status;
  }

  useEffect(() => {
    const updateData = async () => {
      // Verifica a data da próxima requisição
      const nextRequestDate = localStorage.getItem('nextRequestDate');
      const currentDate = new Date();
     

      // Se a data atual é maior ou igual à data da próxima requisição, faz a requisição
      if (!nextRequestDate || currentDate.getTime() >= Date.parse(nextRequestDate)) {
          
        
      }
    };

    updateData();

    const intervalId = setInterval(updateData, 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <>{children}</>;
}

export default AppWrapper;
