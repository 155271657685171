import { forwardRef, useState, useEffect } from 'react';
const ListaChains = forwardRef((props, ref) => {
    
  const { top, left,position, onChainSelect } = props;
    
    
    return(
        <div
        ref={ref}
        data-popper-reference-hidden="false"
        data-popper-escaped="false"
        data-popper-placement="bottom"
        className="np-panel np-panel--open"
        style={{
         
          inset: "0px auto auto 0px",
          zIndex:300000,
          marginTop:top,
          marginLeft:left,
          position:position
         
        }}
      >
        <div className="np-panel__content">
          <ul
            id="tw-calculator-source-select-listbox"
            role="listbox"
            tabIndex={-1}
            className="np-dropdown-menu np-dropdown-menu-desktop np-dropdown-menu-lg p-a-1"
          >
            
            <li className="np-dropdown-header np-text-title-group">
              Todas as redes
            </li>
            <li
       onClick={(e) => {
        e.stopPropagation();
        props.onChainSelect({
          flag: 'currency-flag-polygon',
          abbreviation: 'Polygon'
        });
      }}
              id="option-tw-calculator-source-select-BRL"
              aria-selected="true"
              role="option"
              tabIndex={-1}
              className={`np-dropdown-item clickable ${
                props?.selectedTokenRecebe?.abbreviation === 'Polygon' ? 'active' : ''
              }`}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                    <i className="currency-flag currency-flag-polygon" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      MATIC
                      <span className="np-text-body-default m-l-1">
                        Polygon
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>
          
         
            <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onChainSelect({
                  flag: 'currency-flag-tron',
                  abbreviation: 'Tron'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                  <img style={{width:"35px", height:'35px'}} src='https://brla.digital/static/trx.svg'/>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      TRX
                      <span className="np-text-body-default m-l-1">
                        Tron
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>




          
          
          </ul>
        </div>
      </div>
    )
});
export default ListaChains;