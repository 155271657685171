import SettingsHeader from "../../Components/SettingsHeader";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { ENDPOINT} from '../../variaveis';
import FooterLogged from "../../Components/FooterLogged";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


function Banks() {
  const [banks, setBanks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pendingRequests, setPendingRequests] = useState(1);
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/bankinformation");
  };

  useEffect(() => {
    // Obtem os usuários do localStorage

    // Obtem as carteiras do localStorage
    
    // Obtem os dados do histórico do localStorage
    const loadedAccounts = localStorage.getItem('accounts');
    if (loadedAccounts) {
      setBanks(JSON.parse(loadedAccounts));
    };
    setPendingRequests(0);
  }, []);


  const deleteAccount = async (accountId) => {
    console.log("Deleting account: ", accountId);
  
    const response = await fetch(`${ENDPOINT}/accounts/${accountId}`, {
      method: 'DELETE',
      credentials: 'include',
    });
  
    if(response.ok) {
      toast.success("Account deleted successfully");
      setBanks(banks.filter(bank => bank.id !== accountId));
  
      // Fetch the updated account information after successful deletion
      const resAccounts = await fetch(`${ENDPOINT}/accounts`, {
        method: 'GET',
        credentials: 'include',
      });
  
      // If the response is ok, update the localStorage
      if (resAccounts.ok) {
        const bodyAccounts = await resAccounts.json();
        localStorage.setItem('accounts', JSON.stringify(bodyAccounts.accounts || []));
      } else {
        console.error("Failed to update accounts: ", await resAccounts.text());
      }
    } else {
      toast.error("Failed to delete account");
    }
  };
  
  
  useEffect(() => {
    // Se não há requisições pendentes, então as requisições acabaram
    if (pendingRequests === 0) {
      // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
      setIsLoading(false);
    } 
  }, [pendingRequests]);

  if (isLoading) {
    // Retorne um spinner de loading, um placeholder ou null
    return <div></div>;
  }





    return (
<>
  <div id="__next">
    <div className="grid w-full min-h-screen font-circular grid min-h-screen w-full">
      
      <SettingsHeader/>
            <main className="flex flex-col bg-surface-background">
        <div className="grid flex-1 content-start gap-y-12 py-12 ">
          <div className="px-16 ">
            <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
              <div className="main-container-6">
                <header className="cb-header">
                  <div className="cb-header-head primary">
                    <h1 className="title">Bank accounts</h1>
                    <div className="actions">
                    <button
  className="cb-button base primary"
  type="button"
  data-testid="bank-wires-button"
  onClick={handleButtonClick}
>
  <span>Add Bank</span>
</button>

                    </div>
                  </div>
         
                </header>
              </div>
              <div className="mb-10  main-container-6">
                <div className="">
                <div className="cb-table overflow-x-hidden">
                  <table className="advanced-table border-collapse table-fixed w-full overflow-hidden" style={{ border: 'none' }}>
  <thead data-testid="header">
    <tr>
      <th
        className=""
        colSpan={1}
        style={{ width: '260px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        ACCOUNT NAME
      </th>
     
      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Bank Name
      </th>
      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Bank Code
      </th>

      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Branch Code
      </th>

      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Account Number
      </th>
      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        PIX Key
      </th>
      <th
        className=""
        colSpan={1}
        style={{ width: '150px', textAlign: 'center', border: 'none', background: '#f5f5f8', color: 'grey' }}
      >
        Delete
      </th>
    </tr>
  </thead>
                      <tbody>
    {banks.map((item, index) => (
      <tr key={index}>
                  <td className="mt-4 text-sm font-circular-book">
                            <div className="flex flex-row items-center justify-start">
                              <div className="mr-6">
                                <svg
                                  width={18}
                                  height={18}
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  stroke="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-library pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-library"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    d="M6 12v3m4-3v3m4-3v3M1 19h18M1 8h18M1 5l9-4 9 4M2 8h16v11H2V8Z"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                              <div className="flex flex-col items-start justify-center">
                                <div className="text-sm leading-5 font-circular-book">
                                  {item.accountNickname}
                                </div>
                                <div className="whitespace-nowrap text-sm leading-5 text-black-300 font-circular-regular w-44">
                                  <div
                                    className="truncated-text"
                                    style={{ height: 20 }}
                                  >
                                    <div
                                      aria-hidden="true"
                                      className="absolute w-0 overflow-hidden"
                                      data-testid="font-reference"
                                    >
                                      .
                                    </div>
                                    <div
                                      className="untruncated invisible pointer-events-none"
                                      data-testid="untruncated-text"
                                      style={{ height: 20, marginTop: "-20px" }}
                                    >
                                      
                                    </div>
                                    <div
                                      aria-hidden="true"
                                      className="truncated visible"
                                      data-testid="truncated-text"
                                    >
                                      <div
                                        aria-hidden="true"
                                        className="start"
                                        data-testid="start"
                                        style={{ height: 20 }}
                                      >
                                        {item.id}
                                      </div>
                                      
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
       
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          {item.bankCodeTED}
        </td>
        
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          {item.bankName}
        </td>

        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
        {String(item.branchCode).padStart(4, '0')}
        </td>

        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          {/*item.accountNumber.replace(/^0+(?=\d)/, '0')*/}
          {item.accountNumber.replace(/^0+/, '')}
        </td>
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          {item.pixKey}
        </td>
        <td className="text-sm leading-5 font-circular-book" style={{ width: '150px', textAlign: 'center', border:'none' }}>
          <a style={{color:'#00dc84'}} href="#" onClick={(event) => { event.preventDefault(); deleteAccount(item.id); }}>X</a>
        </td>
      </tr>
    ))}
  </tbody>
                    </table>
                  </div>
                  {
  banks.length == 0 && ( <div className="cb-card surface_primary shadow_sm">
                    <div className="content text-center text-sm leading-5 text-black-500 font-circular-regular">
                      No bank accounts have been linked yet
                    </div>
                  </div> )}
                </div>
              </div>
            </div>
          </div>
        </div>
       <FooterLogged/>
      </main>
    </div>
  </div>
  <ToastContainer />
</>
    )};
export default Banks;