import React, { useState, useEffect } from 'react';

function Header() {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    if (activeAccordion === accordionId) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionId);
    }
  };
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setIsFixed(currentScrollPosition > 100); // Altere '100' para o valor que desejar
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header
      className="header dark-mode"
      style={{
        background:
          'linear-gradient(90deg, rgba(10,27,35,1) 0%, rgba(8,56,63,1) 35%, rgba(0,136,132,1) 100%)',
      }}
    >
      <nav className={`container-fluid desktop-header-container mobile-menu-container`}>
        <div className="brla-logo">
          <a href="https://www.brla.digital/en/" aria-label="Homepage"></a>
        </div>
        <button onClick={toggleMenu} className="menu-button d-lg-none">
          ☰
        </button>
        <ul
          className={`main-navigation d-none d-lg-flex ${
            menuOpen ? 'd-flex' : 'd-none'
          }`}
        >
          {/* Adicione aqui os itens de menu */}
          <li className="menu-list">
            <a className="menu-link" href="/">
              Home
            </a>
            </li>
            <li className="menu-list">
            <a className="menu-link" href="/about">
              About
            </a>
            </li>
            <li className="menu-list">
            <a className="menu-link" href="https://api-documentation.brla.digital" target="_blank">
              API Documentation
            </a>
            </li>
          

           
          {/* ... */}
        </ul>
      </nav>
    </header>
  );
}
export default Header;
