import { forwardRef, useState, useEffect } from 'react';
const ListaTransfer = forwardRef((props, ref) => {
  const { top, left,position, onListSelect } = props;
    
    return(
        <div
        ref={ref}
        data-popper-reference-hidden="false"
        data-popper-escaped="false"
        data-popper-placement="bottom"
        className="np-panel np-panel--open"
        style={{
          position: "absolute",
          inset: "0px auto auto 0px",
          zIndex:1000,
          top:top,
          left:left
        }}
      >
        <div className="np-panel__content">
          <ul
            id="tw-calculator-source-select-listbox"
            role="listbox"
            tabIndex={-1}
            className="np-dropdown-menu np-dropdown-menu-desktop np-dropdown-menu-lg p-a-1"
          >
      
            <li className="np-dropdown-header np-text-title-group">
            Todas as transferências
            </li>
            <li
       onClick={(e) => {
        e.stopPropagation();
        props.onListSelect({
          flag: '',
          abbreviation: 'pix'
        });
      }}
              id="option-tw-calculator-source-select-BRL"
              aria-selected="true"
              role="option"
              tabIndex={-1}
              className={`np-dropdown-item clickable ${
                props?.selectedToken?.abbreviation === 'BRL' ? 'active' : ''
              }`}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                    <i className="currency-flag currency-flag-pix" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      Chave PIX
                    </span>
                  </div>
                </div>
              </a>
            </li>
          
         
            <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onListSelect({
                  flag: '',
                  abbreviation: 'conta'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                  <svg
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
  version="1.1"
  width={25}
  height={25}
  viewBox="0 0 256 256"
  xmlSpace="preserve"
  style={{marginRight:'10px'}}
>
  <defs></defs>
  <g
    style={{
      stroke: "none",
      strokeWidth: 0,
      strokeDasharray: "none",
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeMiterlimit: 10,
      fill: "none",
      fillRule: "nonzero",
      opacity: 1
    }}
    transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)"
  >
    <path
      d="M 84.668 38.004 v -6.27 H 90 V 20 L 45 3.034 L 0 20 v 11.734 h 5.332 v 6.27 h 4.818 v 30.892 H 5.332 v 6.271 H 0 v 11.8 h 90 v -11.8 h -5.332 v -6.271 H 79.85 V 38.004 H 84.668 z M 81.668 35.004 H 66.332 v -3.27 h 15.336 V 35.004 z M 63.332 68.896 v 6.271 h -7.664 v -6.271 H 50.85 V 38.004 h 4.818 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 H 63.332 z M 26.668 38.004 v -6.27 h 7.664 v 6.27 h 4.818 v 30.892 h -4.818 v 6.271 h -7.664 v -6.271 H 21.85 V 38.004 H 26.668 z M 42.15 68.896 V 38.004 h 5.7 v 30.892 H 42.15 z M 37.332 35.004 v -3.27 h 15.336 v 3.27 H 37.332 z M 37.332 71.896 h 15.336 v 3.271 H 37.332 V 71.896 z M 3 22.075 L 45 6.24 l 42 15.835 v 6.659 H 3 V 22.075 z M 8.332 31.734 h 15.336 v 3.27 H 8.332 V 31.734 z M 13.15 38.004 h 5.7 v 30.892 h -5.7 V 38.004 z M 8.332 71.896 h 15.336 v 3.271 H 8.332 V 71.896 z M 87 83.966 H 3 v -5.8 h 84 V 83.966 z M 81.668 75.166 H 66.332 v -3.271 h 15.336 V 75.166 z M 76.85 68.896 H 71.15 V 38.004 h 5.699 V 68.896 z"
      style={{
        stroke: "none",
        strokeWidth: 1,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "rgb(0,0,0)",
        fillRule: "nonzero",
        opacity: 1
      }}
      transform=" matrix(1 0 0 1 0 0) "
      strokeLinecap="round"
    />
  </g>
</svg>

                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      Agência e conta
                    </span>
                  </div>
                </div>
              </a>



            </li>


             
          
          </ul>
        </div>
      </div>
    )
});
export default ListaTransfer;