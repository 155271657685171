import { forwardRef, useState, useEffect} from 'react';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES, USDC_CONTRACT_ADDRESSES, USDT_CONTRACT_ADDRESSES, CRYPTO_FRIENDLY} from '../../variaveis';  
const ListaCoins = forwardRef((props, ref) => {
  const [users, setUsers] = useState([]);
  const { top, left, onTokenSelect } = props;
    const handleTokenSelection = (token) => {
        props.onTokenSelect(token);
      };

      useEffect(() => {    

        const loadedUsers = localStorage.getItem('userData');
    if (loadedUsers) {
      setUsers(JSON.parse(loadedUsers));
    }
  }, []);

    
    return(
        <div
        ref={ref}
        data-popper-reference-hidden="false"
        data-popper-escaped="false"
        data-popper-placement="bottom"
        className="np-panel np-panel--open"
        style={{
          position: "absolute",
          inset: "0px auto auto 0px",
          zIndex:200000,
          top:top,
          left:left
        }}
      >
        <div className="np-panel__content">
          <ul
            id="tw-calculator-source-select-listbox"
            role="listbox"
            tabIndex={-1}
            className="np-dropdown-menu np-dropdown-menu-desktop np-dropdown-menu-lg p-a-1"
          >
      
            <li className="np-dropdown-header np-text-title-group">
            Todas as moedas
            </li>
            <li
       onClick={(e) => {
        e.stopPropagation();
        props.onTokenSelect({
          flag: 'currency-flag-brl',
          abbreviation: 'BRL'
        });
      }}
              id="option-tw-calculator-source-select-BRL"
              aria-selected="true"
              role="option"
              tabIndex={-1}
              className={`np-dropdown-item clickable ${
                props?.selectedToken?.abbreviation === 'BRL' ? 'active' : ''
              }`}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                    <i className="currency-flag currency-flag-brl" />
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      BRL
                      <span className="np-text-body-default m-l-1">
                        Real brasileiro
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            </li>
          
         
            <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onTokenSelect({
                  flag: 'currency-flag-usdc',
                  abbreviation: 'USD'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                  <img style={{width:"25px", height:'25px', marginRight:"7px"}} src='https://brla.digital/static/media/usdc.png'/>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      USDC
                      <span className="np-text-body-default m-l-1">
                        USDC Bridged
                      </span>
                    </span>
                  </div>
                </div>
              </a>



            </li>
         

         
        {  !users?.autoConvert &&
        <>
        <li
           
              id="option-tw-calculator-source-select-USD"
              aria-selected="false"
              role="option"
              tabIndex={-1}
              className="np-dropdown-item clickable"
              onClick={(e) => {
                e.stopPropagation();
                props.onTokenSelect({
                  flag: 'currency-flag-usdt',
                  abbreviation: 'USDT'
                });
              }}
            >
              <a>
                <div className="d-flex np-option-content">
                  <div className="d-flex flex-column">
                   <img style={{width:"25px", height:'25px', marginRight:"7px"}} src='https://brla.digital/static/media/usdt.png'/>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="np-text-body-large">
                      USDT
                      <span className="np-text-body-default m-l-1">
                        Dólar Tether
                      </span>
                    </span>
                  </div>
                </div>
              </a>




            </li>


        

         </>
}

           
          
          </ul>
        </div>
      </div>
    )
});
export default ListaCoins;