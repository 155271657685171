function ConfirmWise() {
    return(
        <>
  <noscript>
    &lt;iframe title="gtm-iframe"
    src="https://gtm.wise.com/ns.html?id=GTM-M7V2XH" height="0" width="0"
    style="display:none;visibility:hidden"&gt;&lt;/iframe&gt;
  </noscript>
  <div id="__next">
    <div className="np-theme-personal">
      <div className="send-flow send-flow--wide-navigation">
        <div className="tw-flow">
          <nav>
            <div className="np-flow-navigation d-flex align-items-center justify-content-center p-y-3 np-flow-navigation--border-bottom">
              <div className="np-flow-header d-flex flex-wrap align-items-center np-flow-navigation__content p-x-3 np-flow-navigation--sm np-flow-navigation--lg">
                <div className="np-flow-header__left">
                  <a href="https://wise.com/home">
                    <svg
                      className="np-logo-svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width={106}
                      height={24}
                      fill="none"
                    >
                      <path
                        fill="#163300"
                        d="M58.738.359h6.498l-3.27 23.322h-6.498L58.739.359Zm-8.193 0L46.16 13.794 44.247.359h-4.545L33.96 13.754 33.243.36h-6.299l2.193 23.322h5.223l6.458-14.75 2.272 14.75h5.143L56.725.359h-6.18Zm54.558 13.555H89.674c.08 3.03 1.894 5.023 4.565 5.023 2.014 0 3.608-1.077 4.844-3.13l5.208 2.368C102.501 21.702 98.729 24 94.08 24c-6.339 0-10.545-4.266-10.545-11.123C83.535 5.342 88.478 0 95.455 0c6.14 0 10.007 4.146 10.007 10.605 0 1.076-.12 2.152-.359 3.309Zm-5.78-4.466c0-2.71-1.516-4.425-3.947-4.425-2.512 0-4.585 1.794-5.144 4.425h9.09ZM6.632 7.387 0 15.139h11.844l1.33-3.655H8.1l3.1-3.586.01-.095-2.016-3.471h9.072l-7.032 19.35h4.812L24.538.358H2.6l4.033 7.028Zm69.168-2.364c2.292 0 4.3 1.233 6.055 3.346l.921-6.575C81.143.688 78.93 0 76 0c-5.82 0-9.09 3.409-9.09 7.734 0 3 1.675 4.834 4.426 6.02l1.315.598c2.452 1.047 3.11 1.565 3.11 2.671 0 1.146-1.106 1.874-2.79 1.874-2.782.01-5.034-1.415-6.728-3.847l-.94 6.699C67.234 23.22 69.708 24 72.97 24c5.532 0 8.93-3.19 8.93-7.615 0-3.01-1.335-4.943-4.704-6.458l-1.435-.678c-1.994-.887-2.671-1.375-2.671-2.352 0-1.056.927-1.874 2.71-1.874Z"
                      />
                    </svg>
                  </a>
                </div>
                <div className="align-items-center d-flex np-flow-header__right justify-content-end order-2">
                  <div data-testid="profile-selector">
                    <nav className="profile-menu nav navbar-nav navbar-right">
                      <button
                        type="button"
                        aria-expanded="false"
                        className="profile-name btn-unstyled"
                      >
                        <span className="sr-only">
                          Abrir ou fechar o menu da conta
                        </span>
                        <div className="tw-avatar tw-avatar--48 tw-avatar--initials np-text-title-body">
                          <div className="tw-avatar__content">CY</div>
                        </div>
                        <span className="hidden-xs hidden-sm hidden-md m-l-1 title-5">
                          Caio Correa Lima El Yanes
                        </span>
                        <span
                          className="tw-icon tw-icon-chevron-up tw-chevron chevron-color bottom m-l-1"
                          aria-hidden="true"
                          role="presentation"
                          data-testid="chevron-up-icon"
                        >
                          <svg
                            width={16}
                            height={16}
                            fill="currentColor"
                            focusable="false"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12.771 7.115a.829.829 0 0 0-1.2 0L3 15.686l1.2 1.2 7.971-7.971 7.972 7.971 1.2-1.2-8.572-8.571Z" />
                          </svg>
                        </span>
                      </button>
                    </nav>
                  </div>
                  <span className="separator" />
                  <button
                    type="button"
                    className="np-close-button close btn-link text-no-decoration np-close-button--x-large"
                    aria-label="Fechar"
                  >
                    <span
                      className="tw-icon tw-icon-cross "
                      aria-hidden="true"
                      role="presentation"
                      data-testid="cross-icon"
                    >
                      <svg
                        width={24}
                        height={24}
                        fill="currentColor"
                        focusable="false"
                        viewBox="0 0 24 24"
                      >
                        <path d="m19.629 5.915-1.2-1.2-6.257 6.257-6.258-6.257-1.2 1.2 6.258 6.257-6.258 6.257 1.2 1.2 6.258-6.257 6.257 6.257 1.2-1.2-6.258-6.257 6.258-6.257Z" />
                      </svg>
                    </span>
                  </button>
                </div>
                <div className="align-items-center d-flex justify-content-center order-1 flex-grow-1">
                  <div className="tw-stepper np-flow-navigation__stepper">
                    <div className="progress">
                      <div className="progress-bar" style={{ width: "75%" }} />
                    </div>
                    <ul className="tw-stepper-steps p-t-1 m-b-0">
                      <li
                        className="hidden-xs tw-stepper__step tw-stepper__step--clickable tw-stepper__step--has-tooltip"
                        style={{ left: "0%" }}
                      >
                        <span className="d-inline-block">
                          <button
                            type="button"
                            className="btn-unstyled tw-stepper__step-label"
                          >
                            <small>
                              <span>Quantia</span>
                            </small>
                          </button>
                        </span>
                      </li>
                      <li
                        className="hidden-xs tw-stepper__step"
                        style={{ left: "25%" }}
                      >
                        <span className="np-text-body-default tw-stepper__step-label small">
                          <span>Você</span>
                        </span>
                      </li>
                      <li
                        className="hidden-xs tw-stepper__step tw-stepper__step--clickable"
                        style={{ left: "50%" }}
                      >
                        <button
                          type="button"
                          className="btn-unstyled tw-stepper__step-label"
                        >
                          <small>
                            <span>Beneficiário</span>
                          </small>
                        </button>
                      </li>
                      <li
                        className="hidden-xs tw-stepper__step tw-stepper__step--active"
                        style={{ left: "75%" }}
                      >
                        <span className="np-text-body-default tw-stepper__step-label small">
                          <span>Conferir</span>
                        </span>
                      </li>
                      <li
                        className="hidden-xs tw-stepper__step"
                        style={{ left: "100%" }}
                      >
                        <span className="np-text-body-default tw-stepper__step-label small">
                          <span>Pagar</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div>
            <section className="tw-flow__main" style={{ position: "relative" }}>
              <div className="tw-flow__steps" style={{}}>
                <div className="tw-flow__step container" style={{}}>
                  <div id="trigger" />
                  <div>
                    <div className="row">
                      <div className="col-md-offset-3 col-md-6 p-b-3">
                        <header className="transfer-flow-main__header">
                          <h2 className="text-xs-left text-sm-center">
                            Confirme os detalhes da transferência
                          </h2>
                        </header>
                        <div />
                      
                        <div data-testid="swift-fee-warning">
                          <div
                            role="alert"
                            className="alert d-flex alert-warning"
                          >
                            <div
                              className="alert__content d-flex flex-grow-1 desktop"
                              data-testid="desktop"
                            >
                              <span
                                data-testid="status-icon"
                                className="status-circle status-circle-lg warning"
                              >
                                <span
                                  className="tw-icon tw-icon-alert status-icon dark"
                                  aria-hidden="true"
                                  role="presentation"
                                  data-testid="alert-icon"
                                >
                                  <svg
                                    width={16}
                                    height={16}
                                    fill="currentColor"
                                    focusable="false"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M11.143 4.286h1.714v9.857h-1.714z" />
                                    <circle cx={12} cy={18} r="1.714" />
                                  </svg>
                                </span>
                              </span>
                              <div className="alert__message">
                                <span className="np-text-body-large d-block">
                                  <span>
                             Revise os detalhes da transação, uma vez enviada, não poderá ser cancelada
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <tw-review-step
                          resources="props.resources"
                          on-complete="props.onComplete"
                          on-change="props.onChange"
                          track-step-internal-event="props.trackStepInternalEvent"
                          loading-next-step="props.loadingNextStep"
                          add-tracking-parameters="props.addTrackingParameters"
                          profiler="props.profiler"
                          theme="props.theme"
                          source-currency-balance="props.sourceCurrencyBalance"
                          quote="props.quote"
                          on-quote-change="props.onQuoteChange"
                          balance-disabled-reason="props.balanceDisabledReason"
                          schedule-details="props.scheduleDetails"
                          schedule-section-details="props.scheduleSectionDetails"
                          open-schedule-modal="props.openScheduleModal"
                          close-schedule-modal="props.closeScheduleModal"
                          is-quote-updating="props.isQuoteUpdating"
                          is-scheduled-transfer="props.isScheduledTransfer"
                          is-schedule-and-pay-now="props.isScheduleAndPayNow"
                          selected-payment-option="props.selectedPaymentOption"
                          contact="props.contact"
                          className="ng-scope ng-isolate-scope"
                        >
                          <div>
                            <tw-review-details-card
                              amount-converted="getConvertedAmount()"
                              balance="vm.sourceCurrencyBalance"
                              delivery-estimate="getDeliveryEstimate()"
                              delivery-method="getDeliveryMethod()"
                              disable-edit-recipient="disableEditRecipient"
                              discount="vm.selectedPaymentOption.fee.discount"
                              errors="errors"
                              exchange-rate="vm.quote.rate"
                              fixed-rate-hours="getFixedRateHours()"
                              fixed-rate="vm.quote.rateType === 'FIXED'"
                              fixed-target-deposit-amount="vm.quote.guaranteedTargetRateFluctuationBuffer"
                              fixed-target="vm.quote.providedAmountType === 'TARGET'"
                              hide-reference-by-default="shouldHideReferenceByDefault()"
                              is-approval-required="isApprovalRequired"
                              is-scheduled-transfer="vm.isScheduledTransfer"
                              locale="locale"
                              on-edit-recipient="editRecipient()"
                              on-edit-schedule="canScheduleTransfer && vm.openScheduleModal"
                              on-edit-transfer="editQuote()"
                              payout="vm.quote.payOut"
                              preselected-payment-option="preselectedPaymentOption"
                              quote="vm.quote"
                              contact="vm.contact"
                              reference-label="getReferenceLabel()"
                              reference="shouldHideReferenceByDefault() ? reference : null"
                              schedule-section-details="vm.scheduleSectionDetails"
                              source-amount="vm.selectedPaymentOption.sourceAmount"
                              source-currency="vm.selectedPaymentOption.sourceCurrency"
                              target-amount="vm.selectedPaymentOption.targetAmount"
                              target-currency="vm.selectedPaymentOption.targetCurrency"
                              total-fee="vm.selectedPaymentOption.fee.total"
                              track-step-internal-event="trackStepInternalEvent"
                              transfer-specification="transferSpecification"
                              selected-payment-option-type="vm.selectedPaymentOption.payIn"
                              className="ng-isolate-scope"
                            >
                              <div className="tw-review-details-card">
                                {/* ngIf: $ctrl.shouldShowCurrentBalance() */}
                                <strong className="tw-review-details-card__title">
                                  <small className="ng-binding">
                                    {" "}
                                    Detalhes da transferência{" "}
                                  </small>
                                </strong>
                                <strong>
                                  <small>
                                    {/* ngIf: $ctrl.onEditTransfer */}
                                    <a
                                      ng-if="$ctrl.onEditTransfer"
                                      ng-click="$ctrl.editTransfer()"
                                      href="/wise"
                                      className="pull-right tw-review-details-card__title-link ng-binding ng-scope"
                                      style={{}}
                                    >
                                      Editar
                                    </a>
                                    {/* end ngIf: $ctrl.onEditTransfer */}
                                  </small>
                                </strong>
                                {/* ngIf: !$ctrl.isSendOrderFlow() */}
                                <div
                                  ng-if="!$ctrl.isSendOrderFlow()"
                                  data-test="regular-review"
                                  className="ng-scope"
                                  style={{}}
                                >
                                  <p className="m-t-2 m-b-1 tw-review-element tw-review-element_large">
                                    <small className="tw-review-element__name ng-binding">
                                      {" "}
                                      Você envia exatamente{" "}
                                    </small>
                                    <strong className="tw-review-element__value h3 ng-binding">
                                      1.000 USDT
                                    </strong>
                                  </p>
                                
                                  {/* ngIf: $ctrl.discount */}
                                  <p
                                    className="m-b-1 tw-review-element"
                                    ng-class="$ctrl.getFeeClass()"
                                  >
                                    <small className="tw-review-element__name ng-binding">
                                      Tarifa (incluída):
                                    </small>
                                    {/* ngIf: $ctrl.getFeePopoverMessage() */}
                                    {/* ngIf: !$ctrl.getFeePopoverMessage() */}
                                    <span
                                      className="tw-review-element__value text-primary ng-binding ng-scope"
                                      ng-if="!$ctrl.getFeePopoverMessage()"
                                    >
                                      40,47 USDT
                                    </span>
                                    {/* end ngIf: !$ctrl.getFeePopoverMessage() */}
                                  </p>
                                  <p className="m-b-1 tw-review-element">
                                    <small
                                      className="tw-review-element__name ng-binding"
                                      ng-hide="$ctrl.sourceCurrency === $ctrl.targetCurrency"
                                    >
                                      Quantia a ser convertida
                                    </small>
                                 
                                    <span className="tw-review-element__value text-primary ng-binding">
                                      959,53 USDT
                                    </span>
                                  </p>
                                  <tw-guaranteed-rate-review-item
                                    quote="$ctrl.quote"
                                    balance-withdrawal-mode="$ctrl.balanceWithdrawalMode"
                                    is-approval-required="$ctrl.isApprovalRequired"
                                    className="ng-isolate-scope"
                                  >
                                    <div className="np-theme-personal">
                                      <p className="m-b-1 tw-review-element">
                                        <small className="tw-review-element__name">
                                          <span className="np-popover">
                                            <span className="d-inline-block">
                                              <span data-toggle="popover">
                                                Câmbio garantido (90 segundos)
                                              </span>
                                            </span>
                                          </span>
                                        </small>
                                        <span className="tw-review-element__value text-primary">
                                          1 USDT = 5,34339 BRL
                                        </span>
                                      </p>
                                    </div>
                                  </tw-guaranteed-rate-review-item>
                                  <p className="m-b-1 tw-review-element tw-review-element_large">
                                    <span className="tw-review-element__name">
                                      <small className="ng-binding">
                                        {" "}
                                        Você recebe{" "}
                                      </small>
                                    </span>
                                    <strong className="tw-review-element__value h3 ng-binding">
                                      5000,57 BRL
                                    </strong>
                                  </p>
                                  {/* ngIf: !$ctrl.scheduleSectionDetails */}
                                </div>
                                {/* end ngIf: !$ctrl.isSendOrderFlow() */}
                                {/* ngIf: $ctrl.isScheduledTransfer */}
                                {/* ngIf: $ctrl.isPaymentsViaBalances */}
                                {/* ngIf: $ctrl.shouldShowRemainingBalance() */}
                                {/* ngIf: $ctrl.reference && !$ctrl.hideReferenceByDefault */}
                                <hr className="m-y-2" />
                                <div className="m-b-2">
                                  <strong className="tw-review-details-card__title">
                                    <small className="ng-binding">
                                      {" "}
                                      Dados do beneficiário{" "}
                                    </small>
                                  </strong>
                                  <strong>
                                    <small>
                                      {/* ngIf: $ctrl.onEditRecipient && !$ctrl.disableEditRecipient */}
                                      <a
                                        ng-if="$ctrl.onEditRecipient && !$ctrl.disableEditRecipient"
                                        ng-click="$ctrl.editRecipient()"
                                        href="/wise"
                                        className="pull-right tw-review-details-card__title-link ng-binding ng-scope"
                                        style={{}}
                                      >
                                        Alterar
                                      </a>
                                      {/* end ngIf: $ctrl.onEditRecipient && !$ctrl.disableEditRecipient */}
                                    </small>
                                  </strong>
                                </div>
                                {/* ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element m-b-1 ng-scope"
                                  style={{}}
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Nome do titular da conta
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    Caio Correa Lima El Yanes
                                  </span>
                                </p>
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element m-b-1 ng-scope"
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Agência bancária
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    2977
                                  </span>
                                </p>
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  ng-repeat="detail in $ctrl.contact.display.details track by $index"
                                  className="tw-review-element m-b-1 ng-scope"
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Número da conta
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    2079-7
                                  </span>
                                </p>
                                {/* end ngRepeat: detail in $ctrl.contact.display.details track by $index */}
                                <p
                                  className="m-b-0 tw-review-element ng-hide"
                                  ng-show="$ctrl.deliveryMethod === 'LINK'"
                                  style={{}}
                                >
                                  <small className="tw-review-element__name ng-binding">
                                    Banco
                                  </small>
                                  <span className="tw-review-element__value text-primary ng-binding">
                                    Itaú
                                  </span>
                                </p>
                                {/* ngIf: $ctrl.scheduleSectionDetails */}
                                <div
                                  ng-if="$ctrl.scheduleSectionDetails"
                                  data-test="schedule-details"
                                  className="ng-scope"
                                >
                                  <hr className="m-y-2" />
                                  <strong className="tw-review-details-card__title">
                                    <small className="ng-binding">
                                      Detalhes do agendamento
                                    </small>
                                  </strong>
                                  <strong>
                                    <small>
                                      {/* ngIf: $ctrl.onEditSchedule */}
                                     
                                      {/* end ngIf: $ctrl.onEditSchedule */}
                                    </small>
                                  </strong>
                                  {/* ngIf: $ctrl.scheduleSectionDetails.error */}
                                  <p className="m-t-2 m-b-1 tw-review-element">
                                    <small className="tw-review-element__name ng-binding">
                                      Enviaremos
                                    </small>
                                    {/* ngIf: !($ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.scheduleDate) */}
                                    <span
                                      ng-if="!($ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.scheduleDate)"
                                      className="tw-review-element__value text-primary ng-binding ng-scope"
                                    >
                                      Agora
                                    </span>
                                    {/* end ngIf: !($ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.scheduleDate) */}
                                    {/* ngIf: $ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.scheduleDate */}
                                  </p>
                                  <p
                                    className="m-b-1 tw-review-element"
                                    ng-class="$ctrl.getDeliveryEstimateClass()"
                                    data-test="review-estimate-section"
                                  >
                                    {/* ngIf: !$ctrl.isApprovalRequired */}
                                    <small
                                      ng-if="!$ctrl.isApprovalRequired"
                                      className="tw-review-element__name ng-binding ng-scope"
                                    >
                                      Estimativa de entrega
                                    </small>
                                    {/* end ngIf: !$ctrl.isApprovalRequired */}
                                    {/* ngIf: $ctrl.isApprovalRequired */}
                                    {/* ngIf: $ctrl.getDeliveryEstimatePopoverMessage() */}
                                    {/* ngIf: !$ctrl.getDeliveryEstimatePopoverMessage() */}
                                    <span
                                      ng-if="!$ctrl.getDeliveryEstimatePopoverMessage()"
                                      className="tw-review-element__value text-primary tw-normal-estimate ng-binding ng-scope"
                                    >
                                      até segunda-feira, 14 de agosto
                                    </span>
                                    {/* end ngIf: !$ctrl.getDeliveryEstimatePopoverMessage() */}
                                  </p>
                                  <p className="m-b-1 tw-review-element">
                                    <small className="tw-review-element__name ng-binding">
                                      Repete
                                    </small>
                                    {/* ngIf: !($ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.frequency) */}
                                    <span
                                      ng-if="!($ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.frequency)"
                                      className="tw-review-element__value text-primary text-capitalize ng-binding ng-scope"
                                    >
                                      Nunca
                                    </span>
                                    {/* end ngIf: !($ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.frequency) */}
                                    {/* ngIf: $ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.frequency */}
                                  </p>
                                  {/* ngIf: $ctrl.scheduleSectionDetails && $ctrl.scheduleSectionDetails.endDate */}
                                </div>
                                {/* end ngIf: $ctrl.scheduleSectionDetails */}
                                {/* ngIf: $ctrl.hideReferenceByDefault */}
                                <div
                                  ng-if="$ctrl.hideReferenceByDefault"
                                  data-test="reference-input"
                                  className="tw-review-card-reference-input ng-scope"
                                  style={{}}
                                >
                                  <hr className="m-y-2" />
                                  <strong className="tw-review-details-card__title">
                                    {/* ngIf: !$ctrl.shouldShowReferenceField */}
                                    <small
                                      ng-if="!$ctrl.shouldShowReferenceField"
                                      className="ng-binding ng-scope"
                                    >
                                      Referência (opcional)
                                    </small>
                                    {/* end ngIf: !$ctrl.shouldShowReferenceField */}
                                    {/* ngIf: $ctrl.shouldShowReferenceField */}
                                  </strong>
                                  <strong>
                                    <small>
                                      <a
                                        ng-click="$ctrl.toggleReferenceField()"
                                        href="https://wise.com/send"
                                        className="pull-right tw-review-details-card__title-link ng-binding"
                                        id="addOrRemoveReference"
                                      >
                                        Adicionar
                                      </a>
                                    </small>
                                  </strong>
                                  {/* ngIf: $ctrl.shouldShowReferenceField */}
                                </div>
                                {/* end ngIf: $ctrl.hideReferenceByDefault */}
                              </div>
                            </tw-review-details-card>
                            {/* ngIf: !shouldHideReferenceByDefault() */}
                            {/* ngIf: isCancellationWarningEnabled() */}
                            {/* ngIf: terms.messages && terms.messages.length > 0 */}
                            <tw-review-continue-button
                              on-click="continue"
                              disabled="isContinueButtonDisabled()"
                              loading="isLoading || loadingNextStep"
                              is-scheduled-transfer="vm.isScheduledTransfer"
                              is-balance-withdrawal="balanceWithdrawalMode"
                              is-approval-required="isApprovalRequired"
                              is-pay-now-and-schedule-transfer="vm.isScheduleAndPayNow"
                              preselected-payment-option="preselectedPaymentOption"
                              className="ng-isolate-scope"
                            >
                              <div className="np-theme-personal">
                                <button
                                  className="btn btn-md np-btn np-btn-md btn-block np-btn-block btn-positive btn-priority-1 m-t-3"
                                  data-testid="continue-button"
                                  type="button"
                                >
                                  Confirmar e pagar
                                </button>
                              </div>
                            </tw-review-continue-button>
                          </div>
                        </tw-review-step>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className="send-flow-support-widget closed">
          <button
            className="btn btn-md np-btn np-btn-md btn-accent btn-priority-1 opener"
            data-testid="help-widget-opener"
            type="button"
          >
            <div className="text">Ajuda</div>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div id="AdHeader" />
    <div id="AdContainer" />
    <div id="AD_Top" />
    <div id="homead" />
    <div id="ad-lead" />
  </div>
</>

    )
};
export default ConfirmWise;