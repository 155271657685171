import { useTranslation } from 'react-i18next';
function FooterLogged() {
  const { t, i18n} = useTranslation();
    return(
        <footer className="py-4 bg-#08383f-50 mt-12 main-container">
        <div className="px-16 font-circular">
          <div className="mx-auto max-w-7xl space-y-7 min-w-4xl">
            <div className="flex">
              <span className="text-xs leading-4 text-#08383f-500">
                © 2023 BRLA Digital. {t('all_rights_reserved')}
              </span>
              <div className="ml-auto flex gap-4 text-xs leading-4">
                <a
                  href="https://brla.digital/20231122_-_BRLA_Digital_-_Termos_de_Uso.pdf"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('terms_of_use')}
                </a>
               
              
              </div>

              <div className="ml-1 flex gap-4 text-xs leading-4">
                <a
                href='https://brla.digital/BRLA_Digital_LTDA_-_Politica_de_PLD_FT_e_KYC_20230825.pdf'
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {t('compliance_policy')}
                </a>
               
              
              </div>

            </div>
          </div>
        </div>
      </footer>

    )
};
export default FooterLogged;