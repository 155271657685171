import logoImage from '../Home/images/logo3.png';
import banksImage from '../Home/images/banks3.png';
import reaisImage from '../Home/images/reais.jpg';
import ferramentaImage from '../Home/images/ferramenta2.png';
import logocorpoImage from '../Home/images/logo_corpo.png';
import logofooterImage from '../Home/images/logo_footer.png';

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Header from '../../Components/Header';
import WorldImage from './worldwide.png'
import SwapImage from './swap.png'
import TradingImage from './exchange-rate.png'
import PaymentsImage from './credit-card.png'
import HCImage from './team.png'

function Intro() {
  
    const [activeAccordion, setActiveAccordion] = useState(null);

  const toggleAccordion = (accordionId) => {
    if (activeAccordion === accordionId) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(accordionId);
    }
  };
    const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      setIsFixed(currentScrollPosition > 100); // Altere '100' para o valor que desejar
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
    return (
       
        <div className="body-wrapper   hs-content-id-75838281598 hs-site-page page " >
          <div data-global-resource-path="brla/templates/partials/header-2022.html" >
            <div 
              id="hs_cos_wrapper_module_16425463459567"
              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
              style={{}}
              data-hs-cos-general-type="widget"
              data-hs-cos-type="module"
            >
              <div className="bumper-placeholder" />
              <Header/>
            </div>
          </div>
          <main className="body-container-wrapper remove-dnd-duplicate">
            <div>
              <div
                id="hs_cos_wrapper_module_16369984703743"
                className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                style={{}}
                data-hs-cos-general-type="widget"
                data-hs-cos-type="module"
              />
              <div className="">
                <div className="">
                  <div className="">
                    <div
                      className=""
                      style={{}}
                      data-widget-type="cell"
                      data-x={0}
                      data-w={0}
                    >
                      <div className="  dnd_area-row-0-background-color dnd-section" style={{paddingTop:'0px' }}>
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-custom_widget dnd-module"
                            style={{}}
                            data-widget-type="custom_widget"
                            data-x={0}
                            data-w={12}
                          >
                            <div
                              id="hs_cos_wrapper_dnd_area-module-1"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style={{}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <div className="container  coins-hero  full-white  text-left updated--style">
                                <div className="row ">
                                  <div className="col breadcrumbs-text"></div>
                                </div>
                                <div className="row">
                                  <div className="hero-top-icon col-12">
                                    <img style={{height:'80px'}} src={logoImage} alt="BRLA" />
                                  </div>
                                  <div className=" col-lg-9    ">
                                  <div
                                      className="intro mobile-font pc-font"
                                      style={{ textAlign: "justify", marginBottom:'20px' }}
                                    >
                                    Hi, we are BRLA
                                    </div>                                    <div
                                      className="intro"
                                      style={{ textAlign: "justify", marginBottom:'50px' }}
                                    >
                                       A Brazilian Real Stablecoin, created to bridge the expensive and painful gap between international crypto companies and the large Brazilian Market.
 
                                    </div>
                                    <div className="coins-cta"></div>
                                  </div>
                                </div>
                              </div>
                              <div  className="coins-bg-bazooka_deep" />
                            </div>
                          </div>
                          {/*end widget-span */}
                        </div>
                        {/*end row*/}
                      </div>
                      {/*end row-wrapper */}
                      <div className="row-fluid-wrapper row-depth-1 row-number-2 dnd_area-row-1-padding dnd-section">
                        <div className="row-fluid ">
                          <div
                            className="span12 widget-span widget-type-cell dnd-column"
                            style={{}}
                            data-widget-type="cell"
                            data-x={0}
                            data-w={12}
                          >
                            <div className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
                              <div className="row-fluid ">
                                <div
                                  className="span12 widget-span widget-type-custom_widget dnd-module"
                                  style={{}}
                                  data-widget-type="custom_widget"
                                  data-x={0}
                                  data-w={12}
                                >
                                  <div
                                    id="hs_cos_wrapper_dnd_area-module-2"
                                    className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                    style={{}}
                                    data-hs-cos-general-type="widget"
                                    data-hs-cos-type="module"
                                  >
                                    <div className="row">
                                      <div className="col-lg-12 col-12">
                                        <h3 className='mobile-only mobile-margin' style={{marginBottom:'15px'}}>Connect to Brazil for free</h3>
                                        <div
                                          className="intro mobile-only"
                                          style={{ textAlign: "justify" }}
                                        >
                                          We work on customizing our stablecoin solution to attend to our partners various needs, which include (but are not limited to):
                                        </div>
                                        
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="">
                      <div className="row-fluid ">
                        <div
                          className="span12 widget-span widget-type-cell dnd-column"
                          style={{}}
                          data-widget-type="cell"
                          data-x={0}
                          data-w={12}
                        >
                          <div className="row-fluid-wrapper row-depth-1 row-number-5 dnd-row">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                style={{}}
                                data-widget-type="custom_widget"
                                data-x={0}
                                data-w={12}
                              >
                                <div
                                  id="hs_cos_wrapper_widget_1654871880310"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                  style={{}}
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="module"
                                >
                                  <div
                                    className="dnd-section  updated--style"
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                  >
                                    <section className="content-section container">
  <div className="row align-items-center">
    <div className="col-lg-6 col-12 order-lg-0 order-1">
      <img
        src={WorldImage}
        alt="banks"
        loading="lazy"
        className="col-image mx-auto d-block mb-0"
        style={{ height: '250px' }}
      />
    </div>
    <div className="col-lg-6 col-12 mobile-margin order-lg-1 order-0">
      <span>
        <h3 style={{ marginBottom: '15px' }}>Onboarding of Brazilian Users</h3>
      </span>
      <div style={{ marginBottom: 24 }}>
        <p
          className="intro"
          style={{ textAlign: "justify" }}
        >
          Easily onboard Brazilian users with our seamless BRLA on and off-ramp, converting between FIAT and BRLA for smooth transactions.
        </p>
      </div>
      <div className="d-none d-md-inline-block"></div>
      <div className="d-block d-md-none text-left justify-content-center"></div>
    </div>
  </div>
</section>

                                  </div>
                                </div>
                              </div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="">
                      <div className="row-fluid ">
                        <div
                          className="span12 widget-span widget-type-cell dnd-column"
                          style={{}}
                          data-widget-type="cell"
                          data-x={0}
                          data-w={12}
                        >
                          <div className="row-fluid-wrapper row-depth-1 row-number-7 dnd-row">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                style={{}}
                                data-widget-type="custom_widget"
                                data-x={0}
                                data-w={12}
                              >
                                <div
                                  id="hs_cos_wrapper_module_16548722404774"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                  style={{}}
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="module"
                                >
                                  <div
                                    className="dnd-section  updated--style"
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                  >
                                    <section className="content-section container">
    <div className="row align-items-center">
        <div className="order-1 order-lg-2 col-lg-6 col-12">
            <img
                src={SwapImage}
                alt="speed"
                loading="lazy"
                className="col-image mx-auto d-block mb-0"
                style={{height:'250px'}}
            />
        </div>
        <div className="order-0 col-lg-6 col-12">
            <span>
                <h3  className='mobile-margin pc-margin'  >BRLA Swap for other currencies</h3>
            </span>
            <div style={{ marginBottom: 24 }}>
                <p
                    className="intro"
                    style={{ textAlign: "justify", marginTop:'15px' }}
                >
                    Effortlessly swap BRLA to USDT and USDC, enabling greater financial flexibility and global reach.
                </p>
            </div>
            <div className="d-none d-md-inline-block"></div>
            <div className="d-block d-md-none text-left justify-content-center"></div>
        </div>
    </div>
</section>


                                  </div>
                                </div>
                              </div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="">
                      <div className="row-fluid ">
                        <div
                          className="span12 widget-span widget-type-cell dnd-column"
                          style={{}}
                          data-widget-type="cell"
                          data-x={0}
                          data-w={12}
                        >
                          <div className="row-fluid-wrapper row-depth-1 row-number-9 dnd-row">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                style={{}}
                                data-widget-type="custom_widget"
                                data-x={0}
                                data-w={12}
                              >
                                <div
                                  id="hs_cos_wrapper_module_16548722873483"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                  style={{}}
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="module"
                                >
                                  <div
                                    className="dnd-section  updated--style"
                                    style={{ paddingTop: 0, paddingBottom: 0 }}
                                  >
<section className="content-section container">
    <div className="row align-items-center">
        <div className="order-1 order-lg-0 col-lg-6 col-12">
            <img
                src={TradingImage}
                alt="Fiat"
                loading="lazy"
                className="col-image mx-auto d-block mb-0"
                style={{height:'250px'}}
            />
        </div>
        <div className="order-0 order-lg-1 col-lg-6 col-12 mobile-margin">
            <span>
                <h3 style={{marginBottom:'15px'}}>BRLA Spot and derivative trading solutions</h3>
            </span>
            <div style={{ marginBottom: 24 }}>
                <p
                    className="intro"
                    style={{ textAlign: "justify" }}
                >
                    Gain efficient access to cryptocurrency exchanges using BRLA, a stablecoin tied to the Brazilian Real, simplifying spot and derivative trading with reliable value representation.
                </p>
            </div>
            <div className="d-none d-md-inline-block"></div>
            <div className="d-block d-md-none text-left justify-content-center"></div>
        </div>
    </div>
</section>


<section className="content-section container">
    <div className="row align-items-center">
        <div className="order-1 order-lg-2 col-lg-6 col-12">
            <img
                src={PaymentsImage}
                alt="speed"
                loading="lazy"
                className="col-image mx-auto d-block mb-0"
                style={{height:'250px'}}
            />
        </div>
        <div className="order-0 col-lg-6 col-12">
            <span>
                <h3  className='mobile-margin pc-margin'  >Enterprise payments using BRLA</h3>
            </span>
            <div style={{ marginBottom: 24 }}>
                <p
                    className="intro"
                    style={{ textAlign: "justify", marginTop:'15px' }}
                >
                    Simplify enterprise payments by utilizing BRLA for cross-border transactions, reducing fees and streamlining the payment process.
                </p>
            </div>
            <div className="d-none d-md-inline-block"></div>
            <div className="d-block d-md-none text-left justify-content-center"></div>
        </div>
    </div>
</section>

<section className="content-section container">
    <div className="row align-items-center">
        <div className="order-1 order-lg-0 col-lg-6 col-12">
            <img
                src={HCImage}
                alt="Fiat"
                loading="lazy"
                className="col-image mx-auto d-block mb-0"
                style={{height:'250px'}}
            />
        </div>
        <div className="order-0 order-lg-1 col-lg-6 col-12 mobile-margin">
            <span>
                <h3 style={{marginBottom:'15px'}}>Headcount payments using BRLA</h3>
            </span>
            <div style={{ marginBottom: 24 }}>
                <p
                    className="intro"
                    style={{ textAlign: "justify" }}
                >
                    Efficiently manage headcount payments with BRLA, offering employees a secure and reliable digital payment solution.
                </p>
            </div>
            <div className="d-none d-md-inline-block"></div>
            <div className="d-block d-md-none text-left justify-content-center"></div>
        </div>
    </div>
</section>

                                  </div>
                                </div>
                              </div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    <div className="">
                      <div className="row-fluid ">
                        <div
                          className="span12 widget-span widget-type-cell dnd-column"
                          style={{}}
                          data-widget-type="cell"
                          data-x={0}
                          data-w={12}
                        >
                          <div className="row-fluid-wrapper row-depth-1 row-number-11 dnd-row">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                style={{}}
                                data-widget-type="custom_widget"
                                data-x={0}
                                data-w={12}
                              ></div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                          <div className="row-fluid-wrapper row-depth-1 row-number-12 dnd-row">
                            <div className="row-fluid ">
                              <div
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                style={{}}
                                data-widget-type="custom_widget"
                                data-x={0}
                                data-w={12}
                              ></div>
                              {/*end widget-span */}
                            </div>
                            {/*end row*/}
                          </div>
                          {/*end row-wrapper */}
                        </div>
                        {/*end widget-span */}
                      </div>
                      {/*end row*/}
                    </div>
                    {/*end row-wrapper */}
                    
                   
                    {/*end row-wrapper */}
                    


                    
                 
                  </div>
                  <div style={{background:'rgb(249, 251, 251)'}}>
                  <section style={{background:'rgb(249, 251, 251)', paddingBottom:'40px' , paddingTop:'50px'}} className="content-section container">
      <div className="row about-row">
        <div className="col-12 col-lg-6 about-text">
          <span>
            <h3 style={{ marginBottom: '15px', marginTop:'15px' }}>Why should you work with BRLA?</h3>
          </span>
          <div>
            <div className="intro mobile-margin-2" style={{ textAlign: 'justify' }}>
            Brazilians have a tendency to think and invest in their native Brazilian Real. Apart from being the cheapest and most flexible solution to do business in Brazil, we also work with the highest technological, financial and regulatory standards. Our use cases include Exchanges, DeFi, Financial Services, Payments and Trading, amongst others.
            </div>
          </div>
          <div className="d-none d-md-inline-block"></div>
          <div className="d-block d-md-none text-left justify-content-center"></div>
        </div>
        <div className="col-12 col-lg-6 about-image">
          <img
            src={logocorpoImage}
            alt="speed"
            loading="lazy"
            className="col-image mx-auto d-block mb-0 mobile-margin"
            style={{ height: '250px' }}
          />
        </div>
      </div>
    </section>

    </div>

                  <footer className="footer dark-mode mobile-color " style={{background:'white'}}>
                    <div className="body-wrapper container ">
                      <div className="row">
                        <div
                          className="mobile-logo col-6 col-lg-8 d-block d-lg-none"
                          style={{ marginBottom: 40 }}
                        >
                          
                        </div>
                      </div>
                      <div className="row nav-wrapper ">
                        <div  className="col-12 col-lg-8 footer-nav-column " >
                          <h2 className="mobile-font-color"  style={{ color: "black", marginBottom:'15px' }}>Contact</h2>
                          <div className="">
                            <p className="mobile-font-color" style={{color:'black'}}>If you are interested in doing business with us or knowing more about BRLA, please feel free to contact our head of Business Development, Lucas, at: <span style={{color:"#00dc84"}}>lgiorgio@brla.digital </span>
Or feel free to send any inquiries to <span style={{color:"#00dc84"}}>partnerships@brla.digital</span></p>
                          </div>
                        </div>
      
                      </div>
                      
                    </div>
                    <div className="col-12 col-lg-12 intro-display" style={{background:"#008884"}}>
                          <div style={{background:"#008884"}} className="social-icons-wrap">
                            <div
                              id="hs_cos_wrapper_module_166196490715532"
                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                              style={{background:"#008884"}}
                              data-hs-cos-general-type="widget"
                              data-hs-cos-type="module"
                            >
                              <h5 style={{color:'black'}} className="footer social-header">
                                Follow BRLA on Social
                              </h5>
                              <div style={{background:"#008884"}} className="social-grid">
  <a
    className="footer-social-icon-link"
    href="https://twitter.com/BRLAdigital"
    aria-label="Link to twitter website"
    target="_blank"
    rel="noopener"
  >
    <FontAwesomeIcon className="social-icon" icon={faTwitter} />
  </a>
  {/*  <a
    className="footer-social-icon-link"
    href="https://www.instagram.com/brla.digital/?hl=en"
    aria-label="Link to instagram website"
    target="_blank"
    rel="noopener"
  >
    <FontAwesomeIcon className="social-icon" icon={faInstagram} />
  </a>
  */}
  <a
    className="footer-social-icon-link"
    href="https://www.linkedin.com/company/brla-digital/"
    aria-label="Link to linkedin website"
    target="_blank"
    rel="noopener"
  >
    <FontAwesomeIcon className="social-icon" icon={faLinkedin} />
  </a>
</div>

                            </div>
                          </div>
                        </div>
                    <div style={{background:"#008884"}} className="container footer-disclaimers-container">
                      <div className="row">
                        <div className="desktop-logo col-3 d-none d-lg-block pr-0">
                          
                        </div>
                        <div style={{background:"#008884"}} className="col-12 col-lg-12">
                          <footer style={{background:"#008884"}} className="">
                            <p className="body-small pr-lg-5">
                              <br />
                              <br />
                              Digital assets are subject to a number of risks,
                              including price volatility. Transacting in digital
                              assets could result in significant losses and may not be
                              suitable for some consumers. Digital asset markets and
                              exchanges are not regulated with the same controls or
                              customer protections available with other forms of
                              financial products and are subject to an evolving
                              regulatory environment. Digital assets do not typically
                              have legal tender status and are not covered by deposit
                              protection insurance. The past performance of a digital
                              asset is not a guide to future performance, nor is it a
                              reliable indicator of future results or performance.
                            </p>
                            <p className="body-small mb-3">© 2023 BRLA Digital</p>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </footer>
                </div>
              </div>
              
            </div>
          </main>
        </div>
       
      
    );
}

export default Intro;
