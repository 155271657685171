/* global BigInt */
import logocorpoImage from '../Home/images/logo_corpo.png';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Web3 from 'web3';
import { Buffer } from "buffer/";
import { BRLAContractAbi } from '../../utils/abis';
import BigNumber from 'bignumber.js';
import { ENDPOINT, BRLA_CONTRACT_ADDRESSES} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Select from "react-select";

function BurnFiat () {
    const [internalWalletId, setInternalWalletId] = useState('');
    const [brlaBalance, setBrlaBalance] = useState(0);
    const [banks, setBanks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [pendingRequests, setPendingRequests] = useState(2);
    const [wallets, setWallets] = useState([]);
    const [users, setUsers] = useState([]);
  

    const navigate = useNavigate();
  
    
   
      
    useEffect(() => {
      // Obtem os usuários do localStorage
      const loadedUsers = localStorage.getItem('userData');
      if (loadedUsers) {
        setUsers(JSON.parse(loadedUsers));
      }
  
      // Obtem as carteiras do localStorage
      const loadedWallets = localStorage.getItem('wallets');
      if (loadedWallets) {
        setWallets(JSON.parse(loadedWallets));
      }
  
      // Obtem os dados do histórico do localStorage
      const loadedAccounts = localStorage.getItem('accounts');
      if (loadedAccounts) {
        setBanks(JSON.parse(loadedAccounts));
      };
      setPendingRequests(0);
    }, []);




  
      
    const [selectedBank, setSelectedBank] = useState('');

    

    const handleAddBankClick = () => {
      navigate('/bankinformation'); 
    };
    const handleDoneClick = () => {
      navigate('/wallet'); 
    };
    const [showPopup, setShowPopup] = useState(false);

    
    const [selectedBankData, setSelectedBankData] = useState(null);

    const bankOptions = banks.map(bank => ({ value: bank.id, label: bank.accountNickname }));



    const handleBankSelectChange = selectedOption => {
      setSelectedBank(selectedOption);
      const bankData = banks.find((bank) => bank.id === selectedOption.value);
      setSelectedBankData(bankData);
    };
    

const handleBankChange = (e) => {
  setSelectedBank(e.target.value);
  const bankData = banks.find((bank) => bank.id === e.target.value);
  setSelectedBankData(bankData);
};



const [connectedWallet, setConnectedWallet] = useState(false);
const [walletAddress, setWalletAddress] = useState(null);

const handleConnectWalletClick = async () => {
  console.log('handleConnectWallet chamada');

 


  const Sigtime = Date.now();
  const msgToSign = `I, ${users.firstName} ${users.lastName}, document ${users.taxId}, confirm that I am the owner of this address. Current time: ${Sigtime}`;
  const msg = `0x${Buffer.from(msgToSign, 'utf8').toString('hex')}`;
  const web3 = new Web3(window.ethereum);
  if (window.ethereum) {
    try {
      const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

      const walletAddress = accounts[0];
      const chainId = await web3.eth.getChainId();

      // Update the state of walletId
      setWalletAddress(walletAddress);

      const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[chainId];
      // Create an instance of the BRLA contract using the imported BRLAContractAbi
      const BRLAContract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);

      // Get the BRLA balance of the connected wallet
      const BRLABalance = await BRLAContract.methods.balanceOf(walletAddress).call();

      console.log('Saldo BRLA:', BRLABalance);

      // Update the local state with the obtained BRLA balance
      setBrlaBalance(BRLABalance);

      console.log("BRLA Balance: ", BRLABalance)

      const getChainName = (chainId) => {
        if ([1, 11155111].includes(chainId)) {
          return 'Ethereum';
        } else if ([137, 80001].includes(chainId)) {
          return 'Polygon';
        } else if ([66, 65].includes(chainId)) {
          return 'OKC';
        }
        return 'Unknown';
      };

      const chainName = getChainName(chainId);
      const connectedWallet = wallets.find(wallet => wallet.walletAddress.toLowerCase() === walletAddress.toLowerCase() && wallet.chain === chainName);
      console.log("Connected Wallet", connectedWallet)
      console.log("chain teste: ", chainId)
      if (connectedWallet) {
          setInternalWalletId(connectedWallet.id);

          // Add success toast
          toast.success('Wallet successfully connected.', {
            
          });

        } else {
          console.error("A carteira conectada não foi encontrada.");

          // Add failure toast
          toast.error('Failed to connect wallet: The connected wallet was not found. Please refresh de page', {
           
          });

          return;
        }

      setConnectedWallet(true);

  } catch (error) {

      console.error('Erro ao conectar a carteira:', error);

      // Add failure toast
      toast.error(`Failed to connect wallet: Please refresh the page and be sure you are connected to metamask in a Polygon wallet`, {
       
      });
    }
  } else {
    alert('MetaMask is not installed. Please install MetaMask to continue.');
  }
};


const handleBurnClick = () => {
    setShowPopup(true);
  };

  const [burnValue, setBurnValue] = useState('');

  // Adicione a função para lidar com a mudança no input de queima
  const handleBurnInputChange = (e) => {
    let value = e.target.value;
  
    // Verifica se o valor é uma string vazia
    if (value === '') {
      setBurnValue(value);
      return;
    }
  
    // Verifica se o valor é um número com até duas casas decimais
    if (/^\d*\.?\d{0,2}$/.test(value)) {
      setBurnValue(value);
    }
  };
  

  const handleMaxClick = () => {
    setBurnValue((brlaBalance / Math.pow(10, 18)).toFixed(2)); // Defina o valor máximo disponível aqui
  };


 



const handleConfirmClick = async () => {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
  
        const web3 = new Web3(window.ethereum);
  
        const walletAddress = accounts[0];
        const chainId = await web3.eth.getChainId();

        
        const getChainName = (chainId) => {
            if ([1, 11155111].includes(chainId)) {
              return 'Ethereum';
            } else if ([137, 80001].includes(chainId)) {
              return 'Polygon';
            } else if ([66, 65].includes(chainId)) {
              return 'OKC';
            }
            return 'Unknown';
          };
    

        const chainName = getChainName(chainId);
        
  
        setWalletAddress(walletAddress);
        console.log("chain", chainId)
       
  
        const BRLA_CONTRACT_ADDRESS = BRLA_CONTRACT_ADDRESSES[chainId];
        console.log("contract", BRLA_CONTRACT_ADDRESS)
  
        const BRLAContract = new web3.eth.Contract(BRLAContractAbi, BRLA_CONTRACT_ADDRESS);
  
        console.log("contract 2", BRLAContract)
        
        
        const SECOND = 1000;
  
        const fromAddress = accounts[0];
  
        const expiry = Math.trunc((Date.now() + 60 * 60 * SECOND) / SECOND);
        const nonce = await BRLAContract.methods.nonces(accounts[0]).call();
        const spender = await BRLAContract.methods.operatorWallet().call();        
        const createPermitMessageData = function () {
          const message = {
            "owner": fromAddress,
            "spender": spender,
            "value": (BigInt(burnValue*100)*BigInt(10**16)).toString(),
            "nonce": nonce,
            "deadline": expiry
          };

        
  
          const msgParams = JSON.stringify({
            domain: {
              chainId: chainId,
              name: 'BRLA Token',
              verifyingContract: BRLA_CONTRACT_ADDRESS,
              version: '1',
            },
  
            message: message,
            primaryType: 'Permit',
            types: {
              EIP712Domain: [
                { name: 'name', type: 'string' },
                { name: 'version', type: 'string' },
                { name: 'chainId', type: 'uint256' },
                { name: 'verifyingContract', type: 'address' },
            ],
            Permit: [
              { "name": "owner", "type": "address" },
              { "name": "spender", "type": "address" },
              { "name": "value", "type": "uint256" },
              { "name": "nonce", "type": "uint256" },
              { "name": "deadline", "type": "uint256" }
            ],
          },
        });

        return {
          msgParams,
          message,
        };
      };

      const signData = async function (web3, fromAddress, data) {
          try {
            const result = await web3.request({
              id: 1,
              method: "eth_signTypedData_v4",
              params: [fromAddress, data.msgParams],
              from: fromAddress,
            })

            const r = result.slice(0, 66);
            const s = "0x" + result.slice(66, 130);
            const v = Number("0x" + result.slice(130, 132));
            const msg = data.message
            return { v, r, s, ...msg };

          } catch (error) {
            console.log(error);
            return;
          }
        };

        const messageData = createPermitMessageData();
        const sig = await signData(window.ethereum, fromAddress, messageData);

        console.log(sig);

  
        console.log("CHAIN ID", chainId)

        if (true) {
          try {
            const response = await fetch(`${ENDPOINT}/pay-out`, {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                accountId: selectedBankData.id,
                walletId: internalWalletId,
                amount: (burnValue*100),
                permit: {
                    deadline: sig.deadline,
                    r: sig.r,
                    s: sig.s,
                    v: sig.v,
                    nonce: parseInt(sig.nonce)
                    },
                    notifyEmail: true
              }),
            });

            if (response.ok) {
              toast.success('Request successfully made. Please wait up to 1 minute for the Burn to be processed.');
              setShowPopup(false);
              const resUsedLimit = await fetch(`${ENDPOINT}/used-limit`, {
                method: 'GET',
                credentials: 'include',
              });
              const bodyUsedLimit = await resUsedLimit.json();
              localStorage.setItem('usedLimit', JSON.stringify(bodyUsedLimit.usedLimit || 0));
            } else {
              console.error('Error in server response:', response.status, response.statusText);
              const errorData = await response.json();
              toast.error(`Error: ${errorData.error || 'An unknown error occurred.'}`);
            }
          } catch (error) {
            console.error('Error making request:', error);
            toast.error(`Error making request: ${error.message || 'An unknown error occurred.'}`);
          }
        } else {
          toast.error('MetaMask is not installed. Please install MetaMask to continue.');
        }
      } catch (error) {
        console.error('Error interacting with MetaMask:', error);
        toast.error(`Error interacting with MetaMask: ${error.message || 'An unknown error occurred.'}`);
      }
    } else {
      toast.error('MetaMask is not installed. Please install MetaMask to continue.');
    }
  };
      
      
      
  const handleBackClick = () => {
    navigate('/wallet')
  };
     
      
  useEffect(() => {
    // Se não há requisições pendentes, então as requisições acabaram
    if (pendingRequests === 0) {
      // Adiciona um delay de 0.2 segundos antes de remover o estado de carregamento
      setIsLoading(false);
    } 
  }, [pendingRequests]);
  
  if (isLoading) {
    // Retorne um spinner de loading, um placeholder ou null
    return <div></div>;

  }
  

    
    return(<>
   {showPopup && selectedBankData && (
  <div className="wallet-popup-overlay">
    <div className="wallet-popup">
      <p>ID: {selectedBankData.id}</p>
      <p>BRANCH CODE: {selectedBankData.branchCode}</p>
      <p>ACCOUNT NUMBER: {selectedBankData.accountNumber}</p>
      <p>PIX KEY: {selectedBankData.pixKey}</p>
      <p className="warning">
        By confirming, you acknowledge that you have read all the information
        above.
      </p>
      <button className="cb-button base primary" onClick={handleConfirmClick}>
        Confirm
      </button>
    </div>
  </div>
)}


        <div id="__next">
          <div className="grid w-full min-h-screen font-circular">
          </div>
          <div
            style={{
              position: "fixed",
              top: 1,
              left: 1,
              width: 1,
              height: 0,
              padding: 0,
              margin: "-1px",
              overflow: "hidden",
              clip: "rect(0px, 0px, 0px, 0px)",
              whiteSpace: "nowrap",
              borderWidth: 0,
              display: "none"
            }}
          />
        </div>
        <next-route-announcer>
          <p
            aria-live="assertive"
            id="__next-route-announcer__"
            role="alert"
            style={{
              border: 0,
              clip: "rect(0px, 0px, 0px, 0px)",
              height: 1,
              margin: "-1px",
              overflow: "hidden",
              padding: 0,
              position: "absolute",
              width: 1,
              whiteSpace: "nowrap",
              overflowWrap: "normal"
            }}
          />
        </next-route-announcer>
        <div id="headlessui-portal-root">
          <div data-headlessui-portal="">
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
             
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
            <div>
              <div
                className="cb-modal"
                data-segmentscreenname="deposit_blockchain_step_2"
                id="headlessui-dialog-:r1:"
                role="dialog"
                aria-modal="true"
                data-headlessui-state="open"
              >
                <div className="modal-container">
                  <div
                    className="overlay opacity-100"
                    data-testid="overlay"
                    id="headlessui-dialog-overlay-:r2:"
                    aria-hidden="true"
                    data-headlessui-state="open"
                  />
                  <div
                    className="modal-content screen opacity-100 scale-100"
                    data-testid="content"
                  >
                    <div className="cb-modal-coolwhip">
                      
                    </div>
                    <div className="h-screen overflow-x-auto">
                      <div className="grid grid-rows-auto-1">
                        <div className="sticky top-0 bg-white z-10">
                          <div className="grid grid-cols-3 py-5 px-9">
                            <div className="flex items-center justify-start">
                              <button onClick={handleBackClick} style={{color:'black'}}  data-testid="back-button">
                                <svg
                                  width={26}
                                  height={26}
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  className="cb-icon cb-icon-chevronleftlight pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-chevronleftlight"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m15 19-7-7 7-7"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="flex items-center justify-center">
      
          <img src={logocorpoImage} style={{ width: '60px', height: '60px' }} />
        
    </div>
                            <div className="flex items-center justify-end">
                              <button
                                className="cb-modal-basic-close top-right relative inset-0"
                                data-testid="close-button"
                                style={{color:'black'}}
                                onClick={handleBackClick}
                              >
                                <svg
                                  width={16}
                                  height={16}
                                  viewBox="0 0 14 14"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-cross pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-cross"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                          <div className="w-full h-1 bg-black-75">
                            <div
                              aria-valuemax={100}
                              aria-valuemin={0}
                              aria-valuenow={-200}
                              className="h-full bg-blue-300 transition-width duration-100"
                              role="progressbar"
                            />
                          </div>
                        </div>
                        <div
                          className="cb-modal-body default relative pt-16 pb-20 font-circular"
                          data-testid="modal-body"
                        >
                          <div>
                            <div
                              className="grid h-full content-center items-center justify-center"
                              data-testid="center-both"
                            >
                              <div className="flex h-full w-184 flex-col items-center justify-between">
                                <div
                                  className="mb-8"
                                  data-testid="blockchain-deposit-instruction"
                                >
                                  <div className="mb-5">
                                    <div className="text-center col-span-4">
          
                                    {wallets.length > 0 ? (
                       <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  <>
    <button
      className="cb-button base primary w-64"
      style={{ marginBottom: '10px' }} // Adds a margin below the button
      disabled=""
      type="submit"
      data-testid="button-submit"
      onClick={handleAddBankClick}
    >
      <span>Add Bank Account</span>
    </button>

    <Select
      value={selectedBank}
      options={bankOptions}
      className="basic-multi-select"
      classNamePrefix="select"
      isSearchable
      placeholder="Select a bank account"
      onChange={handleBankSelectChange}
      styles={{
        control: (base) => ({
          ...base,
          width: 290, // Set the width here
          marginTop: '10px' // Adds a margin above the Select
        }),
      }}
    />
  </>
  </div>
) : (
  <button
    className="cb-button base primary w-64"
    disabled=""
    type="submit"
    data-testid="button-submit"
    onClick={handleAddBankClick}
  >
    <span>Add Bank Account</span>
  </button>
)}



                                    {/*  <p className="mt-4 text-sm leading-5 text-black-600 font-circular-regular">
                                      To transfer BRLA to your BRLA Account, ensure that the CPF registered with your bank account matches the CPF registered with your BRLA Account. This is crucial for a successful transaction.
                                      </p>
                                      
                                      <p className="mt-4 text-sm leading-5 text-black-600 font-circular-regular">
                                      Please note that PIX transactions are typically instant, allowing for prompt transfers. However, outside of regular banking hours, the transactions may experience delays and be subject to limits on the amount transferred. Keep in mind that we rely on the speed of the network to process the transactions. Depending on network traffic, it may take up to 30 minutes for your transfer to be completed.
</p>*/}
                                   {/*   <p className="mt-6 text-sm leading-5 text-black-600 font-circular-regular">
                                        <svg
                                          width={15}
                                          height={15}
                                          viewBox="0 0 16 14"
                                          fill="currentColor"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="cb-icon cb-icon-alertfilled mr-1 mb-1 inline-block text-orange-400 pointer-events-none"
                                          aria-hidden="true"
                                          data-testid="icon-alertfilled"
                                          focusable="false"
                                          role="img"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M6.257 1.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H2.42C.89 14-.073 12.354.677 11.02l5.58-9.92ZM9 11a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM8 3a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1Z"
                                          />
                                        </svg>
                                        <b>Important:</b> Always double-check that the CPF registered with your bank account matches the one registered with your BRLA Account to avoid issues with your transaction. Remember that the processing time may vary depending on factors such as network traffic and banking hours.
                                        <br />
                                        <br />
                                        You may be subject to and responsible for any
                                        and all blockchain network fees for on-chain
                                        transfers.
</p> */}
                                    </div>
                                    <div className='center-div' style={{width:'44%'}}>
                                     {!connectedWallet && (
        <button
          className="cb-button base primary"
          onClick={handleConnectWalletClick}
          style={{width:'290px'}}
          
        >
          Connect Metamask Wallet
        </button>
      )}
     {connectedWallet && (
        <>
             <div className="cb-copy-to-clipboard base-variant mt-4">
  <div className="label">Amount</div>
  <div
                    className="cb-input floating-label no-label box-content h-16 w-40"
                    data-testid="input-container"
                  >
                    <div className="input-container">
                    <div style={{ position: 'relative', width: 'fit-content' }}> {/* Contêiner com posição relativa */}
                    <input
  id="cb-input-email"
  aria-labelledby="cb-input-email"
  aria-describedby=""
  className={`input w-64 ${(burnValue < 200 || burnValue > brlaBalance / Math.pow(10, 18)) && burnValue !== '' ? "error-border" : ""}`} // Adiciona a classe 'error-border' se burnValue for menor que 200 ou maior que brlaBalance e não for ''
  data-testid="input-email"
  defaultValue="0"
  name=""
  onChange={handleBurnInputChange}
  value={burnValue}
  type="text" // Altere o tipo para "text"
/>

  {(burnValue < 200 || burnValue > brlaBalance / Math.pow(10, 18)) && burnValue !== '' && (  // Se burnValue for menor que 200 ou maior que brlaBalance e não for '', renderiza a mensagem de erro
    <span style={{ 
        color: "red", 
        position: 'absolute', // Posiciona absolutamente em relação ao contêiner
        right: '0px', // Ajuste este valor conforme necessário para mover a mensagem de erro para a esquerda ou direita
        top: '45px', // Ajuste este valor conforme necessário para mover a mensagem de erro para cima ou para baixo
        fontSize: "0.8em"
    }}>
      {burnValue < 200 ? "The minimum burn value is 200 BRLA" : "You do not have enough BRLA"}
    </span>
  )}
</div>


                    </div>
                  </div>
</div>        
<div className="available-info" style={{ display: 'flex', alignItems: 'center', width:'400px' }}>
  <small>Available: {(brlaBalance / Math.pow(10, 18)).toFixed(2)} BRLA</small>
  <button style={{marginLeft:'10px', color:'#00dc84'}} className="max-button" onClick={handleMaxClick}>
    Max
  </button>
</div>

          <button 
    disabled={!selectedBank || burnValue <= 0 || burnValue < 200 || burnValue > brlaBalance / Math.pow(10, 18)} 
    className="cb-button base primary" 
    onClick={handleBurnClick}
    style={{width:'290px'}}
>
    Sell
</button>
        </>
      )}
        </div>  

        <p style={{display:'flex', textAlign:'center'}}  className="text-sm leading-5 text-black-600 font-circular-regular">
 BRLA is not a bank; your Account is not a bank account.
  <br/>
  Be sure your metamask is connect to a registred wallet in your account, otherwise the connection won´t work.
</p>
                      

                                  </div>
               
                          
                                </div>
                                <button
                                  className="cb-button base secondary w-60"
                                  type="button"
                                  onClick={handleDoneClick}
                                  

                               
                                >
                                  <span>Back</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              data-headlessui-focus-guard="true"
              aria-hidden="true"
              style={{
                position: "fixed",
                top: 1,
                left: 1,
                width: 1,
                height: 0,
                padding: 0,
                margin: "-1px",
                overflow: "hidden",
                clip: "rect(0px, 0px, 0px, 0px)",
                whiteSpace: "nowrap",
                borderWidth: 0
              }}
            />
          </div>
        </div>
        <ToastContainer />
      </>
      )
};
export default BurnFiat;