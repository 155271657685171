import logocorpoImage from '../Home/images/logo_corpo.png';
import React, { useState ,useEffect} from 'react';
import { useSelect } from "downshift";
import Web3 from 'web3';
import { Buffer } from "buffer/"
import { useForm } from 'react-hook-form';
import jwt_decode from 'jwt-decode';
import { ENDPOINT} from '../../variaveis';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';
function AddWallet() { 
  const [isLoading, setIsLoading] = useState(false);  
const navigate = useNavigate();
 
  const [user, setUser] = useState({
    email: '',
    exp: '',
    firstName: '',
    iat: '',
    isSuperuser: false,
    lastName: '',
    sub: '',
    taxId:''
  });

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({ mode: 'onChange' });

    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedBlockchain, setSelectedBlockchain] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const blockchains = [
        {
          id: 0,
          name: 'Algorand',
          logoComponent: (
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 113 113.4"
              className="cb-icon cb-icon-algo mr-2 pointer-events-none"
              aria-hidden="true"
              data-testid="icon-algo"
              focusable="false"
              role="img"
            >
              <path d="M19.6 113.4 36 85l16.4-28.3 16.3-28.4 2.7-4.5 1.2 4.5 5 18.7-5.6 9.7L55.6 85l-16.3 28.4h19.6L75.3 85l8.5-14.7 4 14.7 7.6 28.4H113L105.4 85l-7.6-28.3-2-7.3L108 28.3H90.2l-.6-2.1L83.4 3l-.8-3H65.5l-.4.6-16 27.7-16.4 28.4L16.4 85 0 113.4h19.6z" />
            </svg>
          ),
        },

        {
            id: 1,
            name: 'Etherium',
            logoComponent: (
                <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1920 1920"
                xmlSpace="preserve"
                className="cb-icon cb-icon-eth mr-2 pointer-events-none"
                aria-hidden="true"
                data-testid="icon-eth"
                focusable="false"
                role="img"
              >
                <path fill="#8A92B2" d="M959.8 80.7 420.1 976.3 959.8 731z" />
                <path
                  fill="#62688F"
                  d="M959.8 731 420.1 976.3l539.7 319.1zM1499.6 976.3 959.8 80.7V731z"
                />
                <path fill="#454A75" d="m959.8 1295.4 539.8-319.1L959.8 731z" />
                <path fill="#8A92B2" d="m420.1 1078.7 539.7 760.6v-441.7z" />
                <path fill="#62688F" d="M959.8 1397.6v441.7l540.1-760.6z" />
              </svg>
            ),
          },

          {
            id: 2,
            name: 'Polygon',
            logoComponent: (
                <svg
          width={16}
          height={16}
          viewBox="0 0 38.4 33.5"
          xmlns="http://www.w3.org/2000/svg"
          className="cb-icon cb-icon-matic mr-2 pointer-events-none"
          aria-hidden="true"
          data-testid="icon-matic"
          focusable="false"
          role="img"
        >
          <path
            fill="#8247E5"
            d="M29 10.2c-.7-.4-1.6-.4-2.4 0L21 13.5l-3.8 2.1-5.5 3.3c-.7.4-1.6.4-2.4 0L5 16.3c-.7-.4-1.2-1.2-1.2-2.1v-5c0-.8.4-1.6 1.2-2.1l4.3-2.5c.7-.4 1.6-.4 2.4 0L16 7.2c.7.4 1.2 1.2 1.2 2.1v3.3l3.8-2.2V7c0-.8-.4-1.6-1.2-2.1l-8-4.7c-.7-.4-1.6-.4-2.4 0L1.2 5C.4 5.4 0 6.2 0 7v9.4c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l5.5-3.2 3.8-2.2 5.5-3.2c.7-.4 1.6-.4 2.4 0l4.3 2.5c.7.4 1.2 1.2 1.2 2.1v5c0 .8-.4 1.6-1.2 2.1L29 28.8c-.7.4-1.6.4-2.4 0l-4.3-2.5c-.7-.4-1.2-1.2-1.2-2.1V21l-3.8 2.2v3.3c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l8.1-4.7c.7-.4 1.2-1.2 1.2-2.1V17c0-.8-.4-1.6-1.2-2.1L29 10.2z"
          />
        </svg>
            ),
          },

          {
            id: 3,
            name: 'Solana',
            logoComponent: (
                <svg
                width={16}
                height={16}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 397.7 311.7"
                xmlSpace="preserve"
                className="cb-icon cb-icon-sol mr-2 pointer-events-none"
                aria-hidden="true"
                data-testid="icon-sol"
                focusable="false"
                role="img"
              >
                <linearGradient
                  id="SOL_svg__a"
                  gradientUnits="userSpaceOnUse"
                  x1="360.879"
                  y1="351.455"
                  x2="141.213"
                  y2="-69.294"
                  gradientTransform="matrix(1 0 0 -1 0 314)"
                >
                  <stop offset={0} style={{ stopColor: "rgb(0, 255, 163)" }} />
                  <stop offset={1} style={{ stopColor: "rgb(220, 31, 255)" }} />
                </linearGradient>
                <path
                  fill="url(#SOL_svg__a)"
                  d="M64.6 237.9c2.4-2.4 5.7-3.8 9.2-3.8h317.4c5.8 0 8.7 7 4.6 11.1l-62.7 62.7c-2.4 2.4-5.7 3.8-9.2 3.8H6.5c-5.8 0-8.7-7-4.6-11.1l62.7-62.7z"
                />
                <linearGradient
                  id="SOL_svg__b"
                  gradientUnits="userSpaceOnUse"
                  x1="264.829"
                  y1="401.601"
                  x2="45.163"
                  y2="-19.148"
                  gradientTransform="matrix(1 0 0 -1 0 314)"
                >
                  <stop offset={0} style={{ stopColor: "rgb(0, 255, 163)" }} />
                  <stop offset={1} style={{ stopColor: "rgb(220, 31, 255)" }} />
                </linearGradient>
                <path
                  fill="url(#SOL_svg__b)"
                  d="M64.6 3.8C67.1 1.4 70.4 0 73.8 0h317.4c5.8 0 8.7 7 4.6 11.1l-62.7 62.7c-2.4 2.4-5.7 3.8-9.2 3.8H6.5c-5.8 0-8.7-7-4.6-11.1L64.6 3.8z"
                />
                <linearGradient
                  id="SOL_svg__c"
                  gradientUnits="userSpaceOnUse"
                  x1="312.548"
                  y1="376.688"
                  x2="92.882"
                  y2="-44.061"
                  gradientTransform="matrix(1 0 0 -1 0 314)"
                >
                  <stop offset={0} style={{ stopColor: "rgb(0, 255, 163)" }} />
                  <stop offset={1} style={{ stopColor: "rgb(220, 31, 255)" }} />
                </linearGradient>
                <path
                  fill="url(#SOL_svg__c)"
                  d="M333.1 120.1c-2.4-2.4-5.7-3.8-9.2-3.8H6.5c-5.8 0-8.7 7-4.6 11.1l62.7 62.7c2.4 2.4 5.7 3.8 9.2 3.8h317.4c5.8 0 8.7-7 4.6-11.1l-62.7-62.7z"
                />
              </svg>
            ),
          },


        // Adicione os outros logotipos de forma semelhante
      ];
      

    
      const handleItemClick = (blockchain) => {
        setSelectedBlockchain(blockchain);
        setIsOpen(false); // Fecha a lista suspensa ao selecionar um item
      };
    
      const toggleMenu = () => {
        setIsOpen(!isOpen);
      };


      
      
      
      const handleConnectWallet = async (data) => {
        try {
          setIsLoading(true); 
          const response = await fetch(`${ENDPOINT}/wallets`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
              walletAddress: data.walletaddress,
              name: data.nickname,
              chain: selectedBlockchain.name,
            }),
          });
      
          console.log('Fetch completed'); 
          setIsLoading(false); 
      
          if (response.ok) {
            console.log('Response OK');
      
            // Buscar novamente as informações da carteira para ter certeza que estão atualizadas
            const resWallets = await fetch(`${ENDPOINT}/wallets`, {
              method: 'GET',
              credentials: 'include',
            });
      
            const bodyWallets = await resWallets.json();
            localStorage.setItem('wallets', JSON.stringify(bodyWallets.wallets || []));
      
            toast.success('Wallet successfully added to your account.');
            setTimeout(() => {
              navigate('/blockchain-address');
            }, 3000); 
          } else if (response.status === 401) {
            navigate('/login'); // Redireciona para a página de login se o status for 401
          } else {
            console.log('Response not OK');
            const errorData = await response.json();
            console.log(errorData);
            toast.error(`Failed to add wallet: ${errorData.error || 'An unknown error occurred.'}`);
          }
        } catch (error) {
          console.error('Error making request:', error);
          toast.error(`Error making request: ${error.message || 'An unknown error occurred.'}`);
        }
      };
        


      
    const handleBackClick = () => {
      navigate('/wallet')
    };
    
      
      
    
    

    return(
<>
   
   
    <div id="headlessui-portal-root">
      <div data-headlessui-portal="">
        <button
          type="button"
          data-headlessui-focus-guard="true"
          aria-hidden="true"
          style={{
            position: "fixed",
            top: 1,
            left: 1,
            width: 1,
            height: 0,
            padding: 0,
            margin: "-1px",
            overflow: "hidden",
            clip: "rect(0px, 0px, 0px, 0px)",
            whiteSpace: "nowrap",
            borderWidth: 0
          }}
        />
        <div>
          <div
            className="cb-modal"
            data-segmentscreenname="address_book_add_address_customer"
            id="headlessui-dialog-:r7:"
            role="dialog"
            aria-modal="true"
            data-headlessui-state="open"
          >
            <div className="modal-container">
              <div
                className="overlay opacity-100"
                data-testid="overlay"
                id="headlessui-dialog-overlay-:r8:"
                aria-hidden="true"
                data-headlessui-state="open"
              />
              <div
                className="modal-content screen opacity-100 scale-100"
                data-testid="content"
              >
                <div className="cb-modal-coolwhip">
                  
                </div>
                <div className="h-screen overflow-x-auto">
                  <div className="grid grid-rows-auto-1">
                    <div className="sticky top-0 bg-white z-10">
                      <div className="grid grid-cols-3 py-5 px-9">
                        <div className="flex items-center justify-start">
                          <button data-testid="back-button">
                            
                          </button>
                        </div>
                        <div
                          className="flex items-center justify-center"
                          data-testid="circle-logo"
                        >
                         <img src={logocorpoImage} style={{width:'60px', height:'60px'}}></img>
                        </div>
                        <div className="flex items-center justify-end">
                          <button
                            className="cb-modal-basic-close top-right relative inset-0"
                            data-testid="close-button"
                            style={{color:'black'}}
                            onClick={handleBackClick}
                          >
                            <svg
                              width={16}
                              height={16}
                              viewBox="0 0 14 14"
                              fill="currentColor"
                              xmlns="http://www.w3.org/2000/svg"
                              className="cb-icon cb-icon-cross pointer-events-none"
                              aria-hidden="true"
                              data-testid="icon-cross"
                              focusable="false"
                              role="img"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M.293.293a1 1 0 0 1 1.414 0L7 5.586 12.293.293a1 1 0 1 1 1.414 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L5.586 7 .293 1.707a1 1 0 0 1 0-1.414Z"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      <div className="w-full h-1 bg-black-75">
                        <div
                          aria-valuemax={100}
                          aria-valuemin={0}
                          aria-valuenow="33.33333333333333"
                          className="h-full bg-blue-300 transition-width duration-100"
                          role="progressbar"
                          style={{ width: "33.3333%" }}
                        />
                      </div>
                    </div>
                    <div
                      className="cb-modal-body default relative pt-16 pb-20 font-circular"
                      data-testid="modal-body"
                    >
                      <div>
                        <div
                          className="grid h-full mb-6 text-center justify-center"
                          data-testid="center-horizontal"
                        >
                          <h2
                            className="text-2xl text-black-900 font-circular-bold"
                            data-testid="address-details-step-title"
                          >
                            Add wallet address
                          </h2>
                          <h3
                            className="mt-2 text-lg font-circular leading-6 text-black-400"
                            data-testid="address-details-step-subtitle"
                          >
                            Please provide the details of your wallet address
                          </h3>
                        </div>
                        <div
                          className="grid h-full content-center items-center justify-center"
                          data-testid="center-both"
                        >
                          <form
                            className="grid w-96 gap-y-6"
                            data-testid="address-details-form"
                            onSubmit={handleSubmit(handleConnectWallet)}
                          >
                          
                            <div
                              className="cb-input floating-label  w-full"
                              data-testid="input-container"
                            >
                              <div className="input-container">
                                <input
                                  aria-describedby=""
                                  aria-labelledby="input-fcf4a4bf-27d6-4f90-a69f-7772fd17327e"
                                  className="input"
                                  data-testid="input-description"
                                  id="input-fcf4a4bf-27d6-4f90-a69f-7772fd17327e"
                                  placeholder="Wallet nickname"
                                  type="text"
                                  defaultValue=""
                                  name="nickname"
                                  {...register('nickname', { required: true })}
                                />
                                
                              </div>
                            </div>

                           <div
                              className="cb-dropdown drop-direction-down w-full"
                              data-testid="dropdown-blockchain"
                              title="Select blockchain"
                              name="blockchain"
                            >
                            
                              <div
  className="dropdown-container"
  
>
<button
  className="toggle labeled"
  type="button"
  id="downshift-26-toggle-button"
  aria-haspopup="listbox"
  aria-expanded="false"
  aria-labelledby="downshift-26-label downshift-26-toggle-button"
  onClick={() => toggleMenu()}
 
>
  {selectedBlockchain ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>{selectedBlockchain.logoComponent}</div>
      <span style={{ marginLeft: "8px" }}>{selectedBlockchain.name}</span>
    </div>
  ) : (
    "Select blockchain"
  )}
                                <svg
                                  width={6}
                                  height={6}
                                  viewBox="0 0 10 6"
                                  fill="currentColor"
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="cb-icon cb-icon-chevrondown caret pointer-events-none"
                                  aria-hidden="true"
                                  data-testid="icon-chevrondown"
                                  focusable="false"
                                  role="img"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M.293.293a1 1 0 0 1 1.414 0L5 3.586 8.293.293a1 1 0 1 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 0-1.414Z"
                                  />
                                </svg>
                              </button>
                             
                            </div>
                            {
  isOpen && (
                            <div className="menu"  style={{
      position: 'absolute',
      backgroundColor: 'white',
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
      zIndex: 1,
      overflowY: 'auto',
      maxHeight: '200px', // Altura máxima da lista suspensa
      width: '100%',
      marginTop: '8px',
    }}
  >
  <ul
    className="dropdown-scroll-container max-menu-items-6 show-overflow-bottom"
    id="downshift-48-menu"
    role="listbox"
    aria-labelledby="downshift-48-label"
    tabIndex={-1}
  >
   {/* <li
      className="item"
      role="option"
      aria-selected="false"
      id="downshift-48-item-0"
      onClick={() => handleItemClick(blockchains[0])}
      
      
    >
      <span className="flex items-center">
        <svg
          width={16}
          height={16}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 113 113.4"
          className="cb-icon cb-icon-algo mr-2 pointer-events-none"
          aria-hidden="true"
          data-testid="icon-algo"
          focusable="false"
          role="img"
        >
          <path d="M19.6 113.4 36 85l16.4-28.3 16.3-28.4 2.7-4.5 1.2 4.5 5 18.7-5.6 9.7L55.6 85l-16.3 28.4h19.6L75.3 85l8.5-14.7 4 14.7 7.6 28.4H113L105.4 85l-7.6-28.3-2-7.3L108 28.3H90.2l-.6-2.1L83.4 3l-.8-3H65.5l-.4.6-16 27.7-16.4 28.4L16.4 85 0 113.4h19.6z" />
        </svg>
        Algorand
      </span>
  </li> */}
   
   {/* <li
      className="item"
      role="option"
      aria-selected="false"
      id="downshift-48-item-2"
      onClick={() => handleItemClick(blockchains[1])}
    >
      <span className="flex items-center">
        <svg
          width={16}
          height={16}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1920 1920"
          xmlSpace="preserve"
          className="cb-icon cb-icon-eth mr-2 pointer-events-none"
          aria-hidden="true"
          data-testid="icon-eth"
          focusable="false"
          role="img"
        >
          <path fill="#8A92B2" d="M959.8 80.7 420.1 976.3 959.8 731z" />
          <path
            fill="#62688F"
            d="M959.8 731 420.1 976.3l539.7 319.1zM1499.6 976.3 959.8 80.7V731z"
          />
          <path fill="#454A75" d="m959.8 1295.4 539.8-319.1L959.8 731z" />
          <path fill="#8A92B2" d="m420.1 1078.7 539.7 760.6v-441.7z" />
          <path fill="#62688F" d="M959.8 1397.6v441.7l540.1-760.6z" />
        </svg>
        Ethereum
      </span>
  </li> */}
    
    <li
      className="item"
      role="option"
      aria-selected="false"
      id="downshift-48-item-5"
      onClick={() => handleItemClick(blockchains[2])}
    >
      <span className="flex items-center">
        <svg
          width={16}
          height={16}
          viewBox="0 0 38.4 33.5"
          xmlns="http://www.w3.org/2000/svg"
          className="cb-icon cb-icon-matic mr-2 pointer-events-none"
          aria-hidden="true"
          data-testid="icon-matic"
          focusable="false"
          role="img"
        >
          <path
            fill="#8247E5"
            d="M29 10.2c-.7-.4-1.6-.4-2.4 0L21 13.5l-3.8 2.1-5.5 3.3c-.7.4-1.6.4-2.4 0L5 16.3c-.7-.4-1.2-1.2-1.2-2.1v-5c0-.8.4-1.6 1.2-2.1l4.3-2.5c.7-.4 1.6-.4 2.4 0L16 7.2c.7.4 1.2 1.2 1.2 2.1v3.3l3.8-2.2V7c0-.8-.4-1.6-1.2-2.1l-8-4.7c-.7-.4-1.6-.4-2.4 0L1.2 5C.4 5.4 0 6.2 0 7v9.4c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l5.5-3.2 3.8-2.2 5.5-3.2c.7-.4 1.6-.4 2.4 0l4.3 2.5c.7.4 1.2 1.2 1.2 2.1v5c0 .8-.4 1.6-1.2 2.1L29 28.8c-.7.4-1.6.4-2.4 0l-4.3-2.5c-.7-.4-1.2-1.2-1.2-2.1V21l-3.8 2.2v3.3c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l8.1-4.7c.7-.4 1.2-1.2 1.2-2.1V17c0-.8-.4-1.6-1.2-2.1L29 10.2z"
          />
        </svg>
        Polygon
      </span>
    </li>
 {/*   <li
      className="item"
      role="option"
      aria-selected="false"
      id="downshift-48-item-6"
      onClick={() => handleItemClick(blockchains[3])}
    >
      <span className="flex items-center">
        <svg
          width={16}
          height={16}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 397.7 311.7"
          xmlSpace="preserve"
          className="cb-icon cb-icon-sol mr-2 pointer-events-none"
          aria-hidden="true"
          data-testid="icon-sol"
          focusable="false"
          role="img"
        >
          <linearGradient
            id="SOL_svg__a"
            gradientUnits="userSpaceOnUse"
            x1="360.879"
            y1="351.455"
            x2="141.213"
            y2="-69.294"
            gradientTransform="matrix(1 0 0 -1 0 314)"
          >
            <stop offset={0} style={{ stopColor: "rgb(0, 255, 163)" }} />
            <stop offset={1} style={{ stopColor: "rgb(220, 31, 255)" }} />
          </linearGradient>
          <path
            fill="url(#SOL_svg__a)"
            d="M64.6 237.9c2.4-2.4 5.7-3.8 9.2-3.8h317.4c5.8 0 8.7 7 4.6 11.1l-62.7 62.7c-2.4 2.4-5.7 3.8-9.2 3.8H6.5c-5.8 0-8.7-7-4.6-11.1l62.7-62.7z"
          />
          <linearGradient
            id="SOL_svg__b"
            gradientUnits="userSpaceOnUse"
            x1="264.829"
            y1="401.601"
            x2="45.163"
            y2="-19.148"
            gradientTransform="matrix(1 0 0 -1 0 314)"
          >
            <stop offset={0} style={{ stopColor: "rgb(0, 255, 163)" }} />
            <stop offset={1} style={{ stopColor: "rgb(220, 31, 255)" }} />
          </linearGradient>
          <path
            fill="url(#SOL_svg__b)"
            d="M64.6 3.8C67.1 1.4 70.4 0 73.8 0h317.4c5.8 0 8.7 7 4.6 11.1l-62.7 62.7c-2.4 2.4-5.7 3.8-9.2 3.8H6.5c-5.8 0-8.7-7-4.6-11.1L64.6 3.8z"
          />
          <linearGradient
            id="SOL_svg__c"
            gradientUnits="userSpaceOnUse"
            x1="312.548"
            y1="376.688"
            x2="92.882"
            y2="-44.061"
            gradientTransform="matrix(1 0 0 -1 0 314)"
          >
            <stop offset={0} style={{ stopColor: "rgb(0, 255, 163)" }} />
            <stop offset={1} style={{ stopColor: "rgb(220, 31, 255)" }} />
          </linearGradient>
          <path
            fill="url(#SOL_svg__c)"
            d="M333.1 120.1c-2.4-2.4-5.7-3.8-9.2-3.8H6.5c-5.8 0-8.7 7-4.6 11.1l62.7 62.7c2.4 2.4 5.7 3.8 9.2 3.8h317.4c5.8 0 8.7-7 4.6-11.1l-62.7-62.7z"
          />
        </svg>
        Solana
      </span>
</li> */}
    
  </ul>
</div>
)
}
</div>  
                            
                           <div
                              className="cb-input floating-label  w-full"
                              data-testid="input-container"
                            >
                              <div className="input-container">
                                <input
                                  aria-describedby=""
                                  aria-labelledby="input-a0821c77-91df-4c84-ae30-edb28734c42d"
                                  className="input"
                                  data-testid="input-address"
                                  id="input-a0821c77-91df-4c84-ae30-edb28734c42d"
                                  placeholder="Wallet Address"
                                  type="text"
                                  defaultValue=""
                                  {...register('walletaddress', { required: true })}
                                />
                                
                              </div>
</div>  
                          
                            <div
                              className="cb-modal-footer padded center"
                              data-testid="modal-footer"
                            >
                              <button
                                className="cb-button base primary w-64"
                                disabled={isLoading}
                                type="submit"
                                data-testid="button-submit"
                                
                              
                              >
                                <span>{isLoading ? "Loading..." : "Connect"}</span>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          data-headlessui-focus-guard="true"
          aria-hidden="true"
          style={{
            position: "fixed",
            top: 1,
            left: 1,
            width: 1,
            height: 0,
            padding: 0,
            margin: "-1px",
            overflow: "hidden",
            clip: "rect(0px, 0px, 0px, 0px)",
            whiteSpace: "nowrap",
            borderWidth: 0
          }}
        />
      </div>
    </div>
    <ToastContainer />
  </>
  )};
  export default AddWallet;