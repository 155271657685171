import { memo, useState } from "react"

import resets from "./_resets.module.css"
import { FrameIcon2 } from "./FrameIcon2.js"
import { FrameIcon } from "./FrameIcon.js"
import classes from "./IpadHome.module.css"
import { useTranslation } from 'react-i18next';
import brazilFlag from './images/brazil.png'; // import your flag images
import usaFlag from './images/usa.png';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
declare var grecaptcha: any;
/* @figmaId 155:548 */
function IpadHome () {
  const { t, i18n} = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [message, setMessage] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setSelectedLanguage(lng);
  };


  function onSubmit(e) {
    e.preventDefault();
  
    grecaptcha.ready(function() {
      grecaptcha.execute('6LdIKIQmAAAAABNZn-t5ilym4wSfgsAkLuzBtn00', {action: 'submit'}).then(function(token) {
        const data = {
          name: name,
          email: email,
          phone: phone,
          company: company,
          message: message,
          captcha: token
        };
  
        fetch('https://api.brla.digital:4567/v1/contact-us', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        })
        .then(response => {
          if (response.ok) {
            toast.success('Message sent successfully. We will respond soon.', {
              autoClose: 6000
            });
            setTimeout(() => window.location.reload(), 7000);
          } else {
            throw new Error('Failed to send message');
          }
        })
        .catch((error) => {
          console.error(error);
          toast.error('Failed to send message. Please try again.', {
            autoClose: 6000
          });
          setTimeout(() => window.location.reload(), 7000);
        });
      });
    });
  }


  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.header}>
        <div style={{marginLeft:'-15px'}} className={classes.logo}></div>
        <div className={classes.ulMainNavigation}>
  <a href='/'>
    <div className={classes.home}>Home</div>
  </a>
  <div className={classes.dropdown}>
    <div className={classes.about}>{t('menu_about')}</div>
    <div className={classes.dropdownContent}>
      <a href='/about-brla-token'>BRLA Token</a>
      <a href='/about-brla-digital'>BRLA Digital</a>
    </div>
  </div>
  <a href='/login'>
    <div className={classes.login}>Login</div>
  </a>
  <a href='signup'>
    <div className={classes.sIGNUP}>{t('menu_sign')}</div>
  </a>
  <a href='https://brla-superuser-api.readme.io/reference/welcome' target='_blank'>
    <div className={classes.aPIREFERENCE}>{t('menu_api')}</div>
  </a>
  <a href='#contact'>
    <div className={classes.cONTACT}>{t('menu_contato')}</div>
  </a>
  

  <div>
      <img 
        src={brazilFlag} 
        alt="Portuguese" 
        onClick={() => changeLanguage('ptbr')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'ptbr' ? 1 : 0.5, width:'30px', height:'30px', marginRight:'20px' }} 

      />
      <img 
        src={usaFlag} 
        alt="English" 
        onClick={() => changeLanguage('en')} 
        style={{ cursor: 'pointer', opacity: selectedLanguage === 'en' ? 1 : 0.5, width:'30px', height:'30px' }} 
      />
    </div>


</div>
      </div>
      <div className={classes.section1}>
        <div className={classes.frame21}>
          <div className={classes.frame19}>
            <div className={classes.brazilianFinancialInfrastructu}>
              {t('brazilian_financial')}
            </div>
            <div className={classes.weHaveDevelopedFinancialSoluti}>
              {t('brazilian_financial_bottom')}
            </div>
            <div className={classes.frame18}>
              <div className={classes.frame2}>
               
                <div className={classes.checkOurSDKDocumentation}>
                <a href="https://brla-superuser-api.readme.io/reference/welcome" target="_blank" style={{color:'white'}}>
                  {t('call_apis')}
                  </a>
                </div>
                
              </div>
              <div className={classes.frame1}>
                <div className={classes.openYourAccount}>
                    <a href="/signup" style={{color:'white'}}>
                      {t('call_brla')}
                    </a>
                    </div>
              </div>
              <div className={classes.frame3}>
                <div className={classes.contactUs}>
                  <a href="#contact"  style={{color:'white'}}>
                    {t('call_contact')}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.frame20}>
            <div className={classes.tela_home}></div>
            <div className={classes.tela_signup}></div>
            <div className={classes.tela_swap}></div>
          </div>
        </div>
      </div>
      <div className={classes.section2}>
        <div className={classes.frame22}>
          <div className={classes.onOffRampPoweredByBRLA}>
            {t('on_off')}
          </div>
          <div className={classes.byUsingOurSDKGlobalCompaniesCa}>
            {t('on_off_bottom')}
          </div>
          <div className={classes.frame12}>
            <div className={classes.aPIReference}>
            <a href="https://brla-superuser-api.readme.io/reference/welcome" target="_blank" style={{color:'black'}}>
                {t('call_apis')}
                </a>
                </div>
          </div>
        </div>
        <div className={classes.frame23}>
          <div className={classes.mint_BRLA}></div>
          <div className={classes.qR_Code}></div>
        </div>
      </div>
      <div className={classes.section3}>
        <div className={classes.frame25}>
          <div className={classes.swap}></div>
        </div>
        <div className={classes.frame24}>
          <div className={classes.createProductsInBRLA}>
            {t('create_products')}
          </div>
          <div className={classes.globalCompaniesCanUseOurSDKToI}>
            {t('create_products_bottom')}
          </div>
          <div className={classes.frame13}>
          <a href="https://brla-superuser-api.readme.io/reference/welcome" target="_blank" style={{color:'white'}}>
            <div className={classes.aPIReference2}>{t('call_apis')}</div>
            </a>
          </div>
        </div>
      </div>
      <div className={classes.section4}>
        <div className={classes.frame27}>
          <div className={classes.bRLAAccount}>{t('brla_account')}</div>
          <div className={classes.byHavingABRLAAccountGlobalComp}>
            <div className={classes.textBlock}>
              {t('brla_account_bt1')}
            </div>
            <ul className={classes.list}>
              <li>
              <br/>
                 
                <div className={classes.textBlock2}>
                 
                  {t('brla_account_bt2')}
                  
                </div>
              </li>
              <li>
                <div className={classes.textBlock3}>
                  {t('brla_account_bt3')}
                </div>
              </li>
              <li>
                <div className={classes.textBlock4}>
                  {t('brla_account_bt4')}
                </div>
              </li>
            </ul>
          </div>
          <div className={classes.frame26}>
            <div className={classes.frame14}>
           
              <div className={classes.openYourAccount2}>
              <a href="/signup" style={{color:'black'}}>{t('call_brla')}</a></div>
              
            </div>
            <div className={classes.frame28}>
              <div className={classes.contactUs2}>
                <a href="#contact" style={{color:'black'}}>
                  {t('call_contact')}
                </a>
                </div>
            </div>
          </div>
        </div>
        <div className={classes.frame282}>
          <div className={classes.tela_home2}></div>
        </div>
      </div>
      <div className={classes.section5}>
        <div className={classes.frame29}>
          <div className={classes.tela_signup2}></div>
        </div>
        <div className={classes.frame30}>
          <div className={classes.areYouAnIndividual}>
            {t('individual')}
          </div>
          <div className={classes.youCanAlsoCreateYourBRLAAccoun}>
            {t('individual_bt')}
          </div>
          <div className={classes.frame15}>
            <div className={classes.openYourAccount3}>
                <a href="/signup" style={{color:'white'}}>
                  {t('call_brla')}
                </a>
                </div>
          </div>
        </div>
      </div>
      <div id="contact" className={classes.footerFooter}>
        <div className={classes.contact}>{t('call_contact')}</div>
        <div className={classes.divRow}>
        <form className={classes.form} onSubmit={onSubmit}>
      <div className={classes.frame32}>
        <div className={classes.inputMobileWidth}>
          <input
            className={classes.divPlaceholder}
            type="text"
            placeholder={t('name')}
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </div>
        <div className={classes.inputMobileWidth2}>
          <input
            className={classes.divPlaceholder2}
            type="email"
            placeholder={t('email')}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div className={classes.frame33}>
        <div className={classes.inputMobileWidth3}>
          <input
            className={classes.divPlaceholder3}
            type="tel"
            placeholder={t('phone')}
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className={classes.inputMobileWidth4}>
          <input
            className={classes.divPlaceholder4}
            type="text"
            placeholder={t('company')}
            value={company}
            onChange={e => setCompany(e.target.value)}
          />
        </div>
      </div>
      <textarea
        className={classes.textarea}
        placeholder={t('message')}
        value={message}
        onChange={e => setMessage(e.target.value)}
      ></textarea>
      <div className={classes.frame34}>
        <button className={classes.button} type="submit">
          <div className={classes.submit}>{t('submit')}</div>
        </button>
      </div>
    </form>
          <div className={classes.divHs_cos_wrapper_module_16619}>
            <div className={classes.followBRLAOnSocial}>
              {t('follow_social')}
            </div>
            <div className={classes.divSocialGrid}>
              <div className={classes.frame}>
                <a href="https://twitter.com/BRLAdigital" target="_blank">
                <FrameIcon className={classes.icon} />
                </a>
              </div>
              <div className={classes.frame4}>
                <a href="https://www.linkedin.com/company/brla-digital/" target="_blank">
                <FrameIcon2 className={classes.icon2} />
                </a>
              </div>
            </div>
            <div className={classes.eMailInfoBrlaDigital}>
              E-mail: info@brla.digital
            </div>
            <div className={classes.phone55113777742}>
              {t('phone')}: +55 11 3777-0742
            </div>
            <div className={classes.addressRuaUrussui125Cj84SaoPau}>
              {t('address')}: Rua Urussui, 125 cj. 84 - São Paulo
            </div>
          </div>
        </div>
        <div className={classes.divRow2}>
          <div className={classes.frame31}>
            <div className={classes.logocoin}></div>
          </div>
          <div className={classes.footer}>
            <div className={classes.digitalAssetsAreSubjectToANumb}>
              {t('disclaimer')}
            </div>
            <div className={classes._2023BRLADigital}>© 2023 BRLA Digital</div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
};
export default IpadHome
